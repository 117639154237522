import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { UserOrderEvent } from 'mobile-web/components/post-checkout';
import { getHandoffMode } from 'mobile-web/lib/order-criteria';
import OrderModel from 'mobile-web/models/order';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';

import style from './index.m.scss';

interface Args {
  order: OrderModel;
}

interface Signature {
  Args: Args;
}

export default class CheckInRoute extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get analyticsProperties() {
    return {
      [AnalyticsProperties.BasketHandoffMode]: getHandoffMode(this.args.order.deliveryMode),
    };
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onInsert() {
    this.analytics.trackEvent(AnalyticsEvents.UserFiredOrderEvent, () => ({
      [AnalyticsProperties.OrderEvent]: UserOrderEvent.CheckIn,
      ...this.analyticsProperties,
    }));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::CheckInRoute': typeof CheckInRoute;
  }
}
