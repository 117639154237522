import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import { computedLocal } from 'mobile-web/lib/computed';
import dayjs from 'mobile-web/lib/dayjs';
import {
  HandoffCriteria,
  HandoffMode,
  isAtStoreMode,
  isDeliveryMode,
  OrderCriteria,
  TimeWantedCriteria,
  TimeWantedType,
  UnspecifiedTimeCriteria,
} from 'mobile-web/lib/order-criteria';
import isSome from 'mobile-web/lib/utilities/is-some';
import AnalyticsServices, { AnalyticsEvents } from 'mobile-web/services/analytics';
import * as OrderCriteriaService from 'mobile-web/services/order-criteria';
import VendorSearchService, { VendorSearchResult } from 'mobile-web/services/vendor-search';

export type VendorSearchParams = {
  addressId?: string;
  address?: string;
  building?: string;
  city?: string;
  zipCode?: string;
  handoffMode?: HandoffMode;
  timeWantedType?: TimeWantedType;
  timeWanted?: string;
  latitude?: string | number;
  longitude?: string | number;
};

export default class VendorSearchResultsRoute extends Route {
  // Service injections
  @service orderCriteria!: OrderCriteriaService.default;
  @service router!: RouterService;
  @service analytics!: AnalyticsServices;
  @service vendorSearch!: VendorSearchService;

  // Untracked properties
  @computedLocal
  localVendorSearchParams?: VendorSearchParams;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Route Model methods
  beforeModel(transition: Transition) {
    const criteria = this.criteriaFromVendorSearchParams(this.localVendorSearchParams ?? {});
    if (criteria) {
      this.orderCriteria.searchOrderCriteria = criteria;
    } else {
      transition.abort();
      this.router.transitionTo('application');
    }
  }

  model() {
    return this.vendorSearch.search(this.localVendorSearchParams);
  }

  afterModel(resolvedModel: VendorSearchResult) {
    if (resolvedModel.results?.length < 1) {
      this.analytics.trackEvent(AnalyticsEvents.NoLocationsFound);
    }
  }

  // Other methods
  private isValidHandoffCriteriaParams(queryParams: VendorSearchParams): boolean {
    if (isDeliveryMode(queryParams.handoffMode)) {
      // If Handoff Mode is Delivery, Handoff Criteria must include
      // Address, City and Zip Code
      return isSome(queryParams.address) && isSome(queryParams.city) && isSome(queryParams.zipCode);
    } else if (isAtStoreMode(queryParams.handoffMode)) {
      // If Handoff Mode is At Store, Handoff Criteria must include
      // an Address, or both Latitute AND Longitude
      if (isSome(queryParams.address)) {
        return true;
      } else if (isSome(queryParams.latitude) && isSome(queryParams.longitude)) {
        return true;
      }
      return false;
    }
    // If Handoff Mode is not Delivery or At Store, then it is invalid
    return false;
  }

  private criteriaFromVendorSearchParams(
    searchParams: VendorSearchParams
  ): OrderCriteria | undefined {
    const {
      addressId,
      address,
      building,
      city,
      zipCode,
      latitude,
      longitude,
      timeWanted: time,
      timeWantedType,
      handoffMode,
    } = searchParams;

    if (!this.isValidHandoffCriteriaParams(searchParams)) {
      return undefined;
    }

    let timeWanted: TimeWantedCriteria;
    if (timeWantedType === 'Advance') {
      timeWanted = {
        timeWantedType: 'Advance',
        timeWanted: dayjs(time),
      };
    } else {
      timeWanted = {
        timeWantedType,
      } as UnspecifiedTimeCriteria;
    }

    let handoffModeCriteria: HandoffCriteria;
    if (isDeliveryMode(handoffMode)) {
      handoffModeCriteria = {
        handoffMode,
        deliveryAddress: {
          id: addressId ?? '',
          streetAddress: address ?? '',
          building: building ?? '',
          city: city ?? '',
          zipCode: zipCode ?? '',
        },
      };
    } else if (isAtStoreMode(handoffMode)) {
      handoffModeCriteria = {
        handoffMode,
        searchAddress: latitude && longitude ? 'Your Current Location' : address ?? '',
        searchCoords:
          latitude && longitude
            ? { latitude: Number(latitude), longitude: Number(longitude) }
            : undefined,
      };
    } else {
      handoffModeCriteria = {
        handoffMode: 'Unspecified',
      };
    }

    return {
      ...handoffModeCriteria,
      ...timeWanted,
    };
  }

  // Tasks

  // Actions and helpers
  @action
  searchDidChange() {
    this.refresh();
  }
}
