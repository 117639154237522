import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Variant } from 'mobile-web/components/button';
import BusService from 'mobile-web/services/bus';
import { ContentString } from 'mobile-web/services/content';

import style from './index.m.scss';

type ConfirmArgs = {
  title: string;
  content: ContentString;
  buttonLabel: string;
  cancelLabel?: string;
  hideClose?: boolean;
  clickOutsideToClose?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
  testSelector?: string;
  buttonTestSelector?: string;
  cancelTestSelector?: string;
  hideCancelButton?: boolean;
  cancelButtonVariant?: Variant;
  confirmButtonVariant?: Variant;
  primaryCancelButtonPosition?: boolean;
};

declare module 'mobile-web/services/bus' {
  interface EventRegistry {
    confirm: ConfirmArgs;
  }
}

export default class ConfirmModal extends Component {
  // Service injections
  @service bus!: BusService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked confirmArgs?: ConfirmArgs;

  // Getters and setters
  get isOpen(): boolean {
    return !!this.confirmArgs;
  }

  get confirmButtonVariant(): Variant {
    return this.confirmArgs?.confirmButtonVariant ?? Variant.Destructive;
  }

  get cancelButtonVariant(): Variant {
    return this.confirmArgs?.cancelButtonVariant ?? Variant.Minor;
  }

  get primaryCancelButtonPosition(): boolean {
    return this.confirmArgs?.primaryCancelButtonPosition ?? false;
  }

  // Lifecycle methods
  constructor(owner: unknown, args: UnknownObject) {
    super(owner, args);

    this.bus.on('confirm', confirmArgs => {
      this.confirmArgs = confirmArgs;
    });
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  cancel() {
    this.confirmArgs?.onCancel?.();
    this.close();
  }

  @action
  confirm() {
    this.confirmArgs?.onConfirm?.();
    this.close();
  }

  @action
  close() {
    this.confirmArgs?.onClose?.();
    this.confirmArgs = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ConfirmModal: typeof ConfirmModal;
  }
}
