import DS from 'ember-data';

import dayjs from 'mobile-web/lib/dayjs';

export default class GroupOrderSerializer extends DS.JSONSerializer {
  serialize(snapshot: DS.Snapshot, options: UnknownObject) {
    const serialized = super.serialize(snapshot, options) as UnknownObject;
    // Required by the back-end because of JJ, but we don't care about it,
    // so just set to a reasonable time in the future.
    serialized.deadline = dayjs().add(14, 'days').toISOString();
    return serialized;
  }
}
