import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';

import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';

export default class AuthenticationService extends Service {
  // Service injections
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  /**
   * Proceeds toward the checkout page after authenticating.
   *
   * @param canReturn indicates whether the user can return to the previous page
   * from the checkout page, such as when checking out as a guest.
   */
  goToCheckout(canReturn = false) {
    if (this.onPremise.hasOpenCheckWithUnsentProducts) {
      this.router.transitionTo('open-check.review');
    } else if (canReturn) {
      this.router.transitionTo('checkout');
    } else {
      this.router.replaceWith('checkout');
    }
  }

  /**
   * Proceeds to the next route after authenticating. The next route is stored
   * when a user visits a page that requires authentication.
   *
   * If no route is specified or the route is one of
   *
   *  - `forgot-password`
   *  - `reset-password`
   *  - `thank-you`
   *
   * then the user will be redirected to the `defaultRoute` instead.
   *
   * @param defaultRoute the default route to proceed to if no next route is
   * stored.
   */
  goToNextRoute(defaultRoute = 'application') {
    const { nextRoute } = this.session;

    this.session.nextRoute = undefined;

    if (nextRoute && this.routeCanRedirect(nextRoute[0])) {
      // @ts-expect-error - nextRoute uses an array type when a tuple is necessary
      this.router.replaceWith(...nextRoute);
    } else {
      this.router.replaceWith(defaultRoute);
    }
  }

  private routeCanRedirect(route: string) {
    switch (route) {
      case 'forgot-password':
      case 'reset-password':
      case 'thank-you':
        return false;
      default:
        return true;
    }
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    authentication: AuthenticationService;
  }
}
