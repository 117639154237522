import { assert } from '@ember/debug';
import DS from 'ember-data';

import isSome from 'mobile-web/lib/utilities/is-some';

export default class BaseJSONSerializer extends DS.JSONSerializer.extend(DS.EmbeddedRecordsMixin) {
  modelName!: string;

  // This is only here to support tests
  pushPayload(store: DS.Store, payload: { id: string }): void {
    assert('`modelName` must be set when extending BaseJSONSerializer', isSome(this.modelName));
    const data = this.normalizeResponse(
      store,
      store.modelFor(this.modelName),
      payload,
      payload.id,
      'findRecord'
    );
    store.push(data);
  }
}
