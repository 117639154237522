import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService, { generatedDineInEmailPrefix } from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import WindowService from 'mobile-web/services/window';

import style from './index.m.scss';

enum SentimentSurveyResult {
  ThumbsUp = 1,
  ThumbsDown = -1,
}

interface Signature {
  Element: HTMLDivElement;

  Blocks: {
    default: [];
  };
}

export default class SurveyHopUp extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service window!: WindowService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked showHopUp = true;

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  dismissSentimentSurvey() {
    this.analytics.trackEvent(AnalyticsEvents.SentimentSurveyDismissed);
    this.storage.sentimentSurveyShown = true;
    this.showHopUp = false;
  }

  @action
  surveyShown() {
    if (this.showHopUp) {
      this.analytics.trackEvent(AnalyticsEvents.SentimentSurveyShown);
    }
  }

  @action
  async handleSentimentSurveyFeedback(result: SentimentSurveyResult) {
    if (!this.session.lastOrderId) {
      return;
    }

    const adapter = this.store.adapterFor('order');

    const redirectUrl = (await adapter.getSurveyRedirectUrl(this.session.lastOrderId))
      .surveyRedirectUrl;
    const emailAddress = this.session.user?.emailAddress;
    let surveyUrl = `${this.channel.settings?.surveyUrl}?redirectUrl=${encodeURIComponent(
      redirectUrl
    )}`;

    if (emailAddress && !emailAddress.startsWith(generatedDineInEmailPrefix)) {
      surveyUrl += `&emailAddress=${encodeURIComponent(emailAddress)}`;
    }

    const analyticsEventName =
      result === SentimentSurveyResult.ThumbsUp
        ? AnalyticsEvents.SentimentSurveyThumbsUp
        : AnalyticsEvents.SentimentSurveyThumbsDown;
    this.analytics.trackEvent(analyticsEventName);

    this.storage.sentimentSurveyShown = true;
    this.storage.visitedSentimentSurvey = true;
    this.window.location().assign(surveyUrl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SurveyHopUp: typeof SurveyHopUp;
  }
}
