import DS from 'ember-data';

import ChannelModel from 'mobile-web/models/channel';

export default class ContentModel extends DS.Model {
  @DS.attr('string')
  key!: string;
  @DS.attr('string')
  value!: string;
  @DS.belongsTo('channel')
  channel!: ChannelModel;
}

export interface ContentModelShape {
  key: string;
  value: string;
  channel: EmberDataId;
}
