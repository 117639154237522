export default {
  "root": "_root_1m1huu",
  "left": "_left_1m1huu",
  "navigationButton": "_navigationButton_1m1huu",
  "home": "_home_1m1huu",
  "homeLoading": "_homeLoading_1m1huu",
  "logo": "_logo_1m1huu",
  "logoLoading": "_logoLoading_1m1huu",
  "right": "_right_1m1huu",
  "accountLoading": "_accountLoading_1m1huu",
  "cartLoading": "_cartLoading_1m1huu",
  "accountButton": "_accountButton_1m1huu",
  "basket": "_basket_1m1huu",
  "notifications": "_notifications_1m1huu",
  "background": "_background_1m1huu"
};
