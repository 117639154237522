import Inflector from 'ember-inflector';

export function initialize() {
  const inflector = Inflector.inflector;

  // Tell the inflector that the plural of "billing-details" is "billing-details"
  inflector.uncountable('billing-details');
}

export default {
  name: 'custom-inflector-rules',
  initialize,
};
