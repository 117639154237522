/**
 * Joins multiple path segments into a single path string using `/` separators.
 *
 * > **⚠ Warning**:
 * >
 * > This is a naive implementation that does not support dot notation in paths
 * > (i.e. `./`, and `../`).
 * >
 * > If you need to support relative paths that use dot notation, replace this
 * > with a plugin such as `path-browserify`.
 *
 * @param paths the path segments to join
 * @returns the joined path string
 */
export const join = (...paths: string[]) => paths.join('/').replace(/\/{2,}/g, '/');
