export const numberOr = (defaultValue: number, value: unknown): number =>
  Number(value) || defaultValue;

export const numberOr0 = (value: unknown): number => numberOr(0, value);

/**
 * Converts a formatted currency string into a regular number.
 *
 * It does this by stripping out any characters other than
 * numbers, periods, and dashes (for negative numbers).
 *
 * @param currency a string formatted to look like currency
 */
export const currencyToNumber = (currency: string): number =>
  Number(currency.replace(/[^0-9.-]+/g, ''));
