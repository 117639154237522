export default {
  "modal": "_modal_1cgfon",
  "header": "_header_1cgfon",
  "contactInfo": "_contactInfo_1cgfon",
  "addressWrapper": "_addressWrapper_1cgfon",
  "mapLink": "_mapLink_1cgfon",
  "phoneWrapper": "_phoneWrapper_1cgfon",
  "phoneHeader": "_phoneHeader_1cgfon",
  "phoneButton": "_phoneButton_1cgfon",
  "calendarWrapper": "_calendarWrapper_1cgfon",
  "cardWrapper": "_cardWrapper_1cgfon",
  "cards": "_cards_1cgfon"
};
