export default {
  "root": "_root_1md2yo",
  "mainHeader": "_mainHeader_1md2yo",
  "subHeader": "_subHeader_1md2yo",
  "input": "_input_1md2yo",
  "button": "_button_1md2yo",
  "form": "_form_1md2yo",
  "isCreateAccount": "_isCreateAccount_1md2yo",
  "isCheckoutAuth": "_isCheckoutAuth_1md2yo"
};
