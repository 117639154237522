import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { getMapSymbol, MapIcons, MapMarker } from 'mobile-web/components/map-window';
import config from 'mobile-web/config/environment';
import dayjs from 'mobile-web/lib/dayjs';
import { HandoffMessageParts, handoffMessageParts } from 'mobile-web/lib/order';
import isSome from 'mobile-web/lib/utilities/is-some';
import DispatchStatusModel, { CourierStatus, State } from 'mobile-web/models/dispatch-status';
import OrderModel from 'mobile-web/models/order';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import MwcIntl from 'mobile-web/services/mwc-intl';

import style from './index.m.scss';

const CHECK_MAPS_ERROR_INTERVAL = 1000;
const REFRESH_INTERVAL = config.environment === 'development' ? 5000 : 60000;

const getEstimatedArrival = (estimatedDropoffTime: Date): number => {
  const now = dayjs();
  const dropoffEstimate = dayjs(estimatedDropoffTime);
  const time = dropoffEstimate.diff(now, 'm');

  if (time < 1) {
    return 1;
  }

  return Math.round(time);
};

const NO_MAP_STATUSES = [
  State.DeliveryError,
  State.OrderError,
  State.PickupError,
  State.DeliverySucceeded,
  State.ReturnInProgress,
  State.ReturnCompleted,
];

interface Args {
  // Required arguments
  order: OrderModel;
  status: DispatchStatusModel;

  // Optional arguments
  hash?: string;
}

interface Signature {
  Args: Args;
}

export default class DispatchStatusRoute extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;
  @service store!: DS.Store;
  @service mwcIntl!: MwcIntl;
  @service intl!: IntlService;
  @service error!: ErrorService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked showMap = false;
  @tracked showTracking = false;
  @tracked consecutiveMapUpdates = 0;

  // Getters and setters
  get isTest() {
    return config.environment === 'test';
  }

  get statusMessageParts(): HandoffMessageParts {
    const state = this.args.status.status;

    if (state === State.DeliverySucceeded) {
      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.completedLabel'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.completedTime'),
      };
    } else if (state === State.PickupInProgress) {
      const endTime = dayjs(this.args.status.estimatedDropoffTimeLocal).format('h:mm A');
      const startTime = dayjs(this.args.status.estimatedDropoffTimeLocal)
        .subtract(10, 'm')
        .format('h:mm A');
      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDelivery'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffRange', {
          endTime,
          startTime,
        }),
      };
    } else if (state === State.ReturnInProgress) {
      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returningLabel'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returningTime'),
      };
    } else if (state === State.ReturnCompleted) {
      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returnedLabel'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returnedTime'),
      };
    } else if (state === State.PickupSucceeded) {
      const time = getEstimatedArrival(this.args.status.estimatedDropoffTimeLocal);

      if (time >= 60) {
        const endTime = dayjs(this.args.status.estimatedDropoffTimeLocal).format('h:mm A');
        const startTime = dayjs(this.args.status.estimatedDropoffTimeLocal)
          .subtract(10, 'm')
          .format('h:mm A');

        return {
          prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDelivery'),
          dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffRange', {
            endTime,
            startTime,
          }),
        };
      }

      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedArrival'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffTime', {
          time,
        }),
      };
    } else if (state === State.OrderReceived && !this.args.order.isAdvance) {
      const time = getEstimatedArrival(this.args.status.estimatedDropoffTimeLocal);

      if (time >= 60) {
        const endTime = dayjs(this.args.status.estimatedDropoffTimeLocal).format('h:mm A');
        const startTime = dayjs(this.args.status.estimatedDropoffTimeLocal)
          .subtract(10, 'm')
          .format('h:mm A');

        return {
          prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDelivery'),
          dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffRange', {
            endTime,
            startTime,
          }),
        };
      }

      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedArrival'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffTime', {
          time,
        }),
      };
    }

    // if we get here, then we must be in an AdvanceOrder

    // Note: the readytimeutc and timezoneid will not ever be null on a vendor (vendors don't load in the API if they are null)
    // here we use the "||" to make the compiler happy
    const relativeDateText = this.mwcIntl.vendorRelativeDateTime(
      this.args.order.timeReadyUtc || new Date(),
      this.args.order.vendor.timeZoneId || '1'
    );

    return handoffMessageParts(this.args.order, relativeDateText);
  }

  get displayDriverInfo() {
    return isSome(this.args.status.driverName) && this.args.status.status === State.PickupSucceeded;
  }

  get isPickupInProgress() {
    return (
      this.args.status.status === State.PickupInProgress && isSome(this.args.status.deliveryService)
    );
  }

  get mapMarkers() {
    const vendor = this.args.order.vendor;
    const deliveryAddress = this.args.order.deliveryAddress;
    if (!isSome(deliveryAddress?.latitude)) {
      return [];
    }
    const addressMarker: MapMarker = {
      name: 'Delivery Address',
      lat: deliveryAddress!.latitude!,
      lng: deliveryAddress!.longitude!,
      icon: getMapSymbol(MapIcons.Person, '#2C7D3C'),
      halo: false,
    };
    const markers = [addressMarker];

    if (
      this.args.status.status === State.PickupSucceeded &&
      this.args.status.latitude &&
      this.args.status.longitude
    ) {
      markers.push({
        name: `${this.args.status.driverName}'s location`,
        lat: this.args.status.latitude,
        lng: this.args.status.longitude,
        icon: getMapSymbol(MapIcons.Car, '#006AD1'),
        halo: true,
      });
      addressMarker.halo = true;
    } else {
      markers.push({
        name: vendor.name,
        lat: vendor.latitude,
        lng: vendor.longitude,
        icon: getMapSymbol(MapIcons.Store, '#006AD1'),
      });
    }

    return markers;
  }

  get updatesIconData() {
    let className, showIcon, iconLabel, ariaLabel;
    switch (this.args.status.status) {
      case State.OrderReceived:
        className = style.updatesActive;
        showIcon = true;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
        break;
      case State.PickupInProgress:
        className = style.updatesActive;
        showIcon = true;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
        break;
      case State.PickupSucceeded:
        if (
          (isSome(this.args.status.latitude) && isSome(this.args.status.longitude)) ||
          !this.showMap
        ) {
          className = style.updatesActive;
          iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        } else {
          className = style.updatesActiveNoLocation;
          iconLabel = this.intl.t('mwc.dispatchTracker.noDriverCoordinates');
        }
        showIcon = true;
        ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
        break;
      case State.DeliverySucceeded:
        className = style.updatesInactive;
        showIcon = true;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.inactiveUpdatesAriaLabel');
        break;
      case State.ReturnInProgress:
        className = style.updatesActive;
        showIcon = true;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
        break;
      default:
        // All various error states fall into here
        className = style.updatesInactive;
        showIcon = false;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.inactiveUpdatesAriaLabel');
        break;
    }
    return {
      className,
      showIcon,
      iconLabel,
      ariaLabel,
    };
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.updateShowTracking();

    // If the initial status is a terminal status, the map is not needed
    this.showMap =
      !isEmpty(this.googleMapsApiKey) && !NO_MAP_STATUSES.includes(this.args.status.status);

    if (config.environment !== 'test') {
      later(this, this.refresh, REFRESH_INTERVAL);
      later(this, this.checkMapsError, CHECK_MAPS_ERROR_INTERVAL);
    }
  }

  // Other methods
  checkMapsError() {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    // We don't have an event to hook into if there is an API key error, so scrape the DOM for the element :(
    if (document?.querySelector('.gm-err-title')) {
      this.showMap = false;
      this.error.sendExternalError(
        new Error(
          'Google Maps referrer not allowed: ' +
            this.channel.current?.name +
            '. Add ' +
            document.domain +
            ' to Google Maps API Console '
        )
      );
    } else {
      later(this, this.checkMapsError, CHECK_MAPS_ERROR_INTERVAL);
    }
  }

  get googleMapsApiKey(): string {
    return this.channel.settings?.disableDispatchDriverMap
      ? ''
      : config.DEFAULT_GOOGLE_MAPS_API_KEY;
  }

  // Other methods
  updateShowTracking() {
    // This is not using a `computed` because using `order.dispatchStatuses.[]`
    // does not re-compute as a dependent key. Referencing `this.args.order.dispatchStatuses`
    // inside a computed to set this value will trigger a fetch of those statuses which
    // is not needed to update the UI state. Also that fetch requires `adapterOptions`.
    this.showTracking = this.args.order.hasMany('dispatchStatuses').ids().length > 1;
  }

  // Tasks

  // Actions and helpers
  @action
  refresh() {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    const dataOptions = {
      reload: true,
      adapterOptions: { orderId: this.args.order.id, hash: this.args.hash },
    };

    const updateOrder = () =>
      this.store
        .findRecord('order', this.args.order.id, dataOptions)
        .then(() => this.updateShowTracking());

    if (this.args.status.courierStatus === CourierStatus.Active) {
      // this.args.status is a live Ember Data object, so when we do this
      // store.findRecord with reload: true, any updates will automagically
      // be reflected in this.args.status. We can't do this.args.status.reload() because
      // of the adapterOptions we need to pass.
      this.store.findRecord('dispatch-status', this.args.status.id, dataOptions).then(() => {
        if (!isEmpty(this.googleMapsApiKey)) {
          this.showMap = !NO_MAP_STATUSES.includes(this.args.status.status);
        }
        if (this.showMap) {
          this.analytics.trackEvent(AnalyticsEvents.LoadDispatchMapData, () => ({
            [AnalyticsProperties.ConsecutiveLoads]: ++this.consecutiveMapUpdates,
            [AnalyticsProperties.OrderId]: this.args.order.id,
            [AnalyticsProperties.OrderID]: this.args.order.id,
          }));
        }
      });

      // Refresh the order as well to show tracking CTA for additional statuses
      if (!this.showTracking) {
        updateOrder();
      }

      if (config.environment !== 'test') {
        later(this, this.refresh, REFRESH_INTERVAL);
      }
    } else if (!this.showTracking && this.args.status.courierStatus !== CourierStatus.Completed) {
      // The current status is no longer active
      // Continue to poll for additional statuses.
      updateOrder();

      if (config.environment !== 'test') {
        later(this, this.refresh, REFRESH_INTERVAL);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::DispatchStatusRoute': typeof DispatchStatusRoute;
  }
}
