import { computed } from '@ember/object';
import { gt } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import savedAttributes, { PrimitiveAttributes } from 'mobile-web/decorators/saved-attributes';
import { CustomField } from 'mobile-web/lib/custom-field';
import { CustomizedChoiceModel } from 'mobile-web/lib/menu';
import BasketProduct from 'mobile-web/models/basket-product';
import ChoiceModel from 'mobile-web/models/choice';
import OptionGroupModel from 'mobile-web/models/option-group';

const LABEL_SEPARATOR = '.';
const LABEL_MAX_LENGTH = 120;

export default class BasketChoiceModel extends DS.Model {
  @DS.attr('string')
  name!: string;
  @DS.attr('number', { defaultValue: 0 })
  quantity!: number;
  @DS.attr('boolean', { defaultValue: true })
  displayInBasket!: boolean;

  @DS.belongsTo('choice')
  choice!: DS.PromiseObject<ChoiceModel>;

  @DS.belongsTo('basket-product', { async: false })
  basketProduct?: BasketProduct;

  @DS.attr('array', { defaultValue: () => [] })
  customFieldValues!: CustomField[];

  @savedAttributes
  savedAttributes!: PrimitiveAttributes<BasketChoiceModel>;

  @gt('quantity', 0)
  isSelected!: boolean;

  @computed('choice.optionGroup.parentChoice')
  get parentChoice(): ChoiceModel | undefined {
    return this.choice.get('optionGroup')?.get('parentChoice');
  }

  @computed('choice.optionGroups')
  get optionGroups(): DS.PromiseManyArray<OptionGroupModel> | undefined {
    return this.choice.get('optionGroups');
  }

  @computed('childrenLabel', 'isSelected', 'name', 'optionGroups.length')
  get choiceLabel(): string {
    if (this.isSelected) {
      if (!this.optionGroups || this.optionGroups.length === 0) {
        return this.name;
      }
      return `${this.name}${LABEL_SEPARATOR} ${this.childrenLabel}`;
    }

    return '';
  }

  @computed('optionGroups.@each.completedOptionsLabel')
  get childrenLabel(): string {
    if (this.optionGroups) {
      return this.optionGroups
        .map(og => og.completedOptionsLabel)
        .filter(label => label !== '')
        .join(`${LABEL_SEPARATOR} `);
    }
    return '';
  }

  @computed('childrenLabel')
  get completedChoiceLabel(): string {
    const completedLabel = isEmpty(this.childrenLabel)
      ? this.childrenLabel
      : `${this.childrenLabel}${LABEL_SEPARATOR}`;
    return completedLabel.length > LABEL_MAX_LENGTH
      ? `${completedLabel.substr(0, completedLabel.lastIndexOf('.', LABEL_MAX_LENGTH))}...`
      : completedLabel;
  }
}

export function isBasketChoice(product: CustomizedChoiceModel): product is BasketChoiceModel {
  return 'displayInBasket' in product;
}
