import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { UserOrderEvent } from 'mobile-web/components/post-checkout';
import dayjs, { Dayjs } from 'mobile-web/lib/dayjs';
import { getHandoffMode } from 'mobile-web/lib/order-criteria';
import OrderModel from 'mobile-web/models/order';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';

import style from './index.m.scss';

interface Args {
  // Required arguments
  order: OrderModel;

  // Optional arguments
  utmMedium?: string;
}

interface Signature {
  Args: Args;
}

export default class ArrivalRoute extends Component<Signature> {
  // Service injections
  @service router!: RouterService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked parkingLocationShown = false;
  @tracked parkingLocation = '';

  startTime: Dayjs;

  // Getters and setters
  get showParkingLocationForm(): boolean {
    return !this.parkingLocationShown && this.args.order.requireArrivalMessage;
  }

  get analyticsProperties() {
    return {
      [AnalyticsProperties.UtmMedium]: isEmpty(this.args.utmMedium) ? 'serve' : this.args.utmMedium,
      [AnalyticsProperties.BasketHandoffMode]: getHandoffMode(this.args.order.deliveryMode),
      [AnalyticsProperties.SupportsParkingLocation]: this.args.order.requireArrivalMessage,
    };
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.startTime = dayjs().utc();
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onSubmit(event: Event) {
    event.preventDefault();

    this.parkingLocationShown = true;

    this.args.order
      .arrived({
        id: this.args.order.orderGuid,
        hash: this.args.order.orderHash,
        message: this.parkingLocation,
      })
      .then(() => {
        const elasped = Math.round(dayjs().utc().diff(this.startTime) / 1000);
        this.analytics.trackEvent(AnalyticsEvents.UserFiredOrderEvent, () => ({
          [AnalyticsProperties.OrderEvent]: UserOrderEvent.Arrival,
          [AnalyticsProperties.ElapsedSeconds]: elasped,
          ...this.analyticsProperties,
        }));
      });
  }

  @action
  onInsert() {
    if (!this.showParkingLocationForm) {
      this.args.order
        .arrived({
          id: this.args.order.orderGuid,
          hash: this.args.order.orderHash,
        })
        .then(() => {
          this.analytics.trackEvent(AnalyticsEvents.UserFiredOrderEvent, () => ({
            [AnalyticsProperties.OrderEvent]: UserOrderEvent.Arrival,
            ...this.analyticsProperties,
          }));
        });
    } else {
      this.analytics.trackEvent(AnalyticsEvents.AskedForParkingLocation, () => ({
        ...this.analyticsProperties,
      }));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::ArrivalRoute': typeof ArrivalRoute;
  }
}
