import { isNone } from '@ember/utils';
import DS from 'ember-data';

import { CustomIcon } from 'mobile-web/lib/custom-icon';
import isSome from 'mobile-web/lib/utilities/is-some';
import { currencyToNumber } from 'mobile-web/lib/utilities/numeric';
import { LabelCode } from 'mobile-web/models/vendor';

export default class UpsellItemSerializer extends DS.JSONSerializer {
  normalize(
    modelClass: DS.Model,
    resourceHash: {
      cost?: string | number;
      quantity?: number;
      labels: Array<{ code: string; icon?: CustomIcon; image?: string }>;
    }
  ): UnknownObject {
    if (isSome(resourceHash.cost)) {
      resourceHash.cost = currencyToNumber(resourceHash.cost as string);
    }
    if (isNone(resourceHash.quantity)) {
      resourceHash.quantity = 0;
    }

    resourceHash.labels?.forEach(label => {
      if (label.image && !label.icon) {
        let icon: CustomIcon | undefined = {
          id: '',
          alt: '',
          name: label.code,
          url: label.image,
          height: 0,
          width: 0,
        };

        switch (label.code) {
          case LabelCode.NYC:
            //Need to hard code id here to match types. Should be ok, we never reference id in ember data.
            icon.id = 2;
            icon.alt = 'High Sodium';
            icon.height = 20;
            icon.width = 20;
            break;

          case LabelCode.PHL:
            icon.id = 4;
            icon.alt = 'PHL High Sodium';
            icon.height = 15;
            icon.width = 121;
            break;

          case LabelCode.Prop65:
            icon.id = 3;
            icon.alt = 'Toxic - California Prop 65';
            icon.height = 20;
            icon.width = 20;
            break;

          default:
            icon = undefined;
            break;
        }
        if (icon) {
          label.icon = icon;
          delete label.image;
        }
      }
    });

    return super.normalize(modelClass, resourceHash);
  }
}
