import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import TransitionContext from 'ember-animated/-private/transition-context';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';

import { getDuration } from 'mobile-web/lib/animation';
import { classes } from 'mobile-web/lib/utilities/classes';
import { raf } from 'mobile-web/lib/utilities/raf';

import style from './index.m.scss';

const PAGE_SIZE = 20;

interface Args<T> {
  // Required arguments

  // Optional arguments
  columnCount?: 1 | 2 | 3 | 4 | 5;
  models?: T[] | DS.RecordArray<T>;
}

interface Signature<T> {
  Element: HTMLUListElement;

  Args: Args<T>;

  Blocks: {
    default: [T, number];
  };
}

export default class CardGrid<T> extends Component<Signature<T>> {
  // Service injections

  // Untracked properties
  listResizeObserver = new ResizeObserver(
    raf<ResizeObserverCallback>(([entry]) => {
      this.onResize(entry.target);
    })
  );
  style = style;

  // Tracked properties
  @tracked hide = true;
  @tracked lastModelProcessed = PAGE_SIZE;
  @tracked listElementWidth = 0;

  // Getters and setters

  get columnCount() {
    const width = this.listElementWidth;
    // prettier-ignore
    return (
      this.args.columnCount ?? (
        width > 1600
        ? 5
        : width > 1280
        ? 4
        : width > 960
        ? 3
        : width > 640
        ? 2
        : 1
      )
    );
  }

  // On the initial render, we see a brief flash of all the cards.
  // These cards then disappear and fade in.
  // If we give the list an initial hidden class, the flash disappears.
  get listClass(): string {
    return classes(this.style.list, this.style[`columns-${this.columnCount}`], {
      [this.style.hidden]: this.hide,
    });
  }

  get cards(): T[] {
    return this.args.models?.slice(0, this.lastModelProcessed) ?? [];
  }

  // Lifecycle methods

  // Other methods
  transition = function* (
    this: CardGrid<T>,
    { insertedSprites, keptSprites, removedSprites }: TransitionContext
  ): IterableIterator<unknown> {
    this.hide = false;
    // We yield Promise.all here because we want to wait for this
    // step before starting what comes after.
    yield Promise.all(removedSprites.map(s => fadeOut(s, { duration: getDuration(500) })));
    for (const sprite of keptSprites) {
      if (!sprite.revealed) {
        // Sprites whose animations get disrupted will disappear
        // so we return if they have been interrupted. Bug in Ember animation.
        return;
      }
      fadeIn(sprite);
      move(sprite);
    }
    for (const sprite of insertedSprites) {
      sprite.startTranslatedBy(0, 30);
      fadeIn(sprite);
      yield move(sprite, { duration: getDuration(100) });
    }
  }.bind(this);

  loadMoreCards() {
    this.lastModelProcessed = this.lastModelProcessed + PAGE_SIZE;
  }

  // Tasks

  // Actions and helpers
  @action
  onResize(listElement: Element) {
    this.listElementWidth = listElement.clientWidth;
  }

  onScroll = raf(() => {
    if (document.documentElement!.scrollHeight - window.scrollY - window.innerHeight < 1000) {
      this.loadMoreCards();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Grid': typeof CardGrid;
  }
}
