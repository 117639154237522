export default {
  "root": "_root_bt25c0",
  "hasGroupOrder": "_hasGroupOrder_bt25c0",
  "cart": "_cart_bt25c0",
  "title": "_title_bt25c0",
  "icon": "_icon_bt25c0",
  "descriptionText": "_descriptionText_bt25c0",
  "content": "_content_bt25c0",
  "status": "_status_bt25c0",
  "inviteButton": "_inviteButton_bt25c0",
  "cancelLink": "_cancelLink_bt25c0",
  "statusEl": "_statusEl_bt25c0",
  "label": "_label_bt25c0",
  "count": "_count_bt25c0"
};
