import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import { HandoffMode } from 'mobile-web/lib/order-criteria';
import isSome from 'mobile-web/lib/utilities/is-some';
import { BILLING_DETAILS_ID } from 'mobile-web/models/billing-details';
import {
  BasketPayloadWithCustomFields,
  normalizeBasketProductCustomFields,
} from 'mobile-web/serializers/basket-product';
import ErrorService from 'mobile-web/services/error';

import { normalizeDeliveryMode } from './product';

export default class BasketSerializer extends DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin) {
  @service error!: ErrorService;

  primaryKey = 'guid';
  attrs = {
    deliveryAddress: {
      embedded: 'always',
    },
  };

  normalize(
    modelClass: DS.Model,
    resourceHash: {
      billingDetails?: number;
      vendorDiscount?: number;
      taxes: Array<{ totalTax?: number }>;
      deliveryMode?: string; //Deprecated
      handoffMode?: HandoffMode;
      timeWanted?: string;
      vendor?: EmberDataId;
      coupon?: AnyObject | null;
      reward?: AnyObject | null;
      groupOrderId?: string;
    },
    prop?: string
  ): UnknownObject {
    // Billing Details is not actually an ID'ed object, but there is
    // only ever 1 of them, so we just hard code the ID to 0 here and
    // in the Billing Details serializer. This lets us keep all the things
    // in Ember Data, and get the billing details off of the basket.
    resourceHash.billingDetails = BILLING_DETAILS_ID;

    if (isNone(resourceHash.vendorDiscount)) {
      resourceHash.vendorDiscount = 0;
    }

    // *sigh*
    // When a coupon or reward is removed, the respective field for that data
    // is simply not returned in the basket payload. If the field isn't there
    // in the payload, but is there in the Ember Data store, Ember Data is
    // super helpful by merging the new data with the old data so you don't
    // lose data. In order to tell Ember Data that we actually meant to lose
    // this data, it has to come back in the serializer as `null` to override
    // the value in the store.
    if (isNone(resourceHash.coupon)) {
      // eslint-disable-next-line no-null/no-null
      resourceHash.coupon = null;
    }
    if (isNone(resourceHash.reward)) {
      // eslint-disable-next-line no-null/no-null
      resourceHash.reward = null;
    }

    // If the totalTax is 0, the back end just doesn't send totalTax at all
    // but we always want totalTax to be present, so we need to set it to 0
    if (resourceHash.taxes) {
      resourceHash.taxes.forEach(t => {
        if (isNone(t.totalTax)) {
          t.totalTax = 0;
        }
      });
    }

    // `deliveryMode` has been deprecated, and will eventually be removed from the back end.
    // `handoffMode` will be added at the same time.
    // Until then, we need to map one to the other ourselves.
    if (isSome(resourceHash.deliveryMode) && !isSome(resourceHash.handoffMode)) {
      resourceHash.handoffMode = normalizeDeliveryMode(resourceHash.deliveryMode);
      delete resourceHash.deliveryMode;
    }

    // `timeWanted` is in the vendor's time zone, but it doesn't come back indicating that
    // so add the time zone indicator to that value here if we can get the offset
    if (resourceHash.vendor && resourceHash.timeWanted) {
      const vendor = this.store.peekRecord('vendor', resourceHash.vendor);
      if (vendor && vendor.utcOffset) {
        const sign = vendor.utcOffset < 0 ? '-' : '+';
        const num = Math.abs(vendor.utcOffset).toString().padStart(2, '0');
        resourceHash.timeWanted = `${resourceHash.timeWanted}${sign}${num}:00`;
      }
    }

    // It's easier for us to check for an undefined group order ID instead of an all 0 GUID.
    if (resourceHash.groupOrderId === '00000000-0000-0000-0000-000000000000') {
      resourceHash.groupOrderId = undefined;
    }

    return super.normalize(modelClass, resourceHash, prop);
  }

  normalizeResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: BasketPayloadWithCustomFields,
    id: string | number,
    requestType: string
  ): UnknownObject {
    const newPayload = normalizeBasketProductCustomFields(payload, this.error);
    return super.normalizeResponse(store, primaryModelClass, newPayload, id, requestType);
  }
}
