import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { CustomizedProductModel } from 'mobile-web/lib/menu';
import AnimationService from 'mobile-web/services/animation';

import style from './index.m.scss';

interface Args {
  // Required arguments
  products: CustomizedProductModel[];

  // Optional arguments
  currency?: string;
  editable?: boolean;
  onClose?: Action;
  showProductChoices?: boolean;
  showProductRecipientName?: boolean;
}

interface Signature {
  Element: Element;
  Args: Args;
}

export default class ProductGrid extends Component<Signature> {
  // Service injections
  @service animation!: AnimationService;

  // Untracked properties
  style = style;
  // Tracked properties
  // Getters and setters
  get transition() {
    return this.animation.slideAndFadeInSlideAndFadeOutTransition;
  }

  get animationDuration() {
    return this.animation.getDuration(40);
  }

  get showProductChoices() {
    return this.args.showProductChoices ?? true;
  }

  get showProductRecipientName() {
    return this.args.showProductRecipientName ?? true;
  }
  // Lifecycle methods
  // Other methods
  // Tasks
  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProductGrid: typeof ProductGrid;
  }
}
