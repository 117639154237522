import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { CvvElement } from '@olo/pay';

import Method, { Membership, OloPayMembership, isMembership } from 'mobile-web/lib/payment/method';
import BillingMembership from 'mobile-web/models/billing-membership';
import BusService from 'mobile-web/services/bus';

import FeaturesService from './features';

export type CvvRevalidation = {
  complete: boolean;
  element?: CvvElement;
  guid: string;
  membership?: BillingMembership;
  method: Membership | OloPayMembership;
};

export default class CvvRevalidationService extends Service {
  // Service injections
  @service features!: FeaturesService;
  @service store!: DS.Store;
  @service bus!: BusService;

  // Untracked properties
  elements: CvvRevalidation[] = [];

  // Tracked properties
  @tracked securityActionJwt!: string;
  @tracked shouldValidateField = false;

  // Getters and setters
  get isCvvRevalFeatureEnabled(): boolean {
    return this.features.flags['olo-78754-cvv-revalidation-recommendation'];
  }

  get isCvvRevalidationComplete(): boolean {
    return this.elements.every(cvv => cvv.complete) || false;
  }

  get shouldTokenizeCvv(): boolean {
    return (
      this.isCvvRevalFeatureEnabled && this.isCvvRevalidationComplete && this.elements.length > 0
    );
  }

  // Lifecycle methods

  // Other methods
  initializeCvvReval(revalidation: CvvRevalidation) {
    const membership =
      this.store.peekRecord('billing-membership', revalidation.method.membershipId) ?? undefined;
    if (!membership) {
      throw new Error('CvvRevalidation: Membership could not be found for method');
    }

    if (membership.securityActionJwt) {
      this.securityActionJwt = membership.securityActionJwt!;
    }

    if (membership.cvvRevalidationRecommended) {
      this.addCvvRevalElement({
        ...revalidation,
        membership,
      });
    }
    this.validateElements();
  }

  onCvvRevalChange({ guid, element, complete }: Omit<CvvRevalidation, 'method'>) {
    this.updateCvvElement({ element, complete, guid });
    this.validateElements();
  }

  onCvvRevalDestroy({ guid }: Pick<CvvRevalidation, 'guid'>) {
    if (this.elements.length > 0) {
      const cvvIndex = this.elements.findIndex(cvv => cvv.guid === guid);
      this.elements = [...this.elements.slice(0, cvvIndex), ...this.elements.slice(cvvIndex + 1)];
    }
    this.shouldValidateField = this.isCvvRevalFeatureEnabled && this.elements.length > 0;
  }

  async tokenizeCvv(selectedBillingMethods: Method[]) {
    const newSelectedBillingMethods = selectedBillingMethods.map(
      async (method: OloPayMembership) => {
        const cvvReval = this.elements.find(c => c.membership?.id === method.membershipId);
        const tokenResult = await cvvReval?.element?.createCvvToken();

        if (tokenResult?.error) {
          throw new Error(tokenResult.error.message);
        }

        const updatedMethod = {
          ...method,
          paymentCard: {
            ...method.paymentCard,
            cvv: tokenResult?.token?.id,
          },
        };
        return updatedMethod;
      }
    );

    return Promise.all(newSelectedBillingMethods);
  }

  onCvvRevalFailure(billingMethods: Method[]) {
    billingMethods.forEach(async (method: OloPayMembership) => {
      if (isMembership(method) && method.paymentCard.cvv?.includes('cvctok_')) {
        const membership = this.store.peekRecord('billing-membership', method.membershipId);
        await membership?.destroyRecord();
        this.bus.trigger('onRemoveMembership', { id: method.membershipId });
      }
    });
  }

  // Tasks

  // Actions and helpers
  @action
  getIsCvvRevalidationRecommended(guid: string): boolean {
    const cvvElement = this.elements.find(cvv => cvv.guid === guid);
    return !!(this.isCvvRevalFeatureEnabled && cvvElement?.membership?.cvvRevalidationRecommended);
  }

  private updateCvvElement({
    guid,
    element,
    complete,
  }: Pick<CvvRevalidation, 'complete' | 'element' | 'guid'>) {
    this.elements = this.elements.map(cvv => {
      if (cvv.guid === guid) {
        return {
          ...cvv,
          element,
          complete,
        };
      }
      return cvv;
    });
  }

  private validateElements() {
    this.shouldValidateField = this.isCvvRevalFeatureEnabled && this.elements.length > 0;
  }

  private addCvvRevalElement(revalidation: CvvRevalidation) {
    // check for item in array
    // push if it doesn't exist, splice if it does exist
    const cvvIndex = this.elements.findIndex(cvv => cvv.guid === revalidation.guid);

    if (cvvIndex < 0) {
      this.elements = [...this.elements, revalidation];
    } else {
      this.elements = [
        ...this.elements.slice(0, cvvIndex),
        revalidation,
        ...this.elements.slice(cvvIndex + 1),
      ];
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'cvv-revalidation': CvvRevalidationService;
  }
}
