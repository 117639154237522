import Component from '@glimmer/component';

import style from './index.m.scss';

type BaseModel = {
  label: string;
  icon?: string; // name of component
  testSelector?: string;
  class?: string;
};

export type RouteModel = BaseModel & {
  route: string;
  isBypass?: boolean;
};

export type ActionModel = BaseModel & {
  action: Action;
};

export type Model = RouteModel | ActionModel;

interface Args {
  // Required arguments
  model: Model;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class PopoverItem extends Component<Signature> {
  style = style;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PopoverItem: typeof PopoverItem;
  }
}
