import Component from '@glimmer/component';

import { IconArg, LabelArg } from '..';
import style from '../index.m.scss';

interface Args {
  // Required arguments
  icon: IconArg;
  label: LabelArg;
  showLabel: boolean;

  // Optional arguments
}

interface Signature {
  Element: HTMLSpanElement;
  Args: Args;
}

export default class HeaderButtonContent extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get iconWrapperStyle(): string {
    return this.style[this.args.icon.position];
  }

  get labelStyle(): string {
    return this.style[this.args.label.size ?? 'small'];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HeaderButton::Content': typeof HeaderButtonContent;
  }
}
