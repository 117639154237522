import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class RewardRoute extends Route {
  @service router!: RouterService;

  beforeModel(transition: Transition) {
    this.router.replaceWith('menu.vendor.rewards', transition.to.params.vendor_slug!);
  }
}
