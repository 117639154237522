import Component from '@glimmer/component';

interface Icon {
  url: string;
  alt: string;
  width: number;
  height: number;
}

interface Args {
  icon: Icon;
}

interface Signature {
  Element: HTMLImageElement;

  Args: Args;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class CustomIcon extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CustomIcon: typeof CustomIcon;
  }
}
