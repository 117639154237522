import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

import { CustomizedProductModel } from 'mobile-web/lib/menu';
import BasketProductModel, { isBasketProduct } from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';

import style from './index.m.scss';

interface Args {
  // Required arguments
  product: CustomizedProductModel;

  // Optional arguments
  readonly: boolean;
  onClose?: Action;
}

interface Signature {
  Element: HTMLElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class ProductGridProductLink extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get product(): ProductModel | undefined {
    if (isBasketProduct(this.args.product)) {
      return this.args.product.product?.content;
    }
    return undefined;
  }

  get canQuickAdd(): boolean {
    return this.product?.quickAddSupported ?? false;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers;
  @action
  updateQuickAddProduct() {
    this.bus.trigger('showProductModal', {
      product: this.product!,
      basketProduct: this.args.product as BasketProductModel,
      onClose: () => {
        this.basket.open();
      },
    });
  }

  @action
  edit() {
    this.analytics.trackEvent(AnalyticsEvents.ViewProductCustomization, () => {
      const product = this.product;

      if (!product) {
        // this should never happen because we can only edit a product when we have a product
        return {};
      }
      return {
        [AnalyticsProperties.ProductName]: product.name,
        [AnalyticsProperties.ProductCategory]: product.category?.name,
        [AnalyticsProperties.ProductBasePrice]: product.baseCost,
        [AnalyticsProperties.HasVisibleCalories]: !isEmpty(product.calorieLabel),
        [AnalyticsProperties.VisibleLabels]: product.labels?.map(l => l.name),
        [AnalyticsProperties.HasProductImages]: !isEmpty(product.images),
        [AnalyticsProperties.HasCategoryImages]: !isEmpty(product.category?.bannerImage),
        [AnalyticsProperties.HasVisiblePrice]: true,
        [AnalyticsProperties.ProductAvailableOptionGroupCount]: product.optionGroups?.map(
          og => og.id
        ).length,
        [AnalyticsProperties.Source]: 'Cart',
      };
    });

    this.args.onClose?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProductGrid::Product::Link': typeof ProductGridProductLink;
  }
}
