import ApplicationInstance from '@ember/application/instance';
import { assert } from '@ember/debug';
import { ModelRegistry } from 'ember-data/model';
import Store from 'ember-data/store';

type PromiseAction = Action<unknown, Promise<unknown>>;

declare module 'ember-data' {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace DS {
    export interface Store {
      collectionAction<M extends keyof ModelRegistry, K extends keyof ModelRegistry[M]>(
        modelName: M,
        key: K,
        params: ModelRegistry[M][K] extends PromiseAction
          ? Parameters<ModelRegistry[M][K]>[0]
          : never
      ): ModelRegistry[M][K] extends PromiseAction ? ReturnType<ModelRegistry[M][K]> : never;
    }
  }
}

async function collectionAction<
  M extends keyof ModelRegistry,
  K extends string & keyof ModelRegistry[M]
>(
  this: Store,
  modelName: M,
  key: K,
  params?: ModelRegistry[M][K] extends PromiseAction ? Parameters<ModelRegistry[M][K]>[0] : never
) {
  const model = this.createRecord(modelName);
  try {
    const action = model[key];

    // `.name` is minified out in production builds, so this check can only be done in an assert.
    assert(
      `${key} should be a collection action on ${modelName}`,
      action && typeof action === 'function' && action.name === 'runCollectionOp'
    );

    // The above assert proves this type conversion is OK.
    const promiseAction = action as unknown as PromiseAction;
    return await promiseAction.call(model, params);
  } finally {
    model.deleteRecord();
  }
}

export function initialize(appInstance: ApplicationInstance) {
  const store = appInstance.lookup('service:store') as AnyObject;
  store.reopen({ collectionAction });
}

export default {
  name: 'collection-action',
  initialize,
};
