import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import { resolve } from 'rsvp';

import IntlService from 'ember-intl/services/intl';

import { local } from 'mobile-web/decorators/storage';
import { Coords, SavedAddress } from 'mobile-web/lib/location/address';
import {
  getAddressFromOrderCriteria,
  isAdvance,
  isAtStore,
  isDelivery,
  mapPostalCode,
  OrderCriteria,
} from 'mobile-web/lib/order-criteria';
import { guids } from 'mobile-web/lib/utilities/guids';
import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import { VendorSearchParams } from 'mobile-web/routes/vendor-search-results';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
  TIME_WANTED_ANALYTICS_LABELS,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';
import * as OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import UserFeedback, { Type as FeedbackType } from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

interface Args {
  // Required arguments
  model: OrderSearchResultModel[];

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class IndexRoute extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service basket!: BasketService;
  @service intl!: IntlService;
  @service orderCriteria!: OrderCriteriaService.default;
  @service session!: SessionService;
  @service userFeedback!: UserFeedback;
  @service store!: DS.Store;
  @service router!: RouterService;
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;

  // Untracked properties
  ids = guids(this, 'mainHeader');
  savingAddressLabel = 'Saving Address';
  style = style;

  // Tracked properties
  @tracked savingAddress = false;
  @tracked vendorSearchAvailable = true;
  @local localVendorSearchParams?: VendorSearchParams;

  // Getters and setters
  get currentChannel() {
    return this.channel.current;
  }

  get isLoggedIn() {
    return this.session.isLoggedIn;
  }

  get savedAddresses() {
    return this.store.peekAll('address');
  }

  get responsiveHeaderSubstitutions(): Dict<string> {
    return { channelname: this.currentChannel?.name ?? '' };
  }

  get vendorSearchUnavailableWarning() {
    if (this.vendorSearchAvailable) {
      return '';
    }
    return this.intl.t('mwc.splash.notAvailableWarningMessageGeneric');
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // The underlying code will crash if there are no public handoff modes passed in,
    // since it is impossible to search for vendors if none are public.
    if (this.currentChannel?.handoffModes.filter(x => !x.isPrivate).length) {
      this.showWarnings();
      this.device.checkForNativeUpdate(true);
    }
  }

  // Other methods
  showWarnings() {
    if (this.vendorSearchUnavailableWarning) {
      this.userFeedback.add({
        type: FeedbackType.Warning,
        title: this.intl.t('mwc.splash.notAvailableWarningTitle'),
        message: this.vendorSearchUnavailableWarning,
      });
    }
  }

  // Tasks

  // Actions and helpers
  @action
  async handleSubmit(
    criteria: OrderCriteria,
    isSubmitDisabled: boolean,
    locationSearchType: string
  ): Promise<void> {
    if (!isSubmitDisabled) {
      if (this.isLoggedIn && isDelivery(criteria) && criteria.deliveryAddress) {
        if (isEmpty(criteria.deliveryAddress.id)) {
          try {
            this.savingAddress = true;
            this.channel.savedCurrentCountry = mapPostalCode(criteria.deliveryAddress.zipCode);
            const newAddress = await this.store.collectionAction('address', 'addDeliveryAddress', {
              ...criteria.deliveryAddress,
              basketId: this.basket.basket?.id,
            });

            if (newAddress) {
              set(criteria, 'deliveryAddress', newAddress);
            }
          } finally {
            this.savingAddress = false;
          }
        }
      }
      this.orderCriteria.searchOrderCriteria = criteria;

      this.showVendorResults(criteria);
    }

    this.analytics.trackEvent(AnalyticsEvents.SearchForLocations, () => ({
      [AnalyticsProperties.SelectedHandoffMode]: criteria.handoffMode.toString(),
      [AnalyticsProperties.SelectedTimeWantedMode]:
        TIME_WANTED_ANALYTICS_LABELS[criteria.timeWantedType],
      [AnalyticsProperties.Location]: getAddressFromOrderCriteria(criteria),
      [AnalyticsProperties.SearchForLocationDisabled]: isSubmitDisabled,
      [AnalyticsProperties.LocationSource]: locationSearchType,
    }));

    return resolve();
  }

  @action
  showVendorResults(criteria: OrderCriteria) {
    const address: Partial<SavedAddress> = isDelivery(criteria)
      ? criteria.deliveryAddress ?? {}
      : {};
    const searchAddress: string | undefined = isAtStore(criteria)
      ? criteria.searchAddress
      : undefined;
    const searchCoords: Coords | undefined = isAtStore(criteria)
      ? criteria.searchCoords
      : undefined;
    this.localVendorSearchParams = {
      addressId: address.id ?? '',
      address: address.streetAddress ?? searchAddress ?? '',
      building: address.building ?? '',
      city: address.city ?? '',
      zipCode: address.zipCode ?? '',
      handoffMode: criteria.handoffMode,
      timeWantedType: criteria.timeWantedType,
      timeWanted:
        isAdvance(criteria) && criteria.timeWanted ? criteria.timeWanted.toISOString() : undefined,
      latitude: searchCoords?.latitude,
      longitude: searchCoords?.longitude,
    };

    if (isDelivery(criteria)) {
      this.router.transitionTo('menu');
    } else {
      this.router.transitionTo('vendor-search-results');
    }
  }

  @action
  onSearchUnavailable(): void {
    set(this, 'vendorSearchAvailable', false);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::IndexRoute': typeof IndexRoute;
  }
}
