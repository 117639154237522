import { helper } from '@ember/component/helper';
import { isNone } from '@ember/utils';

import { Provider } from 'mobile-web/lib/payment';
import { Type } from 'mobile-web/lib/payment/card';
import BillingMembership from 'mobile-web/models/billing-membership';

function getIcon(membership: BillingMembership): string {
  switch (membership.cardType) {
    case Type.VISA:
      return 'visa';
    case Type.AMEX:
      return 'amex';
    case Type.Discover:
      return 'discover';
    case Type.Mastercard:
      return 'mastercard';
    default:
      if (isNone(membership.billingScheme)) {
        return membership.isGiftCard
          ? 'gift-card'
          : membership.isBrandedCard
          ? 'branded-card'
          : 'credit-card';
      }
      return membership.billingScheme.provider === Provider.CreditCard
        ? 'credit-card'
        : membership.billingScheme.provider === Provider.BrandedCard
        ? 'branded-card'
        : 'gift-card';
  }
}

export function membershipIcon(params: (BillingMembership | null | undefined)[] /*, hash*/) {
  return params[0] ? getIcon(params[0]) : 'gift-card';
}

const membershipIconHelper = helper(membershipIcon);

export default membershipIconHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'membership-icon': typeof membershipIconHelper;
  }
}
