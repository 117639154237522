import DS from 'ember-data';

export default class Application extends DS.RESTSerializer.extend({}) {
  normalize(modelClass: DS.Model, resourceHash: any, prop?: string) {
    return super.normalize(modelClass, resourceHash, prop);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  interface SerializerRegistry {
    application: Application;
  }
}
