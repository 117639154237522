import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { appleStoreLink, googleStoreLink } from 'mobile-web/lib/app-store';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';

import style from './index.m.scss';

type BadgeType = 'android' | 'ios';

interface Args {
  // Required arguments
  appIdentifier: string;
  type: BadgeType;

  // Optional arguments
}

interface Signature {
  Element: HTMLSpanElement;

  Args: Args;
}

export default class Badge extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get rootClass(): string {
    return [style.root, style[this.args.type] ?? ''].join(' ');
  }

  get linkId(): string {
    return `${this.args.type}-store-link`;
  }

  get href(): string {
    return this.args.type === 'ios'
      ? appleStoreLink(this.args.appIdentifier)
      : googleStoreLink(this.args.appIdentifier);
  }

  get imgSrc(): string {
    return this.args.type === 'ios'
      ? 'https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg'
      : 'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png';
  }

  get imgAltTextKey(): string {
    return `mwc.footer.${this.args.type}BadgeAltText`;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  track() {
    this.analytics.trackEvent(
      AnalyticsEvents.DownloadOnAppStore,
      () => ({
        [AnalyticsProperties.AppStore]: this.args.type,
      }),
      {
        isLink: true,
        domQuery: `#${this.linkId}`,
      }
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AppStoreBadges::Badge': typeof Badge;
  }
}
