import DS from 'ember-data';
import RSVP from 'rsvp';

import { host } from 'mobile-web/lib/hybrid-util';
import LoyaltyMembershipModel from 'mobile-web/models/loyalty-membership';

import ApplicationAdapter from './application';

export default class LoyaltyMembershipAdapter extends ApplicationAdapter {
  deleteRecord(_store: unknown, _type: unknown, snapshot: DS.Snapshot): RSVP.Promise<unknown> {
    const model = snapshot.record as LoyaltyMembershipModel;
    return this.ajax(
      this.buildURL('loyalty-membership', snapshot.id, snapshot, 'deleteRecord'),
      'DELETE',
      {
        // If we just do `data: model.membershipId` (no string), 0 is interpreted as null
        data: `${model.membershipId}`,
      }
    );
  }

  pathForType(): string {
    return 'loyalty/memberships';
  }

  urlForDeleteRecord(): string {
    return `${host()}/${this.namespace}/${this.pathForType()}`;
  }
}
