import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Ecc } from 'mobile-web/lib/utilities/qr-code-gen/Ecc';
import { QrCode } from 'mobile-web/lib/utilities/qr-code-gen/QrCode';

import style from './index.m.scss';

interface Args {
  // Required arguments
  inputText: string;
  border: number;

  // Optional arguments
}
interface Signature {
  Element: SVGElement;

  Args: Args;
}

export default class QrcodeSvg extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked qrCode: QrCode;

  // Getters and setters
  get svgViewBox(): string {
    return `0 0 ${this.qrCode.size + this.args.border * 2} ${
      this.qrCode.size + this.args.border * 2
    }`;
  }

  get svgPath(): string {
    const parts: Array<string> = [];
    const qr = this.qrCode;
    const border = this.args.border;

    for (let y = 0; y < qr.size; y++) {
      for (let x = 0; x < qr.size; x++) {
        if (qr.getModule(x, y)) parts.push(`M${x + border},${y + border}h1v1h-1z`);
      }
    }
    return parts.join(' ');
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.qrCode = QrCode.encodeText(this.args.inputText, Ecc.MEDIUM);
    if (this.args.border < 0) throw new RangeError('QR Code border must be non-negative');
  }

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    QrcodeSvg: typeof QrcodeSvg;
  }
}
