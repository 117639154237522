import Transition from '@ember/routing/transition';

/**
 * A no-op implementation of Transition to be used when the router will be
 * performing a full-page refresh.
 */
export class NoopTransition implements Transition {
  abort(): Transition<unknown> {
    return this;
  }
  catch<TResult = never>(
    _onRejected?: ((reason: unknown) => TResult | PromiseLike<TResult>) | undefined,
    _label?: string | undefined
  ): Promise<unknown> {
    return this.promise;
  }
  finally(_onFinally?: (() => void) | undefined, _label?: string | undefined): Promise<unknown> {
    return this.promise;
  }
  followRedirects(): Promise<unknown> {
    return this.promise;
  }
  method(_method?: string | undefined): Transition<unknown> {
    return this;
  }
  retry(): Transition<unknown> {
    return this;
  }
  then<TResult1 = unknown, TResult2 = never>(
    _onfulfilled?: ((value: unknown) => TResult1 | PromiseLike<TResult1>) | undefined,
    _onrejected?: ((reason: unknown) => TResult2 | PromiseLike<TResult2>) | undefined,
    _label?: string | undefined
  ): Promise<TResult1 | TResult2> {
    return this.promise as Promise<TResult1 | TResult2>;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  send(_ignoreFailure: boolean, _name: string): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  trigger(_ignoreFailure: boolean, _name: string): void {}
  [Symbol.toStringTag]?: string | undefined;
  data = {};
  // eslint-disable-next-line no-null/no-null
  readonly from = null;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  readonly promise = new Promise(() => {});
  readonly to = {
    attributes: {},
    // eslint-disable-next-line no-null/no-null
    child: null,
    localName: '',
    metadata: {},
    name: '',
    paramNames: [],
    params: {},
    // eslint-disable-next-line no-null/no-null
    parent: null,
    queryParams: {},
    find(): ReturnType<Transition['to']['find']> {
      return undefined;
    },
  };
}
