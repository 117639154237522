import { guidFor } from '@ember/object/internals';

/**
 * @summary
 * Generate a hash of unique IDs for a component.
 *
 * @param obj the object to associate the unique IDs with
 *
 * @param keys the keys to use for additional unique IDs. `'id'` is not allowed as a key as it is set automatically.
 *
 * @returns the hash of unique IDs
 *
 * @example <caption>Each key parameter becomes a key on the hash</caption>
 *
 * guids(this, 'foo', 'bar', 'baz')
 * // {
 * //   id: 'ember123',
 * //   foo: 'ember123-foo',
 * //   bar: 'ember-123-bar',
 * //   baz: 'ember-123-baz'
 * // }
 */
export const guids = <K extends string>(
  obj: unknown,
  ...keys: Exclude<K, 'id'>[]
): Record<K | 'id', string> => {
  const guid = guidFor(obj);

  const ids = {
    id: guid,
  } as Record<K | 'id', string>;

  for (const key of keys) {
    ids[key] = `${guid}-${key}`;
  }

  return ids;
};
