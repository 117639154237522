import { action, set } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import BasketBasketProductsEditController from 'mobile-web/controllers/basket/basket-products/edit';
import { PreviousRoute, vendorToMenuRoute } from 'mobile-web/lib/routing';
import { safeNext } from 'mobile-web/lib/runloop';
import Vendor from 'mobile-web/models/vendor';
import { ProductsRouteModel } from 'mobile-web/routes/menu/vendor/products';
import BasketService from 'mobile-web/services/basket';
import VendorService from 'mobile-web/services/vendor';

export default class BasketBasketProductsEditRoute extends Route {
  previousRoute!: PreviousRoute;

  @service('basket') basketService!: BasketService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service vendor!: VendorService;

  @readOnly('vendor.vendor')
  currentVendor!: Vendor;

  beforeModel(transition: Transition): void {
    if (!this.basketService.basket) {
      transition.abort();
      this.router.transitionTo('index');
    }
  }

  async model(params: { basket_product_id: number }): Promise<ProductsRouteModel> {
    const vendor = this.currentVendor;
    const basketProductId = params.basket_product_id;

    set(this, 'previousRoute', vendorToMenuRoute(vendor));

    const bp = await this.store.findRecord('basket-product', basketProductId);
    const product = await bp.get('product');
    const displayInModal = !product.hasChoices;
    this.basketService.activeBasketProduct = bp;

    return { basketProduct: bp, displayInModal, product, pageTitle: `Edit: ${bp.productName}` };
  }

  afterModel(model: ModelForRoute<this>): void {
    if (isNone(model.basketProduct!.basket)) {
      // basket-product has been removed. In testing, model.isDestroyed did not work, but this did.
      this.router.replaceWith('menu.vendor', this.vendor.vendorSlug!);
    }
  }

  @action
  loading(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controllerFor(
      'basket/basket-products/edit'
    ) as BasketBasketProductsEditController;
    controller.set('isLoading', true);

    transition.finally(() => {
      safeNext(this, () => {
        controller.set('isLoading', false);
      });
    });
  }
}
