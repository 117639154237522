import Service, { inject as service } from '@ember/service';

import IntlService from 'ember-intl/services/intl';

import BootstrapService from 'mobile-web/services/bootstrap';
import { ContentString } from 'mobile-web/services/content';

export default class PaymentService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service intl!: IntlService;

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  displayDeleteSavedCardsModalText(cardIsGiftCard: boolean): ContentString {
    let content: ContentString;
    if (
      this.bootstrap.data?.hasOloAuthProvider &&
      this.bootstrap.data?.isOloAuthLogin &&
      !cardIsGiftCard
    ) {
      content = this.intl.t('mwc.myAccount.confirmDeleteText.oloAuth', {
        p: 'margin-bottom: 2em',
        htmlSafe: true,
      });
    } else {
      content = this.intl.t('mwc.myAccount.confirmDeleteText.default');
    }
    return content;
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    payment: PaymentService;
  }
}
