import RSVP from 'rsvp';

import { host } from 'mobile-web/lib/hybrid-util';

import ApplicationAdapter from './application';

export default class TelemetryAdapter extends ApplicationAdapter {
  searchLocationsSkipped(): RSVP.Promise<unknown> {
    const url = `${host()}/api/telemetry/search-locations-skipped`;
    return this.ajax(url, 'POST');
  }
}
