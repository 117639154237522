export default {
  "loading": "_loading_ciq81k",
  "minor": "_minor_ciq81k",
  "major": "_major_ciq81k",
  "content": "_content_ciq81k",
  "label": "_label_ciq81k",
  "labelText": "_labelText_ciq81k",
  "iconWrapper": "_iconWrapper_ciq81k",
  "icon": "_icon_ciq81k"
};
