import { assert } from '@ember/debug';
import EmberObject from '@ember/object';
import { isNone } from '@ember/utils';

import config from 'mobile-web/config/environment';

import isSome from '../utilities/is-some';

const { localStorageNameSpace } = config;

type Options = {
  normalize?: (arg0: string) => unknown;
};

type Target = Prototype<typeof EmberObject>;

/**
 * Wraps a property such that changes are automatically stored in
 * `sessionStorage`.
 *
 * @deprecated This `@session` decorator has been superseded by the one in
 * `app/decorators/session.ts`. This decorator does not include tracking and
 * requires either `@computed` decorators on getters/setters, or hacky
 * work-arounds to set a `@tracked` property in parallel.
 *
 * Where possible remove usages of this in favor of the newer `@session`
 * decorator.
 */
export function session(target: AnyObject, propertyKey: string | symbol): void;
export function session(options: Options): PropertyDecorator;
export function session(
  targetOrOptions: Target | Options,
  propertyKey?: string | symbol
): void | PropertyDecorator {
  if (isSome(propertyKey)) {
    // called without options
    return sessionDecorator(targetOrOptions as Target, propertyKey);
  }
  // called with options
  const decorator: PropertyDecorator = (target, key) => {
    const options = targetOrOptions as Options;
    return sessionDecorator(target as Target, key, options);
  };
  return decorator;
}

function sessionDecorator(
  target: Target,
  key: string | symbol,
  options: Options = {}
): PropertyDecorator {
  if (typeof key === 'string') {
    const normalize = options.normalize ?? JSON.parse;
    Object.defineProperty(target, key, {
      get() {
        try {
          const value = sessionStorage.getItem(`${localStorageNameSpace}${key}`);
          return value ? normalize(value) : null; // eslint-disable-line no-null/no-null
        } catch (e) {
          return undefined;
        }
      },
      set(this: EmberObject, value) {
        try {
          if (isNone(value)) {
            sessionStorage.removeItem(`${localStorageNameSpace}${key}`);
          } else {
            sessionStorage.setItem(`${localStorageNameSpace}${key}`, JSON.stringify(value));
          }
          this.notifyPropertyChange(`${key}`);
          return true;
        } catch (e) {
          return false;
        }
      },
    });
  } else {
    assert('decorator key should be a string, but was ' + typeof key);
  }

  // Returning a descriptor is required for stage-2 decorators, even if it is just an empty object
  // Without the descriptor, this decorator will not work properly.
  return {} as PropertyDecorator;
}

export default session;
