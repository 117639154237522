import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import GroupOrderService from 'mobile-web/services/group-order';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  newGroupOrderId: string | undefined;
  onSwitch: Action;
  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class GroupOrderJoinNewGroupModal extends Component<Signature> {
  // Service injections
  @service groupOrder!: GroupOrderService;
  @service vendor!: VendorService;
  @service router!: RouterService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks
  switchGroupsTask = taskFor(this.switchGroupsTaskInstance);
  @task *switchGroupsTaskInstance() {
    yield this.groupOrder.endGroupOrder();
    this.groupOrder.groupOrderToJoin = this.args.newGroupOrderId;
    this.args.onSwitch();
  }

  // Actions and helpers
  @action
  onClose() {
    this.goBack();
  }

  @action
  goBack() {
    const slug = this.vendor.vendor?.slug;
    if (slug) {
      this.router.replaceWith('menu.vendor', slug);
    } else {
      this.router.replaceWith('index');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GroupOrder::JoinNewGroupModal': typeof GroupOrderJoinNewGroupModal;
  }
}
