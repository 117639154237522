import Route from '@ember/routing/route';
import { service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

export default class ArrivalRoute extends Route {
  @service store!: DS.Store;

  model(params: { id: EmberDataId; hash: string; message?: string; utm_medium?: string }) {
    return this.store
      .findRecord('order', params.id, { adapterOptions: { hash: params.hash } })
      .then(order =>
        RSVP.hash({
          order,
          utmMedium: params.utm_medium,
        })
      );
  }
}
