import { host } from 'mobile-web/lib/hybrid-util';

import ApplicationAdapter from './application';
export default class BillingSchemeAdapter extends ApplicationAdapter {
  buildURL(_modelName: unknown, id: number, _snapshot: unknown, requestType: string): string {
    if (requestType === 'balance') {
      return `${host()}/${this.namespace}/giftcards/${id}/balance`;
    }
    if (requestType === 'pinRequired') {
      return `${host()}/${this.namespace}/giftcards`;
    }
    return super.buildURL(...arguments);
  }
}
