export default {
  "container": "_container_1glcnz",
  "content": "_content_1glcnz",
  "externalText": "_externalText_1glcnz",
  "twoColumns": "_twoColumns_1glcnz",
  "columns": "_columns_1glcnz",
  "column": "_column_1glcnz",
  "section": "_section_1glcnz",
  "link": "_link_1glcnz",
  "icon": "_icon_1glcnz",
  "cards": "_cards_1glcnz",
  "card": "_card_1glcnz",
  "spacer": "_spacer_1glcnz",
  "description": "_description_1glcnz",
  "subText": "_subText_1glcnz",
  "accountSupport": "_accountSupport_1glcnz",
  "deleteAccount": "_deleteAccount_1glcnz",
  "oloAuthAccountHeading": "_oloAuthAccountHeading_1glcnz",
  "poweredByOlo": "_poweredByOlo_1glcnz",
  "poweredByOloIcon": "_poweredByOloIcon_1glcnz"
};
