import { inject as service } from '@ember/service';

import FeaturesService from 'mobile-web/services/features';

import ApplicationAdapter from './application';
import { buildOrderURL } from './order';

export default class OrderSearchResultAdapter extends ApplicationAdapter {
  @service features!: FeaturesService;

  urlForFindAll(this: OrderSearchResultAdapter): string {
    const baseUrl = this.buildURL('orders');
    return `${baseUrl}/recent`;
  }

  buildURL(
    _modelName?: unknown,
    id?: EmberDataId,
    _snapshot?: unknown,
    requestType?: string
  ): string {
    const url = buildOrderURL(this.namespace, requestType, id);
    return url ?? super.buildURL(...arguments);
  }

  shouldBackgroundReloadAll(): boolean {
    return false;
  }
}
