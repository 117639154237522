import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import fade from 'ember-animated/transitions/fade';
import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import dayjs, { dayIntervals, Dayjs, roundUpTime, timeIntervals } from 'mobile-web/lib/dayjs';
import { getHandoffKey, HandoffMode } from 'mobile-web/lib/order-criteria';
import TimeFormat from 'mobile-web/lib/time/format';
import { fixTimeSlots } from 'mobile-web/lib/time/time-slots';
import TimeWantedMode from 'mobile-web/models/time-wanted-mode';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';

import style from './index.m.scss';

const DAY_LABEL_FORMAT = 'dddd M/D';

type LabeledDateTime = {
  date: string;
  label: string;
};

interface Args {
  // Required arguments
  types: TimeWantedMode[];
  onTypeChange: (type: TimeWantedMode) => void;
  onTimeWantedChange: (timeWanted: Dayjs) => void;

  // Optional arguments
  type?: TimeWantedMode;
  handoffMode?: HandoffMode;
  timeWanted?: Dayjs;
  name?: string;
  label?: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class TimeWantedForm extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service intl!: IntlService;

  // Untracked properties
  style = style;
  transition = fade;
  previousHandoff = 'Unspecified';

  // Tracked properties
  @tracked days: LabeledDateTime[] = [];
  @tracked timeSlots: LabeledDateTime[] = [];

  // Getters and setters
  get name(): string {
    return this.args.name ?? 'time-wanted-form';
  }

  get label(): string {
    return this.args.label ?? this.name;
  }

  get handoffMode(): HandoffMode {
    return this.args.handoffMode ?? 'Unspecified';
  }

  get isAdvance(): boolean {
    return this.type.type === 'Advance';
  }

  get daySlotsDisabled(): boolean {
    return false;
  }

  get timeSlotsDisabled(): boolean {
    return isEmpty(this.timeSlots);
  }

  get day(): string {
    const fallbackValue = this.days.length > 0 ? this.days[0].date : '';

    if (this.isAdvance && this.args.timeWanted) {
      // If today, use the first of the day values we have available, so that we
      // match the value from when we constructed the form. If *not* today, use
      // the top-level day value (resetting to midnight UTC) so that we properly
      // select the "day" there. The fallback value will go all the way down to
      // being an empty string in the case that there are no loaded days
      // whatsoever.
      const isToday = dayjs().dayOfYear() === this.args.timeWanted.dayOfYear();
      return isToday ? fallbackValue : this.args.timeWanted.startOf('day').toISOString();
    }
    return fallbackValue;
  }

  set day(newDay: string) {
    const dayWanted = dayjs(newDay);
    const now = dayjs();
    // Use *right now* as the starting point if the selected day is today,
    // so that users always get all available future time slots.
    const startTime = dayWanted.isSame(dayjs(), 'day') ? now : dayWanted.startOf('day');
    this.getTimeSlotsTask.perform(startTime);
  }

  get timeSlot(): string {
    return this.isAdvance && this.args.timeWanted
      ? this.args.timeWanted.toISOString()
      : !isEmpty(this.timeSlots)
      ? this.timeSlots[0].date
      : '';
  }

  set timeSlot(newTimeSlot: string) {
    this.args.onTimeWantedChange(dayjs(newTimeSlot));
  }

  get type(): TimeWantedMode {
    return this.args.type ?? this.args.types[0];
  }

  // Lifecycle methods

  // Other methods
  setDefaultTimeSlot(): void {
    if (this.isAdvance && !isEmpty(this.timeSlots)) {
      if (this.args.timeWanted) {
        const tsMatch = this.timeSlots.find(ts => {
          const tsDate = dayjs(ts.date);
          return (
            tsDate.hour() === this.args.timeWanted!.hour() &&
            tsDate.minute() === this.args.timeWanted!.minute()
          );
        });
        if (tsMatch) {
          this.timeSlot = tsMatch.date;
        } else {
          this.timeSlot = this.timeSlots[0].date;
        }
      } else {
        this.timeSlot = this.timeSlots[0].date;
      }
    }
  }

  // Tasks
  getDaysTask = taskFor(this.getDaysGenerator);
  @task *getDaysGenerator(): TaskGenerator<void> {
    this.previousHandoff = this.handoffMode;
    const firstAvailable = roundUpTime(dayjs().add(15, 'minute'), 15);
    const advanceOrderDays = this.channel.settings?.advanceOrderDays ?? 0;
    const totalDays = advanceOrderDays + 1;

    if (this.basket.basket && this.args.handoffMode) {
      yield this.basket
        .basket!.getOrderDays({
          handoffModeChar: getHandoffKey(this.args.handoffMode),
        })
        .then(orderDays => {
          this.days = orderDays.map(d => ({
            date: dayjs(d).startOf('day').toISOString(),
            label: TimeFormat.day(dayjs(d), { format: DAY_LABEL_FORMAT }),
          }));
        });
    } else {
      this.days = dayIntervals(totalDays, firstAvailable).map(d => ({
        date: d.toISOString(),
        label: TimeFormat.day(d, { format: DAY_LABEL_FORMAT }),
      }));
    }

    // If the time-wanted is already set, load the slots for the *selected* time.
    let newDay = '';
    if (this.args.timeWanted) {
      newDay = this.args.timeWanted.startOf('day').toISOString();
    }
    // If we didn't have a selected time or the selected time is invalid, load the *first* day's time-slots as a default.
    if (this.days.length > 0 && !this.days.find(d => d.date === newDay)) {
      newDay = this.days[0].date;
    }
    this.day = newDay;
  }

  getTimeSlotsTask = taskFor(this.getTimeSlotsGenerator);
  @task *getTimeSlotsGenerator(start: Dayjs): TaskGenerator<void> {
    const basket = this.basket.basket;
    if (basket && this.args.handoffMode) {
      yield basket
        .getTimeSlots({
          date: start.format('MM/DD/YYYY'),
          handoffModeChar: getHandoffKey(this.args.handoffMode),
        })
        .then(response => fixTimeSlots(response, basket.vendor.get('timeZoneId')!))
        .then(({ timeSlots }) => {
          if (timeSlots) {
            this.timeSlots = timeSlots.map(ts => {
              const localBaseTime = dayjs(ts);

              return {
                date: localBaseTime.toISOString(),
                label: localBaseTime.format(TimeFormat.TIME_FORMAT),
              };
            });
            this.setDefaultTimeSlot();
          }

          return [];
        });
    } else {
      this.timeSlots = timeIntervals(96, 15, start, true).map(t => ({
        date: t.toISOString(),
        label: t.format(TimeFormat.TIME_FORMAT),
      }));
      this.setDefaultTimeSlot();
    }
  }

  // Actions and helpers
  @action
  handleDayChange(dayValue: string) {
    const updatedDay = dayjs(dayValue);
    const timeWanted = this.args.timeWanted ?? dayjs();
    timeWanted.day(updatedDay.day());
    this.args.onTimeWantedChange(timeWanted);
  }

  @action
  handleTimeSlotChange(timeSlotValue: string) {
    const updatedTime = dayjs(timeSlotValue);
    this.args.onTimeWantedChange(updatedTime);
  }

  @action
  handoffUpdated() {
    /**
     * This can be fired when the handoff mode didn't actually change,
     * so we need to keep track to make sure we only update the days
     * when it actually changes to a new value
     */
    if (this.args.type?.type === 'Advance' && this.handoffMode !== this.previousHandoff) {
      this.getDaysTask.perform();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TimeWantedForm: typeof TimeWantedForm;
  }
}
