import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

type Matcher = Action<[string], boolean>;

export default class LoadingService extends Service {
  // Service injections
  @service router!: RouterService;

  // Untracked properties

  // Tracked properties
  @tracked transition?: Transition;

  // Getters and setters
  get isAuth(): boolean {
    const isLogin = this.matches('login', url => url.startsWith('/login'));
    return isLogin || this.isAuthCheckout || this.isAuthCreateAccount || this.isGroupOrderStart;
  }

  get isAuthCheckout(): boolean {
    return this.matches('checkout.auth', '/checkout/auth');
  }

  get isAuthCreateAccount(): boolean {
    return this.matches('signup', '/login', 'mode=1');
  }

  get isCheckout(): boolean {
    return this.matches('checkout', '/checkout');
  }

  get isGroupOrderStart(): boolean {
    return this.matches('group-order.start', '/group-order/start');
  }

  get isLocations(): boolean {
    return this.matches('locations', '/locations');
  }

  get isMenu(): boolean {
    const m: Matcher = s => s.includes('menu') && !s.includes('reward');
    return this.matches(m, m);
  }

  get isMenuProduct(): boolean {
    const m: Matcher = s => s.includes('products');
    return this.matches(m, m);
  }

  get isMenuViewAll(): boolean {
    return this.matches('menu.category', url => url.includes('categories'));
  }

  get isMyAccount(): boolean {
    return this.matches('my-account', '/my-account');
  }

  get isPassword(): boolean {
    return this.isPasswordChange || this.isPasswordReset;
  }

  get isPasswordChange(): boolean {
    return this.matches('my-account.change-password', '/my-account/change-password');
  }

  get isPasswordForgot(): boolean {
    return this.matches('forgot-password', '/forgot-password');
  }

  get isPasswordReset(): boolean {
    return this.matches('reset-password', '/resetpassword');
  }

  get isPay(): boolean {
    return this.matches('pay', s => s.startsWith('/pay'));
  }

  get isSearch(): boolean {
    return this.matches(
      to =>
        [
          'favorites',
          'recent-orders',
          'region-results',
          'rewards-search',
          'vendor-search-results',
          'vendor.rewards',
        ].includes(to),
      url =>
        ['/favorites', '/locations', '/recent-orders', '/rewards', '/search'].some(s =>
          url.includes(s)
        )
    );
  }

  get isSplash(): boolean {
    return this.router.currentRouteName === 'index';
  }

  get isThankYou(): boolean {
    return this.matches(
      to => ['order-summary', 'thank-you'].includes(to),
      url => ['/orders', '/thank-you'].some(s => url.startsWith(s))
    );
  }

  // Lifecycle methods
  constructor(properties: UnknownObject) {
    super(properties);

    this.router.on('routeWillChange', this.transitioning);
  }

  // Other methods
  matches(
    transitionToMatch: string | Matcher,
    urlToMatch: string | Matcher,
    queryParamsToMatch?: string
  ): boolean {
    if (this.transition) {
      return typeof transitionToMatch === 'string'
        ? this.transition.to.name === transitionToMatch
        : transitionToMatch(this.transition.to.name);
    }
    if (!this.router.currentURL) {
      return false;
    }
    if (typeof urlToMatch === 'string') {
      const urlParts = this.router.currentURL.split('?');
      return (
        urlParts[0] === urlToMatch && (!queryParamsToMatch || urlParts[1] === queryParamsToMatch)
      );
    }
    return urlToMatch(this.router.currentURL);
  }

  // Tasks

  // Actions and helpers
  @action
  transitioning(transition: Transition): void {
    // Types incorrectly say that `to` is always defined.
    // Checking here means we need not check again in getter
    if (transition.to && transition.to.name !== 'application_loading') {
      this.transition = transition;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    loading: LoadingService;
  }
}
