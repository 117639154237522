export default {
  "root": "_root_1atkiw",
  "steps": "_steps_1atkiw",
  "step": "_step_1atkiw",
  "activeStep": "_activeStep_1atkiw",
  "completedStep": "_completedStep_1atkiw",
  "errorStep": "_errorStep_1atkiw",
  "infoStep": "_infoStep_1atkiw",
  "stepIcon": "_stepIcon_1atkiw",
  "iconSvg": "_iconSvg_1atkiw",
  "stepLabel": "_stepLabel_1atkiw"
};
