import DS from 'ember-data';

import { TimeWantedType } from 'mobile-web/lib/order-criteria';
import HandoffMode from 'mobile-web/models/handoff-mode';

export default class TimeWantedMode extends DS.Model {
  @DS.attr('string')
  label!: string;
  @DS.attr('number')
  value!: number;
  @DS.attr('string')
  type!: TimeWantedType;
  @DS.attr('boolean')
  isPrivate?: boolean;

  @DS.hasMany('handoff-mode', { async: false })
  handoffModes!: DS.ManyArray<HandoffMode>;
}
