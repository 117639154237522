// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { AccountAction } from 'mobile-web/adapters/account';
import { HeadingLevel } from 'mobile-web/components/heading';
import { StaticContentType } from 'mobile-web/components/static-content-modal';
import { phoneMask } from 'mobile-web/lib/contact-number-rules';
import { Code as CountryCode } from 'mobile-web/lib/country';
import {
  GuestUser,
  MAX_EMAIL_LENGTH,
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
} from 'mobile-web/lib/customer';
import { buildOloErrorHandler } from 'mobile-web/lib/errors';
import { isDineInJunkEmail, isJunkFirstName } from 'mobile-web/lib/on-premise';
import { isErr } from 'mobile-web/lib/result';
import { PASSWORD_MINLENGTH } from 'mobile-web/lib/security';
import Validation, { ValidationConfig, ValidationResult } from 'mobile-web/lib/validation';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BusService from 'mobile-web/services/bus';
import ChallengeService from 'mobile-web/services/challenge';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import OnPremiseService, {
  ON_PREMISE_JUNK_LAST_NAME,
  ON_PREMISE_JUNK_PHONE_NUMBER,
} from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

class Model {
  @tracked firstName = '';
  @tracked lastName = '';
  @tracked email = '';
  @tracked contactNumber = '';
  @tracked password = '';
  @tracked confirmPassword = '';
  @tracked acceptedTerms = false;
  @tracked lastOrderId?: EmberDataId;
  @tracked previousOrderIds?: EmberDataId[];
  @tracked optIn = false;
}

interface Args {
  // Required arguments
  onSubmit: Action;
  onSubmitError: (error: unknown) => void;

  // Optional arguments
  guestUser?: GuestUser;
  headingLevel?: HeadingLevel;
  isUpgrade?: boolean;
  lastOrderId?: EmberDataId;
  previousOrderIds?: EmberDataId[];
  optIn?: boolean;
}

interface Signature {
  Element: HTMLFormElement;
  Args: Args;
}

export default class CreateAccountForm extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bus!: BusService;
  @service challenge!: ChallengeService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service onPremise!: OnPremiseService;

  // Untracked properties
  phoneMask = phoneMask;
  maxFirstNameLength = MAX_FIRST_NAME_LENGTH;
  maxLastNameLength = MAX_LAST_NAME_LENGTH;
  maxEmailLength = MAX_EMAIL_LENGTH;
  style = style;

  // Tracked properties
  @tracked model: Model;
  @tracked originalFirstName: string;
  @tracked originalLastName: string;
  @tracked originalEmail: string;
  @tracked validationResult?: ValidationResult;

  // Getters and setters
  get validationConfig(): ValidationConfig<Model> {
    return {
      bindings: [
        {
          targetProp: 'acceptedTerms',
          ruleName: 'truthful',
          message: 'Please check the Terms & Conditions box',
        },
        {
          targetProp: 'firstName',
          ruleName: 'notBlank',
          message: 'First name must be set',
        },
        {
          targetProp: 'lastName',
          ruleName: 'notBlank',
          message: 'Last name must be set',
        },
        {
          targetProp: 'email',
          ruleName: 'email',
          message: 'Email address must be set and valid',
        },
        {
          targetProp: 'contactNumber',
          ruleName: 'phone',
          message: 'Phone number must be set and valid',
        },
        {
          targetProp: 'password',
          ruleName: 'minLength',
          message: this.intl.t('mwc.password.minLength', { length: PASSWORD_MINLENGTH }),
        },
      ],
    };
  }

  get displayUpgradeForm(): boolean {
    return (
      (!!this.args.lastOrderId || this.onPremise.hasOpenCheck) &&
      !!this.args.isUpgrade &&
      !!this.args.guestUser
    );
  }

  get firstNameDisabled(): boolean {
    return this.displayUpgradeForm && !isJunkFirstName(this.originalFirstName);
  }

  get lastNameDisabled(): boolean {
    return this.displayUpgradeForm && this.originalLastName !== ON_PREMISE_JUNK_LAST_NAME;
  }

  get emailDisabled(): boolean {
    return (
      this.displayUpgradeForm && !isDineInJunkEmail(this.originalEmail) && !!this.originalEmail
    );
  }

  @computed('channel.currentCountry')
  get _currentCountry(): CountryCode | undefined {
    return this.channel.currentCountry;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set _currentCountry(_value: CountryCode | undefined) {}

  get displayCountrySelector(): boolean {
    return this.channel.countries.length > 1;
  }

  get headingLevel(): HeadingLevel {
    return this.args.headingLevel ?? 1;
  }

  get isSubmitDisabled(): boolean {
    const model = this.model;
    return (
      !model.acceptedTerms ||
      !model.firstName ||
      !model.lastName ||
      !model.email ||
      !model.contactNumber ||
      !model.password
    );
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const model = new Model();

    this.originalFirstName = this.args.guestUser?.firstName ?? '';
    model.firstName = isJunkFirstName(this.originalFirstName)
      ? ''
      : this.originalFirstName;

    this.originalLastName = this.args.guestUser?.lastName ?? '';
    if (this.args.guestUser?.lastName !== ON_PREMISE_JUNK_LAST_NAME) {
      model.lastName = this.args.guestUser?.lastName ?? '';
    }

    this.originalEmail = this.args.guestUser?.emailAddress ?? '';
    model.email = isDineInJunkEmail(this.originalEmail) ? '' : this.originalEmail;

    if (this.args.guestUser?.contactNumber !== ON_PREMISE_JUNK_PHONE_NUMBER) {
      model.contactNumber = this.args.guestUser?.contactNumber ?? '';
    }

    model.lastOrderId = this.args.lastOrderId;
    model.previousOrderIds = this.args.previousOrderIds;
    model.optIn = this.args.optIn ?? false;

    this.model = model;

    this.analytics.trackEvent(AnalyticsEvents.ViewCreateAccount, undefined, { bucket: 'all' });
  }

  // Other methods

  // Tasks
  submitTask = taskFor(this.submitTaskFn);
  @task *submitTaskFn(): TaskGenerator<void> {
    this.validationResult = Validation.validate(this.model, this.validationConfig);
    if (isErr(this.validationResult)) {
      return;
    }

    const model = this.model;
    model.confirmPassword = model.password;
    const adapter = this.store.adapterFor('account');

    const userAction = this.displayUpgradeForm ? AccountAction.Upgrade : AccountAction.Register;

    yield taskFor(this.challenge.request).perform(
      () =>
        adapter[userAction](model).then(async response => {
          const { user } = response;
          await this.session.setUserFromPayload(user);
          try {
            await this.analytics.trackEvent(AnalyticsEvents.CreateAccount);
          } catch (err) {
            this.error.sendExternalError(err);
          }
          return this.args.onSubmit();
        }),
      buildOloErrorHandler('register-submit-error', error => {
        this.args.onSubmitError(error);
      })
    );
  }

  // Actions and helpers
  @action
  showTerms(): void {
    this.bus.trigger('showStaticContent', {
      type: StaticContentType.USER_AGREEMENT,
      buttonLabel: 'Acknowledge Terms',
      onButtonClick: () => {
        this.model.acceptedTerms = true;
      },
    });
  }

  @action
  submit(e: Event) {
    e.preventDefault();
    this.submitTask.perform();
  }

  @action
  handleCountryChange(code: CountryCode) {
    this.channel.savedCurrentCountry = code;
    this.model.optIn = this.channel.countrySettings[code]?.optIn ?? false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CreateAccountForm: typeof CreateAccountForm;
  }
}
