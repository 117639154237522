import DS from 'ember-data';

export default class LoyaltyAccountSerializer extends DS.RESTSerializer.extend(
  DS.EmbeddedRecordsMixin
) {
  primaryKey = 'schemeId';
  attrs = {
    qualifyingLoyaltyRewards: {
      key: 'qualifyingRewards',
      embedded: 'always',
    },
  };
  normalize(
    modelClass: DS.Model,
    resourceHash: {
      membershipId?: EmberDataId;
      vendorId?: EmberDataId;
    },
    prop?: string
  ): UnknownObject {
    const { membershipId, vendorId } = this.store.adapterFor('loyalty-account');
    resourceHash.membershipId = membershipId;
    resourceHash.vendorId = vendorId;
    return super.normalize(modelClass, resourceHash, prop);
  }
}
