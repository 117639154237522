export default {
  "header": "_header_x3xzqb _header_1hgyd2",
  "headerText": "_headerText_x3xzqb",
  "orderTime": "_orderTime_x3xzqb",
  "headerButton": "_headerButton_x3xzqb",
  "productsContainer": "_productsContainer_x3xzqb",
  "productList": "_productList_x3xzqb",
  "notifications": "_notifications_x3xzqb",
  "content": "_content_x3xzqb",
  "icon": "_icon_x3xzqb",
  "iconSm": "_iconSm_x3xzqb",
  "iconLg": "_iconLg_x3xzqb",
  "emptyContainer": "_emptyContainer_x3xzqb",
  "emptyHeader": "_emptyHeader_x3xzqb",
  "emptySection": "_emptySection_x3xzqb",
  "emptySubheader": "_emptySubheader_x3xzqb",
  "emptyText": "_emptyText_x3xzqb",
  "minimums": "_minimums_x3xzqb",
  "minimumMessage": "_minimumMessage_x3xzqb",
  "footer": "_footer_x3xzqb",
  "checkoutButtonContainer": "_checkoutButtonContainer_x3xzqb",
  "checkoutButton": "_checkoutButton_x3xzqb",
  "closeTabButtonContainer": "_closeTabButtonContainer_x3xzqb",
  "debugButton": "_debugButton_x3xzqb",
  "debugInput": "_debugInput_x3xzqb"
};
