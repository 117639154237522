import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import dayjs from 'mobile-web/lib/dayjs';
import MwcIntl from 'mobile-web/services/mwc-intl';

export interface Signature {
  Args: {
    Positional: Date[];
  };
  Return: string;
}

export default class FormatRelativeDate extends Helper<Signature> {
  @service mwcIntl!: MwcIntl;

  compute(params?: Signature['Args']['Positional']) {
    if (!isEmpty(params)) {
      return this.mwcIntl.relativeDate(dayjs(params![0]));
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-relative-date': typeof FormatRelativeDate;
  }
}
