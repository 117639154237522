import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BasicDropdownSignature } from 'ember-basic-dropdown/components/basic-dropdown';

import style from './index.m.scss';

type BasicDropdownAPI = BasicDropdownSignature['Blocks']['default'][0];

interface Args {
  // Required arguments

  // Optional arguments
  align?: 'center' | 'left' | 'right';
}

interface Signature {
  Element: HTMLElement;

  Args: Args;

  Blocks: {
    button: [BasicDropdownAPI];
    content: [BasicDropdownAPI];
  };
}

export default class Dropdown extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;
  triggerResizeObserver = new ResizeObserver(this.setTriggerWidth);

  // Tracked properties
  @tracked triggerWidth = 0;

  // Getters and setters
  get align() {
    return this.args.align ?? 'left';
  }

  get contentStyle() {
    return htmlSafe(`--trigger-width: ${this.triggerWidth}px`);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  setTriggerWidth(entries: ResizeObserverEntry[]) {
    for (const entry of entries) {
      this.triggerWidth = entry.target.getBoundingClientRect().width;
      break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Dropdown: typeof Dropdown;
  }
}
