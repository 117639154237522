import Component from '@glimmer/component';

import { guids } from 'mobile-web/lib/utilities/guids';
import { ContentString } from 'mobile-web/services/content';

import style from './index.m.scss';

interface Args {
  // Required arguments
  name: string;

  // Optional arguments
  description?: string;
  header?: ContentString;
  onEdit?: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
    action: [];
  };
}

export default class EditableSection extends Component<Signature> {
  // Service injections

  // Untracked properties
  ids = guids(this, 'heading');
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EditableSection: typeof EditableSection;
  }
}
