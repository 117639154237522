import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';

import OnPremiseService from 'mobile-web/services/on-premise';
import VendorService from 'mobile-web/services/vendor';

type Params = { vendor_slug: string };

export default class PaySelectRoute extends Route {
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service vendor!: VendorService;

  beforeModel(transition: Transition) {
    if (!this.onPremise.tablePosRef) {
      const params = transition.to.params as Params;
      this.router.transitionTo('pay', params.vendor_slug);
    }
  }

  async model(params: Params) {
    await this.vendor.ensureVendorLoaded(params.vendor_slug);

    const checks = await this.onPremise.fetchTickets();
    const sortedByUnpaidCheck = checks
      .toArray()
      .sort((a, b) => (a.totals.due > 0 ? -1 : 1) - (b.totals.due > 0 ? -1 : 1));

    return sortedByUnpaidCheck;
  }

  async afterModel(models: ModelForRoute<this>) {
    if (this.onPremise.isSingleCheck(models)) {
      const ticket = models[0];

      await this.onPremise.payAtTableCheckout(ticket);
    }
  }
}
