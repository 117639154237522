import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';

import style from './index.m.scss';

export default class AccountSupport extends Component {
  // Service injections
  @service analytics!: AnalyticsService;
  @service intl!: IntlService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isExpanded = false;

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  trackOpen(expanded: boolean) {
    this.isExpanded = expanded;
    if (!expanded) {
      return;
    }
    this.analytics.trackEvent(AnalyticsEvents.ViewAccountSupport, undefined, { bucket: 'all' });
  }

  @action
  trackClick() {
    this.analytics.trackEvent(AnalyticsEvents.HelpCenterClicked, undefined, { bucket: 'all' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountSupport: typeof AccountSupport;
  }
}
