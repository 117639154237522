import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import GroupOrderService, { GroupOrderParticipantStatus } from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;
}

class GroupOrderStatusData {
  statusIcon!: string;
  statusIconStyle!: string;
  statusHeadingTextId!: string;
  statusDescriptionTextId!: string;
}

export default class GroupOrderParticipantConfirmationRoute extends Component<Signature> {
  private createdStatus: GroupOrderStatusData = {
    statusIcon: 'clock',
    statusIconStyle: style.waitingIcon,
    statusHeadingTextId: 'mwc.groupOrderParticipantConfirmation.orderStatusWaitingHeading',
    statusDescriptionTextId: 'mwc.groupOrderParticipantConfirmation.orderStatusWaitingText',
  };

  private lockedOrConfirmedStatus: GroupOrderStatusData = {
    statusIcon: 'check',
    statusIconStyle: style.submittedIcon,
    statusHeadingTextId: 'mwc.groupOrderParticipantConfirmation.orderStatusSubmittedHeading',
    statusDescriptionTextId: 'mwc.groupOrderParticipantConfirmation.orderStatusSubmittedText',
  };

  private failedOrCancelledStatus: GroupOrderStatusData = {
    statusIcon: 'x',
    statusIconStyle: style.cancelledIcon,
    statusHeadingTextId: 'mwc.groupOrderParticipantConfirmation.orderStatusCancelledHeading',
    statusDescriptionTextId: 'mwc.groupOrderParticipantConfirmation.orderStatusCancelledText',
  };

  // Service injections
  @service channel!: ChannelService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service storage!: StorageService;
  @service analytics!: AnalyticsService;
  @service groupOrder!: GroupOrderService;
  @service vendor!: VendorService;
  @service basket!: BasketService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get basketTotal(): number {
    return this.basket.displayTotal;
  }

  get groupOrderStatusData(): GroupOrderStatusData {
    switch (this.groupOrder.groupOrder?.status.toLowerCase()) {
      case 'created':
        return this.createdStatus;
      case 'locked':
      case 'confirmed':
        return this.lockedOrConfirmedStatus;
      case 'failed':
      case 'cancelled':
      default:
        return this.failedOrCancelledStatus;
    }
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  async orderMore() {
    const slug = this.vendor.vendor?.get('slug');
    if (!this.groupOrder.canEditGroupOrder) {
      this.onPremise.basketStartOver(); //TK: what is this
    }
    if (this.groupOrder.isParticipantMode) {
      await this.groupOrder.groupOrder?.updateParticipantStatus({
        participantName: this.groupOrder.currentUserName,
        status: GroupOrderParticipantStatus.InProgress,
      });
    }
    if (slug) {
      this.router.transitionTo('menu.vendor', slug);
    } else {
      this.router.transitionTo('index');
    }
    if (this.onPremise.experienceType === OnPremiseExperience.ClosedCheck) {
      this.analytics.trackEvent(AnalyticsEvents.OrderMore);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::GroupOrder::ParticipantConfirmation': typeof GroupOrderParticipantConfirmationRoute;
  }
}
