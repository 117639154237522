import { assert } from '@ember/debug';
import EmberObject from '@ember/object';
import { isNone } from '@ember/utils';

import config from 'mobile-web/config/environment';
import isSome from 'mobile-web/lib/utilities/is-some';
import { getLocalStorage } from 'mobile-web/lib/utilities/local-storage';

const { localStorageNameSpace } = config;

type Options = {
  defaultValue?: unknown;
};

type Target = Prototype<typeof EmberObject>;

/**
 * Wraps a property such that changes are automatically stored in
 * `localStorage`.
 *
 * @deprecated This `@local` decorator has been superseded by the one in
 * `app/decorators/local.ts`. This decorator does not include tracking and
 * requires either `@computed` decorators on getters/setters, or hacky
 * work-arounds to set a `@tracked` property in parallel.
 *
 * Where possible remove usages of this in favor of the newer `@local`
 * decorator.
 */
export function local(target: AnyObject, propertyKey: string | symbol): void;
export function local(options: Options): PropertyDecorator;
export function local(
  targetOrOptions: Target | Options,
  propertyKey?: string | symbol
): void | PropertyDecorator {
  if (isSome(propertyKey)) {
    // called without options
    return localDecorator(targetOrOptions as Target, propertyKey);
  }
  // called with options
  const decorator: PropertyDecorator = (target, key) => {
    const ls = getLocalStorage();
    if (typeof key === 'string') {
      const options = targetOrOptions as Options;
      if (isNone(ls.getItem(`${localStorageNameSpace}${key}`)) && isSome(options?.defaultValue)) {
        ls.setItem(`${localStorageNameSpace}${key}`, JSON.stringify(options?.defaultValue));
      }
    }
    return localDecorator(target as Target, key);
  };
  return decorator;
}

function localDecorator(target: Target, key: string | symbol): void {
  if (typeof key === 'string') {
    Object.defineProperty(target, key, {
      get() {
        const ls = getLocalStorage();
        try {
          const value = ls.getItem(`${localStorageNameSpace}${key}`);
          return value ? JSON.parse(value) : null; // eslint-disable-line no-null/no-null
        } catch (e) {
          return undefined;
        }
      },
      set(this: EmberObject, value) {
        const ls = getLocalStorage();
        try {
          if (isNone(value)) {
            ls.removeItem(`${localStorageNameSpace}${key}`);
          } else {
            ls.setItem(`${localStorageNameSpace}${key}`, JSON.stringify(value));
          }
          this.notifyPropertyChange(`${key}`);
          return true;
        } catch (e) {
          return false;
        }
      },
    });
  } else {
    assert('decorator key should be a string, but was ' + typeof key);
  }

  // Returning a descriptor is required for stage-2 decorators, even if it is just an empty object
  // Without the descriptor, this decorator will not work properly.
  return {} as unknown as void;
}

export default local;
