import DS from 'ember-data';

import { isArray } from 'mobile-web/lib/utilities/_';

const { Transform } = DS;

export default class ArrayTransform extends Transform {
  deserialize(value: unknown) {
    return isArray(value) ? value : [];
  }

  serialize(value: unknown) {
    return isArray(value) ? value : [];
  }
}
