import Component from '@glimmer/component';

import style from './index.m.scss';

interface Signature {
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

export default class HeadingSectionSubsection extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HeadingSection::Subsection': typeof HeadingSectionSubsection;
  }
}
