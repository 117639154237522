import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import LoadingService from 'mobile-web/services/loading';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;
}

export default class ApplicationLoading extends Component<Signature> {
  // Service injections
  @service loading!: LoadingService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ApplicationLoading: typeof ApplicationLoading;
  }
}
