import buildErrorHandler, { squelchErrorHandlerFor } from 'ember-test-friendly-error-handler';

const ERROR_HANDLERS = [
  'Ember.onerror',
  'Olo.reportError',
  'app-load-failure',
  'apply-discount-failure',
  'external-login-failure',
  'login-failure',
  'register-submit-error',
  'remove-discount-error',
  'set-country-error',
] as const;

type ErrorHandler = (typeof ERROR_HANDLERS)[number];

export function squelchAllErrorHandlers(): void {
  ERROR_HANDLERS.forEach(squelchErrorHandlerFor);
}

export function buildOloErrorHandler(
  label: ErrorHandler,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (reason: any) => any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (reason: any) => any {
  return buildErrorHandler(label, callback);
}

export class FriendlyError extends Error {
  messageHeader: string;
  friendlyMessage: string;

  constructor(messageHeader: string, friendlyMessage: string) {
    super(friendlyMessage);
    this.messageHeader = messageHeader;
    this.friendlyMessage = friendlyMessage;
  }
}

// Source: https://github.com/ololabs/platform/blob/develop/MoboLogic/src/FailCategory.cs
export enum ErrorCategory {
  None = 'None',
  Unspecified = 'Unspecified',
  OloInternalError = 'OloInternalError',
  OloInternalDatabaseError = 'OloInternalDatabaseError',
  AlreadySubmitted = 'AlreadySubmitted',
  CapacityThrottled = 'CapacityThrottled',
  CouponRequirementsNotMet = 'CouponRequirementsNotMet',
  CouponRedemptionFailed = 'CouponRedemptionFailed',
  CircuitBroken = 'CircuitBroken',

  DispatchTimeout = 'DispatchTimeout',
  DispatchApiError = 'DispatchApiError',
  DispatchProvidersUnavailable = 'DispatchProvidersUnavailable',
  DispatchQuoteChanged = 'DispatchQuoteChanged',
  DispatchInvalidCustomerInformation = 'DispatchInvalidCustomerInformation',
  DispatchInvalidVendorInformation = 'DispatchInvalidVendorInformation',

  ExpoDeviceCodeInvalid = 'ExpoDeviceCodeInvalid',
  ExpoUserInactive = 'ExpoUserInactive',
  ExpoUserInformationInvalid = 'ExpoUserInformationInvalid',

  FaxServiceUnavailable = 'FaxServiceUnavailable',
  FraudRisk = 'FraudRisk',
  FraudNetworkRisk = 'FraudNetworkRisk',
  HandoffRequirementsNotMet = 'HandoffRequirementsNotMet',
  IllegalInput = 'IllegalInput',

  LoyaltyUnspecified = 'LoyaltyUnspecified',
  LoyaltyMappingError = 'LoyaltyMappingError',
  LoyaltyRedemptionDeclined = 'LoyaltyRedemptionDeclined',
  LoyaltyServiceUnavailable = 'LoyaltyServiceUnavailable',
  LoyaltyMisconfiguration = 'LoyaltyMisconfiguration',
  LoyaltyVendorMisconfiguration = 'LoyaltyVendorMisconfiguration',
  LoyaltyInvalidOrder = 'LoyaltyInvalidOrder',
  LoyaltyAccessDenied = 'LoyaltyAccessDenied',
  LoyaltyResponseTimeout = 'LoyaltyResponseTimeout',
  LoyaltyProviderApiError = 'LoyaltyProviderApiError',
  LoyaltyInternalError = 'LoyaltyInternalError',
  LoyaltyMembershipInputError = 'LoyaltyMembershipInputError',
  LoyaltyAccountInactive = 'LoyaltyAccountInactive',
  LoyaltyUserError = 'LoyaltyUserError',
  LoyaltyExpected = 'LoyaltyExpected',
  LoyaltyUnexpected = 'LoyaltyUnexpected',

  MenuItemOutOfHours = 'MenuItemOutOfHours',
  MenuItemEightySixed = 'MenuItemEightySixed',
  MenuItemDeleted = 'MenuItemDeleted',
  MenuItemFiltered = 'MenuItemFiltered',
  MenuItemNotOffered = 'MenuItemNotOffered',

  OrderingNotSupported = 'OrderingNotSupported',
  OutsideDeliveryRange = 'OutsideDeliveryRange',
  PastStartMakingTime = 'PastStartMakingTime',
  OrderRequirementsNotMet = 'OrderRequirementsNotMet',

  PaymentUnspecified = 'PaymentUnspecified',
  PaymentAccountExpired = 'PaymentAccountExpired',
  PaymentAuthExpired = 'PaymentAuthExpired',
  PaymentCardTypeNotSupported = 'PaymentCardTypeNotSupported',
  PaymentCardExpired = 'PaymentCardExpired',
  PaymentSaleDeclined = 'PaymentSaleDeclined',
  PaymentInsufficientBalance = 'PaymentInsufficientBalance',
  PaymentTippingNotAllowed = 'PaymentTippingNotAllowed',
  PaymentGatewayUnavailable = 'PaymentGatewayUnavailable',
  PaymentOloGatewayUnavailable = 'PaymentOloGatewayUnavailable',
  PaymentMerchantMisconfiguration = 'PaymentMerchantMisconfiguration',
  PaymentRateLimited = 'PaymentRateLimited',
  PaymentRequirementsNotMet = 'PaymentRequirementsNotMet',
  PaymentTotalMismatch = 'PaymentTotalMismatch',
  PaymentUserSubmittedTotalMismatch = 'PaymentUserSubmittedTotalMismatch',
  PaymentTipMismatch = 'PaymentTipMismatch',
  PaymentTypeNotSupported = 'PaymentTypeNotSupported',
  PaymentTransactionDeclined = 'PaymentTransactionDeclined',
  PaymentTransactionDeclinedAvs = 'PaymentTransactionDeclinedAvs',
  PaymentTransactionDeclinedCvv = 'PaymentTransactionDeclinedCvv',
  PaymentTransactionDeclinedCvvRevalidation = 'PaymentTransactionDeclinedCvvRevalidation',
  PaymentTransactionDeclinedPin = 'PaymentTransactionDeclinedPin',
  PaymentTransactionDeclinedFraud = 'PaymentTransactionDeclinedFraud',
  PaymentTransactionDeclinedPartial = 'PaymentTransactionDeclinedPartial',
  PaymentTransactionDeclinedMultiple = 'PaymentTransactionDeclinedMultiple',
  PaymentTransactionInvalid = 'PaymentTransactionInvalid',
  PaymentTransactionFailure = 'PaymentTransactionFailure',
  PaymentTransactionUnknown = 'PaymentTransactionUnknown',
  PaymentTransactionOther = 'PaymentTransactionOther',
  PaymentInquiryDeclined = 'PaymentInquiryDeclined',
  PaymentBillingModesMismatch = 'PaymentBillingModesMismatch',
  PaymentOperationAborted = 'PaymentOperationAborted',
  PaymentMismatchedUserBillingAccount = 'PaymentMismatchedUserBillingAccount',
  PaymentPrepaymentRequired = 'PaymentPrepaymentRequired',

  PosUnspecified = 'PosUnspecified',
  PosMenuItemEightySixed = 'PosMenuItemEightySixed',
  PosCheckClosed = 'PosCheckClosed',
  PosCloudUnavailable = 'PosCloudUnavailable',
  PosMappingError = 'PosMappingError',
  PosIncompatible = 'PosIncompatible',
  PosInvalidPlu = 'PosInvalidPlu',
  PosNotLicensed = 'PosNotLicensed',
  PosOffline = 'PosOffline',
  PosRejectedOrder = 'PosRejectedOrder',
  PosTaxMismatch = 'PosTaxMismatch',
  PosUnavailable = 'PosUnavailable',
  PosInternalContention = 'PosInternalContention',
  PosInternalError = 'PosInternalError',
  PosInternalMisconfiguration = 'PosInternalMisconfiguration',
  PosResponseTimeout = 'PosResponseTimeout',
  PosMisconfiguration = 'PosMisconfiguration',
  PosDiscountError = 'PosDiscountError',
  PosAccessDenied = 'PosAccessDenied',

  ProductModifierRequired = 'ProductModifierRequired',
  ProductModifierCardinality = 'ProductModifierCardinality',

  TaxRatesMisconfiguration = 'TaxRatesMisconfiguration',
  TooFarInAdvance = 'TooFarInAdvance',
  VendorDisabled = 'VendorDisabled',
  VendorOutOfHours = 'VendorOutOfHours',
  WantedTimeNotSpecified = 'WantedTimeNotSpecified',
  TimeWantedModeNotSpecified = 'TimeWantedModeNotSpecified',
  NotificationServiceError = 'NotificationServiceError',

  ConfigurationError = 'ConfigurationError',

  DiscountServiceMappingError = 'DiscountServiceMappingError',

  LoginProviderUnspecified = 'LoginProviderUnspecified',
  LoginProviderRejectedUpdate = 'LoginProviderRejectedUpdate',

  GroupOrderRequirementsNotMet = 'GroupOrderRequirementsNotMet',
  GroupOrderNotFound = 'GroupOrderNotFound',
  GroupOrderChildOrderNotFound = 'GroupOrderChildOrderNotFound',
  GroupOrderNotInitialized = 'GroupOrderNotInitialized',
  GroupOrderEmailFailure = 'GroupOrderEmailFailure',
  GroupOrderSubmissionError = 'GroupOrderSubmissionError',
  GroupOrderClosed = 'GroupOrderClosed',
  GroupOrderLocked = 'GroupOrderLocked',
  GroupOrderCancellationError = 'GroupOrderCancellationError',

  RailsMarkup = 'RailsMarkup',
}
