import { modifier } from 'ember-modifier';

/**
 * Indicates whether smooth scrolling is currently active
 */
let smoothScrolling = false;

/**
 * Briefly enables smooth scrolling on the entire page.
 */
const temporarilyEnableSmoothScrolling = () => {
  if (smoothScrolling) {
    return;
  }

  smoothScrolling = true;

  const html = document.documentElement;
  const { scrollBehavior } = html.style;
  html.style.scrollBehavior = 'smooth';

  // Chromium browsers will use a smooth scrolling behavior if `scroll-behavior`
  // is set to `'smooth'` for a single render frame.
  //
  // Unfortunately, Firefox has some timing inconsistencies:
  //
  //  - a single render frame will not allow for smooth scrolling
  //  - two render frames will behave inconsitently and sometimes allow for
  //    smooth scrolling
  //  - three render frames appears to be enough of a delay to consistently
  //    use the smooth scrolling behavior.
  //
  // 3 frames at 60fps is 50ms, so a single `setTimeout` is used for brevity.
  setTimeout(() => {
    smoothScrolling = false;

    html.style.scrollBehavior = scrollBehavior;
  }, 50);
};

/**
 * The `{{smooth-scroll}}` modifier enables smooth scrolling for anchor links.
 */
const smoothScroll = modifier<HTMLAnchorElement, never[]>(
  element => {
    element.addEventListener('click', temporarilyEnableSmoothScrolling, false);

    return () => {
      element.removeEventListener('click', temporarilyEnableSmoothScrolling, false);
    };
  },
  {
    eager: false,
  }
);

export default smoothScroll;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'smooth-scroll': typeof smoothScroll;
  }
}
