export default {
  "notification": "_notification_1nk5ha",
  "expand": "_expand_1nk5ha",
  "success": "_success_1nk5ha",
  "info": "_info_1nk5ha",
  "warning": "_warning_1nk5ha",
  "error": "_error_1nk5ha",
  "hybrid": "_hybrid_1nk5ha",
  "opening": "_opening_1nk5ha",
  "idling": "_idling_1nk5ha",
  "closing": "_closing_1nk5ha",
  "icon": "_icon_1nk5ha",
  "message": "_message_1nk5ha",
  "closeButton": "_closeButton_1nk5ha",
  "closeIcon": "_closeIcon_1nk5ha"
};
