import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;

  Args: {
    // Required arguments
    display: boolean;

    // Optional arguments
    label?: string;
    inline?: boolean;
  };
}

export default class LoadingIndicator extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get id() {
    return guidFor(this);
  }

  get label(): string {
    return this.args.label ?? 'Loading';
  }

  // Lifecycle methods

  // Other methods
  onInsert() {
    if (this.args.display) {
      document.querySelector<HTMLElement>('[data-focusWhen="loading"]')?.focus();
    }
  }

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LoadingIndicator: typeof LoadingIndicator;
  }
}
