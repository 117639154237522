import { set } from '@ember/object';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { PreviousRoute, vendorToMenuRoute } from 'mobile-web/lib/routing';
import Category from 'mobile-web/models/category';
import { uniqueCategoryId } from 'mobile-web/serializers/vendor';
import FeaturesService from 'mobile-web/services/features';
import VendorService from 'mobile-web/services/vendor';

export default class CategoryRoute extends Route {
  previousRoute?: PreviousRoute;

  @service router!: RouterService;
  @service('vendor') vendorService!: VendorService;
  @service features!: FeaturesService;
  @service store!: DS.Store;

  model(params: { vendor_slug: string; category_id: EmberDataId }): PromiseLike<Category> {
    return this.vendorService.ensureVendorLoaded(params.vendor_slug).then(vendor => {
      set(this, 'previousRoute', vendorToMenuRoute(vendor));
      const category = this.store.peekRecord('category', params.category_id);

      /**
       * We can safely cast to Category to remove null from the type because
       * in the {@link afterModel} we redirect away if there is no category.
       * The template will never see null.
       */
      return (
        category ??
        // Support older deep links to categories
        (this.store.peekRecord(
          'category',
          uniqueCategoryId(params.category_id, vendor.id)
        ) as Category)
      );
    });
  }

  afterModel(model: ModelForRoute<this>) {
    if (!model) {
      // vendorSlug is defined because of ensureVendorLoaded in model hook
      this.router.transitionTo('menu.vendor', this.vendorService.vendorSlug!);
    }
  }
}
