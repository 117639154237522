export default {
  "root": "_root_ec97xw",
  "list": "_list_ec97xw",
  "lineItem": "_lineItem_ec97xw",
  "panelMessage": "_panelMessage_ec97xw",
  "text": "_text_ec97xw",
  "infoIcon": "_infoIcon_ec97xw",
  "content": "_content_ec97xw",
  "moreItems": "_moreItems_ec97xw",
  "closeButton": "_closeButton_ec97xw",
  "closeButtonIcon": "_closeButtonIcon_ec97xw",
  "addToCartButton": "_addToCartButton_ec97xw",
  "title": "_title_ec97xw",
  "header": "_header_ec97xw",
  "buttonContainer": "_buttonContainer_ec97xw"
};
