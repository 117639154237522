import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import dayjs from 'dayjs';
import IntlService from 'ember-intl/services/intl';

import BasketModel from 'mobile-web/models/basket';
import FavoriteModel from 'mobile-web/models/favorite';
import OrderModel from 'mobile-web/models/order';
import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import GroupOrderService from 'mobile-web/services/group-order';
import StorageService from 'mobile-web/services/storage';

import AnalyticsService, { AnalyticsEvents, AnalyticsProperties } from './analytics';
import FeaturesService from './features';

type Reorderable = FavoriteModel | OrderModel | OrderSearchResultModel;

type ReorderOptions = {
  /** Only relevant to OrderSearchResultModel for Mixpanel reporting */
  index?: number;
};

enum ReorderType {
  Favorite,
  Order,
  OrderSearchResult,
}

type ParseResult = (
  | {
      type: ReorderType.Favorite;
      favorite: FavoriteModel;
    }
  | {
      type: ReorderType.Order;
      order: OrderModel;
    }
  | {
      type: ReorderType.OrderSearchResult;
      order: OrderSearchResultModel;
    }
) & {
  slug: string;
  intlPrefix: string;
};

export default class ReorderService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service features!: FeaturesService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service storage!: StorageService;
  @service store!: DS.Store;

  // Untracked properties

  // Tracked properties
  @tracked reorderBasket?: BasketModel;
  // Getters and setters

  // Lifecycle methods

  // Other methods
  reorder(target: Reorderable, options: ReorderOptions = {}): void {
    const result = this._parse(target);
    if (this._shouldConfirm(result)) {
      this._confirm(result, options);
    } else {
      this._reorder(result, options);
    }
  }

  async reorderValidate(sourceId: string, sourceType: string) {
    const adapter = this.store.adapterFor('order');
    return await adapter.validateReOrder(sourceId, sourceType);
  }

  setReorderBasket(basket: BasketModel) {
    this.reorderBasket = basket;
  }

  private _parse(target: Reorderable): ParseResult {
    return 'orderGuid' in target
      ? 'orderSummary' in target
        ? {
            type: ReorderType.OrderSearchResult,
            order: target,
            slug: target.vendorSlug,
            intlPrefix: 'mwc.recentOrders.reorder',
          }
        : {
            type: ReorderType.Order,
            order: target,
            slug: target.vendor.slug,
            intlPrefix: 'mwc.recentOrders.reorder',
          }
      : {
          type: ReorderType.Favorite,
          favorite: target,
          slug: target.vendorSlug,
          intlPrefix: 'mwc.favorites.reorder',
        };
  }

  private _shouldConfirm(result: ParseResult): boolean {
    const lastSlug = this.storage.lastVendorSlug;
    return (lastSlug && lastSlug !== result.slug) || !!this.basket.basket;
  }

  private _confirm(result: ParseResult, options: ReorderOptions): void {
    const content = this._content(result);
    this.bus.trigger('confirm', {
      title: this.intl.t(`${result.intlPrefix}.confirmTitle`),
      content,
      buttonLabel: this.intl.t(`${result.intlPrefix}.confirmButton`),
      onConfirm: () => this._reorder(result, options),
      testSelector: 'confirmReorder',
      buttonTestSelector: 'confirmButton',
    });
  }

  private _content(result: ParseResult): string {
    const basket = this.basket.basket;
    const intlSuffix = basket
      ? basket.vendor?.get('slug') === result.slug
        ? this.groupOrder.hasGroupOrder
          ? 'confirmGroupOrderText'
          : 'confirmBasketText'
        : 'confirmBasketDifferentVendorText'
      : 'confirmDifferentVendorText';

    const basketVendor = basket?.vendor?.get('name');
    const intlOptions =
      result.type === ReorderType.Favorite
        ? {
            basketVendor,
            favoriteVendor: result.favorite.vendorName,
          }
        : {
            basketVendor,
            orderVendor: result.order.vendorName,
            orderSummary:
              result.type === ReorderType.Order
                ? result.order.basketProducts.map(bp => bp.productName).join(', ')
                : result.order.orderSummary,
          };

    return this.intl.t(`${result.intlPrefix}.${intlSuffix}`, intlOptions);
  }

  private async _reorder(result: ParseResult, options: ReorderOptions): Promise<void> {
    let order: OrderModel | OrderSearchResultModel | FavoriteModel;
    if (this.groupOrder.hasGroupOrder) {
      this.analytics.trackEvent(AnalyticsEvents.CancelGroupOrder);
      this.groupOrder.endGroupOrder();
    }
    if (result.type === ReorderType.Favorite) {
      order = result.favorite;
    } else {
      order = result.order;

      // do analytics here
      const orderProperties = {
        [AnalyticsProperties.DaysSinceLastOrder]:
          'localTimePlaced' in order
            ? dayjs().diff(order.localTimePlaced, 'day')
            : dayjs().diff(order.timePlacedLocal, 'day'),
        [AnalyticsProperties.PreviousOrderID]: order.id,
      };

      this.analytics.trackEvent(AnalyticsEvents.ReorderNow, () => ({
        ...orderProperties,
        [AnalyticsProperties.ListIndex]: options.index,
      }));
    }

    return this.basket.reorderTask.perform(order);
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    reorder: ReorderService;
  }
}
