import { getOwner } from '@ember/application';
import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';

import { isFunction } from 'mobile-web/lib/utilities/_';
import isSome from 'mobile-web/lib/utilities/is-some';

export interface Signature {
  Args: {
    Positional: [string, string];
  };
  Return: () => unknown;
}

export default class RouteAction extends Helper<Signature> {
  compute([routeName, actionName]: Signature['Args']['Positional']): Signature['Return'] {
    const owner = getOwner(this) as AnyObject;
    const route = owner.lookup(`route:${routeName}`);
    assert(`first parameter should be a route`, isSome(route));
    const action = route[actionName];
    assert(`second parameter should be a function on the route`, isFunction(action));
    return action;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'route-action': typeof RouteAction;
  }
}
