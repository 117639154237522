import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import GeoService from 'mobile-web/services/geo';
import UserFeedback from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onClose: Action<void, void>;
  setCoords: Action<Coordinates, void>;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class GeolocationPrompt extends Component<Signature> {
  // Service injections
  @service geo!: GeoService;
  @service userFeedback!: UserFeedback;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GeolocationPrompt: typeof GeolocationPrompt;
  }
}
