import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import pick from 'lodash.pick';

import Address, { isAddress } from 'mobile-web/lib/location/address';
import { Address as VendorAddress } from 'mobile-web/models/vendor';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';

const BASE_URI = 'https://maps.google.com/';
const IOS_BASE_URI = 'https://maps.apple.com/';

export default class AddressService extends Service {
  // Service injections
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service features!: FeaturesService;

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  /**
   *
   */
  createMapsApplicaitonLink(address: Address | VendorAddress | string): string {
    const isIOS = this.device.isIOS;

    const url = new URL(isIOS ? IOS_BASE_URI : BASE_URI);

    let q = '';

    if (this.features.flags['ox-append-channel-name-to-map-link-olo-80911']) {
      const channelName = this.channel.name;

      q = channelName + ' ';
    }

    if (typeof address === 'string') {
      q += address;
    } else {
      const props = isAddress(address)
        ? pick<Address, keyof Address>(address, 'streetAddress', 'building', 'city', 'zipCode')
        : pick<VendorAddress, keyof VendorAddress>(
            address,
            'streetAddress',
            'streetAddress2',
            'city',
            'state',
            'postalCode',
            'country'
          );

      q += Object.values(props)
        .filter(v => !isEmpty(v))
        .join(' ');
    }

    url.searchParams.set('q', q);

    return url.toString();
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    address: AddressService;
  }
}
