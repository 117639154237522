import Route from '@ember/routing/route';
import {service} from "@ember/service";
import DS from "ember-data";
import BootstrapService from "mobile-web/services/bootstrap";
import RSVP from "rsvp";

export default class ResetPasswordRoute extends Route {
  @service store!: DS.Store;
  @service bootstrap!: BootstrapService;

  model(params: { id: string | undefined }) : RSVP.Promise<{ id: string, showHouseAccountLanguage: boolean }> {
    const id = params?.id ?? "";
    if (!this.bootstrap.checkForHouseAccountOnPasswordReset || !id) {
      return RSVP.Promise.resolve<{ id: string, showHouseAccountLanguage: boolean }>({
        id: id,
        showHouseAccountLanguage: false
      });
    }

    return this.store.findRecord('show-house-account-language', id).then(r => {
      return {
        id: id,
        showHouseAccountLanguage: r.showHouseAccountLanguage
      };
    })
  }
}
