import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { CustomFontSource } from '@olo/pay';

import { computedSession } from 'mobile-web/lib/computed';
import * as Country from 'mobile-web/lib/country';
import { Code } from 'mobile-web/lib/country';
import { ChannelSettings } from 'mobile-web/models/bootstrap-data';
import Channel from 'mobile-web/models/channel';
import { ContentModelShape } from 'mobile-web/models/content';
import HandoffModeModel from 'mobile-web/models/handoff-mode';

import BootstrapService from './bootstrap';
import ErrorService from './error';

type Font = {
  'font-family': string;
  'font-weight': string;
  'font-style': 'normal' | 'italic' | 'oblique' | undefined;
  srcs: string[];
};

interface ChannelStyles {
  custom: Dict<string>;
  fonts: Font[];
}

export default class ChannelService extends Service {
  // Service injections
  @service store!: DS.Store;
  @service error!: ErrorService;
  @service bootstrap!: BootstrapService;

  // Untracked properties
  @computedSession savedCurrentCountry?: string;

  // Tracked properties
  @tracked fonts?: CustomFontSource[];
  @tracked elementsFont = 'inherit';

  // Getters and setters
  @alias('current.settings')
  settings?: ChannelSettings;

  get currentCountry(): Code {
    return this.savedCurrentCountry === Code.CA ? Code.CA : Code.US;
  }

  get current(): Channel | undefined {
    const data = this.bootstrap.data;
    if (isNone(data)) {
      return undefined;
    }

    const bootstrapChannel = data.channel;
    if (isNone(bootstrapChannel)) {
      throw new Error('Bootstrap data is missing channel configuration.');
    }

    const channel = this.store.peekRecord('channel', bootstrapChannel.id)!;

    const content: ContentModelShape[] = data.content ?? [];
    if (content.length) {
      this.store.pushPayload('content', { content });
    }

    return channel;
  }

  get androidAppIdentifier() {
    return this.current?.androidAppIdentifier ?? '';
  }

  get iOSAppIdentifier() {
    return this.current?.iOSAppIdentifier ?? '';
  }

  get name(): string {
    return this.current?.name ?? '';
  }

  get handoffModes(): HandoffModeModel[] {
    return this.current?.handoffModes ?? [];
  }

  @computed('countrySettings')
  get firstCountry(): Country.Code {
    return Object.keys(this.countrySettings)[0] as Country.Code;
  }

  @computed('settings.channelCountrySettingsByCountryCode')
  get countrySettings(): Country.SettingsPayload {
    return this.settings?.channelCountrySettingsByCountryCode ?? Country.defaultSettings();
  }

  @computed('countrySettings')
  get countries(): Country.Country[] {
    const codes = Object.keys(this.countrySettings) as Country.Code[];
    return codes.map(code => ({ code, name: this.countrySettings[code]!.name }));
  }

  @computed('countries', 'currentCountry', 'firstCountry')
  get countryConfig(): Country.Config {
    return { current: this.currentCountry ?? this.firstCountry, supported: this.countries };
  }

  @computed(
    'channel.{countrySettings,currentCountry}',
    'countrySettings',
    'currentCountry',
    'firstCountry'
  )
  get currentCountrySettings(): Country.SettingsPayload[Country.Code] {
    const countrySettings = this.countrySettings;

    const currentCountrySettings = countrySettings[this.currentCountry || this.firstCountry];
    return currentCountrySettings || countrySettings[this.firstCountry];
  }

  @computed('current')
  get heroImageUrl(): string {
    return this.buildCdnImageUrl('/hero/mobile.png');
  }

  // Lifecycle methods

  // Other methods
  buildCdnImageUrl(path: string): string {
    const current = this.current;
    if (!current) {
      return '';
    }
    const cacheVersion = this.settings?.cacheVersion;
    const cacheQueryString = isNone(cacheVersion) ? '' : `?v=${cacheVersion}`;
    return `${current.cdnUrl}/img/${current.internalName}${path}${cacheQueryString}`;
  }

  /**
   * @todo
   * Figure out a better way to do this.
   * Olo Pay JS passes a style object to the credit card elements that requires information
   * about colors and fonts (including the location of the font files). In a more ideal situation,
   * we would scrape an element for its styles and pass those through, however, we would not be
   * certain of the font file location. `build-json-styles.js` outputs this info for us. We'll
   * fetch the channel-specific JSON from CDN and pass this to Olo Pay JS in its expected format.
   */
  async fetchChannelStyles(): Promise<void> {
    if (this.fonts) return;

    const channelName = window.Olo.channelSlug;
    const cacheVersion = this.settings?.cacheVersion;
    const cdn = this.current?.cdnUrl;
    const url = `${cdn}/mobile-web-client/${channelName}/styles-${cacheVersion}.json`;

    try {
      const response = await fetch(url);
      const styles: ChannelStyles = await response.json();
      const typeOk = typeof styles.custom === 'object' && Array.isArray(styles.fonts);

      if (!typeOk) {
        throw new Error('Unexpected response shape');
      }

      /**
       * @todo
       * Fix issue with `;` being appended to the end of `custom` values.
       * This issue is only happening in Octopus builds; not any local runs.
       */
      Object.entries(styles.custom).map(([key, value]) => {
        if (value.endsWith(';')) {
          styles.custom[key] = value.slice(0, -1);
        }
      });

      const fonts: CustomFontSource[] = styles.fonts.flatMap(font =>
        font.srcs.map(src => {
          const start = src.indexOf('fonts');
          const end = src.indexOf(`')`);
          const path = src.slice(start, end);

          return {
            src: `url(${cdn}/${path})`,
            family: font['font-family'],
            style: font['font-style'],
            weight: font['font-weight'],
          };
        })
      );

      if (!fonts.length) return;

      this.fonts = fonts;
      this.elementsFont =
        styles.custom['text-font-family'] || styles.custom['button-font-family'] || 'inherit';
    } catch (error) {
      this.error.sendExternalError(error, {
        cause: 'credit-card-modal',
        task: `Fetching custom channel styles: ${url}`,
        channelName,
      });
    }
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    channel: ChannelService;
  }
}
