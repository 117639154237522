import { MaskFunction } from 'ember-text-mask';

export const phoneMask: MaskFunction = rawValue => {
  if (rawValue.charAt(0) === '+' || rawValue.charAt(0) === '1') {
    return [
      '+',
      /\d/,
      ' ',
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
};

export const applyPhoneMask = (rawValue: string): string => {
  if (rawValue.match(/^\d{10}$/)) {
    return rawValue.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
  } else if (rawValue.match(/^\d{11}$/)) {
    return rawValue.replace(/(\d)(\d\d\d)(\d\d\d)(\d\d\d\d)/, '+$1 ($2) $3-$4');
  }
  return rawValue;
};
