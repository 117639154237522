import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { RulesContext } from 'ember-animated';
import IntlService from 'ember-intl/services/intl';

import { PrimitiveAttributes } from 'mobile-web/decorators/saved-attributes';
import { getDuration } from 'mobile-web/lib/animation';
import { MILLISECONDS } from 'mobile-web/lib/time/durations';
import { sum } from 'mobile-web/lib/utilities/_';
import BasketProductModel from 'mobile-web/models/basket-product';
import BasketService from 'mobile-web/services/basket';
import GroupOrderService from 'mobile-web/services/group-order';
import { down, up } from 'mobile-web/transitions/slide';

import style from './index.m.scss';

const TRANSITION_DURATION = getDuration(100 * MILLISECONDS);

interface Signature {
  Element: HTMLButtonElement;
}

export default class CartButton extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service intl!: IntlService;
  @service groupOrder!: GroupOrderService;

  // Untracked properties
  duration = TRANSITION_DURATION;
  style = style;

  // Tracked properties

  // Getters and setters
  get hasProducts(): boolean {
    return this.numBasketProducts > 0;
  }

  get isLoading(): boolean {
    return this.basket.orderBasketGroup.isRunning;
  }

  get ariaLabel(): string {
    return this.intl.t('mwc.header.cartAriaLabel', {
      count: this.numBasketProducts,
    });
  }

  get allSavedAttributes(): Array<PrimitiveAttributes<BasketProductModel>> | undefined {
    return this.groupOrder.isHostMode
      ? this.basket.basketProducts.map(p => p.savedAttributes)
      : this.basket.displayProducts.map(p => p.savedAttributes);
  }

  get numBasketProducts(): number {
    return sum(this.allSavedAttributes?.map(a => a.quantity) ?? [0]);
  }

  get buttonStyle(): string {
    return this.hasProducts ? this.style.major : this.style.minor;
  }

  // Lifecycle methods

  // Other methods
  rules({ oldItems, newItems }: RulesContext) {
    const [oldItem = 0] = oldItems as number[];
    const [newItem = 0] = newItems as number[];

    return oldItem < newItem ? up : down;
  }

  // Tasks

  // Actions and helpers
  @action
  onClick() {
    this.basket.toggle();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CartButton: typeof CartButton;
  }
}
