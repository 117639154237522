import Service, { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import { Geolocation } from '@capacitor/geolocation';
import { task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';

import { Variant } from 'mobile-web/components/button';
import { noop } from 'mobile-web/lib/utilities/_';
import { assertNever } from 'mobile-web/lib/utilities/type-helpers';
import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';

import AnalyticsService, { AnalyticsEvents, AnalyticsProperties } from './analytics';

export enum PositionErrorCode {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}

export default class GeoService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service device!: DeviceService;
  @service globalEvents!: GlobalEventsService;
  @service userFeedback!: UserFeedback;
  @service intl!: IntlService;
  @service features!: FeaturesService;
  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  getGeolocation(timeout = 10000): RSVP.Promise<Coordinates | undefined> {
    return new RSVP.Promise((resolve, reject) => {
      if (this.device.isHybrid) {
        Geolocation.getCurrentPosition({ timeout })
          .then(({ coords }) => {
            this.globalEvents.trigger(GlobalEventName.LocationAccessAllowed);
            return resolve(coords as Coordinates);
          })
          .catch(() => {
            Geolocation.checkPermissions().then(status => {
              if (status.location !== 'granted') {
                this.globalEvents.trigger(GlobalEventName.LocationAccessDenied);
                reject({ code: PositionErrorCode.PERMISSION_DENIED });
              }
              reject({ code: PositionErrorCode.POSITION_UNAVAILABLE });
            });
          });
      } else if ('geolocation' in navigator) {
        let hasTimedOut = false;
        const locationTimeout = setTimeout(() => {
          hasTimedOut = true;
          reject({ code: PositionErrorCode.TIMEOUT });
        }, timeout + 20); // We time out later than what we pass to getCurrentPosition, to give the normal flow a chance to run

        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            clearTimeout(locationTimeout);
            // We only resolve if we haven't timed out.
            // If we have timed out, reject has already been called.
            if (!hasTimedOut) {
              this.globalEvents.trigger(GlobalEventName.LocationAccessAllowed);
              resolve(coords);
            }
          },
          (...args) => {
            clearTimeout(locationTimeout);
            // We only reject if we haven't timed out.
            // If we have timed out, reject has already been called.
            if (!hasTimedOut) {
              this.globalEvents.trigger(GlobalEventName.LocationAccessDenied);
              reject(...args);
            }
          },
          {
            timeout,
          }
        );
      } else {
        resolve(undefined);
      }
    });
  }

  // Tasks
  getGeoTask = task(
    async (
      setCoords: Action<Coordinates>,
      showNotificationOnError = true,
      timeout = 10000
    ): Promise<void> => {
      let response: string;
      try {
        const coords: Coordinates | undefined = await this.getGeolocation(timeout);
        if (coords) {
          setCoords(coords);
          response = 'Success';
        } else {
          response = 'Undefined navigator.geolocation';
        }
      } catch (e) {
        if ('code' in e) {
          // Technically this cast isn't true; it could be another error type that has a `code`.
          // That's why we check for non-geolocation errors.
          const geoError = e as GeolocationPositionError;
          response =
            geoError.code === PositionErrorCode.POSITION_UNAVAILABLE
              ? 'Position Unavailable'
              : geoError.code === PositionErrorCode.TIMEOUT
              ? 'Timeout'
              : geoError.code === PositionErrorCode.PERMISSION_DENIED
              ? 'Permission Denied'
              : `Non-GeolocationPositionError: ${e}; Code: ${e.code}`;
        } else {
          response = `Non-GeolocationPositionError: ${e}`;
        }

        if (showNotificationOnError) {
          const reason: { code: PositionErrorCode } = e;
          const title = this.intl.t('mwc.geo.lookUpFailedTitle');
          const type = Type.Warning;
          switch (reason.code) {
            case PositionErrorCode.POSITION_UNAVAILABLE:
              this.userFeedback.add({
                title,
                message: this.intl.t('mwc.geo.locationUnavailableMessage'),
                type,
              });
              break;
            case PositionErrorCode.TIMEOUT:
              this.userFeedback.add({
                title,
                message: this.intl.t('mwc.geo.timeoutMessage'),
                type,
                actions: [
                  { label: 'Input address manually', variant: Variant.Main, fn: noop },
                  {
                    label: 'Try again',
                    variant: Variant.Minor,
                    fn: () => {
                      this.getGeoTask.perform(setCoords);
                    },
                  },
                ],
              });
              break;
            case PositionErrorCode.PERMISSION_DENIED:
              this.userFeedback.add({
                title,
                message: this.intl.t('mwc.geo.turnOnLocationMessage'),
                type,
              });
              break;
            default:
              assertNever(reason.code);
          }
        }
      }
      this.analytics.trackEvent(AnalyticsEvents.LocationDetection, () => ({
        [AnalyticsProperties.Response]: response,
      }));
    }
  );

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    geo: GeoService;
  }
}
