export type AttributeConfigValue = undefined | boolean | string;

export type AttributeConfig =
  | AttributeConfigValue
  | ((attributeValue: string | undefined, element: Element) => AttributeConfigValue);

export type ElementConfig = Record<string, AttributeConfig> | undefined;

export type Config = Record<string, ElementConfig>;

const unwrap = (element: Element) => {
  element.after(...element.childNodes);
  element.remove();
};

const sanitizeAttribute = (element: Element, attributeName: string, config: AttributeConfig) => {
  const attributeValue = element.getAttribute(attributeName) ?? undefined;
  const value = typeof config === 'function' ? config(attributeValue, element) : config;

  switch (value) {
    case undefined:
    case false:
      element.removeAttribute(attributeName);
      return;
    case true:
      return;
    default:
      element.setAttribute(attributeName, value);
      return;
  }
};

const sanitizeElement = (element: Element, config: ElementConfig) => {
  if (!config) {
    unwrap(element);
    return;
  }

  const configAttributes = Object.keys(config);
  const elementAttributes = element.getAttributeNames();

  const attributes = [...new Set([...configAttributes, ...elementAttributes])];

  for (const attributeName of attributes) {
    const attributeConfig = config[attributeName];

    sanitizeAttribute(element, attributeName, attributeConfig);
  }
};

/**
 * @summary
 * Sanitizes a raw HTML string using the provided allowlist of elements and
 * their respective attributes.
 * 
 * @description
 * Sanitizes a raw HTML string based on the provided configuration object.
 * 
 * The property names on the object are the names of HTML elements.
 * 
 * The property values are either element configuration objects for allowed
 * elements (first example), or `undefined` for disallowed elements (second
 * example).
 * 
 * The element configuration objects may contain properties to configure the
 * allowed attributes on the given element type (third example).
 * 
 * The element configuration property values are for attribute configuration.
 * 
 * Attribute configuration can be `undefined`, `boolean`, `string`, or a
 * function that produces `undefined`, `boolean`, or `string` values.
 * 
 * When attribute configuration is `undefined` or `false`, the attribute will be
 * removed from that element.
 * 
 * When attribute configuration is `true`, the attribute will be left as-is.
 * 
 * When attribute configuration is a `string`, the attribute will be set to that
 * value.
 * 
 * When attribute configuration is a function, the function will be called with
 * the existing attribute value and element as parameters to the function. The
 * resultant value will be treated as specified above for static attribute
 * configuration.
 * 
 * @param rawInput the raw HTML to sanitize
 * @param config an object whose properties represent settings for the various
 * HTML elements.
 * 
 * @returns sanitized HTML as a string
 * 
 * @example <caption>Sanitize string to allowed elements</caption>
 * // only paragraph tag is allowed
 * sanitizeHTML('<p>lorem <b>ipsum</b></p>', {
 *   p: {} 
 * })
 * // '<p> lorem ipsum</p>'
 * 
 * @example <caption>Sanitize string to explicitly remove element</caption>
 * // bold tag is not allowed
 * sanitizeHTML('<p>lorem <b>ipsum</b></p>', {
 *   b: undefined,
 *   p: {}
 * })
 * // '<p> lorem ipsum</p>'
 * 
 * @example <caption>Allow attributes on an element</caption>
 * sanitizeHTML('<img src="https://example.com/image" alt="an example image"/> <p>lorem ipsum</p>', {
 *   img: {
 *     src: true,
 *     alt: true
 *   }
 * })
 * // '<img src="https://example.com/image" alt="an example image"/> lorem ipsum'
 */
export const sanitizeHTML = (rawInput: string, config: Config): string => {
  const template = document.createElement('template');

  template.innerHTML = rawInput;

  const elements = template.content.querySelectorAll('*');

  for (const element of elements) {
    const tagName = element.tagName.toLowerCase();
    const elementConfig = config[tagName];

    sanitizeElement(element, elementConfig);
  }

  return template.innerHTML;
};
