import Service, { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import { firstResolvedPromise } from 'mobile-web/lib/promises';

import ChannelService from './channel';
import CmsContentService from './cms-content';

export type LoadedImage = HTMLImageElement & EventTarget & HTMLMediaElement;

export type LogoImages = {
  large: string;
  small: string;
};

export default class ImageService extends Service {
  // Service injections
  @service channel!: ChannelService;
  @service cmsContent!: CmsContentService;

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  /**
   * `getLargeLogosUris` checks CMS for large logo if one is set.
   * Otherwise, it generates possible large logos URIs from CDN
   *
   * @returns the list of URI strings
   */
  getLargeLogoUris(): string[] {
    const cmsImage =
      this.cmsContent.getContent('logoImage')?.imageUriTablet ??
      this.cmsContent.getContent('logoImage')?.imageUriMobile;
    if (cmsImage) {
      return [cmsImage];
    }

    return ['/logo/logo-wide.svg', '/logo/logo-wide.png', '/logo/logo-wide-fallback.png'].map(
      image => this.channel.buildCdnImageUrl(image)
    );
  }

  /**
   * `getSmallLogosUris` checks CMS for small logo if one is set.
   * Otherwise, it generates possible small logos URIs from CDN
   *
   * @returns the list of URI strings
   */
  getSmallLogoUris(): string[] {
    const cmsImage = this.cmsContent.getContent('logoImage')?.imageUriMobile;
    if (cmsImage) {
      return [cmsImage];
    }

    return ['/logo/logo-narrow.svg', '/logo/logo-narrow.png'].map(image =>
      this.channel.buildCdnImageUrl(image)
    );
  }

  /**
   * `load` loads an image from a URI.
   *
   * @param filename the URI to load
   * @returns a promise that resolves to the loaded image.
   */
  load(filename?: string): RSVP.Promise<LoadedImage> {
    if (!filename) {
      return RSVP.Promise.reject('no image file name specified');
    }

    const img = new Image() as LoadedImage;
    img.src = filename;

    return RSVP.Promise.resolve(img.decode()).then(() => img);
  }

  /**
   * `loadSmallLogo` loads the small logo from the CMS if one is set, or by
   * checking if a PNG or SVG file exists in the CDN.
   *
   * If no images load, `undefined` is returned.
   *
   * @returns a Promise that resolves to the Uri for the image, or undefined if
   * no images load.
   */
  loadSmallLogo(): RSVP.Promise<string | undefined> {
    const smallLogos = this.getSmallLogoUris();
    return RSVP.Promise.resolve(
      firstResolvedPromise(smallLogos.map(logoUri => this.load(logoUri).then(() => logoUri))).catch(
        () => undefined
      )
    );
  }

  /**
   * `loadLargeLogo` loads the large logo from the CMS if one is set, or by
   * checking if a PNG or SVG file exists in the CDN.
   *
   * If no images load, `undefined` is returned.
   *
   * @returns a Promise that resolves to the Uri for the image, or undefined if
   * no images load.
   */
  loadLargeLogo(): RSVP.Promise<string | undefined> {
    const largeLogos = this.getLargeLogoUris();
    return RSVP.Promise.resolve(
      firstResolvedPromise(largeLogos.map(logo => this.load(logo).then(() => logo))).catch(
        () => undefined
      )
    );
  }

  /**
   * `loadLogoImages` loads the large and small logos to use in the page header.
   *
   * @returns a promise that resolves to the large and small logo URIs.
   */
  loadLogoImages(): RSVP.Promise<LogoImages> {
    return RSVP.Promise.all([this.loadSmallLogo(), this.loadLargeLogo()]).then(
      ([smallLogo, largeLogo]) => ({
        large: largeLogo ?? smallLogo ?? '',
        small: smallLogo ?? '',
      })
    );
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    image: ImageService;
  }
}
