export type TargetEvent = Event & { target?: Node };

/**
 * Trap focus in a specified target. If there is a focus target, recycle focus
 * to that when focus leaves the trap; otherwise, return it to the trap itself.
 *
 * @param  trap         The container in which to trap focus.
 * @param  focusTarget  The focus target - optional; set to the trap element.
 *                      In general, for best accessibility, the focus target
 *                      should be the first focusable item (a link, a button,
 *                      etc.) in the body of the `trap`. Here we fall back to
 *                      using the `trap` itself for backwards compatibility and
 *                      as a mostly-sane default.
 */
export const handleFocus = (trap: HTMLElement, focusTarget = trap) => (): void => {
  // The existence check on `trap`  is here because it's sometimes undefined in tests,
  // and we don't know why. rAF helps catch the active element on the next frame which
  // can accomodate functionality affecting what the active element is. This is helpful
  // in an Olo Pay enabled `CreditCardModal` with iframe inputs that need to steal focus
  requestAnimationFrame(() => {
    if (!trap || trap.contains(document.activeElement)) {
      return;
    }

    focusTarget.focus();
  });
};

/**
 * Split numbers in the string so that screen readers read separate digits.
 */
export const splitNumbers = (str: string): string => str.replace(/\d/g, '$& ');
