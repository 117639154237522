export default {
  "placeholder": "_placeholder_55q4ea",
  "sideMenu": "_sideMenu_55q4ea",
  "footer": "_footer_55q4ea",
  "bottom": "_bottom_55q4ea",
  "appStoreBadge": "_appStoreBadge_55q4ea",
  "footerLinks": "_footerLinks_55q4ea",
  "contactUsLink": "_contactUsLink_55q4ea",
  "contentInfo": "_contentInfo_55q4ea",
  "appVersion": "_appVersion_55q4ea"
};
