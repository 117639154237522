import Component from '@glimmer/component';

import { range } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

interface Args {
  // Required arguments
  isChange: boolean;
  isForgot: boolean;
  isReset: boolean;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class ApplicationLoadingPassword extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get inputEachArray(): number[] {
    const length = this.args.isChange ? 5 : 3;
    return range(0, length);
  }

  get textEachArray(): number[] {
    const length = this.args.isForgot ? 6 : this.args.isReset ? 2 : 0;
    return range(0, length);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApplicationLoading::Password': typeof ApplicationLoadingPassword;
  }
}
