import { isNone } from '@ember/utils';
import DS from 'ember-data';

import { getHandoffMode, HandoffModeKey } from 'mobile-web/lib/order-criteria';
import { ChannelData } from 'mobile-web/models/bootstrap-data';

export default class BootstrapDataSerializer extends DS.RESTSerializer {
  primaryKey = 'channelSlug';
  modelNameFromPayloadKey(): string {
    return 'bootstrap-data';
  }

  normalize(
    modelClass: DS.Model,
    resourceHash: { channel: ChannelData; handoffModes: Array<{ id: number; type: string }> }
  ): UnknownObject {
    const channel = resourceHash.channel;
    if (isNone(channel.settings.showMenuCategoryNames)) {
      channel.settings.showMenuCategoryNames = true;
    }
    if (isNone(channel.settings.showProductMenuPrices)) {
      channel.settings.showProductMenuPrices = true;
    }
    if (isNone(channel.settings.showProductPriceTicker)) {
      channel.settings.showProductPriceTicker = true;
    }

    // Handle cases where no public vendors have some handoff modes
    // Arguably we could adjust the backend to filter the handoff modes on the channel itself,
    // but that would be a fairly risky change and possibly difficult to roll back than a client-side filter.
    const availableModeIds = resourceHash.handoffModes.map(x => x.id);
    resourceHash.channel.handoffModes = resourceHash.channel.handoffModes.filter(x =>
      availableModeIds.includes(x)
    );

    if (Array.isArray(channel.settings.handoffModesHiddenFromSelection)) {
      // Raw data is an array of ASCII codes
      const raw = channel.settings.handoffModesHiddenFromSelection as unknown as number[];
      channel.settings.handoffModesHiddenFromSelection = raw.map(r =>
        getHandoffMode(String.fromCharCode(r) as HandoffModeKey)
      );
    } else {
      channel.settings.handoffModesHiddenFromSelection = [];
    }

    return super.normalize(modelClass, resourceHash);
  }
}
