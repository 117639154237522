import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { EmptyObject } from '@glimmer/component/-private/component';
import { tracked } from '@glimmer/tracking';

import { PreviousRoute } from 'mobile-web/lib/routing';
import BasketProductModel from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import BusService from 'mobile-web/services/bus';
import { ProductClickFrom } from 'mobile-web/services/global-data';

import style from './index.m.scss';

export type ShowProductModalArgs = {
  product: ProductModel;
  onClose?: Action;
  onSave?: Action;
  basketProduct?: BasketProductModel;
  previousRoute?: PreviousRoute;
  clickFrom?: ProductClickFrom;
};

declare module 'mobile-web/services/bus' {
  interface EventRegistry {
    showProductModal: ShowProductModalArgs;
    dismissProductModal: void;
  }
}

export default class ProductModal extends Component {
  // Service injections
  @service bus!: BusService;

  // Untracked properties
  style = style;
  dismissProductModal = false;

  // Tracked properties
  @tracked eventArgs?: ShowProductModalArgs;
  @tracked isOpen = false;

  // Getters and setters

  // Lifecycle methods
  constructor(owner: unknown, args: EmptyObject) {
    super(owner, args);

    this.bus.on('showProductModal', eventArgs => {
      this.eventArgs = eventArgs;
      this.isOpen = true;
    });

    this.bus.on('dismissProductModal', () => {
      this.dismissProductModal = true;
      this.close();
    });
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  close() {
    this.isOpen = false;
  }

  @action
  onCloseComplete() {
    if (!this.dismissProductModal) {
      this.eventArgs?.onClose?.();
    }
    this.dismissProductModal = false;
    this.eventArgs = undefined;
  }

  @action
  onSave() {
    this.close();
    this.eventArgs?.onSave?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProductModal: typeof ProductModal;
  }
}
