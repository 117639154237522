import EmberArray from '@ember/array';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';
import RSVP from 'rsvp';

import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import { VendorSearchParams } from 'mobile-web/routes/vendor-search-results';

export type VendorSearchResult = {
  address: string | undefined;
  results: EmberArray<VendorSearchResultModel>;
  noSearch: boolean;
};

export default class VendorSearchService extends Service {
  // Service injections
  @service store!: DS.Store;

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  search(vendorSearchParams?: VendorSearchParams): RSVP.Promise<VendorSearchResult> {
    const localVendorSearchParams = vendorSearchParams || {};
    const { latitude, longitude } = localVendorSearchParams;
    const hasCoords = !!latitude || !!longitude;
    const address = hasCoords ? '' : localVendorSearchParams.address;
    const noAddress = isEmpty(address);
    const noSearch = noAddress && !hasCoords;

    const queryParams: VendorSearchParams = {
      ...localVendorSearchParams,
      address,
    };

    const results = noSearch
      ? RSVP.resolve([])
      : this.store.loadRecords('vendor-search-result', queryParams);

    return RSVP.hash({ address, results, noSearch });
  }
  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'vendor-search': VendorSearchService;
  }
}
