import { action } from '@ember/object';
import Component from '@glimmer/component';

import { BasicDropdownSignature } from 'ember-basic-dropdown/components/basic-dropdown';

import style from './index.m.scss';

type BasicDropdownAPI = BasicDropdownSignature['Blocks']['default'][0];

export type DropdownArg = {
  actions: Pick<BasicDropdownAPI['actions'], 'close' | 'toggle'>;
  disabled: BasicDropdownAPI['disabled'];
  isOpen: BasicDropdownAPI['isOpen'];
  uniqueId: BasicDropdownAPI['uniqueId'];
};

interface Args {
  // Required arguments
  dropdown: DropdownArg;

  // Optional arguments
}

interface Signature {
  Element: HTMLButtonElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class DropdownTrigger extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    document.addEventListener('keydown', this.keydown, false);
  }

  willDestroy(): void {
    super.willDestroy();

    document.removeEventListener('keydown', this.keydown, false);
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  keydown(e: KeyboardEvent) {
    if (this.args.dropdown.isOpen && e.key === 'Escape') {
      this.args.dropdown.actions.close(e);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::Trigger': typeof DropdownTrigger;
  }
}
