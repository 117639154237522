import { service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import OrderModel from 'mobile-web/models/order';
import TicketModel from 'mobile-web/models/ticket';
import SplitCheckService from 'mobile-web/services/split-check';

import style from './index.m.scss';

interface Args {
  // Required arguments
  order: OrderModel;
  ticket: TicketModel;
  viewFullReceipt: Action;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class SplitCheckPostCheckoutStatus extends Component<Signature> {
  // Service injections
  @service splitCheck!: SplitCheckService;
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get hasSplitData(): boolean {
    return this.args.order.isSplit;
  }
  get hasRemainingBalance(): boolean {
    return this.args.ticket.totals.due > 0;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SplitCheck::PostCheckoutStatus': typeof SplitCheckPostCheckoutStatus;
  }
}
