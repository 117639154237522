import { modifier } from 'ember-modifier';

const onWindow = modifier(
  (_element: Element, params: Parameters<typeof window.addEventListener>) => {
    window.addEventListener(...params);

    return () => {
      window.removeEventListener(...params);
    };
  }
);

export default onWindow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'on-window': typeof onWindow;
  }
}
