import Service from '@ember/service';
import { isNone } from '@ember/utils';

export default class WindowService extends Service {
  // Service injections

  // Untracked properties

  // Tracked properties

  // Getters and setters
  get OLO(): AnyObject {
    if (isNone(window.OLO)) {
      window.OLO = {};
    }

    return window.OLO;
  }

  set OLO(value: AnyObject) {
    window.OLO = value;
  }

  // Lifecycle methods

  // Other methods
  location(): Location {
    return window.location;
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    window: WindowService;
  }
}
