import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { HEADER_ID } from 'mobile-web/components/header';
import scroll from 'mobile-web/lib/scroll';
import { classes } from 'mobile-web/lib/utilities/classes';
import isSome from 'mobile-web/lib/utilities/is-some';
import { SafeString } from 'mobile-web/services/content';
import FeaturesService from 'mobile-web/services/features';

import style from './index.m.scss';

/**
 * We need a delay because the animations take time to complete; for accurate calculations,
 * we need the animations to be done so that the page length is settled.
 * We got this number by experimentation; it's fast enough to not be noticeable,
 * but slow enough that the page length is correct.
 */
const MUTATION_OBSERVER_DELAY = 100;

interface Args {
  // Required arguments

  // Optional arguments
  class?: string;
  disabled?: boolean;
  placeholderClass?: string;
  id?: string;
  /**
   * Used for calculating offset values for when the brand carousel is present on the vendor menu.
   */
  topOffset?: number;
  bottomOffset?: number;
  alignLeft?: boolean;

  /**
   * scrollableContainers do not work entirely right when the container has a visible scrollbar.
   * The size changes from the scrollbar mess up the math in a way we didn't figure out.
   * If you are using this functionality, make sure to test it in Windows, which has always visible scrollbars.
   */
  scrollableContainer?: HTMLElement;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [
      {
        isSticky: boolean;
      }
    ];
  };
}

export default class StickyElement extends Component<Signature> {
  // Service injections
  @service features!: FeaturesService;

  // Untracked properties
  onScroll!: Action;
  scrollLengthObserver?: MutationObserver;
  style = style;

  // Tracked properties
  @tracked isSticky: false | 'bottom' | 'top' = false;
  @tracked stickyStyle!: SafeString;
  @tracked topOffsetHeight = this.args.topOffset;
  @tracked bottomOffsetHeight = this.args.bottomOffset;

  // Getters and setters
  get stickyElementClass() {
    const isStickyClass =
      this.isSticky === 'bottom'
        ? style.isStickyBottom
        : this.isSticky === 'top'
        ? style.isStickyTop
        : '';
    const absoluteClass =
      this.isSticky && isSome(this.args.scrollableContainer) ? style.absolute : '';
    return classes(style.stickyElement, isStickyClass, absoluteClass, this.args.class);
  }

  get uniqueId(): string {
    return guidFor(this);
  }

  // Lifecycle methods

  // Other methods
  @action
  setup() {
    this.setSticky();
    this.onScroll = scroll(() => {
      this.setSticky();
    });

    this.scrollLengthObserver = new MutationObserver(() => {
      later(this.onScroll, MUTATION_OBSERVER_DELAY);
    });
    const observedElement = isSome(this.args.scrollableContainer)
      ? this.args.scrollableContainer
      : document;
    this.scrollLengthObserver.observe(observedElement, { childList: true, subtree: true });

    (this.args.scrollableContainer || window).addEventListener('scroll', this.onScroll);
  }

  @action
  teardown() {
    if (isSome(this.scrollLengthObserver)) {
      this.scrollLengthObserver.disconnect();
    }

    (this.args.scrollableContainer || window).removeEventListener('scroll', this.onScroll);
  }

  setSticky() {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    if (this.args.disabled) {
      this.isSticky = false;
      return;
    }

    this.topOffsetHeight = this.args.topOffset ? this.args.topOffset : 0;
    this.bottomOffsetHeight = this.args.bottomOffset ? this.args.bottomOffset : 0;

    /** This element occupies the space that the sticky content should slot into. */
    const placeholderElement = document.getElementById(this.uniqueId)!;
    const stickyElement = placeholderElement.children[0] as HTMLElement;
    const placeholderBottom = placeholderElement.offsetTop + stickyElement.offsetHeight;
    placeholderElement.style.height = `${stickyElement.offsetHeight}px`;

    /**
     * This only matters if the scrollable container is the window
     * because only in that case do we have to account for the height of the app header.
     */
    const headerElement = isSome(this.args.scrollableContainer)
      ? undefined
      : document.getElementById(HEADER_ID);
    const headerHeight = headerElement ? headerElement.offsetHeight : 0;
    const finalBottom = placeholderBottom + headerHeight;

    /**
     * We need scrollTop separate because document.body.scrollTop doesn't work.
     * We need window.scrollY here, but everything else works from document.body.
     */
    const scrollTop = isSome(this.args.scrollableContainer)
      ? this.args.scrollableContainer.scrollTop
      : window.scrollY;
    const scrollableContainer = isSome(this.args.scrollableContainer)
      ? this.args.scrollableContainer
      : document.body;
    const scrollContainerVisibleHeight = window.innerHeight - scrollableContainer.offsetTop;
    const scrollContainerVisibleBottom =
      scrollTop + scrollContainerVisibleHeight - this.bottomOffsetHeight!;
    const isAtBottom = scrollContainerVisibleBottom === scrollableContainer.scrollHeight;

    const isAtTop = window.scrollY >= placeholderElement.offsetTop - this.topOffsetHeight!;

    if (scrollContainerVisibleBottom < finalBottom && !isAtBottom) {
      this.isSticky = 'bottom';
      this.stickyStyle = htmlSafe(
        `bottom: ${this.bottomOffsetHeight!}px; ${this.args.alignLeft ? 'left: 0;' : ''}`
      );
    } else if (isAtTop) {
      this.isSticky = 'top';
      this.stickyStyle = htmlSafe(
        `top: ${headerHeight + this.topOffsetHeight!}px; ${this.args.alignLeft ? 'left: 0;' : ''}`
      );
    } else {
      this.isSticky = false;
      this.stickyStyle = htmlSafe('');
    }
  }

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    StickyElement: typeof StickyElement;
  }
}
