type Target = AnyObject;
type PropertyKey = string | symbol;

interface PropertyDescriptorBuilder<Options> {
  (
    options: Options,
    target: Target,
    propertyKey: PropertyKey,
    existingDescriptor: PropertyDescriptor
  ): PropertyDescriptor;
}

/**
 * This type allows the generated decorator to be called with options.
 *
 * @example
 * const example = propertyDecoratorFactory<{ foo: string }>(...);
 *
 * class AnExampleClass {
 *   \@example({ foo: 'bar' }) anExampleProperty: any;
 * }
 */
type PropertyDecoratorWithOptions<Options> = Options extends {} // not null | undefined
  ? (options: Options) => PropertyDecorator
  : () => never;

/**
 * This type allows the generated decorator to be called without options.
 *
 * @example
 * const example = propertyDecoratorFactory<undefined>(...);
 *
 * class AnExampleClass {
 *   \@example anExampleProperty: any;
 * }
 */
type PropertyDecoratorWithoutOptions<Options> = Options extends undefined
  ? PropertyDecorator
  : () => never;

/**
 * This type allows the generated decorator to be called with or without options
 * depending on whether `undefined` is included as part of `Options`.
 */
type OptionsEnabledPropertyDecorator<Options> = PropertyDecoratorWithOptions<Options> &
  PropertyDecoratorWithoutOptions<Options>;

/**
 * Builds a TypeScript- and Ember-compatible decorator function.
 *
 * If `undefined` is used in the `Options` type, the generated decorator can be
 * called without options. (I.E. `@example property: any`).
 *
 * If an object or primitive type is used in the `Options` type, the generated
 * decorator can be called with those options. (I.E.
 * `@example('lorem ipsum') property: any`).
 *
 * @param propertyDescriptorBuilder the function to use to generate the
 * resultant property decorator.
 * @returns a function that can be used with options to generate a
 * `PropertyDecorator`, or used as a `PropertyDecorator` directly.
 */
export const propertyDecoratorFactory = <Options>(
  propertyDescriptorBuilder: PropertyDescriptorBuilder<Options>
): OptionsEnabledPropertyDecorator<Options> =>
  ((...args: [Options] | [Target, PropertyKey] | [Target, PropertyKey, PropertyDescriptor]) => {
    // Determine if options were provided when calling the decorator
    // If so, use those options, otherwise use `undefined`.
    const options =
      args.length === 1
        ? args[0]
        : // the `Options` type must include `undefined` for this signature to be allowed
          (undefined as unknown as Options);

    /**
     * The decorator function to decorate the property on the target.
     *
     * TypeScript expects decorators to be called with the target object and its
     * property key as a string or symbol. The existing property descriptor
     * should be read from the target, and changes to the property descriptor
     * should be applied directly to the target object.
     *
     * Ember expects decorators to be called with the target object, its
     * property key as a string or symbol, and the existing property descriptor.
     * Changes to the property descriptor should be returned so that Ember can
     * compose each subsequent decorator before applying the descriptor to the
     * target object.
     *
     * We can use the length of the arguments to determine which approach to
     * take.
     *
     * @param args
     * @returns
     */
    const decorator = (
      ...decoratorArgs: [Target, PropertyKey] | [Target, PropertyKey, PropertyDescriptor]
    ) => {
      // The target object and property key are always the first two arguments.
      const [target, propertyKey] = decoratorArgs;

      // If the decorator is being applied at the TypeScript level, get the
      // existing descriptor from the target object.
      // Otherwise get the third argument (index 2)
      const existingDescriptor =
        decoratorArgs.length === 2
          ? Object.getOwnPropertyDescriptor(target, propertyKey) ?? {}
          : decoratorArgs[2];

      // Build the new property descriptor using the builder function.
      // Default both `configurable` and `enumerable` to `true` as most
      // properties would want these settings enabled by default, and can
      // explicitly disable them as-needed.
      const newDescriptor = {
        configurable: true,
        enumerable: true,
        ...propertyDescriptorBuilder(options, target, propertyKey, existingDescriptor),
      };

      if (decoratorArgs.length === 2) {
        Object.defineProperty(target, propertyKey, newDescriptor);
        return undefined;
      }
      return newDescriptor;
    };

    return args.length === 1
      ? // if one parameter is passed to the function,
        // treat it as options and return the decorator
        decorator
      : // if more than one parameter is passed to the function,
        // treat them as the decorator's parameters and return the result.
        decorator(...args);
  }) as unknown as OptionsEnabledPropertyDecorator<Options>;
