import { inject as service } from '@ember/service';

import Modifier from 'ember-modifier';

import ScrollService from 'mobile-web/services/scroll';

interface Args {
  Positional: [boolean];
  Named: {
    behavior?: ScrollBehavior;
  };
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

/**
 * Scrolls to the modified element when the first parameter parameter is changed
 * from `false` to `true`.
 *
 * An optional `behavior` parameter can be passed to specify the scroll behavior
 * (defaults to `'smooth'`).
 *
 * @example
 * <div {{scroll-when this.active behavior='auto'}}>
 *   ...
 * </div>
 */
export default class ScrollWhenModifier extends Modifier<Signature> {
  @service scroll!: ScrollService;

  private previous = {
    shouldScroll: false,
    behavior: 'smooth',
  };

  modify(
    element: Signature['Element'],
    [shouldScroll]: Signature['Args']['Positional'],
    { behavior = 'smooth' }: Signature['Args']['Named']
  ) {
    const scrollParent = this.scroll.getOverflowableParent(element);

    if (!scrollParent) {
      return;
    }

    const changed =
      this.previous.shouldScroll !== shouldScroll || this.previous.behavior !== behavior;

    this.previous = {
      shouldScroll,
      behavior,
    };

    if (!shouldScroll || !changed) {
      return;
    }

    this.scroll.scrollStop().then(() => {
      scrollParent.scrollTo({
        top: element.offsetTop,
        left: element.offsetLeft,
        behavior,
      });
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'scroll-when': typeof ScrollWhenModifier;
  }
}
