export default {
  "root": "_root_ektpja",
  "stickyElement": "_stickyElement_ektpja",
  "stickyElementPlaceholder": "_stickyElementPlaceholder_ektpja",
  "content": "_content_ektpja",
  "stickyNav": "_stickyNav_ektpja",
  "isSticky": "_isSticky_ektpja",
  "isLoading": "_isLoading_ektpja",
  "navItemContainer": "_navItemContainer_ektpja",
  "button": "_button_ektpja",
  "active": "_active_ektpja",
  "pushLeft": "_pushLeft_ektpja",
  "trigger": "_trigger_ektpja",
  "popoverButton": "_popoverButton_ektpja _button_ektpja",
  "moreLabel": "_moreLabel_ektpja",
  "moreIcon": "_moreIcon_ektpja",
  "moreIconOpen": "_moreIconOpen_ektpja _moreIcon_ektpja",
  "moreIconClosed": "_moreIconClosed_ektpja _moreIcon_ektpja",
  "moreActiveNavItem": "_moreActiveNavItem_ektpja"
};
