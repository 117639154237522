import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';

import FeaturesService from './features';

const SECURE_ROUTES = ['checkout.auth', 'checkout', 'secure-challenge', 'open-check.review'];

export default class SecurityService extends Service {
  // Service injections
  @service features!: FeaturesService;
  @service router!: RouterService;

  // Getters and setters
  get currentRouteIsSecure(): boolean {
    return this.routeIsSecure(this.router.currentRouteName);
  }

  //Methods
  routeIsSecure(route?: string): boolean {
    return Boolean(SECURE_ROUTES.includes(route || ''));
  }
}

declare module '@ember/service' {
  interface Registry {
    security: SecurityService;
  }
}
