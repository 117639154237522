import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  value?: number;
}

interface Signature {
  Args: Args;
}

export default class DistanceDisplay extends Component<Signature> {
  // Service injections

  // Untracked properties
  ariaUnit = 'miles';
  unit = 'mi';
  style = style;

  // Tracked properties

  // Getters and setters
  get formattedValue() {
    return (this.args.value ?? 0).toFixed(1);
  }

  get ariaLabel(): string {
    return `${this.formattedValue} ${this.ariaUnit}`;
  }

  get label() {
    return `${this.formattedValue} ${this.unit}`;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DistanceDisplay: typeof DistanceDisplay;
  }
}
