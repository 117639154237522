import dayjs, { Dayjs } from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { roundUpToNext } from 'mobile-web/lib/utilities/math';

dayjs.extend(AdvancedFormat);
dayjs.extend(calendar);
dayjs.extend(dayOfYear);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(utc);

export default dayjs;
export { Dayjs };

export const DAY_FORMAT = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: 'MMM Do YYYY',
};

/**
 * Round up from a starting time to a given interval in minutes later.
 *
 * @param time      The time to start from.
 * @param interval  The amount of time (in minutes) to round up. Defaults to
 *                  rounding up to the next multiple-of-5 minute.
 *
 * @returns a new `Dayjs` instance representing the rounded-up time.
 */
export function roundUpTime(time: Dayjs, interval = 5): Dayjs {
  const baseMinutes = time.minute();
  const nextInterval = roundUpToNext(interval, baseMinutes);
  const diff = nextInterval - baseMinutes;
  return time.add(diff, 'minute').second(0).millisecond(0);
}

/**
 * Generate a set of time slots at even intervals, starting at the next even
 * interval from now.
 *
 * NOTE: this currently assumes that you are never generating more than a full
 * year of days.
 *
 * @param count The number of intervals to generate. Defaults to generating
 *        enough intervals for the next 12 hours given the default interval size.
 * @param interval  The length of the intervals (in minutes) to generate.
 *        Defaults to 15.
 * @param start The start time. Defaults to generating "now".
 * @param includeStart Whether to allow the start time to be included. Defaults
 *        to `false`; override e.g. when getting all intervals for a day
 *        starting at midnight.
 */
export function timeIntervals(
  count = 48,
  interval = 15,
  start = dayjs(),
  includeStart = false
): Dayjs[] {
  const possibleFirst = roundUpTime(start, interval);

  // Account for the occasions that we're exactly on the interval when we start.
  const first =
    start.minute() === possibleFirst.minute() && !includeStart
      ? possibleFirst.add(interval, 'minute')
      : possibleFirst;

  const intervals = [first];
  for (let i = 0; i < count - 1; i++) {
    intervals.push(intervals[i].add(interval, 'minute'));
  }

  return intervals.filter(i => i.dayOfYear() === start.dayOfYear());
}

/**
 * Generate a number of days.
 *
 * NOTE: this currently assumes that you are never generating more than a full
 * year of days.
 *
 * @param count The number of days to generate. Defaults to 7 (a week).
 * @param start The first day in the interval. Defaults to "now", i.e. today.
 * @returns `count` `Dayjs` instances representing each day.
 */
export function dayIntervals(count = 7, start = dayjs()): Dayjs[] {
  const days = [start];
  for (let i = 0; i < count - 1; i++) {
    days.push(days[i].add(1, 'day').startOf('day'));
  }

  return days;
}
