import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { CustomizedProductModel } from 'mobile-web/lib/menu';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import { isBasketProduct } from 'mobile-web/models/basket-product';
import BrandModel from 'mobile-web/models/brand';
import AnimationService from 'mobile-web/services/animation';
import GroupOrderService from 'mobile-web/services/group-order';

import style from './index.m.scss';

interface Args {
  // Required arguments
  products: CustomizedProductModel[];

  // Optional arguments
  currency?: string;
  editable?: boolean;
  initialCount?: number;
  onClose?: Action;
  participantName?: string;
  showGroupOrderName?: boolean;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class AggregatedCartProductList extends Component<Signature> {
  // Service injections
  @service groupOrder!: GroupOrderService;
  @service animation!: AnimationService;

  // Untracked properties
  animationDuration = this.animation.getDuration(40);
  style = style;
  brandMap = new Map<BrandModel, CustomizedProductModel[]>();
  transition = this.animation.slideAndFadeInSlideAndFadeOutTransition;
  ids = guids(this, 'brandHeading');

  // Tracked properties
  @tracked isExpanded = false;

  // Getters and setters
  get shownProducts() {
    return this.isExpanded || isNone(this.args.initialCount)
      ? this.brandProductMapArray
      : this.brandProductMapArray.slice(0, this.args.initialCount);
  }

  get hiddenProductCount() {
    return this.brandProductMapArray.length - (this.args.initialCount ?? 0);
  }

  get showExpandButton() {
    return (
      !this.isExpanded &&
      isSome(this.args.initialCount) &&
      this.brandProductMapArray.length > this.args.initialCount
    );
  }

  get brandProductMapArray(): Array<{
    brand: BrandModel;
    products: CustomizedProductModel[];
    totalQuantity: number;
  }> {
    return Array.from(this.brandProductMap)
      .map(([brand, products]) => ({
        brand,
        products,
        totalQuantity: products.reduce((count, p) => count + p.quantity, 0),
      }))
      .sort((a, b) => a.brand.sortOrder - b.brand.sortOrder);
  }

  get brandProductMap(): Map<BrandModel, CustomizedProductModel[]> {
    this.brandMap.clear();
    this.args.products.forEach(basketProduct => {
      if (isBasketProduct(basketProduct)) {
        const product = basketProduct.store.peekRecord('product', basketProduct.product.get('id')!);
        const brandName = product!.brandName!;
        const brand = basketProduct.store.peekAll('brand').filter(b => b.brandName === brandName)
          .firstObject!;
        if (!this.brandMap.has(brand)) {
          // eslint-disable-next-line ember/no-side-effects
          this.brandMap.set(brand, [basketProduct]);
        } else {
          this.brandMap.get(brand)!.push(basketProduct);
        }
      }
    });
    return this.brandMap;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  expandProducts() {
    this.isExpanded = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::AggregatedCartProductList': typeof AggregatedCartProductList;
  }
}
