import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import SideMenuService from 'mobile-web/services/side-menu';
import UserFeedback from 'mobile-web/services/user-feedback';

export type Args = RequireAtLeastOne<
  {
    // Required arguments

    // Optional arguments
    // From <LinkTo> https://deprecations.emberjs.com/v3.x/#toc_ember-built-in-components-legacy-arguments
    onClick?: (e: MouseEvent) => void;
    route?: string;
    model?: unknown;
    models?: unknown[];
    query?: Record<string, unknown>;
    // replace?: boolean; Uncomment when Glint types are updated
    disabled?: boolean;
    // 'current-when'?: string | boolean; Type is correct but Glint can't seem to deal with the hyphen
    activeClass?: string;
    // loadingClass?: string; Uncomment when Glint types are updated
    // disabledClass?: string; Uncomment when Glint types are updated

    // Custom
    testSelector?: string;
  },
  'route' | 'model' | 'models' | 'query'
>;

interface Signature {
  Element: HTMLAnchorElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class LinkTo extends Component<Signature> {
  // Service injections
  @service sideMenu!: SideMenuService;
  @service userFeedback!: UserFeedback;

  // Untracked properties

  // Tracked properties

  // Getters and setters
  get testSelector(): string {
    return this.args.testSelector ?? '';
  }

  get query(): Record<string, unknown> {
    return this.args.query ?? {};
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  handleClick(e: MouseEvent) {
    this.args.onClick?.(e);
    this.sideMenu.close();
    this.userFeedback.clearAll();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OloLinkTo: typeof LinkTo;
  }
}
