import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import UrlTemplates from 'ember-data-url-templates';

import { host } from 'mobile-web/lib/hybrid-util';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';

export default class CategoryAdapter extends ApplicationAdapter.extend(UrlTemplates) {
  @service vendor!: VendorService;

  coalesceFindRequests = true;
  urlTemplate = host() + '/{+namespace}/vendors/{vendorId}/categories/{categoryId}';
  urlSegments = {
    vendorId(
      this: CategoryAdapter,
      _type: unknown,
      _id: unknown,
      snapshot: DS.Snapshot<'category'> | DS.Snapshot<'category'>[]
    ): string {
      const record = 'length' in snapshot ? snapshot[0] : snapshot;

      const vendor = record.belongsTo('vendor') as DS.Snapshot<'vendor'> | undefined;

      if (isNone(vendor) && isNone(this.vendor.vendor)) {
        throw new Error('Vendor Id for Category Not Found');
      }

      // The line above errors if the conditions here don't hold; `!` is safe
      // accordingly.
      return vendor?.record.get('id') ?? this.vendor.vendor!.id;
    },

    categoryId(_type: unknown, _id: unknown, snapshot: DS.Snapshot<'category'>): string {
      return snapshot.id;
    },

    namespace(this: CategoryAdapter): string {
      return this.namespace;
    },
  };
}
