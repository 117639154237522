import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ComponentLike } from '@glint/template';

import BasketProductModel from 'mobile-web/models/basket-product';
import Order from 'mobile-web/models/order';

import { PostCheckoutSubsectionLineItemSignature } from '../subsection/line-item';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  order?: Order;
}

interface Signature {
  Args: Args;

  Blocks: {
    default: [
      {
        lineItem: ComponentLike<PostCheckoutSubsectionLineItemSignature>;
      }
    ];
  };
}

export default class PostCheckoutOrderContents extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;
  products = this.args.order?.basketProducts;
  brandMap = new Map<string, BasketProductModel[]>();

  // Tracked properties
  @tracked showItemCustomizations = false;

  // Getters and setters
  get basketProducts(): BasketProductModel[] {
    return this.args.order?.basketProducts?.toArray() ?? [];
  }

  get brandProductMapArray(): Array<{ brandName: string; products: BasketProductModel[] }> {
    return Array.from(this.brandProductMap).map(([brandName, products]) => ({
      brandName,
      products,
    }));
  }

  get brandProductMap(): Map<string, BasketProductModel[]> {
    this.brandMap.clear();
    this.products?.forEach(basketProduct => {
      const brandName = basketProduct!.brandName!;
      if (!this.brandMap.has(brandName)) {
        // eslint-disable-next-line ember/no-side-effects
        this.brandMap.set(brandName, [basketProduct]);
      } else {
        this.brandMap.get(brandName)!.push(basketProduct);
      }
    });
    return this.brandMap;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  viewItemCustomizations() {
    this.showItemCustomizations = true;
  }

  @action
  hideItemCustomizations() {
    this.showItemCustomizations = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::AggregatedOrderContents': typeof PostCheckoutOrderContents;
  }
}
