import DS from 'ember-data';
import ModelRegistry from 'ember-data/types/registries/model';

import ApplicationAdapter from 'mobile-web/adapters/application';
import { isBoolean } from 'mobile-web/lib/utilities/_';

export default class BootstrapDataAdapter extends ApplicationAdapter.extend({}) {
  urlForFindRecord<K extends keyof ModelRegistry>(
    id: string,
    modelName: K,
    snapshot: DS.Snapshot<K>
  ): string {
    const baseUrl = super.urlForFindRecord(id, modelName, snapshot);
    const includeAnalytics = isBoolean(window.Olo.includeAnalytics)
      ? window.Olo.includeAnalytics
      : false;
    return `${baseUrl}?includeAnalytics=${includeAnalytics}`;
  }
}
