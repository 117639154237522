import { action } from '@ember/object';
import Evented from '@ember/object/evented';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { next } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { session } from 'mobile-web/decorators/storage';
import isSome from 'mobile-web/lib/utilities/is-some';

interface Position {
  left: number;
  top: number;
}

export class PageScrollEvent extends Event {
  transition: Transition;

  constructor(type: 'beforechange' | 'change', transition: Transition) {
    super(type, {
      cancelable: type === 'beforechange',
    });

    this.transition = transition;
  }
}

/**
 * The `PageScrollService` automatically adjusts the scroll position of the page
 * after a route transition.
 *
 * When transitioning to a new route, that page needs to scroll to the
 * [indicated part](https://html.spec.whatwg.org/multipage/browsing-the-web.html#scrolling-to-a-fragment),
 * which is the `:target` element or the top of the page if no target is
 * specified.
 *
 * When returning to a previous route, the page needs to scroll to the previous
 * scroll position before the page was exited.
 *
 * The `PageScrollService` is automatically initialized (see:
 * `app/instance-initializers/page-scroll.ts`) and will use the default page
 * scroll behavior for all route transitions.
 *
 * If scrolling is not desirable, the scroll can be canceled by any code that
 * has access to the service.
 *
 * The `PageScrollService` will emit a cancelable `beforechange` event before
 * changing the scroll position.
 *
 * If the `beforechange` event is not prevented, the service will scroll and
 * then emit a `change` event that can be used to make other adjustments, such
 * as focusing a specific element on the page.
 *
 * Both events expose the `transition` object which can be used to determine
 * behavior:
 *
 * ```
 * this.pageScroll.on('beforechange', (event) => {
 *   if (event.transition.from?.name === 'a.b.c' &&
 *     event.transition.to.name === 'x.y.z') {
 *     event.preventDefault();
 *   }
 * });
 * ```
 */
export default class PageScrollService extends Service.extend(Evented) {
  // Service injections
  @service router!: RouterService;

  // Untracked properties

  // Tracked properties
  /**
   * The unique ID of the entry in the browser history.
   */
  @tracked pageKey?: string;

  /**
   * The element to scroll.
   */
  @tracked pageScrollContext = document.documentElement;

  /**
   * The scroll positions of previous pages.
   *
   * These positions are stored in sessionStorage so that they persist through
   * full page reloads.
   */
  @session scrollPositions?: Record<string, Position | undefined>;

  // Getters and setters
  /**
   * The saved scroll position of the current page.
   */
  get position() {
    const key = this.pageKey;
    return isSome(key) ? this.scrollPositions?.[key] : undefined;
  }

  /**
   * The current `:target` element.
   *
   * Unfortunately `document.querySelector(':target')` may not at times return
   * the correct element even if the location hash is set and the matching DOM
   * node exists.
   */
  get target() {
    return location.hash.length > 1
      ? document.getElementById(location.hash.slice(1)) ?? undefined
      : undefined;
  }

  // Lifecycle Methods
  constructor() {
    super(...arguments);

    this.router.on('routeDidChange', this.routeDidChange);
    this.router.on('routeWillChange', this.routeWillChange);
    window.addEventListener('beforeunload', this.beforeUnload, false);
  }

  willDestroy() {
    this.router.off('routeDidChange', this.routeDidChange);
    this.router.off('routeWillChange', this.routeWillChange);
    window.removeEventListener('beforeunload', this.beforeUnload, false);
  }

  // Other methods
  /**
   * Gets the unique ID of the current entry in the browser history.
   *
   * @returns the unique ID as a string if one exists, otherwise `undefined`
   */
  private getPageKey() {
    const key = history.state?.uuid as unknown;
    return isSome(key) && typeof key === 'string' ? key : undefined;
  }

  /**
   * Saves the current scroll position to `sessionStorage`.
   */
  private saveScrollPosition() {
    const key = this.pageKey;
    if (key) {
      const position = {
        left: this.pageScrollContext.scrollLeft,
        top: this.pageScrollContext.scrollTop,
      };
      this.scrollPositions = {
        ...this.scrollPositions,
        [key]: position,
      };
    }
  }

  /**
   * Updates the page key.
   */
  private updatePageKey() {
    this.pageKey = this.getPageKey();
  }

  /**
   * Updates the scroll position for the page.
   *
   * Fires a cancelable `beforechange` event to determine if the page should
   * scroll.
   *
   * If the page has already been visited (i.e. an existing position was set)
   * it scrolls the page to that position.
   *
   * Otherwise, if the page has a `:target`, it scrolls to the target.
   *
   * Otherwise it scrolls to the top of the page.
   *
   * Finally fires a `change` event.
   *
   * @param transition the page transition
   */
  private updateScrollPosition(transition: Transition) {
    const beforeChangeEvent = new PageScrollEvent('beforechange', transition);
    this.trigger('beforechange', beforeChangeEvent);

    if (beforeChangeEvent.defaultPrevented) {
      return;
    }

    const position = this.position;
    const target = this.target;
    if (isSome(position)) {
      this.pageScrollContext.scrollTo({
        behavior: 'auto',
        ...position,
      });
    } else if (target) {
      target.scrollIntoView({
        block: 'start',
        inline: 'start',
        behavior: 'auto',
      });
    } else {
      this.pageScrollContext.scrollTo({
        behavior: 'auto',
        left: 0,
        top: 0,
      });
    }

    const changeEvent = new PageScrollEvent('change', transition);
    this.trigger('change', changeEvent);
  }

  // Tasks

  // Actions and helpers
  /**
   * Save the position before the user leaves the page such as might happen for
   * an external link, or when hard-reloading the page.
   */
  @action
  private beforeUnload() {
    this.saveScrollPosition();
  }

  /**
   * The callback for when the route has changed.
   *
   * Route changes occur with the following order of events:
   *
   * 1. The browser history API is updated (pushState/replaceState)
   * 2. The `routeWillChange` callback is executed
   * 3. The `routeDidChange` callback is executed
   * 4. The application renders the new route
   *
   * Because the history has already been updated when the `routeWillChange`
   * callback occurs, looking up the current unique ID from `history.state.uuid`
   * will result in the ID for the _new_ route, rather than the previous route.
   *
   * To make sure we save the scroll position correctly, we keep track of the
   * unique ID with the `pageKey` property, which we update here in the
   * `routeDidChange` callback for the new page session.
   *
   * After that, we wait for the next task so that Ember has had a chance to
   * render the new route, allowing the scroll position update to target any new
   * DOM nodes should a location hash be specified.
   */
  @action
  private routeDidChange(transition: Transition) {
    this.updatePageKey();
    next(() => this.updateScrollPosition(transition));
  }

  /**
   * Save the position before the user leaves the current route.
   *
   * When this callback is called, `history.state.uuid` will have already been
   * updated to the ID for the new route, so `pageKey` is used to keep track of
   * the ID from the previous route.
   */
  @action
  private routeWillChange() {
    this.saveScrollPosition();
  }
}

declare module '@ember/service' {
  interface Registry {
    'page-scroll': PageScrollService;
  }
}
