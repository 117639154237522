import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import dayjs from 'mobile-web/lib/dayjs';
import { Coords, distanceBetweenCoords } from 'mobile-web/lib/location/address';
import { getHandoffLabel, HandoffMode, isDelivery } from 'mobile-web/lib/order-criteria';
import { isSome } from 'mobile-web/lib/utilities/is-some';
import Vendor from 'mobile-web/models/vendor';
import AddressService from 'mobile-web/services/address';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  vendor: Vendor;
  onToggleFavorite: () => void;

  // Optional arguments
  showInfoModal?: boolean;
  showMenuLink?: boolean;
}

interface Signature {
  Args: Args;
}

export default class VendorInfo extends Component<Signature> {
  // Service injections
  @service address!: AddressService;
  @service router!: RouterService;
  @service orderCriteria!: OrderCriteriaService;
  @service intl!: IntlService;
  @service groupOrder!: GroupOrderService;
  @service store!: DS.Store;
  @service basket!: BasketService;
  @service error!: ErrorService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service onPremise!: OnPremiseService;
  @service vendor!: VendorService;
  @service features!: FeaturesService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked showInfoModal: boolean;

  // Getters and setters
  get allowFavorites(): boolean {
    return this.channel.settings?.favorites ?? false;
  }

  get mapsUrl() {
    return this.address.createMapsApplicaitonLink(this.args.vendor.address);
  }

  get crossStreet() {
    const crossStreet = this.args.vendor.address.crossStreet;
    return isEmpty(crossStreet) ? '' : `(${crossStreet})`;
  }

  get title() {
    return `${this.args.vendor.name} Menu`;
  }

  get showTimezoneWarning(): boolean {
    return dayjs().utcOffset() !== this.args.vendor.utcOffset * 60;
  }

  get handoffMinTagDescription(): string | undefined {
    // minimumDeliveryOrder and minimumPickupOrder are only enforced
    // by the supportedModes below
    const supportedModes: HandoffMode[] = ['CounterPickup', 'CurbsidePickup', 'Delivery'];

    if (this.orderCriteria.criteria) {
      const handoff = this.orderCriteria.criteria.handoffMode;
      return supportedModes.includes(handoff) ? getHandoffLabel(handoff, this.store) : undefined;
    }
    return undefined;
  }

  get handoffMinTagAmount(): number | undefined {
    if (this.orderCriteria.criteria) {
      const handoff = this.orderCriteria.criteria.handoffMode;
      return handoff === 'Delivery'
        ? this.args.vendor.minimumDeliveryOrder
        : this.args.vendor.minimumPickupOrder;
    }
    return undefined;
  }

  get vendorDistance() {
    if (this.orderCriteria.criteria) {
      const oc = this.orderCriteria.criteria;
      if (isDelivery(oc) && oc.deliveryAddress?.id) {
        const address = this.store.peekRecord('address', oc.deliveryAddress.id);
        if (isSome(address)) {
          try {
            return distanceBetweenCoords(address as Coords, this.args.vendor);
          } catch (e) {
            this.error.sendExternalError(e);
          }
        }
      }
    }
    return undefined;
  }

  // Getters
  get showGroupOrderBanner(): boolean {
    return (
      this.groupOrder.isEnabled && !this.groupOrder.hasGroupOrder && !this.onPremise.hasOpenCheck
    );
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.showInfoModal = args.showInfoModal ?? false;
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  closeInfoModal() {
    this.showInfoModal = false;
  }

  @action
  viewMenu() {
    this.router.transitionTo('menu.vendor', this.args.vendor.slug);
  }

  @action
  trackClick() {
    if (this.features.flags['ox-append-channel-name-to-map-link-olo-80911']) {
      this.analytics.trackEvent(AnalyticsEvents.ViewMapClicked);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VendorInfo: typeof VendorInfo;
  }
}
