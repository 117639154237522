import isSome from './utilities/is-some';

type StorableStyles = 'overflow' | 'paddingRight';

const ORIGINAL_STYLES = new Map<HTMLElement, Map<StorableStyles, string>>();

export function setTemporaryStyle(
  element: HTMLElement,
  styleName: StorableStyles,
  style: string
): void {
  let map = ORIGINAL_STYLES.get(element);
  if (!map) {
    map = new Map();
    ORIGINAL_STYLES.set(element, map);
  }
  if (!map.has(styleName)) {
    map.set(styleName, element.style[styleName]);
    element.style[styleName] = style;
  }
}

export function unsetTemporaryStyle(element: HTMLElement, styleName: StorableStyles): void {
  const map = ORIGINAL_STYLES.get(element);
  const originalStyle = map?.get(styleName);
  if (map && originalStyle !== undefined) {
    element.style[styleName] = originalStyle;
    map.delete(styleName);
  }
}

/**
 * Gets the parent elements of the specified element
 *
 * @param el the element to start at
 * @yields the parent elements of the specified element
 */
export function* getParents(el: HTMLElement): Generator<HTMLElement, void, unknown> {
  for (let parent = el.parentElement; isSome(parent); parent = parent.parentElement) {
    yield parent;
  }
}
