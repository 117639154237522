export default {
  "root": "_root_1e00n9",
  "description": "_description_1e00n9",
  "formFields": "_formFields_1e00n9",
  "formField": "_formField_1e00n9",
  "submitButton": "_submitButton_1e00n9",
  "cancelSection": "_cancelSection_1e00n9",
  "cancelDescription": "_cancelDescription_1e00n9",
  "cancelButton": "_cancelButton_1e00n9"
};
