const WHITESPACE = /[ \r\n\f\t]/g;

type Classes = string | string[] | Record<string, unknown> | undefined;

/**
 * @summary
 * Normalizes a list of strings, string arrays, and objects into a single list of class names.
 *
 *  - duplicate class names are removed
 *  - whitespace is normalized to single spaces
 *  - object keys are included when their values are truthy
 *
 * @param classLists the list of classes to normalize
 *
 * @returns a normalized list of classes
 *
 * @example <caption>Multiple parameters are merged into a single string</caption>
 *
 * classes('foo', 'bar', 'baz')
 * // 'foo bar baz'
 *
 * @example <caption>Object keys are merged when their values are truthy</caption>
 *
 * classes({
 *   'foo': true,
 *   'bar': false,
 *   'lorem': {},
 *   'ipsum': null,
 *   'fizz': 1,
 *   'buzz': 0,
 * })
 * // 'foo lorem fizz'
 */
export const classes = (...classLists: Classes[]): string => {
  const allClasses = classLists
    .flatMap(classList =>
      typeof classList === 'string'
        ? // if it's a string, separate it into individual classes
          classList.split(WHITESPACE)
        : Array.isArray(classList)
        ? // if it's an array, separate each string in the array into individual classes
          classList.flatMap(cl => cl.split(WHITESPACE))
        : classList
        ? // if it's an object,
          Object.entries(classList)
            // figure out which keys are enabled,
            .filter(([c, enabled]) => c && enabled)
            .flatMap(
              // and separate each key into individual classes
              ([c]) => c.split(WHITESPACE)
            )
        : // discard undefined values
          []
    )
    // discard empty strings
    .filter(c => c);

  return Array.from(new Set(allClasses)).join(' ');
};
