import BasketChoiceModel from 'mobile-web/models/basket-choice';
import ApplicationSerializer from 'mobile-web/serializers/application';

export default class BasketChoiceSerializer extends ApplicationSerializer {
  keyForRelationship(
    key: NonDSModelKeys<BasketChoiceModel>,
    typeClass: string,
    method: string
  ): string {
    if (key === 'choice') {
      return 'choiceId';
    }
    return super.keyForRelationship(key, typeClass, method);
  }
}
