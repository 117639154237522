import DS from 'ember-data';

import { HandoffMode } from 'mobile-web/lib/order-criteria';
import isSome from 'mobile-web/lib/utilities/is-some';
import OptionGroupModel from 'mobile-web/models/option-group';
import ApplicationSerializer from 'mobile-web/serializers/application';

export function normalizeDeliveryMode(deliveryMode: string): HandoffMode {
  switch (deliveryMode) {
    case 'Pickup':
      return 'CounterPickup';
    case 'Curbside':
      return 'CurbsidePickup';
    case 'Delivery':
      return 'Delivery';
    case 'Dispatch':
      return 'Dispatch';
    case 'DriveThru':
      return 'DriveThru';
    case 'DineIn':
      return 'DineIn';
    default:
      return 'Unspecified';
  }
}

function normalizeUnavailableHandoffModes(deliveryModes: string[]): HandoffMode[] {
  return deliveryModes.map(normalizeDeliveryMode);
}

export default class ProductSerializer extends ApplicationSerializer {
  normalizeResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: {
      optionGroups: Array<PureModel<OptionGroupModel>>;
      product: { optionGroups: string[] };
    },
    id: EmberDataId,
    requestType: string
  ): UnknownObject {
    if (isSome(payload.optionGroups)) {
      payload.product.optionGroups = payload.optionGroups.map(g => g.id);
    }
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  normalize(
    modelClass: DS.Model,
    resourceHash: {
      id?: number;
      minimumQuantity?: number;
      maximumQuantity?: number;
      unavailableHandoffModes?: HandoffMode[];
    },
    prop?: string
  ): UnknownObject {
    if (isSome(resourceHash.unavailableHandoffModes)) {
      resourceHash.unavailableHandoffModes = normalizeUnavailableHandoffModes(
        resourceHash.unavailableHandoffModes
      );
    }
    return super.normalize(modelClass, resourceHash, prop);
  }
}
