export function appleStoreLink(appIdentifier: string): string {
  return `https://itunes.apple.com/us/app/id${appIdentifier}`;
}

export function appleStoreListingLink(appIdentifier: string) {
  return `itms-apps://itunes.apple.com/us/app/id${appIdentifier}`;
}

export function googleStoreLink(appIdentifier: string) {
  return `https://play.google.com/store/apps/details?id=${appIdentifier}`;
}

export function googleStoreListingLink(appIdentifier: string) {
  return `market://details?id=${appIdentifier}`;
}
