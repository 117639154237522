import { action, set } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

import * as PopoverItem from 'mobile-web/components/popover-item';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  model?: PopoverItem.Model[];
  isHeader?: boolean;
  triggerClass?: string;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    content: [];
    default: [{ isOpen: boolean }];
  };
}

/**
 * @deprecated This component has been superseded by `Dropdown`
 */
export default class Popover extends Component<Signature> {
  style = style;

  triggerEl!: HTMLElement;

  get uniqueId(): string {
    return guidFor(this);
  }

  // workaround for the fact that ember-basic-dropdown doesn't let us set
  // arbitrary `data-` attributes on it
  get triggerSelectorClass(): string {
    return `popover-trigger-${this.uniqueId}`;
  }

  @action
  didInsert() {
    const el = document.querySelector(`.${this.triggerSelectorClass}`)?.firstElementChild;
    if (el) {
      set(this, 'triggerEl', el as HTMLElement);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Popover: typeof Popover;
  }
}
