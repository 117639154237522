import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import ChallengeService from 'mobile-web/services/challenge';

export default class ChallengeRoute extends Route {
  @service challenge!: ChallengeService;
  @service router!: RouterService;

  beforeModel() {
    if (!this.challenge.isChallengeActivated) {
      this.router.transitionTo('index');
    }
  }
}
