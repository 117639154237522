import Service, { inject as service } from '@ember/service';

import { RecentOrder } from 'mobile-web/models/basket';
import { AnalyticsEvents, AnalyticsProperties } from 'mobile-web/services/analytics';
import FeaturesService from 'mobile-web/services/features';
import SessionService from 'mobile-web/services/session';
import StorageService, { VendorHistoryData } from 'mobile-web/services/storage';

import AnalyticsService from './analytics';

export default class OrderItAgainService extends Service {
  // Service injections
  @service storage!: StorageService;
  @service session!: SessionService;
  @service features!: FeaturesService;
  @service analytics!: AnalyticsService;

  // Untracked properties

  // Tracked properties

  // Getters and setters
  get currentVendorStorage(): VendorHistoryData | undefined {
    return this.storage.vendorHistory?.[this.storage.vendorHistory?.length - 1];
  }

  get currentVendorOrderId(): string | undefined {
    return this.currentVendorStorage?.recentOrder?.orderId;
  }

  get currentVendorRecentOrder(): RecentOrder | undefined {
    return this.currentVendorStorage?.recentOrder;
  }

  get hideRecentOrdersCarousel(): boolean {
    return (
      !!this.currentVendorRecentOrder &&
      this.session.isLoggedIn &&
      this.storage.orderItAgainCohort === 'Test'
    );
  }

  get isInExperiment(): boolean {
    return this.storage.orderItAgainCohort === 'Test';
  }

  // Lifecycle methods

  // Other methods
  orderItAgainAnalytics() {
    this.analytics.trackEvent(AnalyticsEvents.OrderItAgainInExperiment, () => ({
      [AnalyticsProperties.OrderItAgainCohort]: this.storage.orderItAgainCohort,
    }));
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'order-it-again': OrderItAgainService;
  }
}
