import { action } from '@ember/object';
import Component from '@glimmer/component';

import { POPUP_OPTIONS } from 'mobile-web/lib/popup';
import { ContentString } from 'mobile-web/services/content';

import style from './index.m.scss';

const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php';

function firstHashTag(text: string): string {
  const matches = text.match(/#\w+/);
  return matches ? matches[0] : '';
}

interface Args {
  // Required arguments
  link: string;

  // Optional arguments
  quote?: ContentString;
}

interface Signature {
  Element: HTMLButtonElement;

  Args: Args;
}

export default class FacebookShareButton extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get quote(): string {
    return this.args.quote?.toString() ?? '';
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  share() {
    const hashtags = encodeURIComponent(firstHashTag(this.quote));
    const text = encodeURIComponent(this.quote);
    const url = `${FACEBOOK_SHARE_URL}?u=${this.args.link}&hashtag=${hashtags}&quote=${text}`;

    window.open(url, 'facebook', POPUP_OPTIONS);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FacebookShareButton: typeof FacebookShareButton;
  }
}
