import { helper } from '@ember/component/helper';

import { Dayjs } from 'mobile-web/lib/dayjs';

const formatDayjs = helper(([value, format]: [Dayjs, string]) => value.format(format));

export default formatDayjs;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-dayjs': typeof formatDayjs;
  }
}
