export default {
  "overlay": "_overlay_1lgice",
  "left": "_left_1lgice",
  "hidden": "_hidden_1lgice",
  "edge": "_edge_1lgice",
  "root": "_root_1lgice",
  "right": "_right_1lgice",
  "tag": "_tag_1lgice",
  "content": "_content_1lgice"
};
