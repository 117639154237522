import { later, next as runloopNext } from '@ember/runloop';
import RSVP from 'rsvp';

type Context = {
  isDestroyed?: boolean;
  isDestroying?: boolean;
};

/**
 * Safely await the next runloop. Throws if the context is destroyed/destroying.
 *
 * @param context the context to check.
 * @returns a promise that resolves on the next runloop.
 */
export const next = (context?: Context) =>
  new RSVP.Promise((resolve, reject) => {
    runloopNext(context, () => {
      if (context?.isDestroyed || context?.isDestroying) {
        reject();
      } else {
        resolve();
      }
    });
  });

export function safeLater(
  context: { isDestroyed?: boolean; isDestroying?: boolean },
  action: () => void,
  wait: number
): ReturnType<typeof later> {
  return later(() => {
    if (!context.isDestroyed && !context.isDestroying) {
      action.bind(context)();
    }
  }, wait);
}

export function safeNext(
  context: { isDestroyed?: boolean; isDestroying?: boolean },
  action: () => void
): ReturnType<typeof runloopNext> {
  return runloopNext(context, () => {
    if (!context.isDestroyed && !context.isDestroying) {
      action.bind(context)();
    }
  });
}
