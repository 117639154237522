/**
 * Originally based on https://github.com/yapplabs/ember-modal-dialog/blob/v4.0.0-alpha.1/addon/components/positioned-container.js
 */

import Component from '@glimmer/component';

interface Signature {
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class PositionedContainer extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ModalDialog::PositionedContainer': typeof PositionedContainer;
  }
}
