import DS from 'ember-data';

import dayjs, { Dayjs } from 'dayjs';
import cloneDeep from 'lodash.clonedeep';

import { Coords, SavedAddress, savedAddressToLabel } from 'mobile-web/lib/location/address';
import isSome from 'mobile-web/lib/utilities/is-some';
import SavedAddressModel from 'mobile-web/models/address';
import HandoffModeModel from 'mobile-web/models/handoff-mode';
import TimeWantedMode from 'mobile-web/models/time-wanted-mode';

import { Code } from './country';

/**
 * Here begins the raw type definitions for Order Criteria.
 * There are two parts of Order Criteria - time wanted and handoff mode.
 * Since both parts can vary in shape depending on their attributes,
 * we will build up the OrderCriteria type in separate pieces.
 **/

/** Time Wanted types */

type SpecifiedTime = 'Advance';
type UnspecifiedTime = 'Immediate' | 'ManualFire';
export type TimeWantedType = SpecifiedTime | UnspecifiedTime;

export type SpecifiedTimeCriteria = {
  timeWantedType: SpecifiedTime;
  timeWanted?: Dayjs;
};

export type UnspecifiedTimeCriteria = {
  timeWantedType: UnspecifiedTime;
};

export type TimeWantedCriteria = SpecifiedTimeCriteria | UnspecifiedTimeCriteria;

/** Handoff Mode types */

type UnspecifiedHandoff = 'Unspecified';
type AtStoreHandoff = 'CounterPickup' | 'CurbsidePickup' | 'DriveThru' | 'DineIn';
type DeliveryHandoff = 'Delivery' | 'Dispatch' | 'MultiDelivery';
export type HandoffMode = UnspecifiedHandoff | AtStoreHandoff | DeliveryHandoff;

type UnspecifiedHandoffCriteria = {
  handoffMode: UnspecifiedHandoff;
};

export type DeliveryHandoffCriteria = {
  handoffMode: DeliveryHandoff;
  deliveryAddress?: SavedAddress;
};

type AtStoreHandoffCriteria = {
  handoffMode: AtStoreHandoff;
  searchAddress: string;
  searchCoords?: Coords;
};

export type HandoffCriteria =
  | UnspecifiedHandoffCriteria
  | DeliveryHandoffCriteria
  | AtStoreHandoffCriteria;

/** Primary Order Criteria type */

export type OrderCriteria = TimeWantedCriteria & HandoffCriteria & { isDefault?: boolean };

/**
 * Several places in the back end insist on using single character keys for handoff modes,
 * so the following supports that. I hope to get rid of all this some day, and just use the
 * full handoff mode string everywhere, but that day is not today.
 */
export type HandoffModeKey = 'N' | 'P' | 'C' | 'D' | 'T' | 'U' | 'I' | 'Z';
export const DELIVERY_MODE_KEYS: HandoffModeKey[] = ['D', 'T', 'Z'];
export const DISPATCH_KEY: HandoffModeKey = 'T';
export const DRIVETHRU_KEY: HandoffModeKey = 'U';

const HandoffMap: { [key in HandoffModeKey]: HandoffMode } & {
  [mode in HandoffMode]: HandoffModeKey;
} = {
  P: 'CounterPickup',
  CounterPickup: 'P',
  C: 'CurbsidePickup',
  CurbsidePickup: 'C',
  U: 'DriveThru',
  DriveThru: 'U',
  I: 'DineIn',
  DineIn: 'I',
  D: 'Delivery',
  Delivery: 'D',
  T: 'Dispatch',
  Dispatch: 'T',
  Z: 'MultiDelivery',
  MultiDelivery: 'Z',
  N: 'Unspecified',
  Unspecified: 'N',
};
export const getHandoffKey = (h: HandoffMode): HandoffModeKey => HandoffMap[h] ?? 'U';
export const getHandoffMode = (k: HandoffModeKey): HandoffMode => HandoffMap[k] ?? 'Unspecified';

/**
 * The rest of this file is various utility methods to help you in dealing with Order Criteria objects.
 * These can be used for type narrowing or for various common checks.
 */

export const isDeliveryMode = (h?: HandoffMode): h is DeliveryHandoff =>
  h === 'Delivery' || h === 'Dispatch' || h === 'MultiDelivery';

export const isAtStoreMode = (h?: HandoffMode): h is AtStoreHandoff =>
  isSome(h) && h !== 'Unspecified' && !isDeliveryMode(h);

export const isAdvance = (c?: OrderCriteria): c is SpecifiedTimeCriteria & HandoffCriteria =>
  c?.timeWantedType === 'Advance';

export const isDelivery = (c?: OrderCriteria): c is DeliveryHandoffCriteria & TimeWantedCriteria =>
  isDeliveryMode(c?.handoffMode);

export const mapPostalCode = (postalcode: string): Code =>
  /^[ABCEFGHJKLMNPRSTVXY][0-9][ABCEFGHJKLMNPRSTVWXYZ][ ]?[0-9][ABCEFGHJKLMNPRSTVWXYZ][0-9]$/.test(
    postalcode?.toUpperCase().trim()
  )
    ? Code.CA
    : Code.US;

export const isAtStore = (c?: OrderCriteria): c is AtStoreHandoffCriteria & TimeWantedCriteria =>
  isAtStoreMode(c?.handoffMode);

// `MultiDelivery` is a special case, in that it can count as either `Dispatch` or `Delivery`
export const handoffMatches = (h1?: HandoffMode, h2?: HandoffMode): boolean =>
  h1 === h2 ||
  (isDeliveryMode(h1) && h2 === 'MultiDelivery') ||
  (h1 === 'MultiDelivery' && isDeliveryMode(h2));

const TimeWantedMap: { [id: number]: TimeWantedType } = {
  1: 'Advance',
  2: 'Immediate',
  4: 'ManualFire',
};
export const getTimeWantedTypeById = (id: number): TimeWantedType =>
  TimeWantedMap[id] ?? 'Immediate';

export const getTimeWantedTypeLabel = (t: TimeWantedType, store: DS.Store): string =>
  store.peekAll('time-wanted-mode').find(tw => tw.type === t)?.label ?? t;

export const getHandoffLabel = (h: HandoffMode, store: DS.Store): string =>
  store.peekAll('handoff-mode').find(handoffMode => handoffMatches(handoffMode.type, h))?.label ??
  h;

export const isForToday = (oc: OrderCriteria): boolean =>
  oc.timeWantedType !== 'Advance' ||
  oc.timeWanted === undefined ||
  oc.timeWanted.isSame(dayjs(), 'day');

// The individual pieces of OrderCriteria are not `tracked`, so in order to get anything
// to respond to an update we have to completely reassign the object. Using `Object.assign`
// like this both updates the data and triggers an update.
export const updateOrderCriteria = <T, P extends keyof T>(
  ctx: T,
  prop: P,
  data: Partial<OrderCriteria>
): void => {
  ctx[prop] = { ...ctx[prop], ...data, isDefault: false };
};

export function getSelectedHandoffModeModel(
  handoffModeModels: HandoffModeModel[],
  selectedHandoffMode?: HandoffMode
): HandoffModeModel | undefined {
  return handoffModeModels.find(hm => handoffMatches(hm.type, selectedHandoffMode));
}

export function getSelectedTimeWantedMode(
  timeWantedModes: TimeWantedMode[],
  selectedTimeWantedType?: TimeWantedType
): TimeWantedMode | undefined {
  return timeWantedModes.find(t => t.type === selectedTimeWantedType);
}

export const getAddressFromOrderCriteria = (order: OrderCriteria | undefined): string =>
  isAtStore(order)
    ? order.searchAddress
    : isDelivery(order)
    ? savedAddressToLabel(order.deliveryAddress)
    : '';

export function cloneOrderCriteria<T extends OrderCriteria>(criteria: T): T {
  const cloned = Object.entries(criteria).reduce((c, [key, value]) => {
    if (value instanceof SavedAddressModel) {
      // Attempting to clone an Ember Data model results in an infinite loop
      c[key] = value;
    } else {
      c[key] = cloneDeep(value);
    }
    return c;
  }, {} as AnyObject);
  return cloned as T;
}
