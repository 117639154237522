import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ModalSignature } from 'mobile-web/components/modal';
import { getDuration } from 'mobile-web/lib/animation';
import { safeLater, safeNext } from 'mobile-web/lib/runloop';

import style from './index.m.scss';

/** Matches $-animated-modal-transition-duration in the style */
const TRANSITION_DURATION = 500;

type TransitionType = 'fade' | 'slideUp';

interface Args {
  // Required arguments
  open: boolean;
  onClose: ModalSignature['Args']['onClose'];
  title: ModalSignature['Args']['title'];

  // Optional arguments
  buttons?: ModalSignature['Args']['buttons'];
  modalClass?: string;
  transition?: TransitionType;
  bodyDidInsert?: ModalSignature['Args']['bodyDidInsert'];
  onCloseComplete?: Action;
  testSelector?: ModalSignature['Args']['testSelector'];
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: ModalSignature['Blocks'];
}

export default class AnimatedModal extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isOpen = false;
  @tracked renderContent = false;

  // Getters and setters
  get wrapperClass(): string {
    return this.style[this.args.transition ?? 'fade'];
  }

  get modalClass(): string {
    const classes = [this.style.modal, this.isOpen ? this.style.open : this.style.closed];
    if (this.args.modalClass) {
      classes.push(this.args.modalClass);
    }

    return classes.join(' ');
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  beginOpenAnimation() {
    this.renderContent = true;

    // We need the component to render the content once before
    // setting isOpen to true, so that we can get the animation
    // from the close class to the open class.
    safeNext(this, () => {
      this.isOpen = true;
    });
  }

  @action
  beginCloseAnimation() {
    if (!this.args.open) {
      // (∩｀-´)⊃━☆ﾟ.*・｡ﾟ
      // This is needed in order for setting `isOpen` to trigger
      // recalcs on things that observe it. We don't know why.
      safeNext(this, () => {
        this.isOpen = false;

        safeLater(
          this,
          () => {
            this.renderContent = false;
            if (this.args.onCloseComplete) {
              this.args.onCloseComplete();
            }
          },
          getDuration(TRANSITION_DURATION)
        );
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AnimatedModal: typeof AnimatedModal;
  }
}
