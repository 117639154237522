import DS from 'ember-data';

export default class UpsellGroupSerializer extends DS.JSONSerializer.extend(
  DS.EmbeddedRecordsMixin
) {
  attrs = {
    items: { embedded: 'always' },
  };

  normalize(modelClass: DS.Model, resourceHash: any) {
    resourceHash.id ||= 'smart'; // NOTE: If the back-end starts sending more than one group for smart upsells, it needs to start sending an id
    return super.normalize(modelClass, resourceHash);
  }
}
