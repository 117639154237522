export default {
  "root": "_root_1e6v6w",
  "content": "_content_1e6v6w",
  "delete": "_delete_1e6v6w",
  "deleteIcon": "_deleteIcon_1e6v6w",
  "description": "_description_1e6v6w",
  "summary": "_summary_1e6v6w",
  "empty": "_empty_1e6v6w",
  "icon": "_icon_1e6v6w",
  "emptyText": "_emptyText_1e6v6w",
  "emptyButton": "_emptyButton_1e6v6w",
  "heading": "_heading_1e6v6w",
  "modalHeaderDescription": "_modalHeaderDescription_1e6v6w",
  "vendorLink": "_vendorLink_1e6v6w",
  "buttonContainer": "_buttonContainer_1e6v6w",
  "expandButton": "_expandButton_1e6v6w"
};
