export enum CalendarType {
  Business = 'Business',
}

/**
 * Date uses 0-indexed month values. This enum can be used to make Date-related
 * code easier to understand.
 */
export enum Month {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11,
}

export enum Weekday {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export type DailySchedule = {
  weekDay: Weekday;
  description: string;
};

export type Calendar = {
  schedule: DailySchedule[];
  // `string` because some descriptions are dictionary keys
  scheduleDescription: CalendarType | string;
};
