import Component from '@glimmer/component';

import { classes } from 'mobile-web/lib/utilities/classes';
import isSome from 'mobile-web/lib/utilities/is-some';
import { GroupOrderParticipantStatus } from 'mobile-web/services/group-order';

import style from './index.m.scss';

type Size = 700 | 600 | 500 | 400 | 300;
type TextStyle = 'heading' | 'text';

interface Args {
  // Required arguments
  status: EnumOrValue<GroupOrderParticipantStatus>;

  // Optional arguments
  hideIcon?: boolean;
  hideLabel?: boolean;
  count?: number;
  size?: Size;
  textStyle?: TextStyle;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class GroupOrderStatus extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;
  displayCount = '';

  // Tracked properties

  // Getters and setters
  get statusDisplay(): { label: string; icon: string } {
    switch (this.args.status) {
      case GroupOrderParticipantStatus.Joined:
        return { label: 'Joined', icon: '' };
      case GroupOrderParticipantStatus.InProgress:
        return { label: 'In Progress', icon: 'clock' };
      case GroupOrderParticipantStatus.Submitted:
        return { label: 'Submitted', icon: 'check' };
      case GroupOrderParticipantStatus.Removed:
        return { label: 'Left the group', icon: 'door' };
      default:
        return { label: '', icon: '' };
    }
  }

  get iconStyle() {
    return style[this.args.status.toLowerCase()];
  }

  get size(): Size {
    return this.args.size ?? 300;
  }

  get textStyle(): TextStyle {
    return this.args.textStyle ?? 'text';
  }

  get rootClass(): string {
    return classes(style.root, style[`${this.textStyle}-${this.size}`]);
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.displayCount = isSome(this.args.count) ? this.args.count.toString() : '';
  }
  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GroupOrder::Status': typeof GroupOrderStatus;
  }
}
