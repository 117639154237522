import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import { OnPremiseDetailsPayload } from 'mobile-web/models/basket';
import OrderModel from 'mobile-web/models/order';
import TicketModel from 'mobile-web/models/ticket';
import BasketService from 'mobile-web/services/basket';
import { SplitCheckMethod } from 'mobile-web/services/split-check';

import style from './index.m.scss';

interface Args {
  // Required arguments
  ticket: TicketModel;
  details: OnPremiseDetailsPayload;

  // Optional arguments
  order?: OrderModel;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class SplitCheckContribution extends Component<Signature> {
  // Service injections
  @service store!: DS.Store;
  @service basket!: BasketService;
  @service router!: RouterService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get splitByMethod(): SplitCheckMethod {
    if (this.args.details && this.args.details.splitItems?.length) {
      return SplitCheckMethod.SplitByItem;
    } else if (
      this.args.details &&
      this.args.details.payFor &&
      this.args.details.splitBetween &&
      this.args.details.splitBetween > this.args.details.payFor
    ) {
      return SplitCheckMethod.SplitEqually;
    }
    return SplitCheckMethod.SplitByAmount;
  }

  get splitItems() {
    const splitItemIds = this.args.details?.splitItems ?? [];
    const ticketItems = this.args.ticket.items;

    if (splitItemIds && ticketItems) {
      return ticketItems.filter(i => splitItemIds.includes(i.id));
    }
    return [];
  }

  get total(): number {
    if (this.args.order) {
      return this.args.order.subTotal + this.args.order.vendorTax;
    }
    // if there is no Order but there are split details to show, there must be a basket
    const basket = this.basket.basket!;
    return basket.subTotal + basket.salesTax;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SplitCheck::Contribution': typeof SplitCheckContribution;
  }
}
