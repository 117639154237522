import OrderModel from 'mobile-web/models/order';

type ReorderSourceType = 'OrderFave' | 'Order';
type ReorderPostBody = { sourceId: EmberDataId; sourceType: ReorderSourceType };
type HandoffMessageParts = { prefix: string; dateTime: string } | undefined;

const handoffMessageParts = (order: OrderModel, dateTime: string): HandoffMessageParts => {
  const handoff = order.handoffDescription.toLocaleLowerCase();

  const tenseVerb = order.isPastOrder ? 'was' : 'is';
  if (order.isDelivery) {
    return order.isAdvance
      ? { prefix: `Your ${handoff} order ${tenseVerb} scheduled to arrive`, dateTime }
      : { prefix: `Your ${handoff} order ${tenseVerb} estimated to arrive`, dateTime };
  }

  return {
    // TODO: create a follow-up ticket to adjust from "at" to "on" when a date is included in the dateTime (OLO-26156)
    prefix: `Your order ${order.isPastOrder ? 'was' : 'will be'} ready for ${handoff} at`,
    dateTime,
  };
};

export { HandoffMessageParts, ReorderPostBody, ReorderSourceType, handoffMessageParts };
