import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { guids } from 'mobile-web/lib/utilities/guids';
import FavoriteModel from 'mobile-web/models/favorite';
import FavoriteProductModel from 'mobile-web/models/favorite-product';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import OnPremiseService from 'mobile-web/services/on-premise';
import ReorderService from 'mobile-web/services/reorder';

import style from './index.m.scss';

interface Args {
  // Required arguments
  favorites: DS.AdapterPopulatedRecordArray<FavoriteModel>;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class FavoritesRoute extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service bus!: BusService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service reorder!: ReorderService;
  @service router!: RouterService;

  // Untracked properties
  ids = guids(this, 'cardHeading');
  style = style;

  // Tracked properties
  @tracked currentFavorite?: FavoriteModel;

  // Getters and setters
  get favoriteProducts(): FavoriteProductModel[] {
    return this.currentFavorite?.favoriteProducts.toArray() ?? [];
  }

  // Lifecycle methods

  // Other methods

  // Tasks
  deleteFavoriteTask = taskFor(this.deleteFavoriteTaskInstance);
  @task *deleteFavoriteTaskInstance(favorite: FavoriteModel): TaskGenerator<void> {
    yield favorite.destroyRecord();
  }

  // Actions and helpers
  @action
  favoriteAction(favorite: FavoriteModel) {
    if (favorite.isDisabled) {
      this.router.transitionTo('menu.vendor', favorite.vendorSlug);
    } else {
      this.reorder.reorder(favorite);
    }
  }

  @action
  deleteFavorite(favorite: FavoriteModel) {
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.favorites.delete.confirmTitle'),
      content: this.intl.t('mwc.favorites.delete.confirmText'),
      buttonLabel: this.intl.t('mwc.favorites.delete.confirmButton'),
      onConfirm: () => this.deleteFavoriteTask.perform(favorite),
      testSelector: 'confirmDelete',
      buttonTestSelector: 'confirmButton',
    });
  }

  @action
  startOrder() {
    this.router.transitionTo('index');
  }

  @action
  viewFavorite(favorite: FavoriteModel) {
    this.currentFavorite = favorite;
  }

  @action
  closeFavorite() {
    this.currentFavorite = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::FavoritesRoute': typeof FavoritesRoute;
  }
}
