import Service, { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

export default class FavoriteService extends Service {
  // Service injections
  @service store!: DS.Store;

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  saveFavorite(orderId: EmberDataId, name: string): RSVP.Promise<unknown> {
    return this.store.adapterFor('favorite').saveFavoriteOrder(orderId, name);
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    favorite: FavoriteService;
  }
}
