/* eslint-disable no-use-before-define */
import { inject as service } from '@ember/service';
import { isEmpty, isNone } from '@ember/utils';
import DS from 'ember-data';

import pick from 'lodash.pick';

import { CustomIcon } from 'mobile-web/lib/custom-icon';
import dayjs from 'mobile-web/lib/dayjs';
import { HandoffMode, isAdvance } from 'mobile-web/lib/order-criteria';
import { DayOfWeek } from 'mobile-web/lib/time/calendar';
import Category, { GlobalCategory } from 'mobile-web/models/category';
import OptionGroup from 'mobile-web/models/option-group';
import OptionGroupModel from 'mobile-web/models/option-group';
import Vendor, { LabelCode } from 'mobile-web/models/vendor';
import ChannelService from 'mobile-web/services/channel';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import StorageService from 'mobile-web/services/storage';

export type MIMImage = {
  groupName: MIMGroup;
  filename: string;
};

export enum MIMGroup {
  Menu = 'mobile-webapp-menu',
  Customize = 'mobile-webapp-customize',
  ResponsiveLarge = 'responsive-large',
}

export type Label = {
  code: LabelCode;
  name: string;
  icon: CustomIcon;
};

type WeekTime = {
  dayOfWeek: DayOfWeek;
  hour: number;
  minute: number;
};

type WeekTimeRange = {
  start: WeekTime;
  end: WeekTime;
  spansAllDay?: boolean;
  spansMultipleDays?: boolean;
};

export type ProductAvailability = {
  startDate?: string;
  endDate?: string;
  weekTimes: WeekTimeRange[];
  isUnavailabilityRange: boolean;
};

export type GlobalProduct = Pick<
  ProductModel,
  | 'id'
  | 'name'
  | 'description'
  | 'shortDescription'
  | 'baseCost'
  | 'baseCostOverrideLabel'
  | 'calorieLabel'
  | 'maximumQuantity'
  | 'minimumQuantity'
  | 'maximumTotalQuantity'
  | 'minimumTotalQuantity'
  | 'isFeatured'
  | 'images'
  | 'labels'
  | 'availability'
  | 'isDisabled'
> & {
  category?: GlobalCategory;
  hasImages?: boolean;
  hasPrice?: boolean;
};

export default class ProductModel extends DS.Model {
  @service orderCriteria!: OrderCriteriaService;
  @service store!: DS.Store;
  @service channel!: ChannelService;
  @service storage!: StorageService;
  @service onPremise!: OnPremiseService;

  @DS.attr('string')
  name!: string;
  @DS.attr('string')
  description!: string;
  @DS.attr('string')
  shortDescription!: string;
  @DS.attr('number')
  baseCost!: number;
  @DS.attr('string')
  baseCostOverrideLabel!: string;
  @DS.attr('string')
  calorieLabel!: string;
  /**
   * The maximum quantity of this product that may be added to the basket per
   * basket product.
   *
   * I.E. if the maximumQuantity is 10, guests may only add 10 of this product
   * per line item, but they may add more than 10 of this item to the basket,
   * such as by adding one line item with 10 and another with 5.
   */
  @DS.attr('number')
  maximumQuantity?: number;
  /**
   * The minimum quantity of this product that may be added to the basket per
   * basket product.
   */
  @DS.attr('number')
  minimumQuantity!: number;
  @DS.attr('number')
  quantityIncrement!: number;
  /**
   * The maximum quantity of this product that may be added to the basket.
   *
   * I.E. if the maximumTotalQuantity is 10, guests may add one line item with
   * 10, or 5 line items with 2, but they may not add more than 10 of this
   * product among all basket products.
   */
  @DS.attr('number')
  maximumTotalQuantity?: number;
  /**
   * The minimum quantity of this product that may be added to the basket.
   */
  @DS.attr('number')
  minimumTotalQuantity?: number;
  @DS.attr('boolean')
  isFeatured!: boolean;
  @DS.attr('array')
  images!: MIMImage[];
  @DS.attr('array')
  labels!: Label[];
  @DS.attr('array')
  unavailableHandoffModes!: HandoffMode[];
  @DS.attr('string')
  availabilityDescription?: string;
  @DS.attr('object')
  availability?: ProductAvailability;
  @DS.attr('boolean')
  isDisabled!: boolean;
  @DS.attr('string')
  brandName?: string;

  // ensureVendorLoaded is called in application route model hook, so `!` is safe here
  @DS.belongsTo('vendor', { async: false })
  vendor!: Vendor;
  @DS.belongsTo('category', { async: false, inverse: 'categoryProducts' })
  category?: Category;
  // separate relationship is necessary so that products can belong to the recent items category as well as another menu category
  @DS.belongsTo('category', { async: false, inverse: 'recentProducts' })
  recentItemCategory?: Category;
  @DS.attr('number')
  recentItemSortOrder?: number;

  @DS.hasMany('option-group')
  optionGroups!: DS.PromiseManyArray<OptionGroup>;

  get totalQuantityBasedOnChoiceQuantities(): boolean {
    return this.firstLevelOptionGroupModels.any(og => og.treatAsProductQuantity);
  }

  get firstLevelOptionGroupModels(): OptionGroup[] {
    return this.optionGroups.filter(
      optionGroup => isNone(optionGroup.parentChoice) && optionGroup.isAvailable
    );
  }

  get hasChoices(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.hasMany('optionGroups' as any).ids().length > 0;
  }

  /**
   * Indicates whether this product supports quick-adding functionality, and
   * should show the quick-add button on product cards.
   */
  get quickAddSupported(): boolean {
    return !this.hasChoices && this.minimumQuantity < 2 && !this.isDisabled;
  }

  get isSingleUse(): boolean {
    return this.category?.isSingleUse ?? false;
  }

  get isRecentItem(): boolean {
    return this.recentItemCategory?.isRecentItem ?? false;
  }

  get availableHandoffModes(): HandoffMode[] {
    return this.vendor.settings.supportedHandoffModes.filter(
      handoff => !this.unavailableHandoffModes.includes(handoff)
    );
  }

  get isVisible(): boolean {
    /*
    If product is ONLY available for DineIn,
      and user cannot get into DineIn because hidden from dropdown (QR code only),
      and current handoff mode is not DineIn
    Then don't show it.
    */
    if (
      this.channel.settings?.handoffModesHiddenFromSelection.includes('DineIn') &&
      this.availableHandoffModes.length === 1 &&
      this.availableHandoffModes[0] === 'DineIn' &&
      this.orderCriteria.criteria.handoffMode !== 'DineIn'
    ) {
      return false;
    }

    /* if Available/Full toggle is set to Full, OR toggle is hidden */
    if (this.storage.showFullMenu) {
      return true;
    }

    return this.isAvailable;
  }

  get isAvailable(): boolean {
    const userOffset = -new Date().getTimezoneOffset();
    const vendorOffset = this.vendor.utcOffset * 60;
    const offsetDifference = userOffset - vendorOffset; // how far ahead of the vendor the user is
    let timeWanted = dayjs();

    if (this.orderCriteria.criteria) {
      const criteria = this.orderCriteria.criteria;

      /* product never available for selected handoff mode */
      if (
        !isEmpty(this.unavailableHandoffModes) &&
        criteria.handoffMode !== 'Unspecified' &&
        this.unavailableHandoffModes.includes(criteria.handoffMode)
      ) {
        return false;
      }

      if (isAdvance(criteria) && criteria.timeWanted) {
        timeWanted = criteria.timeWanted;
      }
    }

    /* product is available at any time*/
    if (isNone(this.availability)) {
      return true;
    }

    const { startDate, endDate, weekTimes, isUnavailabilityRange } = this.availability;
    let startDateParsed = dayjs(startDate);
    let endDayParsed = dayjs(endDate);

    // The date returned by the API is coming without Timezone and without time ex.`2023-08-29T00:00:00`
    // the issue here is when both dates are the same we were subtracting the time causing this a wrong
    // date like `2023-08-28T20:00:00` when the offsetdifference is not 0
    if (!startDateParsed.isSame(endDayParsed)) {
      startDateParsed = startDateParsed.add(offsetDifference, 'm');
      endDayParsed = endDayParsed.add(offsetDifference, 'm');
    }

    // If no hour, minute, or second data is passed (the Menu API should never send this data)
    // then assume the endDate should be considered inclusive of today and change it to have the
    // same hours, minutes and seconds (plus one) so we can compare to today.
    if (endDayParsed.hour() === 0 && endDayParsed.minute() === 0 && endDayParsed.second() === 0) {
      endDayParsed = endDayParsed
        .hour(timeWanted.hour())
        .minute(timeWanted.minute())
        .second(timeWanted.second() + 1);
    }

    if (isUnavailabilityRange) {
      // if the availability rule is "unavailable" and the time wanted is OUTSIDE the
      // UNavailability date range, then the product is available and we return true
      if (
        (startDate && timeWanted.isBefore(startDateParsed)) ||
        (endDate && timeWanted.isAfter(endDayParsed))
      ) {
        return true;
      }
    } else {
      if (
        (startDate && timeWanted.isBefore(startDateParsed)) ||
        (endDate && timeWanted.isAfter(endDayParsed))
      ) {
        return false;
      }
    }

    return weekTimes.any(w => {
      /**
       * Consider offsetDifference when searching for the necessary week,
       * so we do not fall into previous/next week if time wanted is close
       * to the week boundaries.
       */
      const timeWantedVendorTz = timeWanted.subtract(offsetDifference, 'm');
      let start = timeWantedVendorTz;
      /**
       * Setting .day as we do below does not reliably go forward or backward,
       * but adjusts to that day within the same calendar week. Since we want
       * to find the start that is before the timeWanted, we need to
       * go back a week if the start day is later than the wanted day.
       */
      if (timeWantedVendorTz.day() < w.start.dayOfWeek) {
        start = start.add(-1, 'week');
      }
      let end = start;

      start = start
        .day(w.start.dayOfWeek)
        .hour(w.start.hour)
        .minute(w.start.minute)
        .add(offsetDifference, 'm');

      /**
       * Similarly, we want to find the first end *after* the start,
       * so if the end day is before the start day, we add a week.
       */
      if (w.end.dayOfWeek < w.start.dayOfWeek) {
        end = end.add(1, 'week');
      }
      end = end
        .day(w.end.dayOfWeek)
        .hour(w.end.hour)
        .minute(w.end.minute)
        .add(offsetDifference, 'm');

      // The '[]' means that the isBetween comparison is inclusive of start and end.
      // if isUnavailable = true AND timeWanted.isBetween(...), then return false
      // if isUnavaailable = false, then use the existing logic here
      if (isUnavailabilityRange) {
        return !timeWanted.isBetween(start, end, undefined, '[]');
      }
      return timeWanted.isBetween(start, end, undefined, '[]');
    });
  }

  get menuImage(): string {
    return this.getImage(MIMGroup.Menu);
  }

  get defaultQuantity(): number {
    return this.totalQuantityBasedOnChoiceQuantities ? 0 : this.minimumQuantity;
  }

  get primaryProductQuantityOptionGroup(): OptionGroupModel | undefined {
    return this.firstLevelOptionGroupModels.find(og => og.treatAsProductQuantity);
  }

  getImage(group: MIMGroup): string {
    return this.images.find(i => i.groupName === group)?.filename ?? '';
  }

  serializeForGlobalData(): GlobalProduct {
    let category: GlobalCategory | undefined = undefined;
    // Wrapping this in a try/catch because there are instances where Ember
    // will throw a fit (during some tests) that the category hasn't been loaded
    // and it wants it to be `async: true`.
    try {
      category = this.category?.serializeForGlobalData();
    } catch {}
    return {
      ...pick(
        this,
        'id',
        'name',
        'description',
        'shortDescription',
        'baseCost',
        'baseCostOverrideLabel',
        'calorieLabel',
        'maximumQuantity',
        'minimumQuantity',
        'maximumTotalQuantity',
        'minimumTotalQuantity',
        'isFeatured',
        'images',
        'labels',
        'availability',
        'isDisabled'
      ),
      category,
    };
  }
}
