import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default class ContactInfo extends Route {
  model(params: { redirectUrl: string }) {
    return RSVP.hash({
      redirectUrl: params.redirectUrl,
    });
  }
}
