import Service from '@ember/service';
import { dasherize } from '@ember/string';

export default class IDService extends Service {
  // Service injections

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  /**
   * Converts an array of text values into an array of dasherized text for use
   * as user-friendly unique IDs.
   *
   * If multiple values produce the same ID, subsequent IDs will be suffixed
   * with integers starting at `2`. I.E. `['Foo', 'Foo']` will result in
   * `['foo', 'foo-2']`.
   *
   * @param values the array of values to convert
   * @returns the dasherized and suffixed list of IDs.
   */
  getFriendlyIDs(values: string[]): string[] {
    const valueOccurrences = new Map<string, number>();

    return values.map(value => {
      const dasherizedValue = dasherize(value.trim());

      const previousOccurrences = valueOccurrences.get(dasherizedValue) ?? 0;
      const occurrences = previousOccurrences + 1;
      valueOccurrences.set(dasherizedValue, occurrences);

      return previousOccurrences ? `${dasherizedValue}-${occurrences}` : dasherizedValue;
    });
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    id: IDService;
  }
}
