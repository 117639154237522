import DS from 'ember-data';

import BasketProduct from 'mobile-web/models/basket-product';

export default class FavoriteChoiceModel extends DS.Model {
  @DS.attr('string')
  name!: string;
  @DS.attr('number', { defaultValue: 0 })
  quantity!: number;

  // Mirrored relationship from favorite-product model
  @DS.belongsTo('favorite-product', { async: false })
  favoriteProduct!: BasketProduct;
}
