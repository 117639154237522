import DS from 'ember-data';

import { Provider } from 'mobile-web/lib/payment';
import BaseJSONSerializer from 'mobile-web/serializers/base-json-serializer';

export default class BillingSchemeSerializer extends BaseJSONSerializer {
  attrs = {
    memberships: { embedded: 'always' },
  };
  modelName = 'billing-scheme';

  normalize(
    modelClass: DS.Model,
    resourceHash: { memberships?: unknown[]; provider?: Provider }
  ): UnknownObject {
    if (resourceHash.provider === Provider.PassThrough) {
      resourceHash.memberships = [];
    }
    return super.normalize(modelClass, resourceHash);
  }
}
