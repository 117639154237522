export default {
  "root": "_root_1ezfn5",
  "dispatchInfo": "_dispatchInfo_1ezfn5",
  "description": "_description_1ezfn5",
  "estimate": "_estimate_1ezfn5",
  "estimateText": "_estimateText_1ezfn5",
  "icon": "_icon_1ezfn5",
  "iconLabel": "_iconLabel_1ezfn5",
  "updates": "_updates_1ezfn5",
  "updatesInactive": "_updatesInactive_1ezfn5 _updates_1ezfn5",
  "updatesActive": "_updatesActive_1ezfn5 _updates_1ezfn5",
  "pulse": "_pulse_1ezfn5",
  "updatesActiveNoLocation": "_updatesActiveNoLocation_1ezfn5 _updates_1ezfn5"
};
