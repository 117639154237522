import Component from '@glimmer/component';

import { License } from 'mobile-web/lib/license';

import style from './index.m.scss';

interface Args {
  // Required arguments
  licenses: License[];

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class OpenSourceLicensesRoute extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::OpenSourceLicensesRoute': typeof OpenSourceLicensesRoute;
  }
}
