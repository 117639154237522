import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  isProduct: boolean;
  isViewAll: boolean;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class ApplicationLoadingMenu extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get eachArray(): number[] {
    return this.args.isViewAll ? [1] : [1, 2];
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApplicationLoading::Menu': typeof ApplicationLoadingMenu;
  }
}
