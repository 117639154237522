import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import { isArray } from 'mobile-web/lib/utilities/_';
import isSome from 'mobile-web/lib/utilities/is-some';
import { roundUpToNext, roundDownToPrevious } from 'mobile-web/lib/utilities/math';
import FeaturesService from 'mobile-web/services/features';

export default class OptionGroupSerializer extends DS.RESTSerializer {
  @service features!: FeaturesService;

  normalize(
    modelClass: DS.Model,
    resourceHash: {
      id?: number;
      product?: EmberDataId;
      choiceQuantityIncrement?: number;
      choices?: unknown[];
      configuredMaxAggregateQuantity?: number;
      configuredMaxSelect?: number;
      configuredMinAggregateQuantity?: number;
      configuredMinSelect?: number;
      forceSingleSelect?: boolean;
      hasChoiceQuantities?: boolean;
      helpText?: string;
      isAvailable?: boolean;
      isMandatory?: boolean;
      maxAggregateQuantity?: number;
      maxChoiceQuantity?: number;
      maxSelects?: number;
      minAggregateQuantity?: number;
      minChoiceQuantity?: number;
      minSelects?: number;
      treatAsProductQuantity?: boolean;
    },
    prop?: string
  ): UnknownObject {
    // Quality of life renames here. Renaming properties to more
    // correctly reflect the purpose of the property.

    if (isSome(resourceHash.isMandatory)) {
      resourceHash.forceSingleSelect = resourceHash.isMandatory;
      delete resourceHash.isMandatory;
    }
    if (isSome(resourceHash.minSelects)) {
      resourceHash.configuredMinSelect = resourceHash.minSelects;
      delete resourceHash.minSelects;
    }
    if (isSome(resourceHash.maxSelects)) {
      resourceHash.configuredMaxSelect = resourceHash.maxSelects;
      delete resourceHash.maxSelects;
    }

    resourceHash.isAvailable = isArray(resourceHash.choices) && resourceHash.choices.length > 0;

    // Quantity Mucking
    resourceHash.treatAsProductQuantity =
      resourceHash.hasChoiceQuantities === true && isNone(resourceHash.minAggregateQuantity);

    if (isNone(resourceHash.choiceQuantityIncrement) || resourceHash.choiceQuantityIncrement < 1) {
      resourceHash.choiceQuantityIncrement = 1;
    }
    if (isNone(resourceHash.minChoiceQuantity)) {
      resourceHash.minChoiceQuantity = 0;
    }
    if (isNone(resourceHash.maxChoiceQuantity)) {
      resourceHash.maxChoiceQuantity = 0;
    }
    if (isNone(resourceHash.minAggregateQuantity)) {
      resourceHash.minAggregateQuantity = 0;
    }
    if (isNone(resourceHash.maxAggregateQuantity)) {
      resourceHash.maxAggregateQuantity = 0;
    }

    // Align to choiceQuantityIncrement
    if (resourceHash.choiceQuantityIncrement > 1) {
      const step = resourceHash.choiceQuantityIncrement;
      resourceHash.minChoiceQuantity = roundUpToNext(step, resourceHash.minChoiceQuantity);
      resourceHash.maxChoiceQuantity = roundDownToPrevious(step, resourceHash.maxChoiceQuantity);
      resourceHash.minAggregateQuantity = roundUpToNext(step, resourceHash.minAggregateQuantity);
      resourceHash.maxAggregateQuantity = roundDownToPrevious(
        step,
        resourceHash.maxAggregateQuantity
      );
    }

    resourceHash.configuredMinAggregateQuantity = resourceHash.minAggregateQuantity;
    delete resourceHash.minAggregateQuantity;
    resourceHash.configuredMaxAggregateQuantity = resourceHash.maxAggregateQuantity;
    delete resourceHash.maxAggregateQuantity;

    if (isSome(resourceHash.helpText)) {
      delete resourceHash.helpText;
    }

    return super.normalize(modelClass, resourceHash, prop);
  }

  normalizeFindManyResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: {
      optionGroups: Array<{ id: EmberDataId }>;
    },
    id: string | number,
    requestType: string
  ): UnknownObject {
    const adapter = this.store.adapterFor('option-group');
    const findManyIds = adapter.findManyIds;
    if (findManyIds) {
      if (!payload.optionGroups) {
        payload.optionGroups = [];
      }
      payload.optionGroups.forEach(og => {
        findManyIds.removeObject(og.id.toString());
      });
      findManyIds.forEach(ogId => {
        payload.optionGroups.push({ id: ogId });
      });
      delete adapter.findManyIds;
    }
    return super.normalizeFindManyResponse(store, primaryModelClass, payload, id, requestType);
  }
}
