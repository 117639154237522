export default {
  "root": "_root_1khqtc",
  "buttonContainer": "_buttonContainer_1khqtc",
  "addToCartButton": "_addToCartButton_1khqtc",
  "modal": "_modal_1khqtc",
  "quantityLabel": "_quantityLabel_1khqtc",
  "quantityContainer": "_quantityContainer_1khqtc",
  "quantityText": "_quantityText_1khqtc",
  "spinnerIcon": "_spinnerIcon_1khqtc"
};
