import TransitionContext from 'ember-animated/-private/transition-context';

import { keyframes } from 'mobile-web/motion/keyframes';

export function* up({
  insertedSprites,
  removedSprites,
}: TransitionContext): Generator<unknown, void, unknown> {
  for (const sprite of removedSprites) {
    keyframes(sprite, {
      keyframes: {
        opacity: 0,
        transform: 'translateY(-100%)',
      },
    });
  }

  for (const sprite of insertedSprites) {
    keyframes(sprite, {
      keyframes: {
        offset: 0,
        opacity: 0,
        transform: 'translateY(100%)',
      },
    });
  }
}

export function* down({
  insertedSprites,
  removedSprites,
}: TransitionContext): Generator<unknown, void, unknown> {
  for (const sprite of removedSprites) {
    keyframes(sprite, {
      keyframes: {
        opacity: 0,
        transform: 'translateY(100%)',
      },
    });
  }

  for (const sprite of insertedSprites) {
    keyframes(sprite, {
      keyframes: {
        offset: 0,
        opacity: 0,
        transform: 'translateY(-100%)',
      },
    });
  }
}
