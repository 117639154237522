// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { firstResolvedPromise } from 'mobile-web/lib/promises';
import { ChannelSettings } from 'mobile-web/models/bootstrap-data';
import CmsContentService from 'mobile-web/services/cms-content';
import ImageService from 'mobile-web/services/image';

const { Model } = DS;

export default class BrandModel extends Model {
  @service image!: ImageService;
  @service cmsContent!: CmsContentService;

  @tracked brandLogoUri: string | undefined;

  @DS.attr('string')
  brandName!: string;
  @DS.attr('string')
  brandCdnUrl!: string;
  @DS.attr('string')
  brandInternalName!: string;
  @DS.attr('boolean')
  isActive!: boolean;
  @DS.attr('number')
  sortOrder!: number;
  @DS.attr('boolean')
  isCompany!: boolean;

  @alias('current.settings')
  settings?: ChannelSettings;

  get logo(): string | undefined {
    if (this.brandLogoUri === undefined) {
      this.loadLogo().then(logoUri => {
        if (logoUri !== undefined) {
          this.brandLogoUri = logoUri;
        }
      });
    }
    return this.brandLogoUri;
  }

  loadLogo(): RSVP.Promise<string | undefined> {
    const largeLogos = this.getLogoUris();
    return RSVP.Promise.resolve(
      firstResolvedPromise(largeLogos.map(logo => this.image.load(logo).then(() => logo))).catch(
        () => undefined
      )
    );
  }

  getLogoUris(): string[] {
    const cmsImage = this.cmsContent.getContent('logoImage')?.imageUriTablet;
    if (cmsImage) {
      return [cmsImage];
    }

    return ['/logo/logo-wide.svg', '/logo/logo-wide.png'].map(image => this.buildImageUrl(image));
  }

  buildImageUrl(path: string) {
    const cacheVersion = this.settings?.cacheVersion;
    const cacheQueryString = isNone(cacheVersion) ? '' : `?v=${cacheVersion}`;
    const imageUri = this.isCompany
      ? `${this.brandCdnUrl}${path}${cacheQueryString}`
      : `${this.brandCdnUrl}/img/${this.brandInternalName}${path}${cacheQueryString}`;
    return imageUri;
  }
}
