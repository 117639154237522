export default {
  "modal": "_modal_13k0h3",
  "modalHeader": "_modalHeader_13k0h3",
  "modalHeaderIcon": "_modalHeaderIcon_13k0h3",
  "modalBody": "_modalBody_13k0h3",
  "descriptionText": "_descriptionText_13k0h3",
  "shareButtonContainer": "_shareButtonContainer_13k0h3",
  "shareLinkButton": "_shareLinkButton_13k0h3",
  "shareLinkButtonContent": "_shareLinkButtonContent_13k0h3",
  "shareLinkButtonIcon": "_shareLinkButtonIcon_13k0h3",
  "shareableUrlText": "_shareableUrlText_13k0h3",
  "copyLinkContainer": "_copyLinkContainer_13k0h3",
  "copyLinkButton": "_copyLinkButton_13k0h3",
  "copyLinkButtonIcon": "_copyLinkButtonIcon_13k0h3",
  "copyLinkButtonContent": "_copyLinkButtonContent_13k0h3"
};
