import ContentPlaceholdersBase from '../base';

interface Args {
  img?: boolean;
}

export default class ContentPlaceholdersHeading extends ContentPlaceholdersBase<Args> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'content-placeholders/heading': typeof ContentPlaceholdersHeading;
  }
}
