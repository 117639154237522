import { isEmpty } from '@ember/utils';

import isSome from './is-some';

export enum Status {
  Continue = 100,
  SwitchingProtocols = 101,
  Ok = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultipleChoices = 300,
  MovedPermanently = 301,
  Found = 302,
  SeeOther = 303,
  NotModified = 304,
  UseProxy = 305,
  TemporaryRedirect = 307,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  RequestEntityTooLarge = 413,
  RequestUriTooLong = 414,
  UnsupportedMediaType = 415,
  RequestedRangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  UnprocessableEntity = 422,
  TooManyRequests = 429,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505,
}

export const isStatus = (status: Status, toCheck: number | string | Status): boolean =>
  toCheck.toString() === status.toString();

export const ensureProtocol = (url: string): string => {
  const protocol = /^(https?:)?\/\//;
  return protocol.test(url) ? url : `https://${url}`;
};

export const addClass = (selector: string, className: string): void => {
  const element = document.querySelector(selector);
  if (isSome(element) && !isEmpty(className)) {
    element.classList.add(...className.split(' '));
  }
};

export const displayUrl = (url: string): string => {
  try {
    return new URL(url).host.replace('www.', '');
  } catch {
    return '';
  }
};
