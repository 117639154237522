import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { UserOrderEvent } from 'mobile-web/components/post-checkout';
import OrderModel from 'mobile-web/models/order';
import AnalyticsService from 'mobile-web/services/analytics';
import FavoriteService from 'mobile-web/services/favorite';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  order: OrderModel;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class OrderSummaryRoute extends Component<Signature> {
  // Service injections
  @service favorite!: FavoriteService;
  @service router!: RouterService;
  @service userFeedback!: UserFeedback;
  @service vendor!: VendorService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onUserOrderEvent(id: string, hash: string, event: UserOrderEvent) {
    this.router.transitionTo(event, id, { queryParams: { hash } });
  }

  @action
  onCancelOrder() {
    this.userFeedback.add({
      type: Type.Neutral,
      title: 'Cancellation Complete',
      message: 'Your order has been successfully cancelled.',
    });
    this.router.transitionTo('application');
  }

  @action
  saveFavorite(orderId: EmberDataId, name: string) {
    return this.favorite.saveFavorite(orderId, name).then(() => {
      this.args.order.set('isFavorite', true);
      this.userFeedback.add({
        message: `Your order has been saved as ${name}!`,
        title: 'Success!',
        type: Type.Positive,
      });
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::OrderSummaryRoute': typeof OrderSummaryRoute;
  }
}
