import DS from 'ember-data';

import QualifyingLoyaltyRewardModel from 'mobile-web/models/qualifying-loyalty-reward';

export default class LoyaltyAccountModel extends DS.Model {
  @DS.attr('string')
  schemeName!: string;
  @DS.attr('string')
  schemeProviderName!: string;
  @DS.attr('number')
  membershipId?: number;
  @DS.attr()
  vendorId?: EmberDataId;

  @DS.attr('boolean')
  showManageRewardsLink!: boolean;
  @DS.attr('string')
  manageRewardsUrl!: string;
  @DS.attr('string')
  manageRewardsText!: string;

  @DS.attr('boolean')
  hasPoints!: boolean;
  @DS.attr('string')
  balance!: string;

  @DS.hasMany('qualifying-loyalty-reward', { async: false })
  qualifyingLoyaltyRewards!: DS.ManyArray<QualifyingLoyaltyRewardModel>;
}
