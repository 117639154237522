import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import FeaturesService from 'mobile-web/services/features';

export interface Signature {
  Args: {
    Positional: [string];
  };
  Return: boolean;
}

export default class HasFeature extends Helper<Signature> {
  @service features!: FeaturesService;

  compute(params?: Signature['Args']['Positional']) {
    return this.features.flags[params?.[0] ?? ''];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'has-feature': typeof HasFeature;
  }
}
