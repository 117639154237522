import dayjs, { Dayjs } from 'mobile-web/lib/dayjs';
import { TODAY, TOMORROW } from 'mobile-web/lib/strings';

export const TIME_FORMAT = 'h:mm A';

export type DayOptions = {
  /** The string to display for today. Defaults to 'Today'. */
  todayLabel?: string;
  /** The string to display for tomorrow. Defaults to 'Tomorrow'. */
  tomorrowLabel?: string;
  /** The format for days other than today and tomorrow. Defaults to 'L'. */
  format?: string;
};

/**
 * Format a Dayjs as a day
 * @param value The Dayjs to format
 * @param options Additional options
 */
export function day(value: Dayjs, options: DayOptions = {}): string {
  if (dayjs().dayOfYear() === value.dayOfYear()) {
    return options.todayLabel || TODAY;
  } else if (dayjs().add(1, 'day').dayOfYear() === value.dayOfYear()) {
    return options.tomorrowLabel || TOMORROW;
  }
  return value.format(options.format || 'L');
}

export default {
  TIME_FORMAT,
  day,
};
