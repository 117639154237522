import DS from 'ember-data';

import LoyaltyScheme from 'mobile-web/models/loyalty-scheme';

export default class LoyaltyMembershipModel extends DS.Model {
  @DS.attr('number')
  membershipId!: number;
  @DS.attr('string')
  schemeName!: string;
  @DS.attr('string')
  schemeProviderName!: string;
  @DS.attr('string')
  description!: string;

  // Never comes back from the server. We only ever use this when creating a new instance
  // so that the serializer can have the data it needs.
  @DS.belongsTo('loyalty-scheme', { async: false })
  scheme?: LoyaltyScheme;
}
