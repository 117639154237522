import { set, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { UserOrderEvent } from 'mobile-web/components/post-checkout';
import { SaveFavorite } from 'mobile-web/components/save-favorite-modal';
import OrderController from 'mobile-web/controllers/order-controller';
import ThankYouRoute from 'mobile-web/routes/thank-you';
import AnalyticsService from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import FavoriteService from 'mobile-web/services/favorite';
import OnPremiseService from 'mobile-web/services/on-premise';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';

// Necessary because actions need to be merged. Will remove when we add in the
// relevant decorators which merge prototypal properties.
export default class ThankYouController extends OrderController {
  declare model: ModelForRoute<ThankYouRoute>;

  @action
  onUserOrderEvent(id: EmberDataId, hash: unknown, event: UserOrderEvent) {
    this.router.transitionTo(event, id, { queryParams: { hash } });
  }

  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;
  @service favorite!: FavoriteService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service userFeedback!: UserFeedback;

  @alias('channel.settings.showSocialIntegration')
  showSocialIntegration?: boolean;

  saveFavorite: SaveFavorite = (orderId, name) =>
    this.favorite.saveFavorite(orderId, name).then(() => {
      set(this.model, 'isFavorite', true);
      this.userFeedback.add({
        message: `Your order has been saved as ${name}!`,
        title: 'Success!',
        type: Type.Positive,
      });
    });

  queryParams = [{ surveyCompleted: 'surveycompleted' }];

  @tracked surveyCompleted?: string;
}
