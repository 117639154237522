import { isNone } from '@ember/utils';
import DS from 'ember-data';

export default class ObjectTransform extends DS.Transform {
  deserialize(serialized: unknown) {
    return isNone(serialized) ? {} : serialized;
  }

  serialize(deserialized: unknown) {
    return isNone(deserialized) ? {} : deserialized;
  }
}
