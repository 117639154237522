import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import UserFeedback, { Type } from 'mobile-web/services/user-feedback';
import Vendor from 'mobile-web/services/vendor';

export default class OrderController extends Controller {
  @service router!: RouterService;
  @service userFeedback!: UserFeedback;
  @service vendor!: Vendor;

  @action
  onCancelOrder(this: OrderController) {
    this.userFeedback.add({
      type: Type.Neutral,
      title: 'Cancellation Complete',
      message: 'Your order has been successfully cancelled.',
    });
    this.router.transitionTo('application');
  }
}
