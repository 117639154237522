import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { orderCriteriaProperties } from 'mobile-web/lib/analytics';
import {
  getSelectedHandoffModeModel,
  getSelectedTimeWantedMode,
} from 'mobile-web/lib/order-criteria';
import { isAdvance, isAtStore, isDelivery, OrderCriteria } from 'mobile-web/lib/order-criteria';
import { compact } from 'mobile-web/lib/utilities/_';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import MwcIntl from 'mobile-web/services/mwc-intl';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onEdit: Action<[]>;

  // Optional arguments
  searchOnly?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class OrderCriteriaBtn extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service mwcIntl!: MwcIntl;
  @service onPremise!: OnPremiseService;
  @service orderCriteria!: OrderCriteriaService;
  @service store!: DS.Store;
  @service vendor!: VendorService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get criteria(): OrderCriteria {
    return (
      (this.args.searchOnly
        ? this.orderCriteria.searchOrderCriteria
        : this.orderCriteria.basketOrderCriteria) ?? this.orderCriteria.criteria
    );
  }

  get showStaticCriteria(): boolean {
    return (
      this.onPremise.showTableNumber ||
      this.onPremise.isEnabled ||
      this.groupOrder.isParticipantMode
    );
  }

  get orderCriteriaUninitialized(): boolean {
    return this.criteria.isDefault ?? false;
  }

  get isAdvance(): boolean {
    return isAdvance(this.criteria);
  }

  get isAtStore(): boolean {
    return isAtStore(this.criteria);
  }

  get isDelivery(): boolean {
    return isDelivery(this.criteria);
  }

  get isUnknownTime(): boolean {
    return isAdvance(this.criteria) && !this.criteria.timeWanted;
  }

  get location(): string {
    return isDelivery(this.criteria)
      ? compact([
          this.criteria.deliveryAddress?.streetAddress,
          this.criteria.deliveryAddress?.building,
        ]).join(', ')
      : isAtStore(this.criteria)
      ? this.criteria.searchAddress
      : '';
  }

  get currentVendor(): Vendor | undefined {
    return !this.args.searchOnly ? this.vendor.vendor : undefined;
  }

  get deliveryAddress(): string {
    return isDelivery(this.criteria) ? this.criteria.deliveryAddress?.streetAddress ?? '' : '';
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  changeLocation(): void {
    this.orderCriteria.set('showAddressModal', true);
  }

  @action
  edit(): void {
    this.trackChangeOrderCriteriaIntent();
    this.args.onEdit();
  }

  private trackChangeOrderCriteriaIntent() {
    const handoffModeModels = this.orderCriteria.selectableHandoffModes;
    const selectedHandoffModeModel = getSelectedHandoffModeModel(
      handoffModeModels,
      this.orderCriteria.criteria.handoffMode
    );
    const timeWantedModes =
      this.orderCriteria.getSelectableTimeWantedModes(selectedHandoffModeModel);
    const selectedTimeWantedMode = getSelectedTimeWantedMode(
      timeWantedModes,
      this.orderCriteria.criteria.timeWantedType
    );

    this.analytics.trackEvent(AnalyticsEvents.ChangeOrderCriteriaIntent, () => ({
      ...orderCriteriaProperties(
        handoffModeModels,
        timeWantedModes,
        selectedTimeWantedMode,
        selectedHandoffModeModel
      ),
      [AnalyticsProperties.ComponentNameInitiatedModal]: 'Order Criteria Button',
    }));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrderCriteriaBtn: typeof OrderCriteriaBtn;
  }
}
