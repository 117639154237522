import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import fade from 'ember-animated/transitions/fade';

import { Args as LinkToArgs } from 'mobile-web/components/olo-link-to';
import { classes } from 'mobile-web/lib/utilities/classes';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import ScrollService from 'mobile-web/services/scroll';

import style from './index.m.scss';

interface Args<T> {
  // Required arguments
  title: string;
  items: T[];

  // Optional arguments
  description?: string;
  itemClass?: string;
  showHeader?: boolean;
  viewAll?: LinkToArgs;
  centerContent?: boolean; // used to add specific carousel styles to the brand carousel
}

interface Signature<T> {
  Element: HTMLElement;

  Args: Args<T>;

  Blocks: {
    item: [T, number];
    placeholder: [];
  };
}

export default class Carousel<T> extends Component<Signature<T>> {
  // Service injections
  @service('scroll') scrollService!: ScrollService;

  // Untracked properties
  ids = guids(this, 'heading');
  listResizeObserver = new ResizeObserver(this.setListState);
  style = style;

  // Tracked properties
  @tracked listElement?: HTMLElement;
  @tracked listItems: HTMLLIElement[] = [];
  @tracked scrollLeft = 0;
  @tracked transition = fade;
  @tracked isScrolling = false;
  @tracked leftButton?: HTMLButtonElement;
  @tracked leftButtonHover = false;
  @tracked rightButton?: HTMLButtonElement;
  @tracked rightButtonHover = false;

  // Getters and setters
  get leftButtonClass() {
    return classes(this.style.scrollButton, {
      [this.style.hover]: this.leftButtonHover,
    });
  }

  get rightButtonClass() {
    return classes(this.style.scrollButton, {
      [this.style.hover]: this.rightButtonHover,
    });
  }

  get isScrollable(): boolean {
    return isSome(this.listElement) && this.listElement.scrollWidth > this.listElement.clientWidth;
  }

  get showHeader(): boolean {
    return this.args.showHeader ?? true;
  }

  get showLeftArrow() {
    return isSome(this.listElement) && this.scrollLeft > 0;
  }

  get showRightArrow() {
    return (
      isSome(this.listElement) &&
      this.isScrollable &&
      Math.ceil(this.scrollLeft) + this.listElement.offsetWidth < this.listElement.scrollWidth
    );
  }

  get viewAllModels() {
    if (this.args.viewAll?.model) {
      return [this.args.viewAll.model];
    }

    if (this.args.viewAll?.models) {
      return this.args.viewAll.models;
    }

    return [];
  }

  get visibleItems() {
    if (!this.listItems.length) {
      return '';
    }

    const items = this.listItems;

    // first fully visible item where the item's left boundary is past the left
    // button's right boundary
    const firstVisibleIndex =
      this.showLeftArrow && this.leftButton
        ? items.findIndex(
            item =>
              item.getBoundingClientRect().left > this.leftButton!.getBoundingClientRect().right
          )
        : -1;

    // first item partially obscured by the right button. This is the item after
    // the last fully visible item
    const firstOverhangingIndex =
      this.showRightArrow && this.rightButton
        ? items.findIndex(
            item =>
              item.getBoundingClientRect().right > this.rightButton!.getBoundingClientRect().left
          )
        : -1;

    const start =
      firstVisibleIndex === -1
        ? // if no items were found or we're at the start
          1
        : // convert from index to ordinal
          firstVisibleIndex + 1;

    const end =
      firstOverhangingIndex === -1
        ? // if no items were found or we're at the end
          items.length
        : // convert from index to ordinal
          // the last item is `firstOverhangingIndex - 1`
          // the ordinal for the last item is `firstOverhangingIndex - 1 + 1`
          firstOverhangingIndex;

    return start >= end ? start.toString() : `${start}-${end}`;
  }

  get carouselListClass() {
    return classes(this.style.carousel, {
      [this.style.carouselItemsCentered]: this.args.centerContent,
    });
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  scroll(direction: 'left' | 'right', button: HTMLElement) {
    if (!this.listElement || this.isScrolling) {
      return;
    }

    const items = this.listItems;
    const firstItem = this.listItems.length ? this.listItems[0] : undefined;
    const lastItem = this.listItems.length ? this.listItems[this.listItems.length - 1] : undefined;
    const buttonBounds = button.getBoundingClientRect();
    const buttonWidth = buttonBounds.width;

    let item: HTMLLIElement | undefined;
    if (direction === 'right') {
      // Find the first carousel item whose right bound is past (greater
      // than) the right button's left bound (i.e. the item is overhanging
      // to the right). Otherwise use the last item in the carousel.
      item = items.find(i => i.getBoundingClientRect().right > buttonBounds.left) ?? lastItem;
    } else {
      // Find the last carousel item whose left bound is past (less than)
      // the left button's right bound (i.e. the item is overhanging to the
      // left).
      const leftOverhang = items
        .slice()
        .reverse()
        .find(i => i.getBoundingClientRect().left < buttonBounds.right);

      if (!leftOverhang) {
        // if there aren't any items overhanging to the left, scroll to the
        // first item
        item = firstItem;
      } else {
        const rightOffset = leftOverhang.offsetLeft + leftOverhang.clientWidth;

        // +---+-+-------+---+
        // |   | |       |   |
        // | A |B|  D    | A |
        // |   | |       |   |
        // +---+-+-------+---+
        //
        // ^--------C--------^
        //
        // A = button width
        // B = item margin
        const margin = parseInt(getComputedStyle(leftOverhang).marginLeft, 10);
        // C = carousel width
        const carouselWidth = this.listElement.clientWidth;
        // D = viewport width
        // D = C - 2A - B
        const viewportWidth = carouselWidth - buttonWidth * 2 - margin;

        // Find the first carousel item whose left offset is past (greater than)
        // the left overhang item's right offset less the carousel viewport
        // width. That is, find the first item that can be scrolled to where the
        // left overhang item will be fully visible in the carousel.
        item = items.find(i => i.offsetLeft > rightOffset - viewportWidth);
      }
    }

    if (!item) return;

    const leftOffset = item.offsetLeft;

    const margin = parseInt(getComputedStyle(item).marginLeft, 10);

    const bufferWidth = margin + buttonWidth;

    // set the scroll position to the item's left offset less the buffer width
    this.listElement.scrollLeft = leftOffset - bufferWidth;
    this.isScrolling = true;
    this.scrollService.scrollStop(this.listElement).then(() => {
      this.isScrolling = false;
    });
  }

  @action
  onCarouselClick(e: MouseEvent) {
    if (
      this.leftButton &&
      Carousel.isIntersecting(e.clientX, e.clientY, this.leftButton.getBoundingClientRect())
    ) {
      e.preventDefault();
      e.stopPropagation();
      this.scroll('left', this.leftButton);
    }

    if (
      this.rightButton &&
      Carousel.isIntersecting(e.clientX, e.clientY, this.rightButton.getBoundingClientRect())
    ) {
      e.preventDefault();
      e.stopPropagation();
      this.scroll('right', this.rightButton);
    }
  }

  @action
  onCarouselHover(e: MouseEvent) {
    this.leftButtonHover = Boolean(
      this.leftButton &&
        Carousel.isIntersecting(e.clientX, e.clientY, this.leftButton.getBoundingClientRect())
    );
    this.rightButtonHover = Boolean(
      this.rightButton &&
        Carousel.isIntersecting(e.clientX, e.clientY, this.rightButton.getBoundingClientRect())
    );
  }

  @action
  onCarouselLeave() {
    this.rightButtonHover = false;
    this.leftButtonHover = false;
  }

  @action
  setListState() {
    this.scrollLeft = this.listElement?.scrollLeft ?? 0;
    //Adding the self assignment to track all list elements so when the page resizes the page does not have to
    //refresh to resize elements.
    // eslint-disable-next-line no-self-assign
    this.listElement = this.listElement;
  }

  private static isIntersecting(
    clientX: number,
    clientY: number,
    { left, right, top, bottom }: DOMRect
  ) {
    return left <= clientX && clientX <= right && top <= clientY && clientY <= bottom;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Carousel: typeof Carousel;
  }
}
