import { trackedData } from '@glimmer/validator';

import { propertyDecoratorFactory } from 'mobile-web/lib/decorator-factory';

/**
 * Observes a media query and sets the `matches` state on the associated
 * property.
 *
 * @param mediaQueryString the media query to observe
 * @returns a property decorator to assign the media query data to
 *
 * @example
 * \@media('(max-width: 767px)') small!: boolean;
 * \@media('(min-width: 768px) and (max-width: 1023px)') medium!: boolean;
 * \@media('(min-width: 1024px)') large!: boolean;
 */
const media = propertyDecoratorFactory<string>((mediaQueryString, target, propertyKey) => {
  const { getter, setter } = trackedData<typeof target, typeof propertyKey>(
    propertyKey,
    function () {
      const mediaQuery = matchMedia(mediaQueryString);

      // While `addListener` is deprecated, `addEventListener('change', ...)`
      // was only implemented in Safari v14 which was released in 2020
      // (https://caniuse.com/mdn-api_mediaquerylist_eventtarget_inheritance).
      //
      // This can probably be switched back at some point after 2023.
      mediaQuery.addListener(() => {
        this[propertyKey] = mediaQuery.matches;
      });

      return mediaQuery.matches;
    }
  );

  return {
    get() {
      return getter(this);
    },
    set(value) {
      setter(this, value);
    },
  };
});

export default media;
