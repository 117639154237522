import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  imgUrl?: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class ImageCard extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get inlineStyle() {
    return htmlSafe(this.args.imgUrl ? `background-image:url("${this.args.imgUrl}")` : '');
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ImageCard: typeof ImageCard;
  }
}
