export default {
  "root": "_root_5zaavv",
  "header": "_header_5zaavv",
  "button": "_button_5zaavv",
  "minimum": "_minimum_5zaavv",
  "tooltip": "_tooltip_5zaavv",
  "icon": "_icon_5zaavv",
  "upIcon": "_upIcon_5zaavv _icon_5zaavv",
  "downIcon": "_downIcon_5zaavv _icon_5zaavv",
  "schedule": "_schedule_5zaavv",
  "scheduleWeekday": "_scheduleWeekday_5zaavv",
  "scheduleHours": "_scheduleHours_5zaavv"
};
