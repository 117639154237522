import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';

import BootstrapService from 'mobile-web/services/bootstrap';
import ChannelService from 'mobile-web/services/channel';
import VendorService from 'mobile-web/services/vendor';

const INDEXED_ROUTES = [
  'contact-us',
  'frequently-asked-questions',
  'index',
  'privacy-policy',
  'user-agreement',
  'vendor-search-results',
];

const INDEXED_VENDOR_ROUTES = [
  'menu.category',
  'menu.vendor',
  'menu.vendor.index',
  'menu.vendor.products',
];

export default class HeadDataService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service channel!: ChannelService;
  @service router!: RouterService;
  @service vendor!: VendorService;

  // Untracked properties

  // Tracked properties

  // Getters and setters
  get allowSearchEngineIndexing(): boolean {
    return this.bootstrap.data?.allowSearchEngineIndexing ?? false;
  }

  get canonicalURL(): string | undefined {
    const { current } = this.channel;
    if (current && this.router.currentRouteName === 'index') {
      return `https://${current.settings.fullSiteUrl}`;
    }
    return undefined;
  }

  get robotsContent(): 'all' | 'noindex' {
    if (!this.allowSearchEngineIndexing) {
      return 'noindex';
    }

    const { currentRouteName } = this.router;
    if (INDEXED_ROUTES.includes(currentRouteName)) {
      return 'all';
    }
    if (
      INDEXED_VENDOR_ROUTES.includes(currentRouteName) &&
      this.vendor.vendor?.status.toLowerCase() === 'public'
    ) {
      return 'all';
    }

    return 'noindex';
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'head-data': HeadDataService;
  }
}
