import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import IntlService from 'ember-intl/services/intl';

import OrderSummaryController from 'mobile-web/controllers/order-summary';
import authenticated from 'mobile-web/decorators/authenticated';
import { PreviousRoute } from 'mobile-web/lib/routing';
import OrderModel from 'mobile-web/models/order';
import OrderFailureModel from 'mobile-web/models/order-failure';
import SessionService from 'mobile-web/services/session';
import UserFeedbackService, { Type } from 'mobile-web/services/user-feedback';

export type Model = { order: OrderModel; failure: OrderFailureModel };

@authenticated
export default class OrderSummaryRoute extends Route {
  @service store!: DS.Store;
  @service intl!: IntlService;
  @service session!: SessionService;
  @service userFeedback!: UserFeedbackService;

  previousRoute: PreviousRoute = { label: 'Recent Orders', route: 'recent-orders', models: [] };

  async setupController(
    controller: OrderSummaryController,
    model: ModelForRoute<this>,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    const loyaltyProviderName = this.session.getLoyaltyTargetName(
      transition.to.queryParams.linkingTargetSlug
    );

    if (transition.to.queryParams.linkingError === 'true') {
      this.userFeedback.add({
        type: Type.Negative,
        title: '',
        message: this.intl.t('mwc.postCheckout.linkLoyaltyAccountFailure', {
          loyaltyProviderName,
        }),
      });
    } else if (transition.to.queryParams.linkingSuccessful === 'true') {
      this.userFeedback.add({
        type: Type.Positive,
        title: 'Success!',
        message: this.intl.t('mwc.postCheckout.linkLoyaltyAccountSuccess', {
          loyaltyProviderName,
        }),
      });
    }
  }

  model(params: { order_id: string }): RSVP.Promise<Model> {
    return this.store.findRecord('order', params.order_id, { reload: true }).then(order => {
      const failure = order.get('orderFailure');
      return RSVP.hash({ order, failure });
    });
  }
}
