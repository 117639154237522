export default {
  "root": "_root_1yjr23",
  "section": "_section_1yjr23",
  "buttonContainer": "_buttonContainer_1yjr23",
  "heading": "_heading_1yjr23",
  "orderStatusHeading": "_orderStatusHeading_1yjr23",
  "orderStatusSubHeading": "_orderStatusSubHeading_1yjr23",
  "orderSummaryHeading": "_orderSummaryHeading_1yjr23",
  "receivedText": "_receivedText_1yjr23",
  "notice": "_notice_1yjr23",
  "orderMore": "_orderMore_1yjr23",
  "editYourOrderButton": "_editYourOrderButton_1yjr23",
  "editYourOrderText": "_editYourOrderText_1yjr23",
  "contentsRow": "_contentsRow_1yjr23",
  "basketItemCost": "_basketItemCost_1yjr23",
  "basketChoice": "_basketChoice_1yjr23",
  "basketItem": "_basketItem_1yjr23",
  "basketItemQuantity": "_basketItemQuantity_1yjr23",
  "basketItemDetails": "_basketItemDetails_1yjr23",
  "waitingIcon": "_waitingIcon_1yjr23",
  "cancelledIcon": "_cancelledIcon_1yjr23",
  "submittedIcon": "_submittedIcon_1yjr23"
};
