import { alias } from '@ember/object/computed';
import DS from 'ember-data';

import { UserModelPayload } from 'mobile-web/adapters/account';
import { ResetPasswordData, ChangePasswordData } from 'mobile-web/adapters/user';
import collectionAction, { CollectionAction } from 'mobile-web/decorators/collection-action';
import { Code as CountryCode } from 'mobile-web/lib/country';
import { GuestUser } from 'mobile-web/lib/customer';

import { LoginProviderInfo } from './loyalty-olo-auth-link';

const { attr, Model } = DS;

export default class UserModel extends Model {
  @attr('string')
  firstName!: string;
  @attr('string')
  lastName!: string;
  @attr('string')
  emailAddress!: string;
  @attr('string')
  contactPhone!: string;
  @attr('boolean')
  notifyBySms!: boolean;
  @attr('boolean')
  notifyByEmail!: boolean;
  @attr('boolean')
  optIn!: boolean;
  @attr('boolean', { defaultValue: true })
  optInToSms?: boolean;
  @attr('boolean')
  allowOrderFollowUps!: boolean;
  @attr('boolean')
  isUpsellEnabled!: boolean;
  @attr('boolean')
  isGuest!: boolean;
  @attr()
  loginProviderInfo!: LoginProviderInfo;

  @alias('contactPhone')
  contactNumber!: string;

  @collectionAction<{ email: string }>({ type: 'post', path: 'forgotpassword' })
  forgotPassword!: CollectionAction<{ email: string }>;

  @collectionAction<ResetPasswordData>({ type: 'post', path: 'resetpassword' })
  resetPassword!: CollectionAction<ResetPasswordData>;

  @collectionAction<ChangePasswordData>({ type: 'post', path: 'changepassword' })
  changePassword!: CollectionAction<ChangePasswordData>;

  @collectionAction<GuestUser>({ type: 'post', path: 'setguestuser' })
  setGuestUser!: CollectionAction<GuestUser>;

  @collectionAction<GuestUser, UserModelPayload>({ type: 'post', path: 'guestgrouporderstart' })
  guestGrouporderStart!: CollectionAction<GuestUser, UserModelPayload>;
}

export interface GlobalUser {
  isLoggedIn: boolean;
  optIn?: boolean;
  isEmbeddedLevelUp: boolean;
  isSsoLogin: boolean;
  isFacebook: boolean;
  currentCountry: CountryCode;
  uniqueId?: string;
}
