import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  value: boolean;
  onChange: (newValue: boolean) => void;

  // Optional arguments
}

interface Signature {
  Args: Args;
  Element: HTMLButtonElement;
}

export default class Toggle extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Toggle: typeof Toggle;
  }
}
