/**
 * Wraps a callback in a `requestAnimationFrame` callback.
 *
 * The callback is also trailing-edge debounced, meaning that when the callback
 * is called multiple times between animation frames, only the final callback
 * invocation will occur.
 *
 * @param callback the callback to wrap
 * @returns the wrapped callback
 */
export const raf = <Callback extends (...args: unknown[]) => void>(
  callback: Callback
): ((...args: Parameters<Callback>) => void) => {
  let handle = 0;
  return (...args) => {
    window.cancelAnimationFrame(handle);
    handle = window.requestAnimationFrame(() => {
      callback(...args);
    });
  };
};
