import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import { SECONDS } from 'mobile-web/lib/time/durations';
import AnalyticsService, { EventName } from 'mobile-web/services/analytics';
import AnnouncerService from 'mobile-web/services/announcer';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import { ContentString } from 'mobile-web/services/content';
import ErrorService from 'mobile-web/services/error';
import GroupOrderService from 'mobile-web/services/group-order';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

type InviteArgs = {
  title: string;
  description: ContentString;
  shareButtonLabel: string;
  url: string;
  testSelector?: string;
  shareButtonTestSelector?: string;
  copyButtonTestSelector?: string;
  shareButtonAnalytics?: EventName;
  copyButtonAnalytics?: EventName;
  shareDescription?: string;
  onClose?: () => void;
};

declare module 'mobile-web/services/bus' {
  interface EventRegistry {
    invite: InviteArgs;
  }
}

export default class InviteModal extends Component {
  // Service injections
  @service analytics!: AnalyticsService;
  @service announcer!: AnnouncerService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service bus!: BusService;
  @service vendor!: VendorService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked inviteArgs?: InviteArgs;
  @tracked copyLinkButtonLabel: string = this.intl.t('mwc.groupOrder.inviteCopyButton');
  @tracked copyLinkButtonIconName = 'icon';

  // Getters and setters
  get isOpen(): boolean {
    return !!this.inviteArgs;
  }

  get canShare(): boolean {
    return !!navigator.share;
  }

  get url(): string {
    return this.inviteArgs?.url || '';
  }

  // Lifecycle methods
  constructor(owner: unknown, args: UnknownObject) {
    super(owner, args);

    this.bus.on('invite', inviteArgs => {
      this.inviteArgs = inviteArgs;
    });
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  copyLink() {
    if (this.inviteArgs?.copyButtonAnalytics) {
      this.analytics.trackEvent(this.inviteArgs.copyButtonAnalytics);
    }
    navigator.clipboard.writeText(this.inviteArgs!.url);

    this.copyLinkButtonLabel = this.intl.t('mwc.groupOrder.inviteLinkCopied');
    this.copyLinkButtonIconName = 'check';

    later(() => {
      this.copyLinkButtonLabel = this.intl.t('mwc.groupOrder.inviteCopyButton');
      this.copyLinkButtonIconName = 'copy';
      this.announcer.announce(`${this.copyLinkButtonLabel}`, 'polite');
    }, 2 * SECONDS);
  }

  @action
  async shareLink() {
    if (this.inviteArgs?.shareButtonAnalytics) {
      this.analytics.trackEvent(this.inviteArgs.shareButtonAnalytics);
    }
    const shareData = {
      title: this.inviteArgs?.shareDescription,
      text: this.inviteArgs?.shareDescription,
      url: this.inviteArgs?.url,
    };

    try {
      await navigator.share(shareData);
    } catch (e) {
      if (e.toString().includes('AbortError')) {
        // do nothing
      } else {
        this.error.reportError(e);
      }
    }
  }

  @action
  close() {
    this.inviteArgs?.onClose?.();
    this.inviteArgs = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    InviteModal: typeof InviteModal;
  }
}
