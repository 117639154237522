import { Dayjs } from 'dayjs';

import HandoffModeModel from 'mobile-web/models/handoff-mode';
import TimeWantedMode from 'mobile-web/models/time-wanted-mode';
import { AnalyticsProperties } from 'mobile-web/services/analytics';

export function orderCriteriaProperties(
  handoffModeModels?: HandoffModeModel[],
  timeWantedModes?: TimeWantedMode[],
  selectedTimeWantedMode?: TimeWantedMode,
  selectedHandoffModeModel?: HandoffModeModel,
  previousHandoffModeModel?: HandoffModeModel,
  timeWanted?: Dayjs
) {
  let obj = {};

  if (selectedHandoffModeModel || handoffModeModels) {
    obj = {
      [AnalyticsProperties.HandoffSelection]: selectedHandoffModeModel?.label,
      [AnalyticsProperties.HandoffSelectionList]: handoffModeModels?.map(h => h.label),
      [AnalyticsProperties.HandoffSelectionSlug]: selectedHandoffModeModel?.type,
      [AnalyticsProperties.HandoffSelectionSlugList]: handoffModeModels?.map(h => h.type),
    };
  }

  if (previousHandoffModeModel) {
    obj = {
      ...obj,
      [AnalyticsProperties.PreviousHandoffSelection]: previousHandoffModeModel?.label,
      [AnalyticsProperties.PreviousHandoffSelectionSlug]: previousHandoffModeModel?.type,
    };
  }

  if (timeWantedModes || selectedTimeWantedMode) {
    obj = {
      ...obj,
      [AnalyticsProperties.WhenSelection]: selectedTimeWantedMode?.label,
      [AnalyticsProperties.WhenSelectionList]: timeWantedModes?.map(t => t.label),
      [AnalyticsProperties.WhenSelectionSlug]: selectedTimeWantedMode?.type,
      [AnalyticsProperties.WhenSelectionSlugList]: timeWantedModes?.map(t => t.type),
    };
  }

  if (timeWanted) {
    obj = {
      ...obj,
      [AnalyticsProperties.ScheduledTimeWanted]: timeWanted.toString(),
    };
  }

  return obj;
}
