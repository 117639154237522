import ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { dropTask } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';

import { UserData } from 'mobile-web/lib/customer';
import { OloAuthChallengeError } from 'mobile-web/lib/olo-auth';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import BillingMembership from 'mobile-web/models/billing-membership';
import LoyaltyMembershipModel from 'mobile-web/models/loyalty-membership';
import LoyaltyScheme from 'mobile-web/models/loyalty-scheme';
import UserModel from 'mobile-web/models/user';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import AnimationService from 'mobile-web/services/animation';
import BootstrapService from 'mobile-web/services/bootstrap';
import BusService from 'mobile-web/services/bus';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import LoyaltyService from 'mobile-web/services/loyalty';
import Payment from 'mobile-web/services/payment';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  // Required arguments
  memberships: ArrayProxy<LoyaltyMembershipModel>;
  schemes: ArrayProxy<LoyaltyScheme>;

  // Optional arguments
  savedCards?: DS.RecordArray<BillingMembership>;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class MyAccountRoute extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service animation!: AnimationService;
  @service bus!: BusService;
  @service intl!: IntlService;
  @service session!: SessionService;
  @service loyalty!: LoyaltyService;
  @service bootstrap!: BootstrapService;
  @service features!: FeaturesService;
  @service error!: ErrorService;
  @service payment!: Payment;

  // Untracked properties
  ids = guids(this, 'cardHeading', 'membershipHeading');
  style = style;

  // Tracked properties
  @tracked cardToDelete?: BillingMembership;
  @tracked showOloAuthChallenge = false;
  @tracked updatedUserData?: UserData;
  @tracked oloAuthChallengeError?: OloAuthChallengeError;

  // Getters and setters
  private static defaultFirst(a: BillingMembership, b: BillingMembership): number {
    return a.isDefault ? -1 : b.isDefault ? 1 : 0;
  }

  get isExternalAccount(): boolean {
    return this.session.isExternalAccount;
  }

  get editAccountUrl(): string | undefined {
    return this.isExternalAccount ? this.session.editAccountUrl : undefined;
  }

  get isEditable(): boolean {
    return !this.isExternalAccount || isSome(this.editAccountUrl);
  }

  get isOloAuthLogin(): boolean {
    return this.session.isOloAuthLogin ?? false;
  }

  get substitutions(): Dict<string> {
    const bootstrapData = this.bootstrap.data;
    return isSome(bootstrapData) && isSome(bootstrapData.loginProviderName)
      ? { loginprovider: bootstrapData.loginProviderName }
      : {};
  }

  get savedCards(): BillingMembership[] {
    if (isNone(this.args.savedCards)) {
      return [];
    }
    return this.args.savedCards.toArray();
  }

  get sortedCards(): BillingMembership[] {
    const cardsArray = this.savedCards.toArray().filter(card => !card.isGiftCard);
    return cardsArray.sort(MyAccountRoute.defaultFirst);
  }

  get sortedGiftCards(): BillingMembership[] {
    const giftCardsArray = this.savedCards.toArray().filter(card => card.isGiftCard);
    return giftCardsArray.sort(MyAccountRoute.defaultFirst);
  }

  get linkableSchemeNames(): string[] {
    return this.args.schemes.filter(s => s.canLink).map(s => s.schemeName);
  }

  get linkableMemberships(): LoyaltyMembershipModel[] {
    return this.args.memberships.filter(m => this.linkableSchemeNames.includes(m.schemeName));
  }

  get isOloAuthUser(): boolean {
    return !!this.bootstrap.data?.isOloAuthLogin;
  }

  get cardsWithDefaultOption(): Record<string, boolean> {
    return this.savedCards.reduce(
      (cards: Record<string, boolean>, card: BillingMembership) => ({
        ...cards,
        // For Olo Auth accounts, gift cards cannot be set as a default (in addition to the default card)
        // For native accounts, all cards that are not the default can be set as the default card
        [card.id]: this.isOloAuthUser ? !(card.isDefault || card.isGiftCard) : !card.isDefault,
      }),
      {}
    );
  }

  // Lifecycle methods

  // Other methods
  mixpanelTrackCreditCardDeletion(mixpanelEvt: AnalyticsEvents, card: BillingMembership) {
    const isGiftCard = !!card?.isGiftCard;
    const isBrandedCard = !!card?.isBrandedCard;
    //track this event only if credit card
    if (!isGiftCard && !isBrandedCard) {
      const analyticsProps = {
        [AnalyticsProperties.PaymentType]: 'Credit Card',
        [AnalyticsProperties.IsDefault]: card?.isDefault ? card?.isDefault : false,
      };
      this.analytics.trackEvent(mixpanelEvt, () => analyticsProps, {
        bucket: 'all',
      });
    }
  }

  // Tasks
  unlinkMembershipTask = dropTask(async (membership: LoyaltyMembershipModel): Promise<void> => {
    await membership.destroyRecord();
    this.loyalty.unlinkMembership(membership.membershipId);
  });

  deleteCardTask = dropTask(async (card: BillingMembership): Promise<void> => {
    await card.destroyRecord();
  });

  makeDefaultTask = dropTask(async (card: BillingMembership): Promise<void> => {
    await card.makeDefault();
  });

  // Actions and helpers
  @action
  async onChange(user: UserData) {
    const currentUser = this.session.currentUser!;
    const rollbackUser = currentUser.serialize();
    currentUser.setProperties(user);
    try {
      await currentUser.save();
      this.trackSave(currentUser, rollbackUser as UnknownObject);
    } catch (err) {
      this.updatedUserData = user;
      currentUser.setProperties(rollbackUser);
      for (const e of err.errors) {
        if (+e.status === 403) {
          this.showOloAuthChallenge = true;
          return;
        }
      }
      this.error.sendUserMessage(err);
    }
  }

  @action
  async onChallengeChange(token: string) {
    const currentUser = this.session.currentUser!;
    const rollbackUser = currentUser.serialize();
    try {
      currentUser.setProperties(this.updatedUserData!);
      this.oloAuthChallengeError = undefined;
      this.showOloAuthChallenge = false;
      await currentUser.save({
        adapterOptions: {
          token,
        },
      });
      this.trackSave(currentUser, rollbackUser as UnknownObject, true);
      this.updatedUserData = undefined;
    } catch (err) {
      currentUser.setProperties(rollbackUser);
      this.showOloAuthChallenge = true;
      for (const e of err.errors) {
        const status = +e.status;
        if (status === 400 || status === 401) {
          this.oloAuthChallengeError = 'badcode';
          return;
        } else if (status === 403) {
          this.oloAuthChallengeError = 'nocode';
          return;
        } else if (status === 502) {
          this.oloAuthChallengeError = 'failed';
          return;
        }
      }
      this.error.sendUserMessage(err);
    }
  }

  @action
  async onChallengeClose() {
    this.updatedUserData = undefined;
    this.showOloAuthChallenge = false;
  }

  @action
  confirmDelete(card: BillingMembership) {
    this.cardToDelete = card;
    this.mixpanelTrackCreditCardDeletion(
      AnalyticsEvents.AccountsDeleteCreditCardRequest,
      this.cardToDelete
    );
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.myAccount.confirmDeleteTitle'),
      content: this.payment.displayDeleteSavedCardsModalText(!!card.isGiftCard),
      buttonLabel: this.intl.t('mwc.myAccount.confirmDeleteButton'),
      onConfirm: this.delete,
      onClose: this.closeConfirm,
      testSelector: 'confirmDelete',
      buttonTestSelector: 'confirmDeleteButton',
    });
  }

  @action
  delete() {
    this.mixpanelTrackCreditCardDeletion(
      AnalyticsEvents.AccountsDeleteCreditCardConfirm,
      this.cardToDelete!
    );
    this.deleteCardTask.perform(this.cardToDelete!);
  }

  @action
  closeConfirm() {
    this.cardToDelete = undefined;
  }

  @action
  makeDefaultCard(card: BillingMembership) {
    this.analytics.trackEvent(
      AnalyticsEvents.AccountsMakeCardDefault,
      () => ({
        [AnalyticsProperties.PaymentType]: card?.description.slice(
          0,
          card?.description.indexOf('x') - 1
        ),
      }),
      {
        bucket: 'all',
      }
    );
    this.makeDefaultTask.perform(card);
  }

  @action
  trackSave(currentUser?: UserModel, rollbackUser?: UnknownObject, hadChallenge = false) {
    this.analytics.trackEvent(
      AnalyticsEvents.UpdateContactInfo,
      () => ({
        [AnalyticsProperties.IsOloAuth]: this.isOloAuthLogin,
        [AnalyticsProperties.UpdatedPhoneNumber]:
          currentUser?.contactPhone !== rollbackUser?.contactPhone,
        [AnalyticsProperties.UpdatedFirstName]: currentUser?.firstName !== rollbackUser?.firstName,
        [AnalyticsProperties.UpdatedLastName]: currentUser?.lastName !== rollbackUser?.lastName,
        [AnalyticsProperties.VerifiedNewPhone]: hadChallenge,
      }),
      {
        bucket: 'all',
      }
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::MyAccountRoute': typeof MyAccountRoute;
  }
}
