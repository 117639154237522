import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import UrlTemplates from 'ember-data-url-templates';

import { host } from 'mobile-web/lib/hybrid-util';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';
import RSVP from 'rsvp';

export default class ChoiceAdapter extends ApplicationAdapter.extend(UrlTemplates) {
  @service orderCriteria!: OrderCriteriaService;
  @service vendor!: VendorService;

  ajax(url: string, type: string, options?: { data?: object }) : RSVP.Promise<any> {
    let params = {
      ...options,
      data: {
        handoffMode: this.orderCriteria.criteria.handoffMode,
        ...options?.data
      }
    };

    return super.ajax(url, type, params);
  }

  coalesceFindRequests = true;
  urlTemplate = host() + '/{+namespace}/vendors/{vendorId}/choices/{choiceId}';
  urlSegments = {
    vendorId(this: ChoiceAdapter): string {
      const vendor = this.vendor.vendor;
      if (isNone(vendor)) {
        throw new Error('Vendor Id for Choice Not Found');
      }

      return vendor.id;
    },

    choiceId(_type: unknown, _id: unknown, snapshot: DS.Snapshot<'choice'>): string {
      return snapshot.id;
    },

    namespace(this: ChoiceAdapter): string {
      return this.namespace;
    },
  };
}
