export type PerimeterXResponse = {
  appId: string;
  jsClientSrc: string;
  firstPartyEnabled: boolean;
  vid: string;
  uuid: string;
  hostUrl: string;
  blockScript: string;
};

type RiskCallback = (risk: string, name: string) => void;
type PerimeterXEvents = {
  on: (event: 'risk', cb: RiskCallback) => void;
};

export type PerimeterXInstance = {
  Events: PerimeterXEvents;
};

export function asPerimeterXResponse(
  err: AnyObject | null | undefined
): PerimeterXResponse | undefined {
  // Any valid JSON string would work here as a fallback
  const message = err?.errors?.[0]?.message ?? '""';
  try {
    // Try in case of JSON.parse errors
    const response = JSON.parse(message);
    if (typeof response === 'object' && 'blockScript' in response) {
      return response as PerimeterXResponse;
    }
  } catch {
    return undefined;
  }
  return undefined;
}
