import dayjs, { Dayjs } from 'mobile-web/lib/dayjs';

/**
 * A known valid ISO8601-formatted string. Useful for making sure the time data
 * you're using is reliable.
 */
export default class ISO8601 {
  readonly value: string;

  /**
   * Construct an ISO8601 value safely
   * @param value A string to validate as ISO8601.
   * @returns `ISO8601` if the string is valid; `undefined` otherwise.
   */
  static fromString(value: string): ISO8601 | undefined {
    const time = dayjs(value);
    return time.isValid() ? new ISO8601(time.toISOString()) : undefined;
  }

  /**
   * Construct an ISO8601 value given a Dayjs instance.
   * @param time A `Dayjs` instance to construct the value with.
   * @returns `ISO8601` if `time.isValid()` is `true`, otherwise `undefined`
   */
  static fromDayjs(time: Dayjs): ISO8601 | undefined {
    return time.isValid() ? new ISO8601(time.toISOString()) : undefined;
  }

  private constructor(value: string) {
    this.value = value;
  }
}
