/* eslint-disable no-self-assign */
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import * as AddressForm from 'mobile-web/components/address-form';
import { CloseEditor, OpenEditor } from 'mobile-web/components/form-edit-button';
import { orderCriteriaProperties } from 'mobile-web/lib/analytics';
import dayjs, { Dayjs } from 'mobile-web/lib/dayjs';
import { isValid, toLabel } from 'mobile-web/lib/location/address';
import {
  HandoffMode,
  isAdvance,
  isAtStore,
  isDelivery,
  OrderCriteria,
  TimeWantedType,
  updateOrderCriteria,
} from 'mobile-web/lib/order-criteria';
import { ValidationMessage, ValidationResult } from 'mobile-web/lib/validation';
import SavedAddressModel from 'mobile-web/models/address';
import HandoffModeModel from 'mobile-web/models/handoff-mode';
import TimeWantedMode from 'mobile-web/models/time-wanted-mode';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GeoService from 'mobile-web/services/geo';
import MwcIntl from 'mobile-web/services/mwc-intl';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onSubmit: Action<[OrderCriteria, boolean, string]>;
  onSearchUnavailable: Action;

  // Optional arguments
}
interface Signature {
  Element: HTMLFormElement;

  Args: Args;

  Blocks: {
    default: [unknown];
  };
}

const handoffModeSupportsTimeWantedType = (
  handoffModes: HandoffModeModel[],
  handoffMode: HandoffMode,
  timeWantedType: TimeWantedType
): boolean => {
  const handoffModeMeta = handoffModes.find(hm => hm.type === handoffMode)!;
  const found = handoffModeMeta.timeWantedModes?.any(
    (tw: TimeWantedMode) => tw.type === timeWantedType
  );
  return found;
};

export default class VendorSearchForm extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service features!: FeaturesService;
  @service geo!: GeoService;
  @service mwcIntl!: MwcIntl;
  @service orderCriteria!: OrderCriteriaService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  // Untracked properties
  style = style;
  isNearByVendorSearch = false;
  readonly initialHandoffMode: HandoffMode;

  // Tracked properties
  @tracked addressForm!: AddressForm.Model;
  @tracked criteria: OrderCriteria;
  @tracked timeWanted?: Dayjs;
  @tracked timeWantedMode?: TimeWantedMode;
  @tracked validationResult?: ValidationResult;
  @tracked locationError?: string;

  // Getters and setters
  get addresses(): SavedAddressModel[] {
    return this.store.peekAll('address').toArray();
  }

  get advanceTimeSlotsLeft(): boolean {
    if ((this.channel.current?.settings.advanceOrderDays ?? 0) > 0) {
      return true;
    }

    const now = dayjs();
    const tomorrow = dayjs().add(1, 'day').startOf('day');

    // If there were exactly 30 minutes left, i.e. 11:30, you could have 11:45
    // as an option. Past that, the next valid option 12:00 AM the next day. For
    // this to behave correctly when a vendor does not have support for
    // additional days configured, we simply pretend that "today" is tomorrow in
    // that scenario.
    return tomorrow.diff(now, 'minute') >= 30;
  }

  get handoffModes(): HandoffModeModel[] {
    return (
      this.channel.current?.handoffModes.filter(
        hm =>
          !hm.isPrivate &&
          !this.channel.current?.settings.handoffModesHiddenFromSelection.includes(hm.type) &&
          hm.timeWantedModes.filter(tw => tw.type !== 'Advance' || this.advanceTimeSlotsLeft)
            .length > 0
      ) ?? []
    );
  }

  get hasMultipleHandoffModes(): boolean {
    return this.handoffModes.length > 1;
  }

  get selectedHandoffModeModel(): HandoffModeModel | undefined {
    return this.handoffModes.find(hm => hm.type === this.handoffMode);
  }

  get handoffMode(): HandoffMode {
    return this.criteria.handoffMode;
  }

  set handoffMode(newMode) {
    const handoffModeMeta = this.handoffModes.find(hm => hm.type === newMode)!;
    //Does the new mode have the currently set time mode.
    if (
      handoffModeSupportsTimeWantedType(this.handoffModes, newMode, this.criteria.timeWantedType)
    ) {
      updateOrderCriteria(this, 'criteria', { handoffMode: newMode });
    } else {
      updateOrderCriteria(this, 'criteria', {
        handoffMode: newMode,
        // there will always be a time wanted mode.
        timeWantedType: handoffModeMeta.timeWantedModes.firstObject!.type,
      });
    }

    if (newMode === 'MultiDelivery') {
      this.isNearByVendorSearch = false;
    }

    this.locationError = undefined;
  }
  get timeWantedTypes(): Array<TimeWantedMode> {
    return (
      this.selectedHandoffModeModel?.timeWantedModes?.filter(
        timeMode =>
          !timeMode.isPrivate && (timeMode.type !== 'Advance' || this.advanceTimeSlotsLeft)
      ) ?? []
    );
  }

  get canSelectTimeWantedType(): boolean {
    return this.timeWantedTypes.length > 1 || this.timeWantedTypes[0]?.type === 'Advance';
  }

  get timeWantedValue(): string {
    if (isAdvance(this.criteria)) {
      return this.criteria.timeWanted
        ? this.mwcIntl.relativeDateTime(this.criteria.timeWanted)
        : this.mwcIntl.intl.t('mwc.orderCriteria.unknownTimeText');
    }

    return this.timeWantedTypes.find(tw => tw.type === this.criteria.timeWantedType)?.label ?? '';
  }

  get deliveryAddressValue(): string {
    return isDelivery(this.criteria) && isValid(this.criteria.deliveryAddress)
      ? toLabel(this.criteria.deliveryAddress)
      : '';
  }

  @computed('criteria.deliveryAddress.{city,streetAddress,zipCode}')
  get isAddressValid(): boolean {
    return isDelivery(this.criteria) && isValid(this.criteria.deliveryAddress);
  }

  @computed('addressForm.streetAddress', 'addressForm.city', 'addressForm.zipCode')
  get isAddressFormValid(): boolean {
    return isValid(this.addressForm);
  }

  get searchAddress(): string {
    return isAtStore(this.criteria) ? this.criteria.searchAddress : '';
  }

  set searchAddress(address) {
    if (isAtStore(this.criteria)) {
      delete this.criteria.searchCoords;
    }
    updateOrderCriteria(this, 'criteria', {
      searchAddress: address,
    });
  }

  get isDelivery(): boolean {
    return isDelivery(this.criteria);
  }

  get isSubmitDisabled() {
    return isDelivery(this.criteria)
      ? !this.isAddressValid
      : isAtStore(this.criteria)
      ? isEmpty(this.criteria.searchAddress)
      : true;
  }

  get searchLocationErrorMessages(): ValidationMessage[] {
    const messages: ValidationMessage[] = [];

    if (this.locationError) {
      messages.push({ message: this.locationError, target: 'searchAddress' });
    }

    return messages;
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
    this.criteria = {
      ...this.orderCriteria.defaultOrderCriteria,
    };

    this.initialHandoffMode = this.criteria.handoffMode;
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  handleSubmit(e: Event) {
    e.preventDefault();
    if (!this.isSubmitDisabled) {
      if (this.criteria.handoffMode !== this.initialHandoffMode) {
        this.analytics.trackEvent(AnalyticsEvents.ChangeHandoffModeHomePage, () => ({
          [AnalyticsProperties.HandoffDefaultSlug]: this.initialHandoffMode,
          ...orderCriteriaProperties(
            this.handoffModes,
            this.timeWantedTypes,
            this.timeWantedMode,
            this.selectedHandoffModeModel
          ),
        }));
      }
    } else if (this.isSubmitDisabled) {
      this.validateSearchLocation();
    }
    this.features.updateUser();
    this.args.onSubmit(this.criteria, this.isSubmitDisabled, this.getLocationSearchType());
  }

  getLocationSearchType() {
    if (isDelivery(this.criteria)) return 'Multi Field';
    return this.isNearByVendorSearch ? 'Browser Geo' : 'Single Field';
  }

  @action
  validateSearchLocation() {
    this.analytics.trackEvent(AnalyticsEvents.EnteredLocation, () => ({
      [AnalyticsProperties.NewText]: this.searchAddress,
    }));
    const noSearchAddressForNonDeliveryhandoff = !this.searchAddress && !isDelivery(this.criteria);
    const noDeliveryAddressForDeliveryHandoff =
      isDelivery(this.criteria) &&
      !this.criteria.deliveryAddress?.streetAddress &&
      !this.criteria.deliveryAddress?.building &&
      !this.criteria.deliveryAddress?.city &&
      !this.criteria.deliveryAddress?.zipCode;

    this.locationError =
      noSearchAddressForNonDeliveryhandoff || noDeliveryAddressForDeliveryHandoff
        ? 'Please enter your location to start an order.'
        : undefined;
  }

  @action
  onSearchLocationFocus() {
    this.analytics.trackEvent(AnalyticsEvents.EditLocationIntent);
  }

  @action
  handleTimeWantedOpen(openEditor: OpenEditor) {
    this.timeWantedMode = this.timeWantedTypes.find(tw => tw.type === this.criteria.timeWantedType);
    if (isAdvance(this.criteria)) {
      this.timeWanted = this.criteria.timeWanted;
    }
    openEditor();
  }

  @action
  handleTimeWantedClose(closeEditor: CloseEditor) {
    closeEditor();
  }

  @action
  handleTimeWantedApply(closeEditor: CloseEditor) {
    if (this.timeWantedMode) {
      updateOrderCriteria(this, 'criteria', {
        timeWantedType: this.timeWantedMode.type,
        timeWanted: this.timeWanted,
      });

      this.analytics.trackEvent(AnalyticsEvents.ChangeWhenSelection, () =>
        orderCriteriaProperties(
          undefined,
          this.timeWantedTypes,
          this.timeWantedMode,
          undefined,
          undefined,
          this.timeWanted
        )
      );
    }
    closeEditor();
  }

  @action
  changeTimeWantedMode(timeWantedMode: TimeWantedMode) {
    this.timeWantedMode = timeWantedMode;
  }

  @action
  changeTimeWanted(timeWanted: Dayjs) {
    this.timeWanted = timeWanted;
  }

  @action
  handleAddressOpen(openEditor: OpenEditor) {
    const currentValue: Partial<AddressForm.Model> =
      isDelivery(this.criteria) && this.criteria.deliveryAddress
        ? this.criteria.deliveryAddress
        : {};
    const mode =
      isDelivery(this.criteria) && this.criteria.deliveryAddress?.id
        ? AddressForm.Mode.UseExisting
        : AddressForm.Mode.AddNew;
    // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
    this.addressForm = {
      id: currentValue.id || '',
      mode,
      streetAddress: currentValue.streetAddress || '',
      building: currentValue.building || '',
      city: currentValue.city || '',
      zipCode: currentValue.zipCode || '',
    };
    openEditor();
  }

  @action
  handleAddressClose(closeEditor: CloseEditor) {
    closeEditor();
  }

  @action
  async handleAddressConfirm(closeEditor: CloseEditor) {
    if (isDelivery(this.criteria)) {
      updateOrderCriteria(this, 'criteria', { deliveryAddress: this.addressForm });
      this.locationError = undefined;
    }
    closeEditor();
  }

  @action
  setCoords(coords: Coordinates) {
    if (isAtStore(this.criteria)) {
      updateOrderCriteria(this, 'criteria', {
        searchAddress: 'Your Current Location',
        searchCoords: coords,
      });
      this.validateSearchLocation();
    }
  }

  @action
  setCurrentLocationSelected() {
    this.analytics.trackEvent(AnalyticsEvents.AutoDetectLocation);
    this.geo.getGeoTask.perform(this.setCoords);
    this.isNearByVendorSearch = true;
    this.locationError = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VendorSearchForm: typeof VendorSearchForm;
  }
}
