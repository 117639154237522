import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { host } from 'mobile-web/lib/hybrid-util';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';

export default class VendorAdapter extends ApplicationAdapter {
  @service orderCriteria!: OrderCriteriaService;
  @service vendor!: VendorService;

  defaultParams(url: string, options?: { data?: object }): { data?: object } {
    return {
      ...options,
      data: /\/vendors\/\d+\/recentitems/i.test(url ?? '')
        ? options?.data
        : {
            handoffMode: this.orderCriteria.criteria.handoffMode,
            maxRecentItems: this.vendor.maxRecentItems,
            ...options?.data,
          },
    };
  }

  ajax(url: string, type: string, options?: { data?: object }): RSVP.Promise<any> {
    return super.ajax(url, type, this.defaultParams(url, options));
  }

  shouldBackgroundReloadRecord(_store: unknown, snapshot: DS.Snapshot<'vendor'>): boolean {
    /* Reload if there is a vendor record but not associated Menu Data */
    return isEmpty(snapshot.record.categories);
  }

  urlForQueryRecord(query: { slug: string }): string {
    return `${host()}/${this.namespace}/vendors/${query.slug}`;
  }

  // Strips out the slug from the query params
  sortQueryParams(): unknown[] {
    return [];
  }
}
