import 'array-flat-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import 'intersection-observer';

export function initialize() {
  smoothscroll.polyfill();
}

export default {
  name: 'polyfill',
  initialize,
};
