export default {
  "root": "_root_16lwz1",
  "carousel": "_carousel_16lwz1",
  "carouselItemsCentered": "_carouselItemsCentered_16lwz1",
  "header": "_header_16lwz1",
  "headings": "_headings_16lwz1",
  "heading": "_heading_16lwz1",
  "hiddenHeading": "_hiddenHeading_16lwz1",
  "subheading": "_subheading_16lwz1",
  "viewAllLink": "_viewAllLink_16lwz1",
  "description": "_description_16lwz1",
  "carouselContainer": "_carouselContainer_16lwz1",
  "scrollButtonContainer": "_scrollButtonContainer_16lwz1",
  "scrollLeftButtonContainer": "_scrollLeftButtonContainer_16lwz1 _scrollButtonContainer_16lwz1",
  "scrollRightButtonContainer": "_scrollRightButtonContainer_16lwz1 _scrollButtonContainer_16lwz1",
  "scrollButton": "_scrollButton_16lwz1",
  "hover": "_hover_16lwz1",
  "icon": "_icon_16lwz1",
  "item": "_item_16lwz1"
};
