import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import groupOrderRoute from 'mobile-web/decorators/group-order-route';
import Vendor from 'mobile-web/models/vendor';
import GroupOrderService from 'mobile-web/services/group-order';
import VendorService from 'mobile-web/services/vendor';

@groupOrderRoute
export default class GroupOrderStartRoute extends Route {
  @service groupOrder!: GroupOrderService;
  @service router!: RouterService;
  @service vendor!: VendorService;

  queryParams = {
    slug: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (this.groupOrder.groupOrderId) {
      // If there's a group order, there's a basket, and thus a vendor
      this.router.replaceWith('menu.vendor', this.vendor.vendor!.slug);
    }
  }

  async model(params: { slug?: string }) {
    const vendor = params.slug ? await this.vendor.ensureVendorLoaded(params.slug) : undefined;
    return {
      /**
       * We cast here to remove undefined so that the template knows that we
       * will always have a vendor, since in the {@link afterModel} we redirect
       * away if the vendor is undefined.
       */
      vendor: vendor as Vendor,
    };
  }

  afterModel(model: ModelForRoute<this>) {
    if (!model.vendor) {
      this.router.replaceWith('index');
    }
  }
}
