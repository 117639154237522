import DS from 'ember-data';
import RSVP from 'rsvp';

import { memberAction as createMemberAction } from 'ember-api-actions';
import { InstanceOperationOptions } from 'ember-api-actions/utils/member-action';

export type MemberAction<IN = void, OUT = unknown> = (args: IN) => RSVP.Promise<OUT>;

type Options<IN, OUT> = Omit<InstanceOperationOptions<IN, OUT>, 'path' | 'urlType'> & {
  // We sometimes ignore path and use urlType to build custom URLs
  path?: string;
  urlType?: string;
};

const memberAction: <IN = unknown, OUT = unknown>(
  options: Options<IN, OUT>
) => PropertyDecorator = options => (target: Prototype<typeof DS.Model>, key: string) => {
  if (!options.path) {
    options.path = '';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const action = createMemberAction(options as any);
  Object.defineProperty(target, key, {
    get() {
      return action;
    },
  });
  return {};
};

export default memberAction;
