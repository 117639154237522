import Helper from '@ember/component/helper';
import { later } from '@ember/runloop';

import ENV from 'mobile-web/config/environment';
import dayjs from 'mobile-web/lib/dayjs';

type Named = {
  interval?: number;
};

interface Signature {
  Args: {
    Named: Named;
  };
  Return: dayjs.Dayjs;
}

export default class LiveUtc extends Helper<Signature> {
  compute(_positional: unknown[], named: Named): Signature['Return'] {
    if (ENV.environment !== 'test') {
      later(() => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.recompute();
        }
      }, named.interval ?? 1000);
    }
    return dayjs.utc();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'live-utc': typeof LiveUtc;
  }
}
