import Service, { inject as service } from '@ember/service';

import ChannelService from './channel';
import OnPremiseService, { ON_PREMISE_JUNK_PHONE_NUMBER } from './on-premise';
import SessionService from './session';

export enum DineInRequirePhoneNumber {
  HIDDEN = 0,
  OPTIONAL = 1,
  REQUIRED = 2,
}

export default class ContactService extends Service {
  // Service injections
  @service channel!: ChannelService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;

  // Untracked properties

  // Tracked properties

  // Getters and setters - phone number
  get phoneNumberRequired(): boolean {
    if (this.isOnPrem && this.session.isLoggedIn) {
      return this.requirePhone || this.dineInRequirePhone === DineInRequirePhoneNumber.REQUIRED;
    } else if (this.isOnPrem) {
      return this.dineInRequirePhone === DineInRequirePhoneNumber.REQUIRED;
    }
    return this.requirePhone;
  }

  get phoneNumberHiddenForDineIn(): boolean {
    return this.isOnPrem && this.dineInRequirePhone === DineInRequirePhoneNumber.HIDDEN;
  }

  get phoneNumberHiddenForOffPrem() {
    return this.channel.settings?.showPhoneNumberIfNotRequired;
  }

  // Getters and setters - email address
  get emailAddressIsVisible(): boolean {
    return this.onPremise.isPayAtTable || (this.isOnPrem && !this.session.user?.emailAddress);
  }

  get emailRequired(): boolean {
    if (this.isOnPrem) {
      return this.dineInRequireEmail;
    }
    return true;
  }

  private get requirePhone(): boolean {
    return !!this.channel.settings?.requirePhoneNumber;
  }
  private get dineInRequirePhone() {
    return this.channel.settings?.dineInRequirePhoneNumber;
  }
  private get isOnPrem(): boolean {
    return this.onPremise.isEnabled;
  }
  private get dineInRequireEmail() {
    return this.channel.settings?.dineInRequireEmail ?? false;
  }

  // Constructor

  // Other methods
  public getPhoneNumberDefault(customerEntry: string): string {
    if (!this.isOnPrem) return customerEntry;

    const returnPhoneDefault = this.requirePhone ? ON_PREMISE_JUNK_PHONE_NUMBER : '';

    if (this.dineInRequirePhone === DineInRequirePhoneNumber.HIDDEN) {
      return returnPhoneDefault;
    } else if (this.dineInRequirePhone === DineInRequirePhoneNumber.OPTIONAL) {
      return customerEntry ? customerEntry : returnPhoneDefault;
    }
    return customerEntry;
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    contact: ContactService;
  }
}
