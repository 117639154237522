import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import IntlService from 'ember-intl/services/intl';

import { RecentOrder } from 'mobile-web/models/basket';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService, { ON_PREMISE_JUNK_FIRST_NAME } from 'mobile-web/services/on-premise';
import OrderItAgainService from 'mobile-web/services/order-it-again';
import ReorderService from 'mobile-web/services/reorder';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

export default class OrderItAgain extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service intl!: IntlService;
  @service storage!: StorageService;
  @service reorder!: ReorderService;
  @service basket!: BasketService;
  @service orderItAgain!: OrderItAgainService;
  @service onPremise!: OnPremiseService;
  @service groupOrder!: GroupOrderService;
  @service session!: SessionService;

  // Untracked properties
  style = style;
  itemLimit = 3;

  // Tracked properties

  constructor(owner: unknown, args: UnknownObject) {
    super(owner, args);

    this.didRecentOrderUpdated();
  }

  // Getters and setters
  get showOrderItAgain(): boolean {
    // hasRecenntOrder unnecssary here, because we checked when we set value of orderItAgainCohort
    if (this.setOrderItAgain) {
      if (this.session.trackVendorSlug !== this.orderItAgain.currentVendorStorage?.slug) {
        this.orderItAgain.orderItAgainAnalytics();
      }
      return true;
    }
    return false;
  }

  get setOrderItAgain(): boolean {
    const hasRecentOrder = !!this.recentOrder;

    return (
      !this.groupOrder.hasGroupOrder &&
      !this.onPremise.isEnabled &&
      hasRecentOrder &&
      this.orderItAgain.isInExperiment &&
      !this.storage.orderItAgainClosed
    );
  }

  get firstName(): string | undefined {
    return this.recentOrder?.firstName === ON_PREMISE_JUNK_FIRST_NAME
      ? undefined
      : this.recentOrder?.firstName;
  }

  get recentOrder(): RecentOrder | undefined {
    return this.orderItAgain.currentVendorRecentOrder;
  }

  get hiddenItemAmount(): number {
    if (!this.recentOrder) return 0;

    return this.recentOrder.basketProducts.length - this.itemLimit;
  }

  get removedItems(): boolean {
    return !isEmpty(this.reorder.reorderBasket?.failures);
  }

  get moreItemsMessage() {
    return this.intl.t('mwc.orderItAgain.moreItemsMessage', {
      itemAmount: this.hiddenItemAmount,
      plural: this.hiddenItemAmount > 1 ? 's' : '',
    });
  }
  // Lifecycle methods

  // Other methods

  // Tasks
  publishMixPanelAnalytics() {
    if (this.reorder.reorderBasket) {
      if (this.removedItems) {
        const currentDate = dayjs();
        const unavailableProductAmount = this.reorder.reorderBasket.failures.length;
        const recentOrderDateDiff = currentDate.diff(this.recentOrder?.datetime, 'd', true);

        this.analytics.trackEvent(AnalyticsEvents.OrderItAgainUnavailableItem, () => ({
          [AnalyticsProperties.NumberOfUnavailableItems]: unavailableProductAmount,
          [AnalyticsProperties.DaysSinceLastOrder]: recentOrderDateDiff.toFixed(2),
        }));
      }
    }
  }

  // Actions and helpers
  @action
  close() {
    this.storage.orderItAgainClosed = true;

    this.analytics.trackEvent(AnalyticsEvents.OrderItAgainDismiss);
  }

  @action
  async addToCart() {
    if (this.reorder.reorderBasket) {
      await this.basket.activateAndReplace(this.reorder.reorderBasket);
      this.analytics.trackEvent(AnalyticsEvents.OrderItAgainAddToCart, () => ({
        [AnalyticsProperties.HasUnavailableProducts]: this.removedItems,
      }));
    }
  }

  @action
  async addToCartAndCheckout() {
    if (this.reorder.reorderBasket) {
      await this.basket.activateAndReplace(this.reorder.reorderBasket, true);
      this.analytics.trackEvent(AnalyticsEvents.OrderItAgainAddAndCheckout, () => ({
        [AnalyticsProperties.HasUnavailableProducts]: this.removedItems,
      }));
    }
  }

  @action
  async didRecentOrderUpdated() {
    const basketVendor = this.basket.vendor.vendorSlug;
    const reorderBasket = this.reorder.reorderBasket?.vendor.get('slug');
    if (!this.orderItAgain.isInExperiment) {
      return;
    }
    if (!this.reorder.reorderBasket || basketVendor !== reorderBasket) {
      if (this.orderItAgain.currentVendorOrderId) {
        const basket = await this.reorder.reorderValidate(
          this.orderItAgain.currentVendorOrderId,
          'OrderAgain'
        );
        this.reorder.setReorderBasket(basket);
        this.publishMixPanelAnalytics();
        this.session.trackVendorSlug = this.orderItAgain.currentVendorStorage?.slug;
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrderItAgain: typeof OrderItAgain;
  }
}
