import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Customer, UserData } from 'mobile-web/lib/customer';
import { OloAuthChallengeError } from 'mobile-web/lib/olo-auth';
import { isDineInJunkEmail } from 'mobile-web/lib/on-premise';
import isSome from 'mobile-web/lib/utilities/is-some';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BootstrapService from 'mobile-web/services/bootstrap';
import OnPremiseService, {
  ON_PREMISE_JUNK_LAST_NAME,
  ON_PREMISE_JUNK_PHONE_NUMBER,
} from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import WindowService from 'mobile-web/services/window';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onChange: (user: UserData) => void;

  // Optional arguments
  challengeError?: OloAuthChallengeError;
  customer?: Customer;
  editContactDisabled?: boolean;
  errorMessage?: string;
  externalEditUrl?: string;
  headerKey?: string;
  isOloAuthLogin?: boolean;
  isEditable?: boolean;
  isFullyEditable?: boolean;
  isOptInVisible?: boolean;
  onChallengeChange?: (token: string) => void;
  onChallengeClose?: () => void;
  showOloAuthChallenge?: boolean;
}

interface Signature {
  Args: Args;

  Blocks: {
    payAtTableName: [];
    payAtTableEmail: [];
  };
}

export default class ContactInfo extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;
  @service window!: WindowService;
  @service bootstrap!: BootstrapService;
  @service router!: RouterService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked showModal = false;

  // Getters and setters
  get headerKey() {
    return this.args.headerKey ?? 'mwc.contactInfo.header';
  }

  get isEditable() {
    if (this.onPremise.isEnabled) {
      return false;
    }
    return this.args.isEditable ?? true;
  }

  get showFields() {
    return !(this.onPremise.isPayAtTable && !this.session.isLoggedIn);
  }

  get lastName() {
    const customer = this.args.customer;
    if (!customer) {
      return '';
    }
    return customer.lastName === ON_PREMISE_JUNK_LAST_NAME ? '' : customer.lastName;
  }

  get emailAddress() {
    const customer = this.args.customer;
    if (
      !customer ||
      (customer && isDineInJunkEmail(customer.emailAddress)) ||
      this.isCallcenterEmail(customer.emailAddress)
    ) {
      return '';
    }
    return customer.emailAddress;
  }

  get contactNumber() {
    const customer = this.args.customer;
    if (!customer) {
      return '';
    }
    return customer.contactNumber === ON_PREMISE_JUNK_PHONE_NUMBER ? '' : customer.contactNumber;
  }

  get onEdit(): undefined | Action {
    if (this.basket.basket?.isCallcenterOrder) {
      return undefined;
    }
    if (!this.isEditable) {
      return undefined;
    }
    return this.edit;
  }

  get showMarketingCheckbox(): boolean {
    // show the marketing opt-in checkbox if...
    // On the checkout route and either
    // 1. The user is a guest, in Pay @ Table
    // 2. The user is a guest, and channel is configured for OloAuth
    //    (they are shown an OloAuth account creation box on Guest Checkout Form;
    //     Checkout is where they indicate marketing preference)
    // 3. The updateOptInValue is true

    return (
      this.router.currentRouteName === 'checkout' &&
      ((!this.session.isLoggedIn &&
        (this.onPremise.isPayAtTable || this.bootstrap.data?.hasOloAuthProvider)) ||
        this.session?.updateOptInValue)
    );
  }

  // Lifecycle methods

  // Other methods
  isCallcenterEmail(email: string): boolean {
    return /^callcenter\+.*@olo\.com$/.test(email);
  }

  // Tasks

  // Actions and helpers
  @action
  openModal() {
    this.showModal = true;
  }

  @action
  closeModal() {
    this.showModal = false;
  }

  @action
  edit() {
    this.analytics.trackEvent(
      AnalyticsEvents.EditContactInfo,
      () => ({
        [AnalyticsProperties.ExternalEditUrl]: this.args.externalEditUrl,
        [AnalyticsProperties.IsOloAuth]: this.args.isOloAuthLogin,
      }),
      { bucket: 'all' }
    );
    if (isSome(this.args.externalEditUrl)) {
      this.window.location().assign(this.args.externalEditUrl);
    } else {
      this.openModal();
    }
  }

  @action
  handleChange(user: UserData) {
    this.args.onChange(user);
    this.closeModal();
  }

  @action
  handleChallengeChange(token: string) {
    this.args.onChallengeChange?.(token);
    this.closeModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ContactInfo: typeof ContactInfo;
  }
}
