import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import BasketModel, { OnPremiseDetailsPayload } from 'mobile-web/models/basket';
import OrderModel from 'mobile-web/models/order';
import TicketModel, { TicketItem } from 'mobile-web/models/ticket';
import OnPremiseService from 'mobile-web/services/on-premise';
import SplitCheckService from 'mobile-web/services/split-check';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  ticket: TicketModel;
  basket?: BasketModel;
  order?: OrderModel;
  showSubtotal?: boolean;
  itemLimit?: number;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class OnPremiseTicketContents extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service splitCheck!: SplitCheckService;

  // Untracked properties
  style = style;
  maxItemListLength = this.args.itemLimit ?? this.args.ticket.items.length;

  // Tracked properties

  // Getters and setters

  get itemsToShow(): TicketItem[] {
    let ticketItems = this.args.ticket.items;

    const splitItems = this.splitCheck.currentStatus?.splitItems;
    if (splitItems && splitItems.length > 0) {
      ticketItems = ticketItems.filter(t => splitItems.includes(t.id));
    }

    return ticketItems.length <= this.maxItemListLength
      ? ticketItems
      : ticketItems.slice(0, this.maxItemListLength);
  }

  get hiddenItemAmount(): number {
    const ticketItems = this.args.ticket.items?.length;
    return ticketItems <= this.maxItemListLength ? 0 : ticketItems - this.maxItemListLength;
  }

  get onPremiseDetails(): OnPremiseDetailsPayload {
    return (
      this.args.order?.onPremiseDetails ??
      this.args.basket?.onPremiseDetails ?? {
        experienceType: OnPremiseExperience.PayAtTable,
      }
    );
  }

  get payments(): Array<{ name: string; amount: number }> {
    return this.splitCheck.statuses
      .filter(s => s.isOrderPlaced && s.firstName)
      .map(s => ({ name: s.firstName!, amount: s.subTotal + s.salesTax }));
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.router.currentRouteName === 'checkout' && this.args.ticket.totals.due === 0) {
      this.onPremise.handlePaidCheck();
    }
  }
  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OnPremise::TicketContents': typeof OnPremiseTicketContents;
  }
}
