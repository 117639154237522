/**
 * Adapted from https://github.com/ember-a11y/a11y-announcer/blob/v2.0.0/addon/components/route-announcer.js
 */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import AnnouncerService from 'mobile-web/services/announcer';

import style from './index.m.scss';

export default class RouteAnnouncer extends Component {
  @service announcer!: AnnouncerService;

  style = style;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    RouteAnnouncer: typeof RouteAnnouncer;
  }
}
