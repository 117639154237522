export default {
  "root": "_root_q5ed54",
  "headerWrapper": "_headerWrapper_q5ed54",
  "mainHeader": "_mainHeader_q5ed54",
  "subHeader": "_subHeader_q5ed54",
  "formContainer": "_formContainer_q5ed54",
  "form": "_form_q5ed54",
  "linkContainer": "_linkContainer_q5ed54",
  "link": "_link_q5ed54"
};
