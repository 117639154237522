export default {
  "loadingIcon": "_loadingIcon_1vayao",
  "removeButton": "_removeButton_1vayao",
  "removeIcon": "_removeIcon_1vayao",
  "choiceList": "_choiceList_1vayao",
  "quantityLabel": "_quantityLabel_1vayao",
  "priceLabel": "_priceLabel_1vayao",
  "toggleChoicesButton": "_toggleChoicesButton_1vayao",
  "extraInfo": "_extraInfo_1vayao",
  "editable": "_editable_1vayao",
  "root": "_root_1vayao",
  "isCart": "_isCart_1vayao",
  "quantity": "_quantity_1vayao",
  "name": "_name_1vayao",
  "details": "_details_1vayao",
  "price": "_price_1vayao",
  "remove": "_remove_1vayao"
};
