import Service, { inject as service } from '@ember/service';

import ScrollService from 'mobile-web/services/scroll';

export enum CategoryName {
  OptionGroupError = 'option-group-error',
}

export interface FocusData {
  /**
   * The target to focus
   */
  readonly target?: HTMLElement;
  /**
   * Whether the target may receive focus
   */
  allowFocus?(): boolean;
  /**
   * A callback when the target receives focus
   */
  onFocus?(): void;
}

export type TargetOrFocusData = HTMLElement | FocusData;

type Category = Set<TargetOrFocusData>;

/**
 * The FocusManagerService manages focus across collections of components.
 *
 * Components can be registered with the service in categories, and the service
 * is able to focus the first or last component within the category.
 *
 * @example
 * // form field component:
 * this.focusManager.register('invalid-field', {
 *   target: this.input,
 *   allowFocus: () => this.invalid,
 * });
 *
 * // form submit handler:
 * if (this.hasError) {
 *   this.focusManager.focusFirst('invalid-field');
 * }
 */
export default class FocusManagerService extends Service {
  // Service injections
  @service scroll!: ScrollService;

  // Untracked properties
  private categories = new Map<string, Category>();

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  /**
   * Register a focus target for a specific category
   *
   * @param categoryName The name of the category in which the target should be
   * registered.
   * @param targetOrFocusData The element to focus or an object with additional
   * focus options.
   */
  register(categoryName: string, targetOrFocusData: TargetOrFocusData): void {
    const category = this.getCategory(categoryName);
    category.add(targetOrFocusData);
  }

  /**
   * Deregister a focus target from a specific category
   *
   * @param categoryName The name of the category in which the target should be
   * deregistered.
   * @param targetOrFocusData The element or object that was previously
   * registered.
   */
  deregister(categoryName: string, targetOrFocusData: TargetOrFocusData): void {
    const category = this.getCategory(categoryName);
    category.delete(targetOrFocusData);
  }

  /**
   * Focus the first registered target that is able to receive focus.
   *
   * @param categoryName The name of the category to focus.
   */
  focusFirst(categoryName: string): void {
    const category = this.getCategory(categoryName);
    for (const targetOrFocusData of category) {
      if (this.tryFocus(targetOrFocusData)) {
        break;
      }
    }
  }

  /**
   * Focus the last registered target that is able to receive focus.
   *
   * @param categoryName The name of the category to focus.
   */
  focusLast(categoryName: string): void {
    const category = [...this.getCategory(categoryName)].reverse();
    for (const targetOrFocusData of category) {
      if (this.tryFocus(targetOrFocusData)) {
        break;
      }
    }
  }

  /**
   * Focus an element without automatically scrolling the element into view.
   *
   * @param el the element to focus
   *
   * @todo This needs tests
   */
  focusWithoutScroll(el: HTMLElement): void {
    if (FocusManagerService.canPreventScroll) {
      el.focus({
        preventScroll: true,
      });
    } else {
      // Safari, iOS, and older browsers
      const scrollElement = this.scroll.getScrollParent(el);
      const scrollTop = scrollElement?.scrollTop ?? 0;
      el.focus();
      if (scrollElement) scrollElement.scrollTop = scrollTop;
    }
  }

  /**
   * Focus an element and smooth scroll to the focused element.
   *
   * This method also takes into account user animation preferences.
   *
   * @param el the element to focus
   *
   * @todo This needs tests
   */
  focusWithScroll(el: HTMLElement): void {
    this.focusWithoutScroll(el);

    const behavior = matchMedia('(prefers-reduced-motion)').matches ? 'auto' : 'smooth';

    el.scrollIntoView({
      behavior,
    });
  }

  private getCategory(categoryName: string): Category {
    const category = this.categories.get(categoryName) ?? new Set();
    if (!this.categories.has(categoryName)) {
      this.categories.set(categoryName, category);
    }
    return category;
  }

  private tryFocus(targetOrFocusData: TargetOrFocusData): boolean {
    if (targetOrFocusData instanceof HTMLElement) {
      this.focusWithScroll(targetOrFocusData);
      return true;
    }

    const data = targetOrFocusData;

    if (!(data.allowFocus?.() ?? true)) {
      return false;
    }

    if (data.target) {
      this.focusWithScroll(data.target);
    }

    data.onFocus?.();

    return true;
  }

  // Tasks

  // Actions and helpers

  // Statics
  /**
   * Indicates browser support for the `preventScroll` focus parameter
   */
  private static canPreventScroll: boolean;

  static {
    const d = document.createElement('div');
    FocusManagerService.canPreventScroll = false;
    d.focus({
      get preventScroll() {
        FocusManagerService.canPreventScroll = true;
        return true;
      },
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'focus-manager': FocusManagerService;
  }
}
