export default {
  "root": "_root_15oyo0",
  "icon": "_icon_15oyo0",
  "inprogress": "_inprogress_15oyo0",
  "submitted": "_submitted_15oyo0",
  "label": "_label_15oyo0",
  "count": "_count_15oyo0",
  "text-300": "_text-300_15oyo0",
  "text-400": "_text-400_15oyo0",
  "text-500": "_text-500_15oyo0",
  "text-600": "_text-600_15oyo0",
  "text-700": "_text-700_15oyo0",
  "heading-300": "_heading-300_15oyo0",
  "heading-400": "_heading-400_15oyo0",
  "heading-500": "_heading-500_15oyo0",
  "heading-600": "_heading-600_15oyo0",
  "heading-700": "_heading-700_15oyo0"
};
