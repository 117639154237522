export default {
  "imageCard": "_imageCard_oimw80",
  "header": "_header_oimw80",
  "heading": "_heading_oimw80",
  "vendorProperties": "_vendorProperties_oimw80",
  "crossStreet": "_crossStreet_oimw80",
  "vendorLinks": "_vendorLinks_oimw80",
  "vendorDistance": "_vendorDistance_oimw80",
  "inlineLinkContainer": "_inlineLinkContainer_oimw80",
  "placeIcon": "_placeIcon_oimw80",
  "mapLink": "_mapLink_oimw80",
  "info": "_info_oimw80",
  "infoButton": "_infoButton_oimw80",
  "outerLinkContainer": "_outerLinkContainer_oimw80",
  "availability": "_availability_oimw80",
  "timezoneWarning": "_timezoneWarning_oimw80",
  "favoriteButton": "_favoriteButton_oimw80",
  "vendorMinTags": "_vendorMinTags_oimw80",
  "vendorMinTag": "_vendorMinTag_oimw80"
};
