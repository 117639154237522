import DS from 'ember-data';

import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import { CustomField } from 'mobile-web/lib/custom-field';
import { Provider } from 'mobile-web/lib/payment';
import ErrorService from 'mobile-web/services/error';

import BillingMembership from './billing-membership';

/**
 * We are setting a very high default value here, since it should be overwritten
 * by the back end, and if it isn't it'll never actually be hit by the user
 */
const UPPER_LIMIT = 1000;
const LOWER_LIMIT = 0;

export enum BILLING_SCHEME_ID {
  CREDIT_CARD = '1',
  CASH = '2',
}

export type BalancePayload = {
  cardNumber: string;
  pin?: string;
};

export enum BalanceCode {
  Success = 'Success',
  CannotCheck = 'CannotCheck',
  MissingPin = 'MissingPin',
  InvalidPin = 'InvalidPin',
}

export type BalanceResponse = {
  code: BalanceCode;
  balance?: number;
};

export type PinRequiredPayload = {
  cardNumber: string;
  billingSchemeId: string;
};

export type PinRequiredResponse = {
  required: boolean;
  isGiftCard: boolean;
};

export type CustomSetting = {
  name: string;
  value: string;
};

export default class BillingScheme extends DS.Model {
  @DS.attr('string')
  name!: string;
  @DS.attr('string')
  description!: string;
  @DS.attr('boolean')
  isGiftCard?: boolean;
  @DS.attr('boolean')
  isBrandedCard?: boolean;
  @DS.attr('boolean')
  isHouseAccount?: boolean;
  @DS.attr('boolean')
  allowsPin?: boolean;
  @DS.attr('number', { defaultValue: LOWER_LIMIT })
  pinLengthLowerLimit!: number;
  @DS.attr('number', { defaultValue: UPPER_LIMIT })
  pinLengthUpperLimit!: number;
  @DS.attr('number', { defaultValue: LOWER_LIMIT })
  brandedCardSecurityCodeLengthLowerLimit!: number;
  @DS.attr('number', { defaultValue: UPPER_LIMIT })
  brandedCardSecurityCodeLengthUpperLimit!: number;
  @DS.attr('number', { defaultValue: LOWER_LIMIT })
  accountNumberLengthLowerLimit!: number;
  @DS.attr('number', { defaultValue: UPPER_LIMIT })
  accountNumberLengthUpperLimit!: number;
  @DS.attr('string')
  provider!: Provider;
  @DS.attr()
  customFieldValues?: CustomField[];
  @DS.attr()
  customSettings?: CustomSetting[];

  @DS.hasMany('billing-membership', { async: false })
  memberships!: DS.ManyArray<BillingMembership>;

  @memberAction<BalancePayload, BalanceResponse>({
    type: 'post',
    // @ts-ignore - we are intentionally using a fake type to not conflict with other adapter uses
    urlType: 'balance',
  })
  private _getBalance!: MemberAction<BalancePayload, BalanceResponse>;

  @memberAction<PinRequiredPayload, PinRequiredResponse>({
    type: 'post',
    // @ts-ignore - we are intentionally using a fake type to not conflict with other adapter uses
    urlType: 'pinRequired',
  })
  getPinIsRequired!: MemberAction<PinRequiredPayload, PinRequiredResponse>;

  get singleUseOnly(): boolean {
    return (
      this.provider === Provider.LevelUp ||
      this.provider === Provider.PassThrough ||
      this.provider === Provider.HouseAccount
    );
  }

  getBalance(payload: BalancePayload): Promise<BalanceResponse> {
    return this._getBalance(payload).catch(err => {
      if (ErrorService.isCloudflareChallenge(err)) {
        throw err;
      }
      return { code: BalanceCode.CannotCheck };
    });
  }
}
