import { computed } from '@ember/object';
import DS from 'ember-data';

import OrderModel from 'mobile-web/models/order';

const { attr, belongsTo, Model } = DS;

export enum State {
  OrderReceived = 'OrderReceived',
  OrderError = 'OrderError',
  PickupInProgress = 'PickupInProgress',
  PickupError = 'PickupError',
  PickupSucceeded = 'PickupSucceeded',
  DeliveryError = 'DeliveryError',
  DeliverySucceeded = 'DeliverySucceeded',
  ReturnInProgress = 'ReturnInProgress',
  ReturnCompleted = 'ReturnCompleted',
}

export enum CourierStatus {
  Active = 'Active',
  Completed = 'Completed',
  Error = 'Error',
}

const ERROR_STATES: State[] = [State.OrderError, State.PickupError, State.DeliveryError];

type IsDelivered = (state: State) => boolean;
export const isDelivered: IsDelivered = state => state === State.DeliverySucceeded;

type IsError = (state: State) => boolean;
export const isError: IsError = state => ERROR_STATES.includes(state);

export const DispatchState = {
  isDelivered,
  isError,
};

export enum Event {
  StartPickup = 'StartPickup',
  FailPickup = 'FailPickup',
  SucceedPickup = 'SucceedPickup',
  FailDelivery = 'FailDelivery',
  SucceedDelivery = 'SucceedDelivery',
}

export default class DispatchStatusModel extends Model {
  @attr('string')
  deliveryService!: string;
  @attr('string')
  status!: State;
  @attr('string')
  statusDescription!: string;
  @attr('string')
  driverName!: string;
  @attr('date')
  estimatedDropoffTimeLocal!: Date;
  @attr('date')
  estimatedPickupTimeLocal!: Date;
  @DS.attr('number')
  latitude?: number;
  @DS.attr('number')
  longitude?: number;
  @attr('string')
  driverPhoneNumber!: string;

  // Every time we load this, we already have the matching order
  // in the store, so this relationship should always be present.
  @belongsTo('order', { async: false })
  order!: OrderModel;

  @computed('status')
  get courierStatus(): CourierStatus {
    switch (this.status) {
      case State.OrderReceived:
      case State.PickupInProgress:
      case State.PickupSucceeded:
      case State.ReturnInProgress:
        return CourierStatus.Active;
      case State.DeliverySucceeded:
        return CourierStatus.Completed;
      default:
        return CourierStatus.Error;
    }
  }
}
