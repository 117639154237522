import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  class?: string;
  contentClass?: string;
  title?: string;
}

export interface ModalSectionSignature {
  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class ModalSection extends Component<ModalSectionSignature> {
  style = style;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'modal-section': typeof ModalSection;
  }
}
