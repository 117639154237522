import RouterService from '@ember/routing/router-service';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';
import { MediaService } from 'ember-responsive';

import { local, session } from 'mobile-web/decorators/storage';
import { UserData } from 'mobile-web/lib/customer';
import dayjs from 'mobile-web/lib/dayjs';
import {
  keepPastOrders,
  closedCheckData,
  ClosedCheckData,
  OnPremiseExperience,
} from 'mobile-web/lib/on-premise';
import { OrderCriteria } from 'mobile-web/lib/order-criteria';
import BasketModel from 'mobile-web/models/basket';
import Ticket from 'mobile-web/models/ticket';
import TicketModel from 'mobile-web/models/ticket';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ErrorService from 'mobile-web/services/error';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

import ChannelService from './channel';
import ContactService from './contact';
import FeaturesService from './features';
import VendorService from './vendor';

export const generatedDineInEmailPrefix = 'dinein+';
export const generatedDineInEmailSuffix = '@olo.com';
export const getGeneratedDineInEmail = () =>
  generatedDineInEmailPrefix + dayjs().unix() + generatedDineInEmailSuffix;
export const ON_PREMISE_JUNK_FIRST_NAME = '-';
export const ON_PREMISE_JUNK_LAST_NAME = '-';
export const ON_PREMISE_JUNK_PHONE_NUMBER = '5055555555';

export default class OnPremiseService extends Service {
  // Service injections
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service contact!: ContactService;
  @service error!: ErrorService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service media!: MediaService;
  @service orderCriteria!: OrderCriteriaService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service storage!: StorageService;
  @service vendor!: VendorService;

  // Untracked properties

  // Tracked properties
  @tracked showPlaceOrderModal = false;
  /** Only exists to be set on thank-you page for a P@T order so we can tweak the header */
  @tracked isPayAtTableOrder?: boolean;
  @tracked payAtTableHasError = false;

  @local({ key: 'closedCheckData' }) closedCheck?: ClosedCheckData;
  @local({ key: 'tablePosRefData' }) tablePosRef?: string;
  @local private vendorSlugForOpenCheck?: string;

  @session openCheck?: boolean;
  @session openCheckRoundsOrdered?: number;
  @session openCheckRoundStarted?: boolean = false;
  @session payAtTable?: boolean;

  // Getters and setters
  get showTableNumber(): boolean {
    return (
      !this.isPayAtTable &&
      !!this.tablePosRef &&
      !this.features.flags['hide-user-unfriendly-table-number']
    );
  }

  get currentVendorSlug(): string {
    return this.closedCheck?.vendorSlug ?? this.vendorSlugForOpenCheck ?? '';
  }

  get isEnabled(): boolean {
    return !!this.closedCheck || this.openCheck === true || this.isPayAtTable;
  }

  get experienceType(): OnPremiseExperience {
    if (this.closedCheck) {
      return OnPremiseExperience.ClosedCheck;
    } else if (this.openCheck === true) {
      return OnPremiseExperience.OpenCheck;
    }

    return OnPremiseExperience.Default;
  }

  get hasOpenCheck(): boolean {
    return (
      this.basket.basket?.onPremiseDetails?.experienceType === OnPremiseExperience.OpenCheck ||
      this.openCheck === true
    );
  }

  get isOpenCheckReview(): boolean {
    return this.router.currentRouteName === 'open-check.review';
  }

  get pastClosedCheckOrdersExist(): boolean {
    return !!this.closedCheck?.pastOrders?.length;
  }

  get hideHeaderWhitespaceOnMobile(): boolean {
    return !!this.isEnabled && this.media.isMobile;
  }

  get hasOpenCheckWithUnsentProducts(): boolean {
    if (!this.openCheck || this.basket.basketProducts.length === 0) {
      return false;
    }

    return this.basket.openCheckUnsentBasketProducts.length > 0;
  }

  get isOpenCheckFirstRound(): boolean {
    return this.openCheck! && this.basket.openCheckSentBasketProducts.length === 0;
  }

  get isOpenCheckSubsequentRound(): boolean {
    return this.openCheck! && !this.isOpenCheckFirstRound;
  }

  get isPayAtTable(): boolean {
    return this.payAtTable || this.isPayAtTableOrder || false;
  }

  // Lifecycle methods

  // Other methods
  onBootstrapInit(): void {
    if (
      this.basket.basket?.onPremiseDetails?.experienceType === OnPremiseExperience.ClosedCheck &&
      !this.closedCheck &&
      this.vendor.vendorSlug
    ) {
      this.closedCheck = closedCheckData(this.vendor.vendorSlug);
    }

    if (this.closedCheck || this.openCheck) {
      if (this.router.currentURL === '/') {
        this.storage.showExitOnPremiseModal = true;
        return;
      }
    }

    if (this.closedCheck) {
      const data = this.closedCheck;

      const now = dayjs();
      const start = dayjs(data.start);
      if (start.add(24, 'hours').isBefore(now)) {
        this.endOnPremise();
        return;
      }

      const user = this.session.currentUser;
      const pastOrders = data.pastOrders;
      if (pastOrders.length && !keepPastOrders(user, pastOrders)) {
        this.closedCheck = { ...this.closedCheck, pastOrders: [] };
      }

      const lastOrderTime = data.pastOrders[data.pastOrders.length - 1]?.timePlaced;
      const actionTimes = [lastOrderTime, data.continueTime, data.start].filter(
        t => t !== undefined
      ) as string[];
      const lastActionTime = [...actionTimes].sort()[actionTimes.length - 1];
      if (dayjs(lastActionTime).add(2, 'hours').isBefore(now)) {
        this.storage.showOnPremiseContinueModal = true;
      }
    }
  }

  async endOnPremise(): Promise<void> {
    if (!(this.closedCheck || this.openCheck) && this.isOpenCheckSubsequentRound) {
      return;
    }

    this.closedCheck = undefined;
    this.openCheck = undefined;
    this.tablePosRef = undefined;

    if (this.session.localGuestUser?.contactNumber === ON_PREMISE_JUNK_PHONE_NUMBER) {
      this.session.localGuestUser = undefined;
    }

    if (this.session.isSavedGuest) {
      await this.session.logout();
    }

    this.orderCriteria.searchOrderCriteria = undefined;
  }

  async setOnPremiseDetails(
    tablePosRef: string | undefined,
    experienceType: OnPremiseExperience,
    vendorSlug: string
  ): Promise<void> {
    this.setExperienceType(experienceType, vendorSlug);
    this.setTablePosRef(tablePosRef);

    if (this.basket.basket) {
      try {
        await this.setBasketOnPremiseDetails();
      } catch (e) {
        this.error.reportError(e);
      }
    }
  }

  setExperienceType(experience: OnPremiseExperience, vendorSlug: string): void {
    //todo: why is this circular....

    if (
      experience === OnPremiseExperience.ClosedCheck ||
      experience === OnPremiseExperience.OpenCheck
    ) {
      if (this.orderCriteria.isValidHandoffMode('DineIn')) {
        this.orderCriteria.updateSearchOrderCriteria('DineIn');
      }
    }
    if (experience === OnPremiseExperience.ClosedCheck) {
      if (!this.closedCheck) {
        this.closedCheck = closedCheckData(vendorSlug);
      }
    } else {
      this.closedCheck = undefined;
    }
    this.openCheck = experience === OnPremiseExperience.OpenCheck;
    if (this.openCheck) {
      this.vendorSlugForOpenCheck = vendorSlug;
    }
  }

  async setTablePosRef(table: string | undefined): Promise<void> {
    if (!this.orderCriteria.isValidHandoffMode('DineIn')) {
      this.tablePosRef = undefined;
      this.error.sendUserMessage({
        detail: this.intl.t('mwc.errors.onPremiseHandoffRequired'),
      });
    } else {
      this.orderCriteria.updateSearchOrderCriteria('DineIn');
      this.tablePosRef = table;
    }
  }

  async setBasketOnPremiseDetails(): Promise<void> {
    if (this.basket.basket) {
      await this.basket.basket.setOnPremiseDetails({
        tablePosReference: this.tablePosRef,
        experienceType: this.experienceType,
      });
    }
  }

  resetLocalValues(currentRouteName: string) {
    const payAtTableResetRoutes = ['menu', 'thank-you'];

    if (this.isPayAtTable && payAtTableResetRoutes.some(s => currentRouteName.includes(s))) {
      this.payAtTable = false;
    }
  }

  goToThankYouPage(): void {
    if (!this.closedCheck) {
      return;
    }

    const latestOrderId =
      this.closedCheck.pastOrders[this.closedCheck.pastOrders.length - 1].displayId;

    this.router.transitionTo('thank-you', latestOrderId);
  }

  goToCloseOpenCheck() {
    if (this.hasOpenCheckWithUnsentProducts) {
      this.showPlaceOrderModal = true;
    } else {
      this.router.transitionTo('checkout.auth');
    }
  }

  goToStoreVendorPage(): void {
    if (this.closedCheck) {
      this.router.transitionTo('menu.vendor', this.closedCheck.vendorSlug, {
        queryParams: { closedCheck: true },
      });
    }
    if (this.openCheck) {
      this.router.transitionTo('menu.vendor', this.vendorSlugForOpenCheck!, {
        queryParams: { openCheck: true },
      });
    }
  }

  handlePaidCheck() {
    this.router.transitionTo('pay', this.vendor.vendorSlug!, {
      queryParams: { paid: true },
    });
  }

  basketStartOver(): void {
    this.basket.basket?.startOver();
    this.basket.clear();
  }

  prepareGuestUser(user: UserData): void {
    if (!user.emailAddress && !this.contact.emailRequired) {
      user.emailAddress = getGeneratedDineInEmail();
    }
    if (!user.lastName) {
      user.lastName = ON_PREMISE_JUNK_LAST_NAME;
    }
    if (this.isPayAtTable && !user.contactNumber) {
      user.contactNumber = ON_PREMISE_JUNK_PHONE_NUMBER;
    }
  }

  /**
   * Fetches all tickets (checks) at the current vendor and table.
   * Vendor comes from vendor service.
   * Table comes from tablePosRef.
   */
  fetchTickets(): DS.PromiseArray<TicketModel> {
    return this.store.query('ticket', { tableId: this.tablePosRef });
  }
  /**
   * Return True if only one check and check is Unpaid
   */
  isSingleCheck(checks: Array<Ticket>): boolean {
    return checks.length === 1 && checks[0].totals.due > 0;
  }

  /**
   * Creates a basket from the ticket and sets it on the basket service.
   * It's expected that the ticket represent an open (unpaid) check.
   */
  async createBasketFromTicket(ticket: TicketModel, openCheckGuid?: string): Promise<boolean> {
    try {
      const basket = await ticket.createBasket();
      if (openCheckGuid && basket.onPremiseDetails) {
        const details = basket.onPremiseDetails;
        await basket.setOnPremiseDetails({
          tablePosReference: details.tablePosReference,
          experienceType: details.experienceType,
          openCheckGuid,
        });
      }
      this.basket.basket = basket;
      await this.checkBasketTimeImmediate(this.basket.basket);
      return true;
    } catch (err) {
      this.error.reportError(err);
      return false;
    }
  }

  async checkBasketTimeImmediate(basket: BasketModel): Promise<boolean> {
    if (this.features.flags['check-basket-time-immediate-olo-61363'] && basket.isAdvance) {
      const AsapCriteria: OrderCriteria = {
        handoffMode: 'DineIn',
        timeWantedType: 'Immediate',
        searchAddress: '',
      };

      try {
        const result = await this.vendor.vendor?.preCheck(AsapCriteria);

        if (result && result.isValid) {
          await this.orderCriteria.updateBasket();
          return true;
        }
      } catch {
        throw new Error('Store hours are unavailable for digital payment');
      }
    }
    return true;
  }

  preparePayAtTableGuestUser(): void {
    this.session.set('localGuestUser', {
      firstName: ON_PREMISE_JUNK_FIRST_NAME,
      lastName: ON_PREMISE_JUNK_LAST_NAME,
      emailAddress: '',
      contactNumber: ON_PREMISE_JUNK_PHONE_NUMBER,
      optIn: this.channel.currentCountrySettings?.optIn,
    });
  }

  async payAtTableCheckout(ticket: TicketModel, openCheckGuid?: string): Promise<boolean> {
    const success = await this.createBasketFromTicket(ticket, openCheckGuid);
    if (success) {
      this.payAtTable = true; // this shouldn't be necessary, but might fix a race condition [OLO-62681]
      this.payAtTableHasError = false;
      this.preparePayAtTableGuestUser();
      this.router.transitionTo('checkout');
      return true;
    }
    this.payAtTableHasError = true;
    return false;
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'on-premise': OnPremiseService;
  }
}
