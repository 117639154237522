import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { buildOloErrorHandler } from 'mobile-web/lib/errors';
import { LoginProvider } from 'mobile-web/models/bootstrap-data';
import LoginRoute from 'mobile-web/routes/login';
import AuthenticationService from 'mobile-web/services/authentication';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import VendorService from 'mobile-web/services/vendor';
import WindowService from 'mobile-web/services/window';

export default class LoginController extends Controller {
  declare model: ModelForRoute<LoginRoute>;

  // Service injections
  @service authentication!: AuthenticationService;
  @service bootstrap!: BootstrapService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service vendor!: VendorService;
  @service onPremise!: OnPremiseService;
  @service window!: WindowService;

  // Untracked properties
  queryParams = ['mode', 'upgrade', 'returnUrl'];

  // Tracked properties
  @tracked mode?: string;
  @tracked upgrade?: boolean;
  @tracked returnUrl?: string;

  // Getters and setters
  get signInCreateAccountMode(): number {
    return Number(this.mode) || 0;
  }

  get preferOloAuth(): boolean {
    return !!this.bootstrap.data?.hasOloAuthProvider;
  }

  // Lifecycle methods

  // Other methods
  invalidLogin(reason: string) {
    this.error.sendUserMessage({ detail: reason });
  }

  // Tasks

  // Actions and helpers
  @action
  afterCreateAccount() {
    if (this.onPremise.hasOpenCheck) {
      const slug = this.vendor.vendor?.get('slug');
      if (slug) {
        this.session.set('nextRoute', ['menu.vendor.index', slug]);
      } else {
        this.session.set('nextRoute', ['application']);
      }
    }
    this.authentication.goToNextRoute('my-account');
  }

  @action
  afterCreateAccountError(error: string) {
    this.error.sendUserMessage(error);
  }

  @action
  login(email: string, password: string) {
    this.session.internalLogin(
      email,
      password,
      () => {
        if (this.returnUrl && this.returnUrl.startsWith('/')) {
          this.window.location().assign(this.returnUrl);
        } else {
          this.authentication.goToNextRoute();
        }
      },
      buildOloErrorHandler('login-failure', reason => this.invalidLogin(reason))
    );
  }

  @action
  externalLogin(loginProvider: LoginProvider) {
    this.session.externalLogin(loginProvider.slug, false);
  }

  @action
  switchModes(mode: number) {
    // @ts-ignore -- types need to be updated
    this.router.transitionTo({ queryParams: { mode } });
  }
}
