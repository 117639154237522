import DS from 'ember-data';

import { Type } from 'mobile-web/lib/payment/card';
import { isSome } from 'mobile-web/lib/utilities/is-some';
import BaseJSONSerializer from 'mobile-web/serializers/base-json-serializer';

export default class BillingMembershipSerializer extends BaseJSONSerializer {
  modelName = 'billing-membership';

  normalize(
    model: DS.Model,
    resourceHash: {
      id?: number;
      billingScheme?: unknown;
      billingAccountId?: number;
      cardType?: string;
      country?: string;
    }
  ): UnknownObject {
    // This field is never set in production, but does get set from Mirage
    // so we need to clean it up to match our normal server response.
    delete resourceHash.billingScheme;

    // In different contexts, the id can come back as either `id` or `billingAccountId`,
    // so we need to normalize on just one of those.
    if (isSome(resourceHash.billingAccountId)) {
      resourceHash.id = resourceHash.billingAccountId;
      delete resourceHash.billingAccountId;
    }

    const cardType = resourceHash.cardType;
    if (isSome(cardType)) {
      switch (cardType.toLowerCase()) {
        case 'visa':
          resourceHash.cardType = Type.VISA;
          break;
        case 'discover':
          resourceHash.cardType = Type.Discover;
          break;
        case 'mastercard':
          resourceHash.cardType = Type.Mastercard;
          break;
        case 'amex':
          resourceHash.cardType = Type.AMEX;
          break;
        default:
          resourceHash.cardType = Type.Unknown;
          break;
      }
    }

    // Some old saved cards have country codes saved uppercase instead of the expected lowercase.
    const country = resourceHash.country;
    if (isSome(country)) {
      resourceHash.country = country.toLowerCase();
    }

    return super.normalize(model, resourceHash);
  }
}
