import { helper } from '@ember/component/helper';

import { Dayjs } from 'mobile-web/lib/dayjs';
import Vendor from 'mobile-web/models/vendor';

export function vendorTime(utc: Dayjs, vendor: Vendor): Dayjs {
  return utc.add(vendor.utcOffset, 'hour');
}

type Named = {
  utc: Dayjs;
  vendor: Vendor;
};

const vendorTimeHelper = helper((_positional: unknown[], named: Named) =>
  vendorTime(named.utc, named.vendor)
);

export default vendorTimeHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'vendor-time': typeof vendorTimeHelper;
  }
}
