export const POPUP_OPTIONS =
  'status=1' +
  ',width=' +
  575 +
  ',height=' +
  400 +
  ',left=' +
  (window.innerWidth - 575) / 2 +
  ',top=' +
  (window.innerHeight - 400) / 2;
