import Component from '@glimmer/component';

import style from './index.m.scss';

export interface PostCheckoutSubsectionLineItemSignature {
  Element: HTMLDivElement;

  Blocks: {
    default: [];
  };
}

export default class PostCheckoutSubsectionLineItem extends Component<PostCheckoutSubsectionLineItemSignature> {
  style = style;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'post-checkout/subsection/line-item': typeof PostCheckoutSubsectionLineItem;
  }
}
