import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { FuzzyOptions, Searcher } from 'fast-fuzzy';

import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';

import style from './index.m.scss';

interface FilterSearch {
  filter: (search: string, obj: unknown) => void;
}

interface FuzzySearch {
  searcher: Searcher<AnyObject, FuzzyOptions>;
}

type Args<T> = {
  // Required arguments
  items: T[];

  // Optional arguments
  onOpen?: () => void;
  onFocus?: () => void;
  onFocusOut?: (searchText: undefined | string, matchingItems: undefined | T[]) => void;
  onClose?: () => void;
  onSelect?: (search: string, items: T) => void;
} & (FilterSearch | FuzzySearch);

interface Signature<T> {
  Element: HTMLDivElement;

  Args: Args<T>;

  Blocks: {
    default: [
      {
        item: T;
      }
    ];
  };
}

const MAX_RESULTS = 20;

export default class MenuSearch<T> extends Component<Signature<T>> {
  // Service injections

  // Untracked properties
  style = style;
  ids = guids(this, 'searchLabel', 'searchInput', 'searchResults');

  // Tracked properties
  @tracked active = false;
  @tracked search = '';
  @tracked currentFocus: number | undefined = undefined;
  @tracked rootElement?: HTMLElement;
  @tracked input?: HTMLElement;

  // Getters and setters
  get matchedItems(): T[] {
    if ('filter' in this.args) {
      return this.args.items
        .filter(obj => (this.args as FilterSearch).filter(this.search, obj))
        .slice(0, MAX_RESULTS);
    } else if (this.args.searcher) {
      return this.args.searcher.search(this.search).slice(0, MAX_RESULTS);
    }
    return [];
  }

  // Lifecycle methods

  // Other methods
  setFocusOnListItem(currentFocus: number) {
    (document.querySelector(`li[data-id='${currentFocus}']`) as HTMLElement)!.focus();
  }

  // Tasks

  // Actions and helpers
  @action
  onFocusIn(): void {
    this.active = true;
    this.args.onOpen?.();
    this.args.onFocus?.();
  }

  @action
  onFocusOut(): void {
    this.args.onFocusOut?.(this.search, this.matchedItems);
  }

  @action
  close(): void {
    this.active = false;
    this.args.onClose?.();
  }

  @action
  onSelect(item: T): void {
    this.args.onSelect?.(this.search, item);
    this.close();
  }

  @action
  setupEventListeners(rootElement: HTMLElement) {
    this.rootElement = rootElement;
    document.addEventListener('click', this.handleOutsideClick);
  }

  @action
  teardownEventListeners() {
    this.rootElement = undefined;
    document.removeEventListener('click', this.handleOutsideClick);
  }

  @action
  handleOutsideClick(event: MouseEvent) {
    if (!this.active) {
      return;
    }

    this.currentFocus = undefined;

    const target = event.target as HTMLElement | null;
    if (isSome(target) && !this.rootElement!.contains(target)) {
      this.close();
    }
  }

  @action
  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      this.currentFocus = undefined;
      (event.target as HTMLElement)!.blur();
      this.close();
    } else if (!this.search) {
      return;
    } else if (this.currentFocus === undefined) {
      if (event.key === 'ArrowDown') {
        this.currentFocus = 0;
        this.setFocusOnListItem(this.currentFocus);
        event.preventDefault();
      }
    } else {
      switch (event.key) {
        case 'ArrowDown':
          if (this.currentFocus < this.matchedItems.length - 1) {
            this.currentFocus++;
            this.setFocusOnListItem(this.currentFocus);
          }
          event.preventDefault();
          return;
        case 'ArrowUp':
          if (this.currentFocus === 0) {
            this.currentFocus = undefined;
            this.input!.focus();
          } else if (this.currentFocus > 0) {
            this.currentFocus--;
            this.setFocusOnListItem(this.currentFocus);
          }
          event.preventDefault();
          return;
        case 'Enter':
          this.onSelect(this.matchedItems[this.currentFocus]);
          return;
        default:
          return;
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MenuSearch: typeof MenuSearch;
  }
}
