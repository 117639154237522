import { service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { SectionModel } from 'mobile-web/components/sticky-nav';
import { noop } from 'mobile-web/lib/utilities/_';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

type Card = {
  button: {
    onClick: () => void;
    label: string;
  };
  content: string;
  heading: string;
};

export default class HubRoute extends Component {
  // Service injections
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get cards(): Array<
    SectionModel<{
      button: {
        onClick: () => void;
        label: string;
      };
      content: string;
      heading: string;
    }>
  > {
    return [
      {
        id: 'order',
        // Type guards don't work well in Glint yet, so to keep the types nice
        // in the template we create a noop model that uses `content` to signal
        // to the template that we should render the central card
        model: {
          button: { onClick: noop, label: '' },
          content: this.intl.t('mwc.hub.sections.orderName'),
          heading: '',
        },
        name: this.intl.t('mwc.hub.sections.orderName'),
      },
      {
        id: 'join-group',
        model: {
          button: {
            onClick: noop,
            label: this.intl.t('mwc.hub.sections.joinButton'),
          },
          content: this.intl.t('mwc.hub.sections.joinContent'),
          heading: this.intl.t('mwc.hub.sections.joinHeading'),
        },
        name: this.intl.t('mwc.hub.sections.joinName'),
      },
      {
        id: 'pay',
        model: {
          button: {
            onClick: noop,
            label: this.intl.t('mwc.hub.sections.payButton'),
          },
          content: this.intl.t('mwc.hub.sections.payContent'),
          heading: this.intl.t('mwc.hub.sections.payHeading'),
        },
        name: this.intl.t('mwc.hub.sections.payName'),
      },
      {
        id: 'take-home',
        model: {
          button: {
            onClick: noop,
            label: this.intl.t('mwc.hub.sections.takeHomeButton'),
          },
          content: this.intl.t('mwc.hub.sections.takeHomeContent'),
          heading: this.intl.t('mwc.hub.sections.takeHomeHeading'),
        },
        name: this.intl.t('mwc.hub.sections.takeHomeName'),
      },
    ];
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  cardId = (card: Card): string => this.cards.filter(c => c.model.heading === card.heading)[0].id;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::HubRoute': typeof HubRoute;
  }
}
