import Component from '@glimmer/component';

import style from './index.m.scss';

export default class ApplicationLoadingCheckout extends Component {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApplicationLoading::Checkout': typeof ApplicationLoadingCheckout;
  }
}
