import RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

type PathConfig = {
  cookieName: string;
  href: (params: Record<string, string | undefined>) => string;
  toName: string;
};

const CONFIGS: PathConfig[] = [
  {
    cookieName: 'olo-serve-next-licenses',
    href: () => '/open-source-licenses',
    toName: 'open-source-licenses',
  },
  {
    cookieName: 'srvnxtty',
    href: params => `/thank-you/${Object.values(params)[0]}`,
    toName: 'thank-you',
  },
];

export default class MigrationService extends Service {
  // Service injections
  @service router!: RouterService;

  // Untracked properties

  // Tracked properties
  @tracked isRedirecting = false;

  // Getters and setters

  // Lifecycle methods
  constructor() {
    super(...arguments);

    this.router.on('routeWillChange', async transition => {
      for (const config of CONFIGS) {
        if (
          transition.to?.name === config.toName &&
          document.cookie.includes(`${config.cookieName}=1`)
        ) {
          this.isRedirecting = true;

          const href = config.href(transition.to.params);
          window.location.href = href;

          transition.abort();
        }
      }
    });
  }

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    migration: MigrationService;
  }
}
