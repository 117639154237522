import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';

import BasketModel from 'mobile-web/models/basket';
import BasketService from 'mobile-web/services/basket';

import isSome from './utilities/is-some';

export enum DiscountTypes {
  Offer = 'Offer',
  Coupon = 'Coupon',
  None = 'None',
}

export const validChange = (change: string): boolean =>
  ([DiscountTypes.Coupon, DiscountTypes.Offer] as string[]).includes(change);

export type DiscountChange =
  | {
      type: DiscountTypes.Offer;
      detail: { id: EmberDataId; reference: EmberDataId };
    }
  | {
      type: DiscountTypes.Coupon;
      detail: { code: string };
    };

export function isDiscountApplied(basketService: BasketService): boolean {
  if (isSome(basketService.basket)) {
    return (
      basketService.basket.get('hasReward') ||
      (isSome(basketService.basket.coupon) && isSome(basketService.basket.coupon.code))
    );
  }
  return false;
}

export function currentDiscountType(basketService: BasketService): DiscountTypes {
  const { basket } = basketService;
  if (isSome(basket)) {
    if (basket.get('hasReward')) {
      return DiscountTypes.Offer;
    } else if (isSome(basket.coupon) && isSome(basket.coupon.code)) {
      return DiscountTypes.Coupon;
    }
  }

  return DiscountTypes.None;
}

/**
 * IMPURE-FUNCTION
 * This function alters the application state by removing any active
 * reward or coupon in the basket service.
 */
export function removeCurrentDiscounts(
  basketService: BasketService
): RSVP.Promise<void | BasketModel> {
  const basket = basketService.basket;
  if (isSome(basket)) {
    if (basket.hasReward) {
      // We can safely assert here because `reward` is always set if `hasReward`
      // is true.
      const { id, externalReference } = basket.reward!;
      return basket.removeReward({ membershipId: id, reference: externalReference });
    }
    const currentCouponCode = isSome(basket.coupon) ? basket.coupon.code : '';

    if (!isEmpty(currentCouponCode)) {
      return basketService.basket!.removeCoupon();
    }
  }
  return RSVP.resolve();
}

/**
 * IMPURE-FUNCTION
 * This function alters the application state by removing a reward or coupon
 * in the basket service.
 */
export function removeDiscount(
  basketService: BasketService,
  change: DiscountChange
): RSVP.Promise<void | BasketModel> {
  return change.type === DiscountTypes.Coupon
    ? basketService.basket!.removeCoupon()
    : basketService.basket!.removeReward({
        membershipId: change.detail.id,
        reference: change.detail.reference,
      });
}

/**
 * IMPURE-FUNCTION
 * This function alters the application state by applying a reward or coupon
 * in the basket service.
 */
export function applyDiscount(
  basketService: BasketService,
  change: DiscountChange
): RSVP.Promise<void | BasketModel> {
  return basketService.createBasket().then(() =>
    change.type === DiscountTypes.Coupon
      ? basketService.basket!.applyCoupon(change.detail.code)
      : basketService.basket!.applyReward({
          membershipId: change.detail.id,
          reference: change.detail.reference,
        })
  );
}

const Discount = {
  apply: applyDiscount,
  remove: removeDiscount,
  removeAll: removeCurrentDiscounts,
  currentType: currentDiscountType,
  isApplied: isDiscountApplied,
  validChange,
};

export default Discount;
