import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { Tag } from 'mobile-web/models/vendor-search-result';

import style from './index.m.scss';

interface Args {
  // Required arguments
  leadTime: number;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class VendorLeadTimeTag extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;

  // Untracked properties
  style = style;

  // Tracked properties
  get leadTimeTag() {
    const leadTime = this.args.leadTime;

    let leadTimeTag: Tag;

    if (leadTime < 30) {
      leadTimeTag = {
        type: 'positive',
        content: this.intl.t('mwc.vendorCard.leadTimeLessThan30'),
      };
    } else {
      leadTimeTag = {
        type: leadTime < 41 ? 'warning' : 'negative',
        content: this.intl.t('mwc.vendorCard.leadTimeRange', {
          min: leadTime - 5,
          max: leadTime + 5,
        }),
      };
    }

    return leadTimeTag;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VendorLeadTimeTag: typeof VendorLeadTimeTag;
  }
}
