import DS from 'ember-data';

import ChannelModel from './channel';

export default class CustomLinkModel extends DS.Model {
  @DS.attr('number')
  displayOrder!: number;

  @DS.attr('string')
  linkLabelText!: string;

  @DS.attr('string')
  htmlContent!: string;

  @DS.attr('string')
  url?: string;

  @DS.belongsTo('channel')
  channel!: ChannelModel;
}
