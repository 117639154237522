import Route from '@ember/routing/route';

import ENV from 'mobile-web/config/environment';
import { License } from 'mobile-web/lib/license';

export default class OpenSourceLicensesRoute extends Route {
  async model() {
    const licensesResponse = await fetch(
      `${ENV.scriptBaseUrl}assets/open-source-licenses.json?t=${Date.now()}`
    );
    return JSON.parse(await licensesResponse.text()) as License[];
  }
}
