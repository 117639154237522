import Component from '@glimmer/component';

import { Transition } from 'ember-animated/-private/transition';

import MyAccountRoute from 'mobile-web/components/routes/my-account-route';
import BillingMembership from 'mobile-web/models/billing-membership';

import style from './index.m.scss';

interface Args {
  // Required arguments
  cards: BillingMembership[];
  cardsWithDefaultOption: Record<string, boolean>;
  confirmDeleteCard: (card: BillingMembership) => void;
  header: string;
  ids: MyAccountRoute['ids'];
  makeDefaultCard: (card: BillingMembership) => void;
  sectionName: string;
  transition: Transition;

  // Optional arguments
}

interface Signature {
  Element: HTMLUListElement;

  Args: Args;
}

export default class PaymentMethod extends Component<Signature> {
  style = style;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PaymentCardMethods: typeof PaymentMethod;
  }
}
