export default {
  "root": "_root_1h8hkf",
  "messageOnly": "_messageOnly_1h8hkf",
  "submitButton": "_submitButton_1h8hkf",
  "heading": "_heading_1h8hkf",
  "information": "_information_1h8hkf",
  "infoIcon": "_infoIcon_1h8hkf",
  "text": "_text_1h8hkf",
  "field": "_field_1h8hkf",
  "instruction": "_instruction_1h8hkf",
  "paidHeading": "_paidHeading_1h8hkf",
  "paidAlert": "_paidAlert_1h8hkf"
};
