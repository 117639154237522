export default {
  "container": "_container_qi3ftn",
  "button": "_button_qi3ftn _container_1csiaz _standard_1csiaz",
  "errorMessage": "_errorMessage_qi3ftn",
  "requirementMessage": "_requirementMessage_qi3ftn",
  "errorAlt": "_errorAlt_qi3ftn",
  "errorIcon": "_errorIcon_qi3ftn",
  "requirementIcon": "_requirementIcon_qi3ftn",
  "errorMessageText": "_errorMessageText_qi3ftn",
  "requirementMessageText": "_requirementMessageText_qi3ftn",
  "labelInputContainer": "_labelInputContainer_qi3ftn _inlineLabelInputContainer_1csiaz _labelInputContainerBase_1csiaz",
  "error": "_error_qi3ftn",
  "label": "_label_qi3ftn _inlineLabel_1csiaz",
  "labelIcon": "_labelIcon_qi3ftn _icon_1csiaz",
  "labelText": "_labelText_qi3ftn _inlineLabelText_1csiaz",
  "input": "_input_qi3ftn _inlineSelectInput_1csiaz _selectInputBase_1csiaz _inputBase_1csiaz _inlineInputBase_1csiaz",
  "placeholder": "_placeholder_qi3ftn _inlineSelectInput_1csiaz _selectInputBase_1csiaz _inputBase_1csiaz _inlineInputBase_1csiaz",
  "icon": "_icon_qi3ftn",
  "responsive": "_responsive_qi3ftn _responsive_1csiaz"
};
