import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import ContentService from 'mobile-web/services/content';

export default class PrivacyPolicyRoute extends Route {
  @service content!: ContentService;

  model() {
    return this.content.getHTML('PRIVACY_HTML');
  }
}
