/** 0 represents 'other' */
export type TipPercentage = 0 | 0.15 | 0.2 | 0.25;

export const percentages: TipPercentage[] = [0.15, 0.2, 0.25, 0];

export function calculatePercentage(subTotal: number, tip: number): TipPercentage {
  const match = percentages.find(p => percentageAmount(p, subTotal) === tip);
  return match ?? 0;
}

export function percentageAmount(percentage: number, subTotal: number): number {
  return +(percentage * subTotal).toFixed(2);
}
