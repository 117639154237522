import RSVP from 'rsvp';

import { host } from 'mobile-web/lib/hybrid-util';

import ApplicationAdapter from './application';

export type GiftCardResponse = {
  accountId: number;
  balance: number;
  description: string;
  billingSchemeId: number;
  pinLengthLowerLimit: number;
  pinLengthUpperLimit: number;
  accountNumberLengthLowerLimit: number;
  accountNumberLengthUpperLimit: number;
};

export default class BillingMembershipAdapter extends ApplicationAdapter {
  urlForDeleteRecord(id: EmberDataId): string {
    return `${host()}/${this.namespace}/billingaccounts?ids=${id}`;
  }

  pathForType(): string {
    return 'billingaccounts';
  }

  getGiftCard(id: EmberDataId): RSVP.Promise<GiftCardResponse> {
    const url = `${host()}/${this.namespace}/giftcards/${id}`;
    return this.ajax(url, 'GET');
  }
}
