export default {
  "root": "_root_18e0yt",
  "isSlider": "_isSlider_18e0yt",
  "inputContainer": "_inputContainer_18e0yt",
  "inputWrapper": "_inputWrapper_18e0yt",
  "label": "_label_18e0yt",
  "button": "_button_18e0yt",
  "isStepper": "_isStepper_18e0yt",
  "leftButton": "_leftButton_18e0yt _button_18e0yt",
  "rightButton": "_rightButton_18e0yt _button_18e0yt",
  "icon": "_icon_18e0yt",
  "input": "_input_18e0yt",
  "thumb": "_thumb_18e0yt",
  "thumbLarge": "_thumbLarge_18e0yt"
};
