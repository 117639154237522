import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import { host } from 'mobile-web/lib/hybrid-util';
import FeaturesService from 'mobile-web/services/features';

import ApplicationAdapter from './application';

export enum FavoriteRequestType {
  Basket = 'basket',
}

export default class FavoriteAdapter extends ApplicationAdapter {
  @service features!: FeaturesService;

  query(): RSVP.Promise<unknown> {
    const baseUrl = this.buildURL('favorite'),
      url = `${baseUrl}/list`;

    return this.ajax(url, 'GET');
  }

  saveFavoriteOrder(orderId: EmberDataId, name: string): RSVP.Promise<unknown> {
    const url = this.buildURL('favorite');

    return this.ajax(url, 'POST', { data: { orderId, name } });
  }

  buildURL(_modelName?: unknown, _id?: string, _snapshot?: unknown, requestType?: string): string {
    if (requestType === FavoriteRequestType.Basket) {
      return `${host()}/${this.namespace}/baskets/reorder`;
    }
    return super.buildURL(...arguments);
  }
}
