import { isNone } from '@ember/utils';

import { MaskFunction, PipeFunction } from 'ember-text-mask';

import { range } from 'mobile-web/lib/utilities/_';

export enum Type {
  VISA = 'VISA',
  Discover = 'Discover',
  Mastercard = 'Mastercard',
  AMEX = 'AMEX',
  Unknown = 'Unknown',
}

type LeadingStrings = string[];

type Rule = [Type, LeadingStrings[]];

type CreditRange = {
  Start: number;
  End: number;
  Network: Type;
};

/** Please keep these in order to make it easy to find any
 *  potential overlaps.
 */
const binRanges: CreditRange[] = [
  { Start: 21844150, End: 27248619, Network: Type.Mastercard },
  { Start: 30000000, End: 30599999, Network: Type.Discover },
  { Start: 30880000, End: 30949999, Network: Type.Discover },
  { Start: 30950000, End: 30959999, Network: Type.Discover },
  { Start: 30960000, End: 33499999, Network: Type.Discover },
  { Start: 34000000, End: 34999999, Network: Type.AMEX },
  { Start: 35280000, End: 35899999, Network: Type.Discover },
  { Start: 36000000, End: 36999999, Network: Type.Discover },
  { Start: 37000000, End: 37999999, Network: Type.AMEX },
  { Start: 38000000, End: 39999999, Network: Type.Discover },
  { Start: 40000000, End: 49999819, Network: Type.VISA },
  { Start: 50016500, End: 58241199, Network: Type.Mastercard },
  { Start: 58927400, End: 58927499, Network: Type.VISA },
  { Start: 58946000, End: 58971899, Network: Type.Mastercard },
  { Start: 60110000, End: 60119999, Network: Type.Discover },
  { Start: 60128100, End: 60128199, Network: Type.VISA },
  { Start: 62109400, End: 65291499, Network: Type.Discover },
  { Start: 65291500, End: 65291599, Network: Type.Discover },
  { Start: 65291600, End: 65999999, Network: Type.Discover },
  { Start: 73890000, End: 73890799, Network: Type.VISA },
  { Start: 81000000, End: 81719999, Network: Type.Discover },
];

const binLength = 8;

const cardBinNumber = (partialCard: string): number =>
  Number(
    partialCard
      .replace(/[^0-9]/g, '') /** Only numerics */
      .padEnd(binLength, '0') /** Pad partial numbers out to full bin */
      .substring(0, binLength) /** Trim full numbers to only bin */
  );

function findRange(cardBin: number): Type | undefined {
  const foundRange = binRanges.find(x => cardBin >= x.Start && cardBin <= x.End);
  return foundRange ? foundRange.Network : undefined;
}

/** Generate an inclusive range of numbers converted to strings. */
const rangeOfStrings = (start: number, end: number): string[] =>
  range(start, end + 1).map(v => v.toString());

const RULES: Rule[] = [
  [Type.VISA, [['4'], ['50'], rangeOfStrings(56, 58)]],
  [
    Type.Discover,
    [['6011'], rangeOfStrings(622126, 622925), rangeOfStrings(644, 649), ['65'], ['8']],
  ],
  [
    Type.Mastercard,
    [['2'], ['67'], ['97'], rangeOfStrings(51, 55), ['6004'], ['6008'], ['639'], ['67']],
  ],
  [Type.AMEX, [['34'], ['37']]],
];

const cardMatchesRule =
  (card: string) =>
  (leadingStrings: LeadingStrings): boolean =>
    leadingStrings.some(leadingString => card.startsWith(`${leadingString}`));

const toMatchingType =
  (card: string) =>
  (matched: Type | undefined, [type, leadingStrings]: Rule) =>
    matched ?? (leadingStrings.some(cardMatchesRule(card)) ? type : undefined);

function detectNewType(card?: string | null): Type | undefined {
  return isNone(card) ? undefined : findRange(cardBinNumber(card));
}

export function detectType(card?: string | null): Type | undefined {
  return isNone(card)
    ? undefined
    : RULES.reduce(toMatchingType(card.replace(/\s/g, '')), undefined);
}

export function detectCardType(
  useNewCardDetection: boolean,
  card?: string | null
): Type | undefined {
  if (useNewCardDetection) {
    return detectNewType(card);
  }
  return detectType(card);
}

export function matchBrandWithType(brand: string): Type {
  switch (brand) {
    case 'visa':
      return Type.VISA;
    case 'mastercard':
      return Type.Mastercard;
    case 'amex':
      return Type.AMEX;
    case 'discover':
      return Type.Discover;
    default:
      return Type.Unknown;
  }
}

// Input Masking types for credit card forms
const DEFAULT_CC_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const AMEX_CC_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const ccMask: MaskFunction = rawValue =>
  detectType(rawValue) === Type.AMEX ? AMEX_CC_MASK : DEFAULT_CC_MASK;
export const expMask: MaskFunction = rawValue => {
  // These 3 special cases are to handle different autofill/paste possibilities
  if (/^\d{2}\/\d{4}$/.test(rawValue)) {
    return [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  }
  if (/^\d{1}\/\d{4}$/.test(rawValue)) {
    return ['0', /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  }
  if (/^\d{1}\/\d{2}$/.test(rawValue)) {
    return ['0', /\d/, '/', /\d/, /\d/];
  }
  return [/\d/, /\d/, '/', /\d/, /\d/];
};
export const expPipe: PipeFunction = conformedValue => {
  if (/^\d{2}\/\d{4}$/.test(conformedValue)) {
    const [month, year] = conformedValue.split('/');
    return `${month}/${year.slice(2)}`;
  }

  if (conformedValue.length === 1 && Number(conformedValue) > 1) {
    return {
      value: `0${conformedValue}`,
      indexesOfPipedChars: [0],
    };
  }

  return conformedValue;
};

const Card = {
  detectType,
  Type,
};

export default Card;
