import { readOnly, or } from '@ember/object/computed';
import Service from '@ember/service';

import update from 'ember-object-update';
import isEqual from 'lodash.isequal';

import { Variant } from 'mobile-web/components/button';

export enum Type {
  Neutral = 'neutral',
  Positive = 'positive',
  Warning = 'warning',
  Negative = 'negative',
}

enum Icon {
  Info = 'info',
  Exclamation = 'exclamation',
  Cancel = 'cancel',
  Check = 'checkmark',
}

export const ICONS: { [K in Type]: Icon } = {
  [Type.Neutral]: Icon.Info,
  [Type.Negative]: Icon.Cancel,
  [Type.Positive]: Icon.Check,
  [Type.Warning]: Icon.Exclamation,
};

export type Action = {
  label: string;
  fn: () => void;
  variant?: Variant;
  cancelAction?: boolean;
};

export type Message = {
  type: Type;
  title: string;
  message: string;
  actions: Action[];
};

type MessageArg = Optional<Message, 'actions'>;

/**
 * UserFeedbackService will show messages to the user in a banner at the top of the page.
 * Only one message will be shown at once, and will wait for the user to dismiss.
 */
export default class UserFeedbackService extends Service {
  // Service injections

  // Untracked properties
  _stack: Message[] = [];

  // Tracked properties

  // Getters and setters
  @readOnly('_stack')
  messages!: Message[];

  @or('isDestroying', 'isDestroyed')
  tearingDown!: boolean;

  // Lifecycle methods

  // Other methods
  add({ type, title, message, actions = [] }: MessageArg): Message[] {
    return !this.tearingDown
      ? update(this, '_stack', stack => {
          if (stack.find(m => m.message === message)) {
            return stack;
          }
          return stack.concat({ type, title, message, actions });
        })
      : this._stack;
  }

  clear(message: Message): void {
    if (!this.tearingDown) {
      update(this, '_stack', stack => stack.filter(m => !isEqual(m, message)));
    }
  }

  clearAll(): void {
    if (!this.tearingDown) {
      update(this, '_stack', _ => []);
    }
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'user-feedback': UserFeedbackService;
  }
}
