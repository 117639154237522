import { service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { host } from 'mobile-web/lib/hybrid-util';
import { SplitCheckOrderStatus } from 'mobile-web/lib/split-check';
import isSome from 'mobile-web/lib/utilities/is-some';
import TicketModel from 'mobile-web/models/ticket';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';

export type TicketResponse = {
  ticket: TicketModel;
  orders: SplitCheckOrderStatus[];
};

export type TicketsResponse = {
  tickets: PureModel<TicketModel>[];
};

export default class TicketAdapter extends ApplicationAdapter {
  @service vendor!: VendorService;
  @service store!: DS.Store;

  get buildURLPrefix(): string {
    return `${host()}/${this.namespace}`;
  }

  buildURL(
    _modelName: unknown,
    _id: unknown,
    snapshot: unknown,
    requestType: string,
    query?: { tableId?: string }
  ): string {
    if (requestType === 'basketFromExistingTicket') {
      return this.buildBasketFromExistingTicketURL(snapshot);
    } else if (requestType === 'query' && query?.tableId) {
      return this.buildQueryURL();
    }
    return super.buildURL(...arguments);
  }

  getTickets(tableId: string): RSVP.Promise<TicketsResponse> {
    const url = `${this.buildQueryURL()}?tableId=${tableId}`;
    return this.ajax(url, 'GET');
  }

  async getTicket(ticketId: string): Promise<TicketResponse> {
    const url = `${this.buildQueryURL()}/${ticketId}`;
    const response = await this.ajax(url, 'GET');
    if (isSome(response.ticket)) {
      this.store.pushPayload({ ticket: response.ticket });
    }
    return response;
  }

  private buildBasketFromExistingTicketURL(snapshot: unknown) {
    const s = snapshot as DS.Snapshot<'ticket'>;
    const attributes = s.attributes();

    const vendorId = this.getVendorId();

    return `${this.buildURLPrefix}/basketFromExistingTicket?vendorId=${vendorId}&ticketNumber=${attributes.ticketNumber}`;
  }

  private buildQueryURL() {
    const vendorId = this.getVendorId();
    // We don't explicitly need a `tableId` query param.
    // Because this is `store.query`, the keys and values of `query`
    // automatically get appended as query params
    return `${this.buildURLPrefix}/vendors/${vendorId}/tickets`;
  }

  private getVendorId(): string {
    const vendorId = this.vendor.vendor?.id;
    if (!vendorId) {
      throw new Error('Vendor Id for Ticket Not Found');
    }
    return vendorId;
  }
}
