import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SaveFavorite } from 'mobile-web/components/save-favorite-modal';
import { isFunction } from 'mobile-web/lib/utilities/_';
import BillingComponent from 'mobile-web/models/billing-component';
import Order from 'mobile-web/models/order';
import AnalyticsService from 'mobile-web/services/analytics';
import BootstrapService from 'mobile-web/services/bootstrap';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Args {
  // Required arguments
  order: Order;

  // Optional arguments
  isLoggedIn?: boolean;
  isRestrictedFlow?: boolean;
  saveFavorite?: SaveFavorite;
  hideTotals?: boolean;
}

interface Signature {
  Args: Args;
}

export default class PostCheckoutAdditionalDetails extends Component<Signature> {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service storage!: StorageService;
  @service analytics!: AnalyticsService;
  @service groupOrder!: GroupOrderService;
  @service features!: FeaturesService;
  @service onPremise!: OnPremiseService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked showSaveFavoriteModal = false;

  // Getters and setters
  get isMultiConcept(): boolean {
    return (
      this.features.flags.VBGK_MENU_AGGREGATION &&
      this.args.order.basketProducts.any(b => !!b.brandName)
    );
  }

  get paymentMethods(): BillingComponent[] {
    return this.args.order.billingComponents.toArray();
  }

  get onlyOnePaymentMethod(): boolean {
    return this.paymentMethods.length === 1;
  }

  get firstPaymentMethodDescription(): string {
    return this.paymentMethods[0].description;
  }

  get showSaveFavorite(): boolean {
    return (
      !this.args.order.groupOrderId &&
      !this.args.isRestrictedFlow &&
      !this.args.order.isFavorite &&
      isFunction(this.args.saveFavorite) &&
      !!this.args.isLoggedIn &&
      !this.isMultiConcept
    );
  }

  get hideTotals(): boolean {
    return (this.args.hideTotals ?? false) || this.args.order.groupOrderId !== undefined;
  }

  get orderDisplayId(): string {
    const displayId = this.args.order.displayId;
    const last3 = this.features.flags['three-digit-order-number-olo-61552'];

    return last3 ? displayId.substring(displayId.length - 3) : displayId;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  openSaveFavoriteModal() {
    this.showSaveFavoriteModal = true;
  }

  @action
  closeSaveFavoriteModal() {
    this.showSaveFavoriteModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::AdditionalDetails': typeof PostCheckoutAdditionalDetails;
  }
}
