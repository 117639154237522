export default {
  "list": "_list_ed651g",
  "item": "_item_ed651g",
  "columns-1": "_columns-1_ed651g",
  "columns-2": "_columns-2_ed651g",
  "columns-3": "_columns-3_ed651g",
  "columns-4": "_columns-4_ed651g",
  "columns-5": "_columns-5_ed651g",
  "hidden": "_hidden_ed651g"
};
