/**
 * Originally based on https://github.com/yapplabs/ember-modal-dialog/blob/v4.0.0-alpha.1/addon/components/modal-dialog.js
 */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { APPLICATION_FRAME_ID } from 'mobile-web/components/application-frame';
import { safeNext } from 'mobile-web/lib/runloop';
import { guids } from 'mobile-web/lib/utilities/guids';
import { bindActionToKeyEvent, Key } from 'mobile-web/lib/utilities/keys';
import FocusService from 'mobile-web/services/focus';
import ModalStackService, { ModalConfig } from 'mobile-web/services/modal-stack';

interface Args {
  // Required arguments

  // Optional arguments
  onClose?: () => void;
  clickOutsideToClose?: boolean;
  containerClass?: string;
  overlayClass?: string;
  wrapperClass?: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class ModalDialog extends Component<Signature> {
  // Service injections
  @service focus!: FocusService;
  @service modalStack!: ModalStackService;

  // Untracked properties
  ids = guids(this, 'modal', 'overlay');

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  setup(element: HTMLElement) {
    const modal: ModalConfig = {
      element,
      lastFocus: document.activeElement,
    };
    if (this.args.onClose) {
      modal.onClose = bindActionToKeyEvent(Key.Escape, this.args.onClose);
    }
    this.modalStack.push(modal);

    safeNext(this, () => this.initialize(modal));
  }

  @action
  initialize(modal: ModalConfig, focus?: typeof document.activeElement) {
    document.getElementById(APPLICATION_FRAME_ID)?.setAttribute('aria-hidden', 'true');

    this.focus.addTrap(modal.element);

    if (this.modalStack.previous?.onClose) {
      document.removeEventListener('keydown', this.modalStack.previous.onClose, true);
    }
    if (modal.onClose) {
      document.addEventListener('keydown', modal.onClose, true);
    }

    modal.element.setAttribute('tabindex', '0');
    this.focus.focus(focus ?? modal.element);
  }

  @action
  teardown() {
    const modal = this.modalStack.pop()!;

    if (this.modalStack.length === 0) {
      document.getElementById(APPLICATION_FRAME_ID)?.removeAttribute('aria-hidden');
    }

    this.focus.removeTrap(modal.element);

    if (modal.onClose) {
      document.removeEventListener('keydown', modal.onClose, true);
    }

    if (this.modalStack.top) {
      this.initialize(this.modalStack.top, modal.lastFocus);
    } else {
      this.focus.focus(modal.lastFocus);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ModalDialog: typeof ModalDialog;
  }
}
