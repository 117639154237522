import Component from '@glimmer/component';

import { ensureSafeComponent } from '@embroider/util';
import { ComponentLike, WithBoundArgs } from '@glint/template';

import Svg from 'mobile-web/components/svg';

import { PostCheckoutSubsectionButtonContainerSignature } from './button-container';
import style from './index.m.scss';
import { PostCheckoutSubsectionLineItemSignature } from './line-item';
import { PostCheckoutSubsectionLineItemContainerSignature } from './line-item-container';

interface Args {
  heading?: string;
  icon?: WithBoundArgs<typeof Svg, 'icon'>;
}

interface Signature {
  Args: Args;

  Blocks: {
    buttons: [
      {
        buttonClass: string;
        buttonContainer: ComponentLike<PostCheckoutSubsectionButtonContainerSignature>;
      }
    ];
    content: [
      {
        lineItem: ComponentLike<PostCheckoutSubsectionLineItemSignature>;
        lineItemContainer: ComponentLike<PostCheckoutSubsectionLineItemContainerSignature>;
      }
    ];
  };
}

export default class PostCheckoutSubsection extends Component<Signature> {
  style = style;

  get icon() {
    const icon = this.args.icon;
    // Passing `icon` to `ensureSafeComponent` stopped working after an upgrade.
    // Glint and Embroider types probably just need a few releases to get in sync.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return icon ? (ensureSafeComponent(icon as any, this) as typeof icon) : undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::Subsection': typeof PostCheckoutSubsection;
  }
}
