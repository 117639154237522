import { inject as service } from '@ember/service';

import UrlTemplates from 'ember-data-url-templates';

import { host } from 'mobile-web/lib/hybrid-util';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';

export default class QualifyingLoyaltyReward extends ApplicationAdapter.extend(UrlTemplates) {
  @service vendor!: VendorService;

  coalesceFindRequests = true;
  urlTemplate = host() + '/{+namespace}/vendors/{vendorId}/rewards/{rewardId}';
  urlSegments = {
    vendorId(this: QualifyingLoyaltyReward): string | undefined {
      return this.vendor.vendor?.id;
    },

    rewardId(_type: unknown, id: EmberDataId): EmberDataId {
      return id;
    },

    namespace(this: QualifyingLoyaltyReward): string {
      return this.namespace;
    },
  };
}
