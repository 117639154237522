import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import config from 'mobile-web/config/environment';
import { guids } from 'mobile-web/lib/utilities/guids';
import DispatchStatusModel, { CourierStatus } from 'mobile-web/models/dispatch-status';
import OrderModel from 'mobile-web/models/order';

import style from './index.m.scss';

const REFRESH_INTERVAL = 60000;

interface Args {
  // Required arguments
  order: OrderModel;
  initialStatuses: DS.RecordArray<DispatchStatusModel>;

  // Optional arguments
  hash?: string;
}

interface Signature {
  Args: Args;
}

export default class DispatchSummaryRoute extends Component<Signature> {
  // Service injections
  @service router!: RouterService;
  @service store!: DS.Store;

  // Untracked properties
  ids = guids(this, 'heading');
  style = style;

  // Tracked properties
  @tracked statuses: DispatchStatusModel[] = [];

  // Getters and setters
  get hasActiveStatus(): boolean {
    return this.statuses.reduce(
      (isActive, status) => isActive || status.courierStatus === CourierStatus.Active,
      false
    );
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.statuses = this.args.initialStatuses.toArray();

    if (config.environment !== 'test') {
      later(this, this.refresh, REFRESH_INTERVAL);
    }
  }

  // Other methods
  async refresh() {
    if (!this.isDestroying && !this.isDestroyed && this.hasActiveStatus) {
      const newStatuses = await this.store.query('dispatch-status', {
        orderId: this.args.order.id,
        hash: this.args.hash,
      });

      newStatuses.forEach(ns => {
        if (!this.statuses.find(s => s.id === ns.id)) {
          this.statuses = [...this.statuses, ns];
        }
      });

      later(this, this.refresh, REFRESH_INTERVAL);
    }
  }

  // Tasks

  // Actions and helpers
  @action
  goToDetails(status: DispatchStatusModel) {
    this.router.transitionTo('dispatch-status', status.order.id, status.id, {
      queryParams: { hash: this.args.hash },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::DispatchSummaryRoute': typeof DispatchSummaryRoute;
  }
}
