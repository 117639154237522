import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { MediaService } from 'ember-responsive';

import { noop } from 'mobile-web/lib/utilities/_';
import { guids } from 'mobile-web/lib/utilities/guids';

import style from './index.m.scss';

type Event = 'hover' | 'click' | 'focus' | 'none';
type Side = 'top' | 'right' | 'bottom' | 'left';

/**
 * Ember Tooltip supports Popper "modifier" options.
 */
export type PopperOptions = {
  modifiers: AnyObject;
};

interface Args {
  // Required arguments

  // Optional arguments
  arrowClass?: string;
  class?: string;
  event?: Event;
  onShow?: () => void;
  options?: PopperOptions;
  showArrow?: boolean;
  side?: Side;
  /**
   * @todo "left" and "right" sides are not supported currently.
   * Likely will never be used and deleted if these are needed,
   * but leaving this quick-fix of using Popper default arrows.
   */
  useDefaultArrows?: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLSpanElement;
  Blocks: {
    content: [];
    trigger: [];
  };
}

export default class Tooltip extends Component<Signature> {
  // Service injections
  @service media!: MediaService;

  // Untracked properties
  ids = guids(this, 'trigger');
  style = style;

  // Tracked properties

  // Getters and setters
  get arrowClasses(): string {
    if (this.args.useDefaultArrows) {
      return '';
    }
    if (this.args.arrowClass) {
      return this.args.arrowClass;
    }
    return this.args.showArrow ? this.style.arrowClass : this.style.hideArrow;
  }

  get tooltipClasses(): string {
    return this.args.class || this.style.customTooltip;
  }

  get event(): Event {
    if (this.args.event) {
      return this.args.event;
    }

    return this.media.isMobile ? 'click' : 'hover';
  }

  get side(): Side {
    return this.args.side ?? 'top';
  }

  get onShow(): () => void {
    return this.args.onShow ?? noop;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Tooltip: typeof Tooltip;
  }
}
