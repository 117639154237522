export default {
  "root": "_root_2ik4e3",
  "checkCard": "_checkCard_2ik4e3",
  "title": "_title_2ik4e3",
  "heading": "_heading_2ik4e3",
  "tablePanelMessage": "_tablePanelMessage_2ik4e3",
  "text": "_text_2ik4e3",
  "paidBadge": "_paidBadge_2ik4e3",
  "infoIcon": "_infoIcon_2ik4e3",
  "checkIcon": "_checkIcon_2ik4e3",
  "resultsContainer": "_resultsContainer_2ik4e3",
  "container": "_container_2ik4e3",
  "header": "_header_2ik4e3",
  "headingCenter": "_headingCenter_2ik4e3",
  "message": "_message_2ik4e3",
  "phoneButton": "_phoneButton_2ik4e3",
  "list": "_list_2ik4e3",
  "lineItem": "_lineItem_2ik4e3",
  "item": "_item_2ik4e3",
  "itemSection": "_itemSection_2ik4e3",
  "quantity": "_quantity_2ik4e3",
  "information": "_information_2ik4e3"
};
