import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import ChannelService from 'mobile-web/services/channel';
import ImageService, { LogoImages } from 'mobile-web/services/image';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  isError?: boolean;
}

interface Signature {
  Element: HTMLElement;

  Args: Args;

  Blocks: {};
}

export default class Logo extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service image!: ImageService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked logoImages: LogoImages = {
    large: '',
    small: '',
  };

  // Getters and setters
  get largeImage() {
    return this.logoImages.large;
  }

  get smallImage() {
    return this.logoImages.small;
  }

  // Lifecycle methods
  constructor(owner: unknown, args: UnknownObject) {
    super(owner, args);

    this.image.loadLogoImages().then(
      logoImages => {
        this.logoImages = logoImages;
      },
      () => {
        // TODO: decide what to do when loading the images fails
      }
    );
  }

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Logo: typeof Logo;
  }
}
