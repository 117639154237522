import Evented from '@ember/object/evented';
import Service from '@ember/service';

/**
 * This interface intentionally left empty. It is meant to be extended in other files:
 *
 * declare module 'mobile-web/services/bus' {
 *   interface EventRegistry {
 *     // ...
 *   }
 * }
}
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EventRegistry {}

export default class BusService extends Service.extend(Evented) {
  // Service injections

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  on<K extends keyof EventRegistry>(name: K, method: (arg: EventRegistry[K]) => void): this {
    return super.on(name, method);
  }

  trigger<K extends keyof OmitExtends<EventRegistry, void>>(name: K, arg: EventRegistry[K]): void;
  trigger<K extends keyof PickExtends<EventRegistry, void>>(name: K): void;
  trigger<K extends keyof EventRegistry>(name: K, arg?: unknown): void {
    super.trigger(name, arg);
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    bus: BusService;
  }
}
