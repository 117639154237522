import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { Capacitor } from '@capacitor/core';
import { MediaService } from 'ember-responsive';

import ENV from 'mobile-web/config/environment';
import { ErrorCategory } from 'mobile-web/lib/errors';
import { host, mapFetch, EmberResponse } from 'mobile-web/lib/hybrid-util';
import { Status } from 'mobile-web/lib/utilities/http';
import isSome from 'mobile-web/lib/utilities/is-some';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import ChallengeService from 'mobile-web/services/challenge';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import ErrorService, { Errors, ServerError, CommonError } from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

const HEADER = '__RequestVerificationToken';
const getToken = () =>
  document.querySelector<HTMLInputElement>(`input[name=${HEADER}]`)?.value ?? '';

export type AdapterHeaders = Record<string, string>;
export type ResponseHeaders = Record<string, string>;

export default class ApplicationAdapter extends DS.RESTAdapter {
  @service media!: MediaService;
  @service storage!: StorageService;
  @service device!: DeviceService;
  @service session!: SessionService;
  @service channel!: ChannelService;
  @service onPremise!: OnPremiseService;
  @service error!: ErrorService;
  @service challenge!: ChallengeService;
  @service features!: FeaturesService;
  @service analytics!: AnalyticsService;

  namespace = 'api';
  host = host();

  @tracked private currentPath?: string;

  @computed(
    'channel.currentCountry',
    'currentPath',
    'device.{isHybrid,platform,viewport}',
    'onPremise.isEnabled',
    'session.serveAppToken',
    'storage.forceChallengePattern'
  )
  // Issue is TS2611: defined as a property in the base class, but a getter here
  // Base types are wrong in this case. Docs say you are allowed to define `headers` as a getter.
  // @ts-ignore
  get headers(): AdapterHeaders {
    const headers: AdapterHeaders = {
      [HEADER]: getToken(),
      Accept: 'application/json, */*',
      'X-Requested-With': 'XMLHttpRequest',
      'X-Olo-Request': '1',
      'X-Olo-Viewport': this.device.viewport,
      'X-Olo-App-Platform': this.device.platform,
      'X-Olo-Country': this.channel.currentCountry,
    };

    const pattern = this.storage.forceChallengePattern;
    if (pattern && new RegExp(pattern).test(this.currentPath ?? '')) {
      headers['X-PX-Block'] = '1';
    }

    if (this.device.isHybrid && this.session.serveAppToken) {
      headers['X-Olo-Serve-User-Token'] = this.session.serveAppToken;
    }

    if (this.onPremise.isEnabled) {
      headers['X-Olo-Onpremise'] = '1';
    }

    return headers;
  }

  shouldBackgroundReloadRecord(_store: DS.Store, _snapshot: DS.Snapshot): boolean {
    return false;
  }

  /**
   * The RESTAdapter uses this method to parse any error responses that come through the system.
   * This is a private API method, which is sad, but overriding it allows us to customize
   * the shape of the error responses we get from Ember Data.
   *
   * The payloadData parameter comes to us as a string, but it is *usually* a stringified JSON
   * object. We do a JSON.parse on it to get the actual error shape, and then massage it to the
   * shape we want. Thr try/catch is here to make the JSON.parse call a safe call, because we
   * don't want to be throwing errors from our error handler. If things go wrong, we just let
   * Ember Data do its normal thing by calling up the super method.
   */
  normalizeErrorResponse(status: Status, _headers: unknown, payload?: ServerError): CommonError[] {
    const responseHeaders = _headers as ResponseHeaders | undefined;
    const traceId = responseHeaders?.['olo-trace-id'];

    try {
      if ((this.features.flags['cloudflare-turnstile-olo-83472'] ?? false) && !ENV.isHybrid) {
        if (responseHeaders) {
          const challengeRequested = responseHeaders['cf-mitigated'] === 'challenge';
          if (challengeRequested) {
            this.analytics.trackEvent(AnalyticsEvents.ApiRequestChallenged, () => ({
              [AnalyticsProperties.Source]: this.currentPath,
              [AnalyticsProperties.ResponseHeaders]: Object.keys(responseHeaders)
                .filter(k => k.startsWith('cf-'))
                .map(key => `${key}=${responseHeaders[key]}`),
            }));

            return [
              {
                status,
                message: JSON.stringify({ challengeRequested }),
              },
            ];
          }
        }
      }

      if (payload?.errorCode === Errors.ForUser || payload?.errorCode === 'MessageForUser') {
        return [
          {
            status,
            title: Errors.ForUser,
            message: payload.message,
            category: payload.category as ErrorCategory | undefined,
            traceId,
          },
        ];
      }
    } catch {
      /* catching stringify errors */
    }

    // @ts-ignore
    const normalizedErrs = super.normalizeErrorResponse(status, _headers, payload) as CommonError[];
    const tracedErrs = normalizedErrs.map(err => ({ ...err, traceId }));

    return tracedErrs;
  }

  generatedDetailedMessage(
    status: unknown,
    headers: unknown,
    payload: unknown,
    requestData: unknown
  ): string {
    // @ts-ignore using private API :(
    return super.generatedDetailedMessage(status, headers, JSON.stringify(payload), requestData);
  }

  // TODO: Remove with extreme prejudice. MWC-3421
  // ^ probably unlikely until Ember gives us some public way to do this :(
  _fetchRequest(options: UnknownObject): unknown {
    const fetchMethod =
      !ENV.useMirage && ENV.isHybrid && Capacitor.isNativePlatform()
        ? // If this is a hybrid app, we need to use the Cordova HTTP plugin to get around CORS issues
          mapFetch
        : // @ts-ignore private API :(
          super._fetchRequest;

    return fetchMethod(options).then((r: EmberResponse) => {
      // We have to do this because we have a handful of endpoints that return no body content
      // with a status code of 200. Ember really doesn't like that, and ends up throwing an error.
      // Endpoints that return no body should be using a status code of 204.
      // This manipulates the response to pretend like it is a good response until we have a chance
      // to make a PLAT PR that actually makes them be good responses.
      if ((r._bodyText === '' || r._bodyBlob?.size === 0) && r.status === 200) {
        // @ts-ignore
        r.status = 204;
      }
      try {
        const cacheControl = r.headers.get('Cache-Control');
        const expectedCacheControl = 'no-store';
        if (isSome(cacheControl) && cacheControl.indexOf(expectedCacheControl) === -1) {
          throw new Error(
            `Incorrect cache headers received! Expected ${expectedCacheControl}, but got: ` +
              cacheControl
          );
        }
      } catch (e) {
        // The above is a safety guard for bad cloudflare settings
        // If we can't check the cache-control header for some reason, continue anyway.
        this.error.sendExternalError(e);
      }
      return r;
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  ajax(url: string, type: string, options?: object): RSVP.Promise<any> {
    // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
    this.currentPath = url;
    return super.ajax(url, type, options);
  }
}
