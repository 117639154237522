export default {
  "minor": "_minor_1l8nai",
  "major": "_major_1l8nai",
  "buttonContent": "_buttonContent_1l8nai",
  "label": "_label_1l8nai",
  "small": "_small_1l8nai _label_1l8nai",
  "large": "_large_1l8nai _label_1l8nai",
  "iconWrapper": "_iconWrapper_1l8nai",
  "icon": "_icon_1l8nai",
  "left": "_left_1l8nai _iconWrapper_1l8nai",
  "right": "_right_1l8nai _iconWrapper_1l8nai"
};
