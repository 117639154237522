import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  heading: string;

  // Optional arguments
  testSelector?: string;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    content: [];
    buttons: [];
  };
}

export default class HubCard extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  get testSelector(): string {
    return this.args.testSelector ?? '';
  }

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Hub::Card': typeof HubCard;
  }
}
