export default {
  "root": "_root_1xgbo0",
  "subsection": "_subsection_1xgbo0",
  "header": "_header_1xgbo0",
  "list": "_list_1xgbo0",
  "lineItem": "_lineItem_1xgbo0",
  "modifiers": "_modifiers_1xgbo0",
  "content": "_content_1xgbo0",
  "subTotal": "_subTotal_1xgbo0",
  "moreItems": "_moreItems_1xgbo0",
  "itemPrice": "_itemPrice_1xgbo0",
  "leftToPay": "_leftToPay_1xgbo0",
  "indented": "_indented_1xgbo0"
};
