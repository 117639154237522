import Controller from '@ember/controller';

import DispatchSummaryRoute from 'mobile-web/routes/dispatch-summary';

export default class DispatchSummaryController extends Controller {
  declare model: ModelForRoute<DispatchSummaryRoute>;

  queryParams = ['hash', 'id'];

  hash?: string;
}
