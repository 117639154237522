import { action } from '@ember/object';
import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  // Optional arguments
  visible?: boolean;
  onDismiss?: () => void;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    message: [];
    button: [];
  };
}

export default class CheckoutBanner extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get isVisible() {
    return this.args.visible ?? true;
  }

  get isDismissable() {
    return this.args.visible !== undefined && this.args.onDismiss !== undefined;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  dismiss() {
    this.args.onDismiss?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checkout::Banner': typeof CheckoutBanner;
  }
}
