/**
 * Adapted from https://github.com/michalsnik/ember-content-placeholders/blob/v1.0.0/addon/templates/components/content-placeholders.hbs
 * We had a dependency on that project until we realized that it was dead and triggering deprecations.
 * The API is the same except we dropped unused features:
 *    - `placeholder.nav`
 *    - `centered` argument
 *    - `rounded` argument
 */

import Component from '@glimmer/component';

import { WithBoundArgs } from '@glint/template';

import ContentPlaceholdersHeading from './heading';
import ContentPlaceholdersImg from './img';
import ContentPlaceholdersText from './text';

interface Signature {
  Element: HTMLElement;

  Args: {
    animated?: boolean;
  };

  Blocks: {
    default: [
      {
        heading: WithBoundArgs<typeof ContentPlaceholdersHeading, 'animated'>;
        text: WithBoundArgs<typeof ContentPlaceholdersText, 'animated'>;
        img: WithBoundArgs<typeof ContentPlaceholdersImg, 'animated'>;
      }
    ];
  };
}

export default class ContentPlaceholders extends Component<Signature> {
  get animated() {
    return this.args.animated ?? true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ContentPlaceholders: typeof ContentPlaceholders;
  }
}
