import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { later } from '@ember/runloop';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';

import * as PopoverItem from 'mobile-web/components/popover-item';
import { noop } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

interface Args {
  // Required arguments
  target: HTMLElement;

  // Optional arguments
  isHeader?: boolean;
  model?: PopoverItem.Model[];
  onClick?: Action;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class PopoverContent extends Component<Signature> {
  style = style;

  get onClick(): Action {
    return this.args.onClick ?? noop;
  }

  get uniqueId(): string {
    return guidFor(this);
  }

  @action
  didInsert() {
    later(this, this.positionArrow, 1);
  }

  positionArrow() {
    const ARROW_WIDTH = 16; // hard-coded copy of value (`$spacing` * 2) from SCSS

    // We can't do anything if the content or arrow elements don't exist. (They
    // always should, but better to do nothing than to trigger an error.)
    const contentEl = document.querySelector(`[data-popover-content="${this.uniqueId}"]`);
    const arrowEl = document.querySelector(`[data-popover-content-arrow="${this.uniqueId}"]`);
    if (isNone(contentEl) || isNone(arrowEl)) {
      return;
    }

    const triggerCenter = this.args.target.offsetWidth / 2;
    const triggerLeftEdge = this.args.target.getBoundingClientRect().left;
    const contentLeftEdge = contentEl.getBoundingClientRect().left;
    const leftEdgeDiff = contentLeftEdge < triggerLeftEdge ? triggerLeftEdge - contentLeftEdge : 0;
    const offset = leftEdgeDiff + triggerCenter - ARROW_WIDTH / 2;

    arrowEl.setAttribute('style', `left: ${offset}px;`);
  }

  asActionModel = (m: PopoverItem.Model): PopoverItem.ActionModel | undefined =>
    'action' in m ? m : undefined;

  asRouteModel = (m: PopoverItem.Model): PopoverItem.RouteModel | undefined =>
    'route' in m ? m : undefined;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PopoverContent: typeof PopoverContent;
  }
}
