import DS from 'ember-data';

import FavoriteChoiceModel from './favorite-choice';

export default class FavoriteProductModel extends DS.Model {
  @DS.attr('string')
  productName!: string;
  @DS.attr('string')
  recipient!: string;
  @DS.attr('string')
  specialInstructions!: string;
  @DS.attr('number')
  quantity!: number;

  @DS.hasMany('favorite-choice', { async: false })
  favoriteChoices!: DS.ManyArray<FavoriteChoiceModel>;
}
