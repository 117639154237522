import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { stateFullName } from 'mobile-web/lib/state';
import ChallengeService from 'mobile-web/services/challenge';
import ChannelService from 'mobile-web/services/channel';

export default class RegionResultsRoute extends Route {
  @service challenge!: ChallengeService;
  @service channel!: ChannelService;
  @service router!: RouterService;
  @service store!: DS.Store;

  get pageTitle() {
    const params = this.paramsFor(this.routeName) as { region_code: string };
    return stateFullName(params.region_code) + ' Locations';
  }

  beforeModel(transition: Transition) {
    if (!this.channel.settings?.showParticipatingRestaurants) {
      transition.abort();
      this.router.transitionTo('index');
    }

    const region = transition.to.params.region_code!;
    if (region !== region.toUpperCase()) {
      transition.abort();
      this.router.transitionTo('region-results', region.toUpperCase());
    }
  }

  model(params: { region_code: string }) {
    return RSVP.hash({
      result: this.store.query('vendor-search-result', { state: params.region_code }),
      pageTitle: this.pageTitle,
    });
  }
}
