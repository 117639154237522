import OldComponent from '@ember/component';
import { next } from '@ember/runloop';
import Component from '@glimmer/component';

import { modifier } from 'ember-modifier';

const ref = modifier(
  (element: Element, [context, key]: [Component<any> | OldComponent, string]) => {
    const ctx = context as unknown as UnknownObject;
    next(context, () => {
      const value = ctx[key];
      if (Array.isArray(value)) {
        ctx[key] = [...value, element];
      } else {
        ctx[key] = element;
      }
    });

    return () => {
      next(context, () => {
        const value = ctx[key];
        if (Array.isArray(value)) {
          ctx[key] = value.filter(el => el !== element);
        } else {
          ctx[key] = undefined;
        }
      });
    };
  },
  {
    eager: false,
  }
);

export default ref;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ref: typeof ref;
  }
}
