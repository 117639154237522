import ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  model?: ArrayProxy<OrderSearchResultModel>;
}

interface Signature {
  Args: Args;
}

export default class RecentOrdersRoute extends Component<Signature> {
  // Service injections
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get orders() {
    return this.args.model?.toArray() ?? [];
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  startOrder() {
    this.router.transitionTo('index');
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::RecentOrdersRoute': typeof RecentOrdersRoute;
  }
}
