/**
 * Originally based on https://github.com/yapplabs/ember-modal-dialog/blob/v4.0.0-alpha.1/addon/components/basic-dialog.js
 */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { MODAL_DIALOG_ROOT_ID } from 'mobile-web/instance-initializers/modal-dialog';
import { guids } from 'mobile-web/lib/utilities/guids';
import DeviceService from 'mobile-web/services/device';

import style from './index.m.scss';

interface Args {
  // Required arguments
  modalId: string;
  overlayId: string;

  // Optional arguments
  onClose?: () => void;
  clickOutsideToClose?: boolean;
  containerClass?: string;
  overlayClass?: string;
  wrapperClass?: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class BasicDialog extends Component<Signature> {
  // Service injections
  @service device!: DeviceService;

  // Untracked properties
  destinationElement = document.getElementById(MODAL_DIALOG_ROOT_ID)!;
  ids = guids(this, 'modal');
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods
  makeOverlayClickableOnIOS() {
    if (this.device.isIOS) {
      const overlayEl = document.getElementById(this.args.overlayId);
      if (overlayEl) {
        overlayEl.style.cursor = 'pointer';
      }
    }
  }

  // Tasks

  // Actions and helpers
  @action
  didInsert() {
    this.makeOverlayClickableOnIOS();
  }

  @action
  onClickOverlay(e: Event) {
    if (!this.args.clickOutsideToClose) {
      return;
    }

    const target = e.target as HTMLElement | null;

    // if the click has already resulted in the target
    // being removed or hidden, do nothing
    if (!target?.offsetWidth && !target?.offsetHeight) {
      return;
    }

    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    const modalEl = document.getElementById(this.args.modalId);
    // if the click is within the dialog, do nothing
    if (modalEl && modalEl.contains(target)) {
      return;
    }

    this.args.onClose?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ModalDialog::BasicDialog': typeof BasicDialog;
  }
}
