import DS from 'ember-data';

import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';

import { uniqWith } from 'mobile-web/lib/utilities/_';
import SavedAddressModel from 'mobile-web/models/address';

const ADDRESS_COMPARISON_PROPS = ['streetAddress', 'building', 'city', 'zipCode'] as const;
const addressFieldsOf = (
  input: SavedAddressModel
): Pick<SavedAddressModel, typeof ADDRESS_COMPARISON_PROPS[number]> =>
  pick(input, ADDRESS_COMPARISON_PROPS);

function addressesMatch(a: SavedAddressModel, b: SavedAddressModel): boolean {
  return isEqual(addressFieldsOf(a), addressFieldsOf(b));
}

export default class AddressSerializer extends DS.JSONSerializer {
  normalizeArrayResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: PureModel<SavedAddressModel>[],
    id: EmberDataId,
    requestType: string
  ): UnknownObject {
    const deduped = uniqWith(payload, addressesMatch);
    return super.normalizeArrayResponse(store, primaryModelClass, deduped, id, requestType);
  }
}
