import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { dropTask, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import OrderModel from 'mobile-web/models/order';

export type SaveFavorite = (orderId: EmberDataId, name: string) => RSVP.Promise<void>;

interface Args {
  // Required arguments
  onClose: () => void;
  order: OrderModel;
  saveFavorite: SaveFavorite;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class SaveFavoriteModal extends Component<Signature> {
  // Service injections

  // Untracked properties

  // Tracked properties
  @tracked name = '';

  // Getters and setters
  get isSubmitDisabled(): boolean {
    return this.submitTask.isRunning || isEmpty(this.name);
  }

  get products() {
    return this.args.order.basketProducts.toArray();
  }

  // Lifecycle methods

  // Other methods

  // Tasks
  submitTask = taskFor(this.submitGenerator);
  @dropTask *submitGenerator(
    saveFavorite: SaveFavorite,
    orderId: string,
    name: string,
    close: () => void
  ): TaskGenerator<void> {
    yield saveFavorite(orderId, name);
    close();
  }

  // Actions and helpers
  @action
  doSubmit() {
    this.submitTask.perform(
      this.args.saveFavorite,
      this.args.order.id,
      this.name,
      this.args.onClose
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SaveFavoriteModal: typeof SaveFavoriteModal;
  }
}
