/* eslint-disable no-use-before-define */
import DS from 'ember-data';

import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import isSome from 'mobile-web/lib/utilities/is-some';
import { GroupOrderParticipantStatus } from 'mobile-web/services/group-order';

import BasketModel from './basket';

export type GroupOrderParticipant = {
  participantName: string;
  status?: GroupOrderParticipantStatus;
  timeJoined?: Date;
  timeUpdated?: Date;
};

export default class GroupOrderModel extends DS.Model {
  @DS.attr basketId!: string;
  @DS.attr hostBasketId!: string;
  @DS.attr host!: { firstName: string; lastName: string };
  @DS.attr currentUserIsHost!: boolean;
  @DS.attr groupOrderNumber!: number;
  @DS.attr status!: string;
  @DS.attr participants?: GroupOrderParticipant[];

  @memberAction<GroupOrderParticipant, AnyObject>({
    type: 'post',
    path: 'join',
    after: pushPayload,
  })
  joinGroupOrder!: MemberAction<
    GroupOrderParticipant,
    { basket: BasketModel; groupOrder: GroupOrderModel }
  >;

  @memberAction<GroupOrderParticipant, AnyObject>({
    type: 'post',
    path: 'joinAsHost',
    after: pushPayload,
  })
  joinGroupOrderAsHost!: MemberAction<void, { basket: BasketModel; groupOrder: GroupOrderModel }>;

  @memberAction<GroupOrderParticipant, { basket: BasketModel; groupOrder: GroupOrderModel }>({
    type: 'post',
    path: 'update-participant-status',
  })
  updateParticipantStatus!: MemberAction<
    GroupOrderParticipant,
    { basket: BasketModel; groupOrder: GroupOrderModel }
  >;

  @memberAction<void, GroupOrderModel>({
    type: 'post',
    path: 'cancel',
  })
  cancelGroupOrder!: MemberAction<void, GroupOrderModel>;

  @memberAction<void, AnyObject>({
    type: 'put',
    path: 'lock',
    after: pushPayload,
  })
  lockGroupOrder!: MemberAction<void, GroupOrderModel>;

  @memberAction<void, AnyObject>({
    type: 'put',
    path: 'unlock',
    after: pushPayload,
  })
  unlockGroupOrder!: MemberAction<void, GroupOrderModel>;
}

export function pushPayload(this: GroupOrderModel, response: AnyObject) {
  if (isSome(response.basket)) {
    this.store.pushPayload({ groupOrder: response.groupOrder });
    return { basket: response.basket, groupOrder: response.groupOrder };
  }

  this.store.pushPayload({ groupOrder: response });
  return response;
}
