import ApplicationInstance from '@ember/application/instance';

/**
 * We use this instance initializer in order to eagerly instantiate the error service.
 * Please do not remove this.
 * It is needed in order for the error service init to run, which attaches a handler
 * to Ember.onerror, which lets us show error banners whenever there is an error in
 * the system anywhere. Global error handling is global and fun.
 */
export function initialize(appInstance: ApplicationInstance) {
  const errorService = appInstance.lookup('service:error');
  if (!errorService) {
    throw new Error('Failed to instantiate the error service');
  }
}

export default {
  name: 'error-handler',
  initialize,
};
