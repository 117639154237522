import { modifier } from 'ember-modifier';

const handler = (e: KeyboardEvent) => {
  if (
    !(e.currentTarget instanceof HTMLElement) ||
    e.ctrlKey ||
    e.metaKey ||
    e.altKey ||
    e.defaultPrevented
  ) {
    return;
  }

  if ((e.type === 'keydown' && e.key === 'Enter') || (e.type === 'keyup' && e.key === ' ')) {
    e.preventDefault();
    e.currentTarget.click();
  } else if (e.type === 'keydown' && e.key === ' ') {
    e.preventDefault();
  }
};

/**
 * The `key-click` modifier is used to fire `click` events from `Enter` and
 * `Space` keys on elements that don't natively fire `click` events for those
 * keys.
 *
 * Where possible, avoid this modifier in favor of `<button type="button">`.
 */
const keyClick = modifier(
  (element: Element) => {
    element.addEventListener('keydown', handler, false);
    element.addEventListener('keyup', handler, false);

    return () => {
      element.removeEventListener('keydown', handler, false);
      element.removeEventListener('keyup', handler, false);
    };
  },
  {
    eager: false,
  }
);

export default keyClick;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'key-click': typeof keyClick;
  }
}
