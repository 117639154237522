import Application from '@ember/application';

import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

import config from 'mobile-web/config/environment';
import { squelchAllErrorHandlers } from 'mobile-web/lib/errors';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

if (config.environment === 'development') {
  // Leaving this unsquelched in dev mode leads to infinite loops
  squelchAllErrorHandlers();
}

loadInitializers(App, config.modulePrefix);

// Fix the native back button breaking authentication, which crosses secure routes
// Menu -> Proceed to checkout -> Back button pressed -> Menu (with stale client state)
// https://web.dev/bfcache/
// https://stackoverflow.com/questions/33860241/safari-back-button-doesnt-reload-page-when-used
window.addEventListener(
  'pageshow',
  evt => {
    if (evt.persisted) {
      setTimeout(() => {
        window.location.reload();
      }, 10);
    }
  },
  false
);
