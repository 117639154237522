import { helper } from '@ember/component/helper';

import isSome from 'mobile-web/lib/utilities/is-some';

const classesHelper = helper((params: (string | undefined)[]) => params.filter(isSome).join(' '));

export default classesHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    classes: typeof classesHelper;
  }
}
