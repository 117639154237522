import ApplicationInstance from '@ember/application/instance';

export function initialize(appInstance: ApplicationInstance) {
  // construct the `PageScrollService` so that it applies to all pages.
  appInstance.lookup('service:page-scroll');
}

export default {
  initialize,
};
