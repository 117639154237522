import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  class?: string;
  iconClass?: string;
}

interface Signature {
  Element: HTMLSpanElement;
  Args: Args;
}

export default class LoadingSpinnerIcon extends Component<Signature> {
  style = style;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Icons::LoadingSpinner': typeof LoadingSpinnerIcon;
  }
}
