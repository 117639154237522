import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { vendorTime } from 'mobile-web/helpers/vendor-time';
import dayjs from 'mobile-web/lib/dayjs';
import Vendor from 'mobile-web/models/vendor';
import AddressService from 'mobile-web/services/address';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onClose: Action;
  vendor: Vendor;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class VendorInfoModal extends Component<Signature> {
  // Service injections
  @service address!: AddressService;
  @service intl!: IntlService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service features!: FeaturesService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get currentDate(): string {
    return vendorTime(dayjs().utc(), this.args.vendor).format(
      this.intl.t('mwc.vendorInfoModal.currentDayFormat')
    );
  }

  get mapsUrl() {
    return this.address.createMapsApplicaitonLink(this.args.vendor.address);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  trackClick() {
    if (this.features.flags['ox-append-channel-name-to-map-link-olo-80911']) {
      this.analytics.trackEvent(AnalyticsEvents.ViewMapClicked);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VendorInfoModal: typeof VendorInfoModal;
  }
}
