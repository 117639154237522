import { isNone } from '@ember/utils';
import RSVP, { resolve } from 'rsvp';

import { host } from 'mobile-web/lib/hybrid-util';

import ApplicationAdapter from './application';

export default class VendorSearchResultAdapter extends ApplicationAdapter {
  pathForType(): string {
    // This path is used by member actions, like toggleFavorite
    return 'vendors';
  }

  urlForQuery(): string {
    const path = this.pathForType();
    return `${host()}/${this.namespace}/${path}/search`;
  }

  query(
    _store: unknown,
    _typeClass: unknown,
    query: UnknownObject | null | undefined
  ): RSVP.Promise<unknown> {
    if (isNone(query)) {
      return resolve({ 'vendor-search-results': [] });
    }

    let request;

    if (query.state) {
      // Used when query is made by RegionResultsRoute
      const url = `${this.urlForQuery()}/${query.state}`;
      request = this.ajax(url, 'GET');
    } else {
      // Used when query is made by VendorSearchResultsRoute
      const url = `${this.urlForQuery()}`;
      request = this.ajax(url, 'POST', { data: query });
    }

    return request.then(data => {
      let results = data['vendor-search-results'];

      if (data.hasOwnProperty('favorite-vendor-search-results')) {
        results = data['favorite-vendor-search-results'].concat(data['vendor-search-results']);
      }

      return { 'vendor-search-results': results };
    });
  }
}
