import { assert } from '@ember/debug';
import { defineProperty, computed } from '@ember/object';
import DS from 'ember-data';

import isSome from 'mobile-web/lib/utilities/is-some';

type AttributeKeys<T extends DS.Model> = {
  [K in keyof T]: K extends 'savedAttributes' ? never : T[K] extends Primitive ? K : never;
}[keyof T] &
  NonDSModelKeys<T>;
/**
 * Ideally, we would be able to figure out which properties were decorated with DS.attr and get only those key names.
 * But TS has no way of determining that, so we approximate it by only including keys of strings/numbers/booleans.
 */
export type PrimitiveAttributes<T extends DS.Model> = { [K in AttributeKeys<T>]: T[K] };

const savedAttributes: PropertyDecorator = (
  target: { constructor: typeof DS.Model },
  key: string
) => {
  assert(
    '`key` must be equal to `savedAttributes` to keep a consistent API',
    key === 'savedAttributes'
  );

  defineProperty(
    target,
    key,
    computed('hasDirtyAttributes', function (this: DS.Model) {
      if (this.get('hasDirtyAttributes')) {
        const changes = this.changedAttributes();
        const result: UnknownObject = {};
        target.constructor.eachAttribute((name: string) => {
          const change = changes[name];
          result[name] = isSome(change) ? change[0] : this[name as keyof DS.Model];
        }, this);
        return result;
      }
      return this;
    })
  );
  return {};
};

export default savedAttributes;
