import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import DeviceService from 'mobile-web/services/device';

export interface Signature {
  Args: {
    Positional: Array<
      | 'isAndroid'
      | 'isHybrid'
      | 'isHybridAndroid'
      | 'isHybridIOS'
      | 'isIOS'
      | 'isWeb'
      | 'isWebAndroid'
      | 'isWebIOS'
    >;
  };
  Return: boolean;
}

/**
 * Use this to reference the getters on the device service in templates,
 * e.g. `{{device 'isHybrid'}} to check DeviceService#isHybrid
 */
export default class Device extends Helper<Signature> {
  @service device!: DeviceService;

  compute(params: Signature['Args']['Positional']): Signature['Return'] {
    return this.device[params[0]];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    device: typeof Device;
  }
}
