import { action } from '@ember/object';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import StickyNav from 'mobile-web/components/sticky-nav-old';
import { guids } from 'mobile-web/lib/utilities/guids';

import style from './index.m.scss';

export function sectionId(sectionElement: Element) {
  return sectionElement.getAttribute('data-section-id');
}

interface Args {
  // Required arguments
  stickyNav: StickyNav;
  title: string;

  // Optional arguments
  isHidden?: boolean;
}

interface Signature {
  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class StickyNavSection extends Component<Signature> {
  // Service injections

  // Untracked properties
  ids = guids(this, 'root');
  style = style;

  // Tracked properties
  @tracked rootElement!: HTMLDivElement;

  // Getters and setters
  get id() {
    // Some titles have double quotes, and that breaks when doing a querySelector call.
    return dasherize(this.args.title + this.ids.root).replace(/"/g, '');
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  setup(e: HTMLDivElement) {
    this.rootElement = e;
    this.args.stickyNav.registerSection(this);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'sticky-nav-old/section': typeof StickyNavSection;
  }
}
