import { Capacitor } from '@capacitor/core';
import { HTTP, HTTPResponse } from '@ionic-native/http';

import ENV from 'mobile-web/config/environment';
import { Platform } from 'mobile-web/services/device';

function channelSlug(): string {
  return window.Olo.channelSlug;
}

function host(): string {
  return ENV.isHybrid ? window.Olo.hybridAppHost : '';
}

type FetchMethod = 'get' | 'post' | 'put' | 'patch';

interface EmberResponse extends Response {
  _bodyText: string;
  _bodyInit: string;
  _bodyBlob: Blob;
}

function mapFetchResponse(resp: HTTPResponse, redirected: boolean): EmberResponse {
  const { status, headers = {}, url, data, error } = resp;
  const body = error || data;
  const parsedHeaders = new Headers(Object.entries(headers)) as Headers;
  const ok = status >= 200 && status < 300;
  const blob = new Blob([data]);
  const response = {
    _bodyText: body,
    _bodyInit: body,
    _bodyBlob: blob,
    status,
    headers: parsedHeaders,
    url,
    body,
    bodyUsed: body ? true : false,
    arrayBuffer: () => Promise.reject(new Error('arrayBuffer not supported by the hybrid app.')),
    blob: () => Promise.resolve(blob),
    formData: () => Promise.reject(new Error('formData not supported by the hybrid app.')),
    json: () => JSON.parse(body),
    text: () => body,
    ok,
    redirected,
    statusText: ok ? 'OK' : '',
    clone: () => ({ ...response }),
    trailer: Promise.resolve(parsedHeaders),
    type: 'basic' as ResponseType,
  };
  return response;
}

function handleFetchResponse(
  resp: HTTPResponse,
  verb: FetchMethod,
  body: BodyInit | null | undefined,
  headers: HeadersInit | undefined,
  redirected = false
) {
  if (resp.status > 300 && resp.status < 310) {
    return runFetch(verb, resp.headers.location, body, headers, true);
  }
  return mapFetchResponse(resp, redirected);
}

function runFetch(
  verb: FetchMethod,
  url: string,
  body: BodyInit | null | undefined,
  headers: HeadersInit | undefined,
  redirected = false
): Promise<EmberResponse> {
  return HTTP[verb](url as string, body, headers)
    .then(resp => handleFetchResponse(resp, verb, body, headers, redirected))
    .catch(resp => handleFetchResponse(resp, verb, body, headers, redirected));
}

async function fetchOAuthCallback(
  url: string,
  isAppleCallback: boolean
): Promise<HTTPResponse | undefined> {
  HTTP.setFollowRedirect(false);
  HTTP.setCookie(window.Olo.hybridAppHost, 'serve_netcore=1');

  try {
    return await (isAppleCallback
      ? HTTP.post(
          url,
          {},
          {
            'X-Olo-Request': '1',
            'x-olo-app-platform': Capacitor.getPlatform() as Platform,
          }
        )
      : HTTP.get(
          url,
          {},
          {
            'X-Olo-Request': '1',
            'x-olo-app-platform': Capacitor.getPlatform() as Platform,
          }
        ));
  } catch (e) {
    if (!e || e.status !== 302) {
      throw new Error('OAuth callback failed');
    }
    return undefined;
  }
}

async function fetchNativeAppleLogin(
  identityToken: string,
  firstName: string,
  lastName: string,
  state: string
): Promise<HTTPResponse | undefined> {
  HTTP.setDataSerializer('json');

  const searchParams =
    firstName && lastName
      ? new URLSearchParams({
          identityToken,
          user: JSON.stringify({ name: { firstName, lastName } }),
          state,
        }).toString()
      : new URLSearchParams({
          identityToken,
          state,
        }).toString();

  return await HTTP.post(
    `${window.Olo.hybridAppHost}/user/oauthcallback?${searchParams}`,
    {},
    {
      'X-Olo-Request': '1',
      'x-olo-app-platform': Capacitor.getPlatform() as Platform,
    }
  );
}

async function mapFetch({
  method,
  headers,
  body,
  url,
}: RequestInit & { url: string }): Promise<EmberResponse> {
  // Dirty hack to avoid a race condition in Capacitor
  // If the plugin is called before Capacitor's injected scripts have finished running, it will explode
  // Create a microtask and wait for it to run before calling the plugin
  // For some reason this only happens when loading assets from s3, instead of localhost
  // Maybe Capacitor's internal proxy already accounts for this case?
  // @todo: look at how to remove this because it adds an extra frame to every HTTP request
  await new Promise(resolve => setTimeout(resolve, 0));
  HTTP.setDataSerializer('json');
  HTTP.setFollowRedirect(false);
  HTTP.setCookie(window.Olo.hybridAppHost, 'serve_netcore=1');

  const verb = method?.toLowerCase() as FetchMethod;
  if (!['post', 'put', 'patch'].includes(verb)) {
    const query = (url as string).match(/([^?]{1,})([?]{1}([^?]{1,}))/gi);
    if (query && query[1]) {
      url = query[0];
      body = query[1];
    }
  } else {
    const origBody = body;
    body = body && typeof body === 'string' ? JSON.parse(body) : body || {}; // Undo Ember's serialization
    if (typeof body !== 'object') {
      body = origBody; // the JSON parsed body will have had all its quotes removed, so put it back
      HTTP.setDataSerializer('utf8');
    }
  }

  return runFetch(verb, url as string, body, {
    ...headers,
    'X-PX-COOKIE': localStorage.getItem('pxcookie') || '', // This localstorage item gets set in challenge.ts after a user passes a PX challenge
    'User-Agent': navigator.userAgent, // This is set for the browser, but needs to be reused with this plugin
  });
}

async function nativeFetch(url: string, args: RequestInit) {
  return mapFetch({ ...args, url });
}

export {
  nativeFetch,
  mapFetch,
  channelSlug,
  host,
  EmberResponse,
  fetchOAuthCallback,
  fetchNativeAppleLogin,
};
