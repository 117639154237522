import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import LoyaltyAccountModel from 'mobile-web/models/loyalty-account';
import QualifyingLoyaltyRewardModel from 'mobile-web/models/qualifying-loyalty-reward';
import Vendor from 'mobile-web/models/vendor';
import ChannelService from 'mobile-web/services/channel';

import style from './index.m.scss';

interface Args {
  // Required arguments
  vendor: Vendor;
  accounts: LoyaltyAccountModel[];

  // Optional arguments
  showInfoModal?: boolean;
}

interface Signature {
  Args: Args;
}

export default class MenuVendorRewardsRoute extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get allRewards(): QualifyingLoyaltyRewardModel[] {
    return this.args.accounts.map(account => account.qualifyingLoyaltyRewards.toArray()).flat();
  }

  get managedReward(): LoyaltyAccountModel | undefined {
    return this.args.accounts.find(
      r => r.showManageRewardsLink && r.manageRewardsUrl && r.manageRewardsText
    );
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  toggleFavorite() {
    this.args.vendor.toggleFavorite();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::Menu::Vendor::RewardsRoute': typeof MenuVendorRewardsRoute;
  }
}
