import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import ContentService from 'mobile-web/services/content';

export default class ContactUs extends Route {
  @service content!: ContentService;

  model() {
    return this.content.getHTML('CONTACT_US_HTML');
  }
}
