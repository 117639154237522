import { inject as service } from '@ember/service';
import DS from 'ember-data';

import ApplicationAdapter from './application';

export default class AddressAdapter extends ApplicationAdapter {
  @service store!: DS.Store;

  pathForType(): string {
    return 'deliveryaddresses';
  }
}
