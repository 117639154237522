import { helper } from '@ember/component/helper';

import { isErr } from 'mobile-web/lib/result';
import { ValidationMessage, ValidationResult } from 'mobile-web/lib/validation';

const validationMessages = helper(
  ([validationResult, messageName]: [ValidationResult | undefined, string]):
    | ValidationMessage[]
    | undefined => {
    if (!validationResult) {
      return undefined;
    }
    return isErr(validationResult) ? validationResult.err[messageName] : undefined;
  }
);

export default validationMessages;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'validation-messages': typeof validationMessages;
  }
}
