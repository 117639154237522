import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class VendorInfoRoute extends Route {
  @service router!: RouterService;

  model(params: { vendor_slug: string }) {
    this.router.replaceWith('menu.vendor', params.vendor_slug);
  }
}
