import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { PopperOptions } from 'mobile-web/components/tooltip';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';

import style from './index.m.scss';

interface Args {
  // Required arguments
  text: string;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class OloTooltip extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get popperOptions(): PopperOptions {
    return {
      modifiers: {
        preventOverflow: {
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      },
    };
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  trackShow() {
    this.analytics.trackEvent(AnalyticsEvents.OloAuthCheckoutViewTooltip, undefined, {
      bucket: 'olo-auth',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OloAuth::Tooltip': typeof OloTooltip;
  }
}
