import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { HeaderButtonMode } from 'mobile-web/components/header-button';
import LoyaltyMembership from 'mobile-web/models/loyalty-membership';
import LoyaltyScheme from 'mobile-web/models/loyalty-scheme';
import Vendor from 'mobile-web/models/vendor';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  // Required arguments
  linkedSchemeNames: string[];
  memberships: LoyaltyMembership[];
  onLink: Action<boolean>;
  schemes: LoyaltyScheme[];
  vendor: Vendor;

  // Optional arguments
  isOpen?: boolean;
  mode?: HeaderButtonMode;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class LinkRewardsButton extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get isOpen(): boolean {
    return this.args.isOpen ?? false;
  }

  get show(): boolean {
    return !isEmpty(this.args.memberships) || this.args.schemes.any(s => s.canLink);
  }

  get canLink(): boolean {
    return (
      this.session.isLoggedIn &&
      this.args.schemes.any(s => s.canLink && !this.args.linkedSchemeNames.includes(s.schemeName))
    );
  }

  get buttonText(): string {
    if (this.canLink) {
      return this.intl.t('mwc.linkRewards.linkRewardsButton');
    }
    if (this.hasRewardsAvailable) {
      return this.intl.t('mwc.linkRewards.rewardsAvailableButton');
    }
    return this.intl.t('mwc.linkRewards.viewRewardsButton');
  }

  get hasRewardsAvailable(): boolean {
    return (
      this.session.isLoggedIn &&
      this.store
        .peekAll('loyalty-account')
        .filter(a => this.args.schemes.findBy('id', a.id)?.vendor.id === this.args.vendor.id)
        .any(a => a.qualifyingLoyaltyRewards.length > 0)
    );
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onClick() {
    if (this.session.isLoggedIn) {
      if (this.canLink) {
        this.args.onLink(!this.isOpen);
      } else {
        this.router.transitionTo('menu.vendor.rewards', this.args.vendor.slug);
      }
    } else {
      this.session.transitionToLogin();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'link-rewards/button': typeof LinkRewardsButton;
  }
}
