import Service, { inject as service } from '@ember/service';

import ScrollService from './scroll';

export default class ModalService extends Service {
  // Service injections
  @service scroll!: ScrollService;
  // Untracked properties
  private openModals = new Set<object>();
  // Tracked properties

  // Getters and setters
  // Lifecycle methods
  // Other methods

  /**
   * Registers modals with the intent of keeping track of how many are opened,
   * so that we know when to disable document scrolling.
   *
   * @param modal The modal that is being opened
   */
  register(modal: object) {
    if (!this.openModals.size) {
      this.scroll.disableDocumentScroll();
    }
    this.openModals.add(modal);
  }

  /**
   * Unregisters modals with the intent of keeping track of how many are opened,
   * so that we know when to re-enable document scrolling.
   *
   * @param modal The modal that is being closed
   */
  unregister(modal: object) {
    const removalSucceeded = this.openModals.delete(modal);
    if (removalSucceeded && !this.openModals.size) {
      this.scroll.enableDocumentScroll();
    }
  }
  // Tasks
  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    modal: ModalService;
  }
}
