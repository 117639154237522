import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { feedbackUrl } from 'mobile-web/lib/feedback';
import OrderModel from 'mobile-web/models/order';
import ChannelService from 'mobile-web/services/channel';

interface Args {
  // Required arguments

  // Optional arguments
  order?: OrderModel;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [
      {
        feedbackUrl: string | undefined;
        socialLink: string | undefined;
      }
    ];
  };
}

export default class PostCheckoutFeedbackContainer extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;

  // Untracked properties

  // Tracked properties

  // Getters and setters
  get feedbackUrl(): string | undefined {
    const showFeedback = !!this.channel.settings?.allowUserFeedback;
    return showFeedback ? feedbackUrl(this.channel, this.args.order?.feedbackUrl) : undefined;
  }

  get socialLink(): string | undefined {
    const showSocial = !!this.channel.settings?.showSocialIntegration;
    return showSocial ? this.channel.settings?.fullSiteUrl ?? window.location.origin : undefined;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::FeedbackContainer': typeof PostCheckoutFeedbackContainer;
  }
}
