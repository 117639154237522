import Component from '@glimmer/component';

import { classes } from 'mobile-web/lib/utilities/classes';

export interface Signature<TArgs> {
  Element: HTMLElement;

  Args: TArgs & {
    animated?: boolean;
  };
}

export default class ContentPlaceholdersBase<TArgs = UnknownObject> extends Component<
  Signature<TArgs>
> {
  get rootClass(): string {
    return classes({
      'ember-content-placeholders-is-animated': this.args.animated ?? true,
    });
  }
}
