import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { isOk } from 'mobile-web/lib/result';
import Validation, { ValidationResult, ValidationConfig } from 'mobile-web/lib/validation';
import ChallengeService from 'mobile-web/services/challenge';
import ErrorService from 'mobile-web/services/error';
import SessionService from 'mobile-web/services/session';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

export default class ForgotPasswordRoute extends Component {
  // Service injections
  @service challenge!: ChallengeService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked email = '';
  @tracked validationResult?: ValidationResult;

  // Getters and setters
  get validationConfig(): ValidationConfig<ForgotPasswordRoute> {
    return {
      bindings: [
        {
          targetProp: 'email',
          ruleName: 'email',
          message: this.intl.t('mwc.forgotPassword.errors.email'),
        },
      ],
    };
  }

  get isContinueDisabled(): boolean {
    return isEmpty(this.email);
  }

  // Lifecycle methods

  // Other methods

  // Tasks
  continueTask = task(async (event: Event): Promise<void> => {
    event.preventDefault();

    this.validationResult = Validation.validate(this, this.validationConfig);
    if (isOk(this.validationResult)) {
      taskFor(this.challenge.request).perform(
        async () => {
          await this.store.collectionAction('user', 'forgotPassword', { email: this.email });
          this.userFeedback.add({
            type: Type.Positive,
            title: this.intl.t('mwc.forgotPassword.successTitle'),
            message: this.intl.t('mwc.forgotPassword.successMessage'),
          });
        },
        e => {
          this.error.reportError(e);
        }
      );
    }
  });

  // Actions and helpers
  @action
  onCancel() {
    this.router.transitionTo('login');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::ForgotPasswordRoute': typeof ForgotPasswordRoute;
  }
}
