import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import BasketService from 'mobile-web/services/basket';
import ErrorService from 'mobile-web/services/error';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

export default class OnPremisePlaceOrderModal extends Component {
  // Service injections
  @service basket!: BasketService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service error!: ErrorService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks
  dropItems = task(async () => {
    this.onPremise.showPlaceOrderModal = false;
    await this.basket.deleteItems.perform(this.basket.openCheckUnsentBasketProducts);
    this.router.transitionTo('checkout.auth');
  });

  // Actions and helpers
  @action
  sendItems() {
    this.onPremise.showPlaceOrderModal = false;
    this.basket.sendOpenCheckProducts().then(() => {
      this.router.transitionTo('checkout.auth');
    });
  }

  @action
  close() {
    this.onPremise.showPlaceOrderModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OnPremise::PlaceOrderModal': typeof OnPremisePlaceOrderModal;
  }
}
