import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { WithBoundArgs } from '@glint/template';
import TransitionContext from 'ember-animated/-private/transition-context';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import IntlService from 'ember-intl/services/intl';
import { MediaService } from 'ember-responsive';

import { HEADER_ID } from 'mobile-web/components/header';
import * as PopoverItem from 'mobile-web/components/popover-item';
import { getDuration } from 'mobile-web/lib/animation';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import { SafeString } from 'mobile-web/services/content';
import ScrollService from 'mobile-web/services/scroll';

import { BRAND_CAROUSEL_ID } from '../routes/menu/vendor-route';

import style from './index.m.scss';
import StickyNavSection, { sectionId } from './section';

function navItemSelector(id: string): string {
  return `[data-section-nav-item="${id}"]`;
}

const ICON_WIDTH = 24;
const OVERFLOW_NAV_ITEMS_ALLOWED = 4;

export const STICKY_NAV_ID = 'olo-sticky-nav';

interface Args {
  // Required arguments
  // Optional arguments
  navClass?: string;
  visibleNavItemCount?: number; // Only used for testing.
  stickyElementId?: string;
  topOffset?: number;
  bottomOffset?: number;
}

interface Signature {
  Args: Args;

  Blocks: {
    default: [
      {
        section: WithBoundArgs<typeof StickyNavSection, 'stickyNav'>;
      }
    ];
  };
}

export default class StickyNavOld extends Component<Signature> {
  // Service injections
  @service media!: MediaService;
  @service intl!: IntlService;
  @service analytics!: AnalyticsService;
  @service scroll!: ScrollService;

  // Untracked properties
  ids = guids(this, 'moreDescription');
  stickyNavId = STICKY_NAV_ID;
  style = style;

  // Tracked properties
  @tracked activeSection?: StickyNavSection;
  @tracked contentElement!: HTMLElement;
  @tracked navElement!: HTMLElement;
  @tracked navItemWidths: number[] = [];
  @tracked observer!: IntersectionObserver;
  @tracked sections: StickyNavSection[] = [];
  @tracked visibleSectionIds = new Set<string>();
  @tracked isLoading = true;

  // Getters and setters
  get headerHeight(): number {
    return document.getElementById(HEADER_ID)?.offsetHeight ?? 0;
  }

  get inlineButtonStyle(): SafeString {
    // Allow four nav items to overflow/ellipsis into their containers.
    // This fills in the gaps without getting too crowded.
    if (this.visibleNavItemCount > OVERFLOW_NAV_ITEMS_ALLOWED) {
      return htmlSafe('overflow: unset; text-overflow: unset');
    }
    return htmlSafe('');
  }

  get nonHiddenSections(): StickyNavSection[] {
    return this.sections.filter(s => !s.args.isHidden);
  }

  get visibleNavItems(): StickyNavSection[] {
    // Include one more element in the nav to fill in the gap.
    const navItemsToShow = this.visibleNavItemCount + 1;

    // Until navItemWidths has been fully set up, we need to be rendering every section, since
    // setupNav only runs on rendered items. That's OK because the items will have opacity: 0.
    // Once we know all the widths, we can filter down to just the items that should be visible;
    // at that point, opacity will also no longer be 0.
    return this.navItemWidths.length < this.sections.length
      ? this.sections
      : this.nonHiddenSections.slice(0, navItemsToShow);
  }

  get moreNavItems(): PopoverItem.Model[] {
    return isSome(this.visibleNavItemCount)
      ? this.nonHiddenSections.slice(this.visibleNavItemCount).map((s, idx) => ({
          label: s.args.title,
          action: () => this.goToSection(s, this.visibleNavItemCount! + idx, true),
          class: s === this.activeSection ? this.style.moreActiveNavItem : '',
        }))
      : [];
  }

  get shouldCollapse() {
    return this.media.isTablet;
  }

  get isMoreActive(): boolean {
    return (
      isSome(this.activeSection) &&
      !this.visibleNavItems.includes(this.activeSection) &&
      this.nonHiddenSections.includes(this.activeSection)
    );
  }

  get moreLabel(): string {
    return this.isMoreActive
      ? this.activeSection!.args.title
      : this.intl.t('mwc.stickyNav.moreLabel');
  }

  get visibleNavItemCount(): number {
    if (isSome(this.args.visibleNavItemCount)) {
      return this.args.visibleNavItemCount;
    }

    if (
      !this.navElement ||
      !this.contentElement ||
      isEmpty(this.nonHiddenSections) ||
      isEmpty(this.navElement.children) ||
      this.navItemWidths.length < this.sections.length
    ) {
      return this.nonHiddenSections.length;
    }

    // Keeps track of how many items can be visible without overflowing
    let visibleCount;
    if (this.shouldCollapse) {
      // Gets the widths of all nav items that could be visible
      const visibleWidths = this.navItemWidths.filter(
        (_w, idx) => !this.sections[idx].args.isHidden
      );

      // Keeps track of how much visible width we've used so far
      let totalWidth = (this.navElement.children[0] as HTMLElement).offsetLeft;

      for (visibleCount = 0; visibleCount < visibleWidths.length; visibleCount++) {
        // Calculates max width by taking the width of the parent container, subtracting
        // the widest possible nav item in the more dropdown, and subtracting the icon
        // width to account for the more arrow.
        const maxMoreWidth = Math.max(...visibleWidths.slice(visibleCount));
        const maxWidth = this.contentElement.clientWidth - maxMoreWidth - ICON_WIDTH;

        totalWidth += visibleWidths[visibleCount];
        if (totalWidth > maxWidth) {
          // If we've overflowed the max width, we can't display any more items.
          // We don't need to decrement because visibleCount is 0-based, but the
          // actual count of visible items is 1-based.
          break;
        }
      }
    } else {
      visibleCount = this.sections.length;
    }
    return visibleCount;
  }

  // Lifecycle methods

  // Other methods
  registerSection(section: StickyNavSection) {
    this.observer.observe(section.rootElement);
    this.sections = [...this.sections, section];
  }

  setActiveSection(section?: StickyNavSection) {
    if (!this.shouldCollapse && isSome(section)) {
      const navItem = document.querySelector<HTMLElement>(navItemSelector(section.id));
      this.navElement.scrollTo({
        left: navItem?.parentElement?.offsetLeft,
        behavior: 'smooth',
      });
    }

    this.activeSection = section;
  }

  // Tasks

  // Actions and helpers
  @action
  *transition({ insertedSprites, keptSprites, removedSprites }: TransitionContext) {
    yield Promise.all(
      removedSprites.map(s => {
        fadeOut(s, { duration: getDuration(200) });
        s.applyStyles({ display: 'none' });
      })
    );
    yield Promise.all(
      keptSprites.map(s => {
        move(s, { duration: getDuration(200) });
      })
    );
    yield Promise.all(
      insertedSprites.map(s => {
        fadeIn(s, { duration: getDuration(200) });
        s.applyStyles({ display: '' });
      })
    );
    this.isLoading = false;
  }

  @action
  setupContent(e: HTMLElement) {
    this.contentElement = e;
    this.setup();
  }

  @action
  setupNav(e: HTMLElement) {
    this.navElement = e;
    this.setup();
  }

  @action
  setup() {
    if (!this.contentElement || !this.navElement) {
      return;
    }

    this.observer = new IntersectionObserver(this.visibleSectionsChanged, {
      // We don't know the height of the navElement yet, so we just need something that is reasonably close.
      // Doing headerHeight * 3 gives us enough wiggle room to work at all resolutions,
      // but still look reasonable to the user when scrolling.
      // (∩｀-´)⊃━☆ﾟ.*・｡ﾟ
      rootMargin: `-${this.headerHeight * 3}px 0px 0px 0px`,
      threshold: 0,
    });
  }

  @action
  setupNavItem(e: HTMLElement) {
    if (this.navItemWidths.length < this.sections.length) {
      this.navItemWidths = [...this.navItemWidths, e.offsetWidth];
    }
  }

  @action
  teardown() {
    this.observer?.disconnect?.();
  }

  @action
  visibleSectionsChanged(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      const id = sectionId(entry.target)!;
      if (entry.isIntersecting) {
        this.visibleSectionIds.add(id);
      } else {
        this.visibleSectionIds.delete(id);
      }
      // eslint-disable-next-line no-self-assign
      this.visibleSectionIds = this.visibleSectionIds;
    });

    if (!this.scroll.isAutoScrolling) {
      const firstVisibleSection = this.nonHiddenSections.find(s =>
        this.visibleSectionIds.has(s.id)
      );
      this.setActiveSection(firstVisibleSection);
    }
  }

  @action
  contentElementChanged() {
    // eslint-disable-next-line no-self-assign
    this.contentElement = this.contentElement;
  }

  @action
  async goToSection(section: StickyNavSection, idx: number, isMoreCategory: boolean) {
    this.analytics.trackEvent(AnalyticsEvents.StickyNavCategoryClick, () => ({
      [AnalyticsProperties.CategoryName]: section.args.title,
      [AnalyticsProperties.CategoryIndex]: idx + 1,
      [AnalyticsProperties.CategoryIsWithinViewMore]: isMoreCategory,
      [AnalyticsProperties.TotalCategories]: this.sections.length,
    }));
    this.scroll.isAutoScrolling = true;

    const brandCarousel = document.getElementById(BRAND_CAROUSEL_ID);
    const brandCarouselHeight = brandCarousel ? brandCarousel.offsetHeight : 0;

    if (section.rootElement) {
      window.scrollTo({
        top: section.rootElement.offsetTop - this.navElement.offsetHeight - brandCarouselHeight,
        behavior: 'smooth',
      });

      await this.scroll.scrollStop();

      section.rootElement.focus({ preventScroll: true });
    }

    this.scroll.isAutoScrolling = false;
    this.setActiveSection(section);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    StickyNavOld: typeof StickyNavOld;
  }
}
