export default {
  "root": "_root_176xyr",
  "highlight": "_highlight_176xyr",
  "border-fade": "_border-fade_176xyr",
  "header": "_header_176xyr",
  "name": "_name_176xyr",
  "edit": "_edit_176xyr",
  "modal": "_modal_176xyr",
  "button": "_button_176xyr",
  "buttonText": "_buttonText_176xyr",
  "doneButton": "_doneButton_176xyr"
};
