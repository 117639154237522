import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { taskFor } from 'ember-concurrency-ts';

import Vendor from 'mobile-web/models/vendor';
import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import BasketService from 'mobile-web/services/basket';
import ErrorService from 'mobile-web/services/error';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onTransfer: Action<[VendorSearchResultModel, boolean, Vendor?]>;

  // Optional arguments
  currentVendor?: Vendor;
}

interface Signature {
  Args: Args;

  Blocks: {
    default: [
      {
        // eslint-disable-next-line no-use-before-define
        transfer: BasketTransferAction['transfer'];
      }
    ];
  };
}

export default class BasketTransferAction extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service error!: ErrorService;
  @service router!: RouterService;
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties
  /**
   * This is the raw sideloaded basket JSON from the server, which we don't have a good type for.
   */
  @tracked newBasketData?: AnyObject;
  @tracked newVendor?: VendorSearchResultModel;
  @tracked warnings?: string[];
  @tracked showConfirmationPrompt = false;

  // Getters and setters

  // Lifecycle methods

  // Other methods
  setBasket() {
    const basketData = this.newBasketData!;
    const store = this.store;
    store.pushPayload('basket', basketData);
    this.basket.set('basket', store.peekRecord('basket', basketData.basket.guid));
  }

  // Actions and helpers
  @action
  async transfer(newVendor: VendorSearchResultModel) {
    const previousVendor = this.args.currentVendor;
    await taskFor(this.basket.transferTask).perform(newVendor);
    this.args.onTransfer(newVendor, isEmpty(this.basket.basket?.warnings), previousVendor);
  }

  @action
  confirmationClose() {
    this.showConfirmationPrompt = false;
  }

  @action
  continueSwitch() {
    this.showConfirmationPrompt = false;
    this.setBasket();
    this.args.onTransfer(this.newVendor!, false);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BasketTransferAction: typeof BasketTransferAction;
  }
}
