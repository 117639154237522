import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { EmptyObject } from '@glimmer/component/-private/component';
import { tracked } from '@glimmer/tracking';

import { getNameForLoginProvider } from 'mobile-web/lib/login-provider';
import { LoginProvider } from 'mobile-web/models/bootstrap-data';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BootstrapService from 'mobile-web/services/bootstrap';
import FeaturesService from 'mobile-web/services/features';
import OloAuthService from 'mobile-web/services/olo-auth';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

export default class LinkOloAuthAccountBanner extends Component<unknown> {
  // Untracked properties
  style = style;

  @tracked showLinkingBanner = false;

  @service session!: SessionService;
  @service features!: FeaturesService;
  @service bootstrap!: BootstrapService;
  @service analytics!: AnalyticsService;
  @service oloAuth!: OloAuthService;

  constructor(owner: unknown, args: EmptyObject) {
    super(owner, args);
    this.calcShowLinkingBanner();
  }

  get loyaltyProvider(): LoginProvider | undefined {
    const providers = this.session.loginProviders;

    return providers?.find(
      lp => lp.enableOloAuthLinking && lp.name === this.session.loginProviderName
    );
  }

  get loyaltyProviderName(): string {
    return getNameForLoginProvider(this.loyaltyProvider, 'Loyalty');
  }

  @action
  linkOloAuthAccount() {
    if (this.loyaltyProvider) {
      this.analytics.trackEvent(
        AnalyticsEvents.LinkOloAuthAccountClicked,
        () => ({
          [AnalyticsProperties.LinkLoyaltySource]: this.loyaltyProviderName,
          [AnalyticsProperties.LinkLoyaltyTarget]: this.session.oloAuthProviderSlug,
        }),
        {
          bucket: 'olo-auth',
        }
      );
      // eslint-disable-next-line no-console
      console.log('Linking!');
    }
  }

  @action
  async calcShowLinkingBanner() {
    const isFFEnabled = this.features.flags['olo-auth-link-loyalty-to-olo-account'];

    // pre-requisites to showing the banner
    // 0. FF is enabled
    // 1. brand has olo auth enabled
    // 2. customer is not currently logged into an olo auth account
    //        (needs to be present because some brands have "olo auth linking" enabled for olo auth provider itself)
    // 3. customer is logged into a provider with olo auth linking enabled
    // 4. customer has no linked login providers
    const preRequisites =
      isFFEnabled &&
      this.bootstrap.data?.hasOloAuthProvider &&
      !this.bootstrap.data?.isOloAuthLogin &&
      !!this.loyaltyProvider &&
      this.session.linkedLoginProviders.length === 0;

    if (preRequisites) {
      this.showLinkingBanner = await this.oloAuth.oloAuthLinkabilityStatus();
    }
  }

  @action
  dismissLinkingBanner() {
    this.showLinkingBanner = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::LinkOloAuthAccountBanner': typeof LinkOloAuthAccountBanner;
  }
}
