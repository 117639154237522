import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { classes } from 'mobile-web/lib/utilities/classes';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import GroupOrderService, { GroupOrderParticipantStatus } from 'mobile-web/services/group-order';

import style from './index.m.scss';

interface Args {
  // Required arguments
  // Optional arguments
  currentVendorSlug?: string;
  location?: 'cart' | 'vendor';
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class GroupOrderBanner extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service router!: RouterService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get rootClass(): string {
    return classes(style.root, {
      [style.hasGroupOrder]: this.groupOrder.hasGroupOrder,
      [style.cart]: this.args.location === 'cart',
    });
  }

  get titleClass(): string {
    return classes(style.title, { [style.cart]: this.args.location === 'cart' });
  }

  get contentClass(): string {
    return classes(style.content, { [style.status]: this.showStatusSummary });
  }

  get titleText(): string {
    if (this.args.location === 'cart' && this.groupOrder.isHostMode) {
      return this.intl.t('mwc.groupOrder.yourGroup');
    }
    return this.intl.t('mwc.groupOrder.orderName', {
      hostName: this.groupOrder.hostDisplayNamePossessive,
    });
  }

  get descriptionText(): string {
    const currentUserName =
      this.groupOrder.currentUserName === '' ? 'no_name' : this.groupOrder.currentUserName;

    return this.groupOrder.isHostMode
      ? this.intl.t('mwc.groupOrder.addToOrderHostDesc')
      : this.intl.t('mwc.groupOrder.addToOrderParticipantDesc', { name: currentUserName });
  }

  get leaveButtonLabel(): string {
    return this.args.location === 'vendor'
      ? this.intl.t('mwc.groupOrder.leaveButton', {
          hostName: this.groupOrder.hostDisplayNamePossessive,
        })
      : this.intl.t('mwc.groupOrder.leaveGroupTitle');
  }

  get showDescription(): boolean {
    return this.args.location === 'vendor';
  }

  get showInviteButton(): boolean {
    return this.groupOrder.isHostMode && this.args.location === 'vendor';
  }

  get showCancelButton(): boolean {
    return this.showInviteButton;
  }

  get showLeaveButton(): boolean {
    return this.groupOrder.isParticipantMode;
  }

  get showStatusSummary(): boolean {
    return this.groupOrder.isHostMode && this.args.location === 'cart';
  }

  get statusesToShow(): { status: GroupOrderParticipantStatus; statusCount: number }[] {
    const statusArray = [
      GroupOrderParticipantStatus.InProgress,
      GroupOrderParticipantStatus.Submitted,
    ];
    return statusArray.map(status => {
      const statusCount = this.groupOrder.statusCount(status);
      return { status, statusCount };
    });
  }
  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  startGroupOrder() {
    this.analytics.trackEvent(AnalyticsEvents.StartGroupOrder, () => ({
      [AnalyticsProperties.LinkLocation]: 'Banner Link',
    }));

    if (this.basket.basket && this.basket.basket.hasReward) {
      this.bus.trigger('confirm', {
        title: this.intl.t('mwc.confirmModal.groupOrderStartTitle'),
        content: this.intl.t('mwc.confirmModal.groupOrderStartBody'),
        buttonLabel: this.intl.t('mwc.confirmModal.groupOrderStartConfirm'),
        testSelector: 'start-group-order-with-rewards-confirmation-modal',
        onConfirm: async () => {
          await this.basket.basket!.removeReward({
            membershipId: this.basket.basket!.reward!.id,
            reference: this.basket.basket!.reward!.externalReference,
          });
          this.router.transitionTo('group-order.start', {
            queryParams: { slug: this.args.currentVendorSlug },
          });
        },
        buttonTestSelector: 'start-group-order-with-rewards-confirm-button',
        cancelTestSelector: 'start-group-order-with-rewards-cancel-button',
      });
    } else {
      this.router.transitionTo('group-order.start', {
        queryParams: { slug: this.args.currentVendorSlug },
      });
    }
  }

  @action
  cancelGroupOrder() {
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.groupOrder.cancelGroupTitle'),
      content: this.intl.t('mwc.groupOrder.cancelGroupConfirmationMessage'),
      buttonLabel: this.intl.t('mwc.groupOrder.confirmCancelGroupButton'),
      onConfirm: () => {
        this.analytics.trackEvent(AnalyticsEvents.CancelGroupOrder);
        this.groupOrder.endGroupOrder();
      },
      testSelector: 'cancel-group-order-confirmation-modal',
      buttonTestSelector: 'cancel-group-order-confirm-button',
      cancelTestSelector: 'cancel-group-order-cancel-button',
    });
  }

  @action
  leaveGroupOrder() {
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.groupOrder.leaveGroupTitle'),
      content: this.intl.t('mwc.groupOrder.leaveGroupConfirmationMessage', { htmlSafe: true }),
      buttonLabel: this.intl.t('mwc.groupOrder.leaveButton', {
        hostName: this.groupOrder.hostDisplayNamePossessive,
      }),
      onConfirm: () => {
        this.analytics.trackEvent(AnalyticsEvents.LeaveGroupOrder);
        this.groupOrder.endGroupOrder();
      },
      testSelector: 'leave-group-order-confirmation-modal',
      buttonTestSelector: 'leave-group-order-confirm-button',
      cancelTestSelector: 'leave-group-order-cancel-button',
      primaryCancelButtonPosition: true,
    });
  }

  @action
  inviteToGroupOrder() {
    this.analytics.trackEvent(AnalyticsEvents.GroupOrderInviteOthersButton);
    this.groupOrder.showInviteModal();
  }

  @action
  showInviteModal() {
    if (this.groupOrder.openInviteModal) {
      this.groupOrder.showInviteModal();
      this.groupOrder.openInviteModal = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GroupOrder::Banner': typeof GroupOrderBanner;
  }
}
