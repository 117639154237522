import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import cloneDeep from 'lodash.clonedeep';

import { Code } from 'mobile-web/lib/country';
import { isCreditCardWithAddress } from 'mobile-web/lib/payment';
import { Variant } from 'mobile-web/lib/payment/method';
import isSome from 'mobile-web/lib/utilities/is-some';
import { roundDecimals } from 'mobile-web/lib/utilities/math';
import OrderSubmission from 'mobile-web/models/order-submission';
import BasketService from 'mobile-web/services/basket';

export default class OrderSubmissionSerializer extends DS.JSONSerializer {
  @service basket!: BasketService;

  primaryKey = 'orderId';

  serialize(s: DS.Snapshot, options: UnknownObject): UnknownObject {
    const snapshot = s as DS.Snapshot<'order'>;
    const json = cloneDeep(super.serialize(snapshot, options)) as ModelProperties<OrderSubmission>;

    delete json.orderId;

    if (snapshot.record.guestNewsletterOptIn === undefined) {
      delete json.guestNewsletterOptIn;
    }

    if (snapshot.record.newsletterOptIn === undefined) {
      delete json.newsletterOptIn;
    }

    const tip = isSome(this.basket.basket) ? this.basket.basket.tip : 0;
    if (isSome(json.customFields)) {
      json.customFields.forEach(c => {
        delete c.valueMessages;
      });
    }

    json.selectedBillingMethods!.forEach(m => {
      if (m.variant === Variant.NewCard && isCreditCardWithAddress(m.paymentCard)) {
        m.paymentCard.countryCode = m.paymentCard.countryCode.toUpperCase() as Code;
      }
      if (tip === 0) {
        set(m, 'includesTip', false);
      } else if (m.includesTip && json.selectedBillingMethods!.length > 1) {
        set(m, 'amount', m.amount + tip);
      }
      set(m, 'amount', roundDecimals(m.amount));
    });

    return { ...json, ...json.bookingUser };
  }
}
