import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import fetch from 'fetch';

import FeaturesService from './features';

export default class HealthCheckService extends Service {
  // Service injections
  @service features!: FeaturesService;

  // Untracked properties

  // Tracked properties
  @tracked isHealthy = true;

  // Getters and setters

  // Lifecycle methods

  // Other methods
  async checkSystemHealth(): Promise<boolean> {
    if (!this.features.flags['health-check']) {
      return true;
    }
    try {
      const healthCheckResponse = await fetch('/api/healthcheck');
      this.isHealthy = !(healthCheckResponse.status >= 400 && healthCheckResponse.status <= 599);
    } catch (error) {}
    return this.isHealthy;
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    'health-check': HealthCheckService;
  }
}
