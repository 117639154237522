import { Month } from './calendar';

/**
 *
 * @returns the current timezone offset in hours
 */
export function localOffset(): number {
  return new Date().getTimezoneOffset() / -60;
}

/**
 * @summary
 * Gets the standard (non-DST) timezone offset in hours.
 *
 * @returns the standard timezone offset in hours
 */
export function getStandardOffset() {
  const thisYear = new Date().getFullYear();
  return new Date(thisYear, Month.January).getTimezoneOffset() / -60;
}

/**
 * @summary
 * Gets the summer (DST, if observed) timezone offset in hours.
 *
 * @returns the summer timezone offset in hours
 */
export function getSummerOffset() {
  const thisYear = new Date().getFullYear();
  return new Date(thisYear, Month.July).getTimezoneOffset() / -60;
}

/**
 * @summary
 * Gets the IANA time zone name for the user's current time zone
 *
 * @returns the time zone name (e.g. "America/New_York")
 */
export function getTimeZoneName() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * @summary
 * Determines whether the user's local timezone observes DST
 */
export function observesDST() {
  return getStandardOffset() !== getSummerOffset();
}

/**
 * Gets a message indicating the timezone offset difference between the target and source.
 */
export function offsetDifferenceMessage(
  target: string,
  targetOffset: number | undefined = undefined,
  source = 'you',
  sourceOffset = localOffset()
): string {
  if (targetOffset === undefined || targetOffset === sourceOffset) {
    return '';
  }

  const diff = Math.abs(sourceOffset - targetOffset);
  const preposition = targetOffset < sourceOffset ? 'behind' : 'ahead of';
  const hours = diff === 1 ? 'hour' : 'hours';
  return `${target} is ${diff} ${hours} ${preposition} ${source}`;
}

export default {
  localOffset,
  getStandardOffset,
  getSummerOffset,
  getTimeZoneName,
  observesDST,
  offsetDifferenceMessage,
};

/**
 * Gets the time zone offset from GMT in minutes.
 *
 * Note that this value will have an opposite sign compared to
 * `Date.prototype.getTimezoneOffset`.
 *
 * @example
 * getTimeZoneOffset('America/New_York', new Date('2020-01-01T00:00Z')) // -300
 * new Date('2020-01-01T00:00Z').getTimezoneOffset() // 300
 *
 * @param timeZone the IANA time zone ID
 * @param date the date when the offset is being
 */
export const getTimeZoneOffset = (timeZone: string, date: Date): number => {
  // create the UTC representation of the date as a local time
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));

  // create the specified time zone representation of the date as a local time
  const timeZoneDate = new Date(date.toLocaleString('en-US', { timeZone }));

  const MS_PER_MINUTE = 60000;

  const timeZoneOffsetMS = timeZoneDate.getTime() - utcDate.getTime();

  return timeZoneOffsetMS / MS_PER_MINUTE;
};
