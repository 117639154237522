import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { UserData } from 'mobile-web/lib/customer';
import ErrorService from 'mobile-web/services/error';
import SessionService from 'mobile-web/services/session';
import WindowService from 'mobile-web/services/window';

import style from './index.m.scss';

interface Args {
  redirectUrl: string;
}

interface Signature {
  Args: Args;
}

export default class ContactInfoRoute extends Component<Signature> {
  // Service injections
  @service session!: SessionService;
  @service router!: RouterService;
  @service error!: ErrorService;
  @service window!: WindowService;

  // Required arguments

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // This route should only be used to fix missing contact info after apple login
    if (!this.session.currentUser || this.session.currentUser.firstName !== '-') {
      this.router.transitionTo('index');
    }
  }

  // Other methods

  // Tasks

  @action
  async closeModal() {
    await this.session.logout();
    this.router.transitionTo('index');
  }

  @action
  async handleChange(user: UserData) {
    const currentUser = this.session.currentUser!;
    currentUser.setProperties(user);

    try {
      await currentUser.save();
      this.window.location().assign(this.args.redirectUrl ?? '/');
    } catch (err) {
      this.error.sendUserMessage(err);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::ContactInfoRoute': typeof ContactInfoRoute;
  }
}
