import { getOwner } from '@ember/application';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';

import GroupOrderService from 'mobile-web/services/group-order';

const groupOrderRoute = (route: { prototype: Route }): void => {
  const original = route.prototype.beforeModel;
  route.prototype.beforeModel = function (transition: Transition) {
    const owner = getOwner(this) as AnyObject;
    const groupOrder: GroupOrderService = owner.lookup('service:group-order');
    const router: RouterService = owner.lookup('service:router');

    if (groupOrder.isEnabled) {
      original.call(this, transition);
    } else {
      router.replaceWith('index');
    }
  };
};

export default groupOrderRoute;
