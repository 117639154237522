import { get } from '@ember/object';
import RouteInfo from '@ember/routing/-private/route-info';
import { isEmpty } from '@ember/utils';

import VendorModel from 'mobile-web/models/vendor';

export type TransitionOptions = { queryParams: UnknownObject };
export type TransitionArg = UnknownObject | string | number | TransitionOptions;

export interface TransitionArgsArray extends Array<TransitionArg> {
  [index: number]: TransitionArg;
  0: string;
}

export type PreviousRoute = {
  label: string;
  route: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  models: any[];
};

export const homeRoute: PreviousRoute = {
  label: 'Home',
  route: 'index',
  models: [],
};

/**
 * Returns an array with the following format:
 * `['name of current route', 'dynamic segment 1', 'dynamic segment 2', . . ., { queryParams }]`.
 * The array always has at least one element.
 */
export const currentTransitionArgs = (currentRoute?: RouteInfo): TransitionArgsArray => {
  if (!currentRoute) {
    return ['index'];
  }

  const transitionArgs: TransitionArgsArray = [currentRoute.name];

  if (!isEmpty(Object.keys(currentRoute.params))) {
    transitionArgs.push(...(Object.values(currentRoute.params) as string[]));
  }

  if (!isEmpty(Object.keys(currentRoute.queryParams))) {
    transitionArgs.push({ queryParams: currentRoute.queryParams });
  }

  return transitionArgs;
};

export const vendorToMenuRoute = (vendor: VendorModel): PreviousRoute => {
  const label = 'Menu';
  const route = 'menu.vendor';

  return { label, route, models: [get(vendor, 'slug')] };
};
