import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import { local } from 'mobile-web/decorators/storage';
import dayjs from 'mobile-web/lib/dayjs';
import {
  OrderCriteria,
  isDelivery,
  isAtStore,
  getTimeWantedTypeLabel,
  getHandoffLabel,
  isAdvance,
} from 'mobile-web/lib/order-criteria';
import { toString } from 'mobile-web/lib/utilities/_';
import { VendorResultRoutes } from 'mobile-web/lib/vendor';
import VendorSearchResultModel, { FailureCategory } from 'mobile-web/models/vendor-search-result';
import { VendorSearchParams } from 'mobile-web/routes/vendor-search-results';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
  TIME_WANTED_ANALYTICS_LABELS,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import FeaturesService from 'mobile-web/services/features';
import MwcIntl from 'mobile-web/services/mwc-intl';
import * as OrderCriteriaService from 'mobile-web/services/order-criteria';
import StorageService from 'mobile-web/services/storage';
import { VendorSearchResult } from 'mobile-web/services/vendor-search';

import style from './index.m.scss';

interface Args {
  // Required arguments
  model: VendorSearchResult;

  // Optional arguments
  isRewardSearch?: boolean;
  onSearchChange?: Action;
}

interface Signature {
  Args: Args;
}

export default class VendorSearchResultsRoute extends Component<Signature> {
  // Service injections
  @service('order-criteria') orderCriteriaService!: OrderCriteriaService.default;
  @service basket!: BasketService;
  @service router!: RouterService;
  @service analytics!: AnalyticsService;
  @service store!: DS.Store;
  @service mwcIntl!: MwcIntl;
  @service features!: FeaturesService;
  @service storage!: StorageService;

  // Untracked properties
  style = style;

  // Tracked properties
  @local localVendorSearchParams?: VendorSearchParams;

  // Getters and setters
  get address(): string {
    return this.localVendorSearchParams?.address ?? '';
  }

  get destRoute(): VendorResultRoutes {
    return this.args.isRewardSearch ? VendorResultRoutes.Rewards : VendorResultRoutes.Menu;
  }

  get orderCriteria(): OrderCriteria {
    return this.orderCriteriaService.criteria;
  }

  get handoffLabel(): string {
    return getHandoffLabel(this.orderCriteria.handoffMode, this.store);
  }

  get noResultsLabel(): string {
    if (this.noHandoffResults) {
      return this.handoffLabel;
    } else if (this.noTimeWantedResults) {
      return isAdvance(this.orderCriteria) && this.orderCriteria.timeWanted
        ? this.mwcIntl.relativeDateTime(this.orderCriteria.timeWanted)
        : getTimeWantedTypeLabel(this.orderCriteria.timeWantedType, this.store);
    }
    return '';
  }

  get vendors(): VendorSearchResultModel[] {
    if (this.args.isRewardSearch) {
      return this.args.model.results.toArray();
    }

    return this.args.model.results.filter(
      ({ hasOnlineOrdering, displayNationalMenu }) => hasOnlineOrdering || displayNationalMenu
    );
  }

  get availableVendors(): VendorSearchResultModel[] {
    return this.vendors.filter(v => v.available);
  }

  get unavailableVendors(): VendorSearchResultModel[] {
    return this.vendors.filter(v => !v.available);
  }

  get handoffFailedVendors(): VendorSearchResultModel[] {
    return this.vendors.filter(v => v.preCheckCategory === FailureCategory.HandoffMode);
  }

  get timeModeFailedVendors(): VendorSearchResultModel[] {
    return this.vendors.filter(v => v.preCheckCategory === FailureCategory.TimeWantedMode);
  }

  get noHandoffResults(): boolean {
    return this.vendors.length === this.handoffFailedVendors.length;
  }

  get noTimeWantedResults(): boolean {
    return this.vendors.length === this.timeModeFailedVendors.length;
  }

  get vendorScheduleDay() {
    return isAdvance(this.orderCriteria) && this.orderCriteria.timeWanted
      ? this.orderCriteria.timeWanted
      : dayjs();
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  showCriteria() {
    this.orderCriteriaService.openModal({ onChange: this.updateSearch, searchOnly: true });
  }

  @action
  showVendorResults(address: string) {
    this.localVendorSearchParams = { address };
    this.args.onSearchChange?.();
  }

  @action
  updateSearch(model: OrderCriteria) {
    // We call `toString` in the `model.vendorAddress|deliveryAddress.*` case so that we do not
    // end up binding the query params to the same string *reference* as the
    // order criteria address. If we do *not* do this, we'll end up with very
    // confusing double-render bugs from any `link-to` which has to reference
    // this controller to make sure any query params are resolved -- as happens
    // when the router looks up vendor routes!
    let address: string | undefined;
    let addressId: string | undefined;
    if (isDelivery(model) && model.deliveryAddress) {
      address = model.deliveryAddress.streetAddress.toString();
      const id = model.deliveryAddress.id;
      if (typeof id === 'string') {
        addressId = id.toString();
      }
    } else if (isAtStore(model)) {
      address = model.searchAddress;
    }

    const { latitude, longitude } =
      isAtStore(model) && model.searchCoords
        ? model.searchCoords
        : { latitude: undefined, longitude: undefined };

    const [building, city, zipCode] = isDelivery(model)
      ? [
          model.deliveryAddress?.building,
          model.deliveryAddress?.city,
          model.deliveryAddress?.zipCode,
        ].map(toString)
      : ['', '', ''];

    this.localVendorSearchParams = {
      addressId,
      address,
      building,
      city,
      zipCode,
      latitude,
      longitude,
      handoffMode: model.handoffMode,
      timeWantedType: model.timeWantedType,
      timeWanted: isAdvance(model) && model.timeWanted ? model.timeWanted.toISOString() : undefined,
    };

    this.args.onSearchChange?.();
  }

  getChosenVendorIndex(chosenVendor: VendorSearchResultModel): number {
    const index = this.availableVendors.findIndex(vendor => vendor.id === chosenVendor.id);

    return index > -1 ? index + 1 : 0;
  }

  @action
  goToVendor(newVendor: VendorSearchResultModel) {
    const search = this.orderCriteria;
    const handoffMode = search.handoffMode;
    const timeWantedMode = TIME_WANTED_ANALYTICS_LABELS[search.timeWantedType];
    this.analytics.trackEvent(AnalyticsEvents.BeginOrder, () => ({
      [AnalyticsProperties.StoreName]: newVendor.name,
      [AnalyticsProperties.StoreCity]: newVendor.address.city,
      [AnalyticsProperties.StoreState]: newVendor.address.state,
      [AnalyticsProperties.StorePostalCode]: newVendor.address.postalCode,
      [AnalyticsProperties.SelectedHandoffMode]: handoffMode,
      [AnalyticsProperties.SelectedTimeWantedMode]: timeWantedMode,
      [AnalyticsProperties.LocationSearchIndex]: this.getChosenVendorIndex(newVendor),
    }));

    this.router.transitionTo(this.destRoute, newVendor.slug);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::VendorSearchResultsRoute': typeof VendorSearchResultsRoute;
  }
}
