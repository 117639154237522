import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import UrlTemplates from 'ember-data-url-templates';

import { host } from 'mobile-web/lib/hybrid-util';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';

export default class ProductAdapter extends ApplicationAdapter.extend(UrlTemplates) {
  @service orderCriteria!: OrderCriteriaService;
  @service vendor!: VendorService;

  ajax(url: string, type: string, options?: { data?: object }) : RSVP.Promise<any> {
    let params = {
      ...options,
      data: {
        handoffMode: this.orderCriteria.criteria.handoffMode,
        ...options?.data
      }
    };

    return super.ajax(url, type, params);
  }

  coalesceFindRequests = true;
  urlTemplate = host() + '/{+namespace}/vendors/{vendorId}/products/{productId}';
  urlSegments = {
    vendorId(
      this: ProductAdapter,
      _type: unknown,
      _id: unknown,
      snapshot: DS.Snapshot<'product'> | DS.Snapshot<'product'>[]
    ): string {
      const record = 'length' in snapshot ? snapshot[0] : snapshot;
      const vendor = record.belongsTo('vendor') as DS.Snapshot<'vendor'> | undefined;

      let vendorId;
      if (vendor) {
        vendorId = vendor.record.get('id');
      } else if (this.vendor.vendor) {
        vendorId = this.vendor.vendor.id;
      } else {
        throw new Error('Vendor Id for Product Not Found');
      }

      return vendorId;
    },

    productId(_type: unknown, _id: unknown, snapshot: DS.Snapshot): string {
      return snapshot.id;
    },

    namespace(this: ProductAdapter): string {
      return this.namespace;
    },
  };

  shouldBackgroundReloadRecord(): boolean {
    return false;
  }

  findMany(
    _store: unknown,
    type: unknown,
    ids: EmberDataId[],
    snapshots: DS.Snapshot[]
  ): RSVP.Promise<unknown> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const url = this.buildURL((type as any).modelName, ids, snapshots, 'findMany');
    return this.ajax(url, 'GET', { data: { ids } });
  }
}
