import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';

import ChannelService from 'mobile-web/services/channel';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import VendorService from 'mobile-web/services/vendor';

export default class LoginRoute extends Route {
  @service channel!: ChannelService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service vendor!: VendorService;
  @service onPremise!: OnPremiseService;
  @service groupOrder!: GroupOrderService;

  beforeModel(transition: Transition) {
    if (!this.session.internalSignOnAllowed) {
      transition.abort();
      this.router.replaceWith('application');
    }
  }

  model(params: { upgrade: boolean }) {
    const guestUser = this.session.currentUser?.isGuest
      ? this.session.currentUser
      : this.session.guestUser;
    const lastOrderId = this.session.lastOrderId;
    const previousOrderIds =
      this.onPremise.closedCheck?.pastOrders.map(x => x.displayId) ?? undefined;

    return params.upgrade
      ? { guestUser, lastOrderId, previousOrderIds }
      : { guestUser: undefined, lastOrderId: undefined };
  }
}
