import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import GroupOrderService, { GroupOrderParticipantStatus } from 'mobile-web/services/group-order';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  participantName?: string;
  showStatusIcon?: boolean;
  isHost?: boolean;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class GroupOrderName extends Component<Signature> {
  // Service injections
  @service groupOrder!: GroupOrderService;

  style = style;

  // Getters and setters
  get groupOrderNameLabel(): string {
    return this.groupOrder.isHostMode && !this.args.participantName ? 'host' : 'participant';
  }

  get groupOrderName(): string {
    if (!this.args.isHost && this.args.participantName) {
      return this.args.participantName;
    } else if (this.args.isHost || this.groupOrder.isHostMode) {
      const name = this.groupOrder.hostDisplayName
        ? this.groupOrder.hostDisplayName
        : this.args.participantName;
      return name + ' (You)';
    }
    return '';
  }

  get status(): GroupOrderParticipantStatus {
    return this.groupOrder.participantStatus(this.args.participantName!);
  }

  get groupOrderLeftTheGroup(): boolean {
    const status = this.groupOrder.participantStatus(this.args.participantName!);
    return status === GroupOrderParticipantStatus.Removed;
  }

  get showStatusIcon(): boolean {
    return this.args.showStatusIcon ?? true;
  }
  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GroupOrder::Name': typeof GroupOrderName;
  }
}
