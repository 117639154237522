export function addScript(src: string, onLoad?: Action): void {
  const existing = document.querySelector(`script[src="${src}"]`);
  if (existing) {
    onLoad?.();
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.type = 'text/javascript';
  script.src = src;
  if (onLoad) {
    script.onload = onLoad;
  }
  document.body.appendChild(script);
}
