import { action } from '@ember/object';
import Component from '@glimmer/component';

import { noop } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;
}

export default class HubCentralCard extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  startOrder() {
    noop();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Hub::CentralCard': typeof HubCentralCard;
  }
}
