export default {
  "overlay": "_overlay_1hgyd2",
  "modal": "_modal_1hgyd2",
  "fullHeight": "_fullHeight_1hgyd2",
  "header": "_header_1hgyd2",
  "title": "_title_1hgyd2",
  "closeButton": "_closeButton_1hgyd2",
  "closeButtonIcon": "_closeButtonIcon_1hgyd2",
  "body": "_body_1hgyd2",
  "footer": "_footer_1hgyd2",
  "stacked": "_stacked_1hgyd2",
  "button": "_button_1hgyd2",
  "errorMessage": "_errorMessage_1hgyd2",
  "errorIcon": "_errorIcon_1hgyd2"
};
