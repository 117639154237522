import config from 'mobile-web/config/environment';

export type AnimationKeyframes = Keyframe[] | PropertyIndexedKeyframes | null;
export type AnimationOptions = Omit<KeyframeAnimationOptions, 'fill'>;

export const ANIMATION_GRADUAL_IN_TIME = getDuration(350);

/**
 * @deprecated Use animationService.animateTo instead
 * see https://www.youtube.com/watch?v=9-6CKCz58A8&t=14m26s for detailed explanation
 */
export function animateTo(
  element: Element,
  keyframes: AnimationKeyframes,
  options: AnimationOptions
): Promise<undefined> {
  // skip animation for older browsers that don't support the Web Animations API
  if (!element.animate || !Animation?.prototype?.commitStyles) {
    return Promise.resolve(undefined);
  }

  return new Promise((resolve, reject) => {
    try {
      const animation = element.animate(keyframes, {
        ...options,
        fill: 'both',
      });

      animation.addEventListener('finish', () => {
        try {
          animation.commitStyles();
          animation.cancel();
          resolve(undefined);
        } catch (e) {
          reject(e);
        }
      });

      animation.addEventListener('cancel', reject);
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * @deprecated Use animationService.getDuration instead
 */
export function getDuration(desiredDuration: number): number {
  return config.environment === 'test' ? 0 : desiredDuration;
}
