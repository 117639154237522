import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import authenticated from 'mobile-web/decorators/authenticated';
import SessionService from 'mobile-web/services/session';

@authenticated
export default class ChangePassword extends Route {
  @service router!: RouterService;
  @service session!: SessionService;

  beforeModel(transition: Transition) {
    if (this.session.isExternalAccount) {
      transition.abort();
      this.router.transitionTo('application');
    }

    return super.beforeModel(transition);
  }
}
