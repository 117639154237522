import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import ENV from 'mobile-web/config/environment';
import { noop } from 'mobile-web/lib/utilities/_';
import BootstrapService from 'mobile-web/services/bootstrap';
import DeviceService from 'mobile-web/services/device';

import ErrorService from './error';
import FeaturesService from './features';
import SecurityService from './security';
import UserFeedbackService from './user-feedback';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorHandler = (error: any) => void;

type ChallengeOptionsBase = {
  onCancel?: Action;
  wasUnseccesfull?: boolean;
};

type RequestChallengeOptions = ChallengeOptionsBase & {
  retry: Action;
  onError?: ErrorHandler;
};

type ModelHookChallengeOptions = ChallengeOptionsBase & {
  transition: Transition;
};

export default class ChallengeService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service device!: DeviceService;
  @service router!: RouterService;
  @service security!: SecurityService;
  @service userFeedback!: UserFeedbackService;
  @service error!: ErrorService;
  @service features!: FeaturesService;

  // Tracked properties
  @tracked private challengeOptions?: RequestChallengeOptions | ModelHookChallengeOptions;
  @tracked wasUnseccesfull = false;

  // Getters and setters
  get isChallengeActivated(): boolean {
    return !!this.challengeOptions;
  }

  /**
   * We are only expecting this to be true when the endpoints that load user data,
   * which are called as part of bootrap init, are challenged.
   * If the bootstrap data endpoint itself is challenged, we can't recover.
   */
  get initBootsrapChallenged(): boolean {
    return this.bootstrap.initBootstrapFailed && this.isChallengeActivated;
  }

  // Lifecycle methods

  // Other methods
  openChallenge(): void {
    this.challengeOptions = {
      retry: noop,
    };
  }

  @action
  setupChallenge(): void {
    window.turnstile.render('.cf-turnstile', {
      sitekey: ENV.CLOUDFLARE_TURNSTILE,
      callback: async () => {
        await this.deactivateChallenge(true);
      },
      'error-callback': (errorCode: string) => {
        this.wasUnseccesfull = true;

        this.error.sendExternalError(Error('Issue solving cloudflare turnstile challenge'), {
          cause: 'cf-turnstile',
          cfErrorCode: errorCode,
        });
      },
    });
  }

  // Tasks
  @task *request(request: Action, onError?: ErrorHandler): TaskGenerator<void> {
    try {
      yield request();
    } catch (e) {
      this.handleErrorResponse(e, request, onError);
    }
  }

  private handleErrorResponse(e: AnyObject, request: Action, onError?: ErrorHandler) {
    const response = ErrorService.isCloudflareChallenge(e);
    if (response) {
      this.challengeOptions = { retry: request, onError };
    } else {
      if (onError) {
        onError(e);
      } else {
        throw e;
      }
    }
  }

  // Actions and helpers
  @action
  onRouteError(transition: Transition): void {
    // if user challenged on route transition,
    // we could reload a page so that user is challenged by CF full page reload
    // therefore all the hooks (beforeModel, afterModel etc) realoaded after user complete CF challenge
    // and therefore we don't have to track each failed request and retry to properly bootstrap the page
    this.challengeOptions = {
      transition,
      onCancel: () => {
        this.router.transitionTo('index');
      },
    };
    if (this.security.routeIsSecure(transition.to.name)) {
      this.router.transitionTo('secure-challenge');
    } else {
      this.router.transitionTo('challenge');
    }
  }

  @action
  async deactivateChallenge(retry?: boolean): Promise<void> {
    if (!this.challengeOptions) {
      return;
    }

    if (!retry) {
      this.challengeOptions.onCancel?.();
      this.challengeOptions = undefined;
      this.wasUnseccesfull = false;
      return;
    }

    if ('retry' in this.challengeOptions) {
      // this is called when explicitly use this.*request method
      // or openChallenge method is called
      // when this.*request is called it sets retry function into args
      // when openChallenge method is called it sets noop function into args ( there might be a better way to handle that?)
      taskFor(this.request).perform(this.challengeOptions.retry, this.challengeOptions.onError);
    } else {
      // this is only called for routeError and user succesfully completed challenge:
      // retry param is true, this.challengeOptions.retry is undefined
      if (this.initBootsrapChallenged) {
        await this.bootstrap.initBootstrap();
      }
      // therefore transition should not be null here
      this.challengeOptions.transition.retry();
    }

    this.challengeOptions = undefined;
    this.wasUnseccesfull = false;
  }
}

declare module '@ember/service' {
  interface Registry {
    challenge: ChallengeService;
  }
}
