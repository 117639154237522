import DS from 'ember-data';

const { attr, belongsTo, Model } = DS;

export default class OrderFailureModel extends Model.extend({
  order: belongsTo('order'),
  basket: belongsTo('basket'),

  // Note: should be mapped from Retryable.Encouraged -> True, .Pointless or .Unsafe -> False
  canRetry: attr('boolean'),
  customerFriendlyMessage: attr('string'),
}) {}
