import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BusService from 'mobile-web/services/bus';
import ErrorService from 'mobile-web/services/error';
import NotificationsService, {
  NotificationLevel,
  NotificationType,
} from 'mobile-web/services/notifications';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  isOloAuthLogin?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class DeleteAccount extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bus!: BusService;
  @service intl!: IntlService;
  @service storage!: StorageService;
  @service session!: SessionService;
  @service router!: RouterService;
  @service notifications!: NotificationsService;
  @service error!: ErrorService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isExpanded = false;

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  openDeleteAccountConfirmationModal() {
    this.analytics.trackEvent(AnalyticsEvents.DeleteAccountClicked);
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.deleteAccount.confirmationModalTitle'),
      content: this.intl.t('mwc.deleteAccount.confirmationModalContent'),
      buttonLabel: this.intl.t('mwc.deleteAccount.confirmationModalButtonLabel'),
      cancelLabel: this.intl.t('mwc.deleteAccount.confirmationModalCancelLabel'),
      onConfirm: this.deleteAccount,
      testSelector: 'delete-account-confirmation-modal',
      buttonTestSelector: 'delete-account-confirm-button',
      cancelTestSelector: 'delete-account-cancel-button',
    });
  }

  @action
  async deleteAccount() {
    if (!this.session.currentUser) return;
    try {
      const { pending } = await this.session.deleteCurrentUser();
      await this.session.logout();

      if (pending) {
        this.analytics.trackEvent(AnalyticsEvents.DeleteAccountClicked, undefined, {
          bucket: 'olo-auth',
        });
        this.analytics.trackEvent(AnalyticsEvents.DeleteAccountRequested, undefined, {
          bucket: 'all',
        });
      } else {
        this.analytics.trackEvent(AnalyticsEvents.DeleteAccountConfirmed, undefined, {
          bucket: 'all',
        });
      }

      const startupNotifications = this.storage.get('startupNotifications') ?? [];
      startupNotifications.addObject({
        message: pending
          ? this.intl.t('mwc.notifications.accountDeleteSubmitted')
          : this.intl.t('mwc.notifications.accountDeleted'),
        type: NotificationType.AccountDeleted,
        level: NotificationLevel.Success,
      });

      this.storage.set('startupNotifications', startupNotifications);
      this.router.transitionTo('index');
    } catch (e) {
      this.error.sendExternalError(e);
      this.notifications.error({
        message: this.intl.t('mwc.notifications.accountDeleteSubmitFailed'),
        type: NotificationType.AccountDeleted,
      });
    }
  }

  @action
  trackOpen(expanded: boolean) {
    this.isExpanded = expanded;
    if (!expanded) {
      return;
    }
    this.analytics.trackEvent(AnalyticsEvents.ViewDeleteAccount, undefined, { bucket: 'all' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DeleteAccount: typeof DeleteAccount;
  }
}
