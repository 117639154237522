import Controller from '@ember/controller';

import DispatchStatusRoute from 'mobile-web/routes/dispatch-status';

export default class DispatchStatusController extends Controller {
  declare model: ModelForRoute<DispatchStatusRoute>;

  queryParams = ['hash'];

  hash?: string;
}
