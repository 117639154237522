import { helper } from '@ember/component/helper';

import { applyPhoneMask } from 'mobile-web/lib/contact-number-rules';
import isSome from 'mobile-web/lib/utilities/is-some';

export function formatPhone(params: (string | null | undefined)[] /*, hash*/) {
  let value = params[0];
  if (isSome(value)) {
    if (value.length === 11) {
      value = value.substr(1, 10);
    } else if (value.length === 12) {
      value = value.substr(2, 10);
    }
    return applyPhoneMask(value);
  }

  return value;
}

const formatPhoneHelper = helper(formatPhone);
export default formatPhoneHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-phone': typeof formatPhoneHelper;
  }
}
