import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task } from 'ember-concurrency';

import { orderCriteriaProperties } from 'mobile-web/lib/analytics';
import dayjs, { Dayjs } from 'mobile-web/lib/dayjs';
import {
  getSelectedHandoffModeModel,
  getSelectedTimeWantedMode,
  isAdvance,
} from 'mobile-web/lib/order-criteria';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import Vendor from 'mobile-web/models/vendor';
import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import { VendorSearchParams } from 'mobile-web/routes/vendor-search-results';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

export default class BasketTransferModal extends Component {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bus!: BusService;
  @service store!: DS.Store;
  @service basket!: BasketService;
  @service router!: RouterService;
  @service orderCriteria!: OrderCriteriaService;
  @service vendor!: VendorService;

  // Untracked properties
  ids = guids(this, 'searchInstructions');
  style = style;

  // Tracked properties
  @tracked searchResults!: DS.RecordArray<VendorSearchResultModel>;
  @tracked latitude?: number;
  @tracked longitude?: number;
  @tracked address = '';
  @tracked showGeolocationPrompt = false;
  @tracked hasRequestedGeolocation = false;
  @tracked hasSearched = false;

  // Getters and setters
  get searchInstructionsId(): string | undefined {
    return this.hasSearched ? undefined : this.ids.searchInstructions;
  }

  get currentVendor(): Vendor | undefined {
    return this.vendor.vendor;
  }

  get timeWanted(): Dayjs | undefined {
    return isAdvance(this.orderCriteria.criteria)
      ? this.orderCriteria.criteria.timeWanted
      : undefined;
  }

  get wrapperClass(): string {
    return this.showGeolocationPrompt ? style.hide : '';
  }

  get vendorScheduleDay(): dayjs.Dayjs {
    return isAdvance(this.orderCriteria.criteria) && this.orderCriteria.criteria.timeWanted
      ? this.orderCriteria.criteria.timeWanted
      : dayjs();
  }

  get searchLocationDistances(): Array<number> {
    return this.searchResults.map(res => res.distance ?? 0);
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Record<string, unknown>) {
    super(owner, args);

    this.searchResults = this.store.peekAll('vendor-search-result');
  }

  // Other methods
  sendChangeLocationAnalytics(analyticEvent: AnalyticsEvents) {
    const handoffModeModels = this.orderCriteria.selectableHandoffModes;
    const selectedHandoffModeModel = getSelectedHandoffModeModel(
      handoffModeModels,
      this.orderCriteria.criteria.handoffMode
    );
    const timeWantedModes =
      this.orderCriteria.getSelectableTimeWantedModes(selectedHandoffModeModel);
    const selectedTimeWantedMode = getSelectedTimeWantedMode(
      timeWantedModes,
      this.orderCriteria.criteria.timeWantedType
    );

    this.analytics.trackEvent(analyticEvent, () => ({
      ...orderCriteriaProperties(
        handoffModeModels,
        timeWantedModes,
        selectedTimeWantedMode,
        selectedHandoffModeModel
      ),
      [AnalyticsProperties.SelectedVendorLocation]: this.currentVendor?.address,
      [AnalyticsProperties.NumberOfLocations]: this.searchResults.length,
      [AnalyticsProperties.ClosestLocationDistance]: this.searchLocationDistances.length
        ? Math.min(...this.searchLocationDistances)
        : 0,
      [AnalyticsProperties.FurthestLocationDistance]: this.searchLocationDistances.length
        ? Math.max(...this.searchLocationDistances)
        : 0,
      [AnalyticsProperties.VendorIds]: this.searchResults.map(res => res.id),
      [AnalyticsProperties.VendorDistances]: this.searchLocationDistances,
      [AnalyticsProperties.NumberOfLocationsPerPage]: this.searchResults.length,
      [AnalyticsProperties.HandoffSelectionAvailability]: this.timeWanted?.toISOString() ?? '',
    }));
  }

  // Tasks
  searchVendorsTask = task(async (): Promise<void> => {
    const { address, latitude, longitude } = this;

    if (!isEmpty(address) || isSome(latitude) || isSome(longitude)) {
      const queryParams: VendorSearchParams = {
        address,
        latitude,
        longitude,
      };

      const searchResults = await this.store.loadRecords('vendor-search-result', queryParams);
      this.searchResults = searchResults as DS.RecordArray<VendorSearchResultModel>;
      this.hasSearched = true;
      this.sendChangeLocationAnalytics(AnalyticsEvents.ChangeStoreLocationResults);
    }
  });

  // Actions and helpers
  @action
  didInsert() {
    if (this.searchResults.length === 0) {
      this.sendChangeLocationAnalytics(AnalyticsEvents.ChangeStoreLocationSearchNearby);
      this.promptForGeolocation();
    } else {
      this.sendChangeLocationAnalytics(AnalyticsEvents.ChangeStoreLocationResults);
    }
  }

  @action
  promptForGeolocation() {
    if (!this.hasRequestedGeolocation) {
      this.showGeolocationPrompt = true;
      this.hasRequestedGeolocation = true;
    }
  }

  @action
  hideGeolocationPrompt() {
    this.showGeolocationPrompt = false;
  }

  @action
  search(e?: Event) {
    e?.preventDefault();
    if (!isEmpty(this.address)) {
      this.latitude = undefined;
      this.longitude = undefined;
    }
    this.searchVendorsTask.perform();
  }

  @action
  setCoords(coords: Coordinates) {
    this.latitude = coords.latitude;
    this.longitude = coords.longitude;
    this.hideGeolocationPrompt();
    this.search();
  }

  @action
  transferComplete(newVendor: VendorSearchResultModel, clean: boolean, previousVendor: Vendor) {
    if (clean && this.router.currentRouteName === 'checkout') {
      // This behavior is only valid on the checkout route, otherwise we want to redirect
      this.onTransfer(previousVendor);
    } else {
      this.onClose();
      this.router.transitionTo('menu.vendor', newVendor.slug);
    }
  }

  @action
  onClose() {
    this.orderCriteria.set('showAddressModal', false);
  }

  @action
  onTransfer(previousVendor: Vendor) {
    this.bus.trigger('onBasketChange');
    this.bus.trigger('onVendorChange', { previousVendor });
    this.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BasketTransferModal: typeof BasketTransferModal;
  }
}
