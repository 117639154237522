import { action } from '@ember/object';
import Service from '@ember/service';

import { local, session } from 'mobile-web/decorators/storage';
import { computedLocal, computedSession } from 'mobile-web/lib/computed';
import { UserData } from 'mobile-web/lib/customer';
import { RecentOrder } from 'mobile-web/models/basket';
import { StoredOrderSubmission } from 'mobile-web/models/order-submission';
import Vendor from 'mobile-web/models/vendor';
import { Notification } from 'mobile-web/services/notifications';

export type VendorHistoryData = {
  slug: string;
  recentOrder?: RecentOrder;
};

export default class StorageService extends Service {
  // Service injections

  // Untracked properties
  @computedLocal hybridEverOpened?: boolean;

  @computedSession forceChallengePattern?: string;
  @computedSession orderSubmission?: StoredOrderSubmission;
  @computedSession ignorePrecheckError?: boolean;
  @computedSession lastPreCheckValid?: boolean;
  @computedSession lastVendorSlug?: string;
  @computedSession firstRouteName?: string;
  @computedSession startupNotifications?: Notification[];
  @computedSession showOnPremiseContinueModal?: boolean;
  @computedSession showExitOnPremiseModal?: boolean;
  @computedSession createOloAccount?: boolean;
  @computedSession signedInViaOloAuthOverlay?: boolean;
  @computedSession trackedThankYouOrderId?: string;

  // Tracked properties
  @session lastShownAppRatingPromptDate?: string;
  @session receivingCustomer?: UserData;
  @session showFullMenu!: boolean;
  @session redirectFromHome!: boolean;
  @session orderItAgainClosed?: boolean;
  @session wasLoggedIn?: boolean;
  @local orderItAgainCohort?: 'Test' | 'Control' | 'OFF';

  @local sentimentSurveyShown?: boolean;
  @local shownOneTimeNotifications?: AnyObject;
  @local vendorHistory?: VendorHistoryData[];
  @local visitedSentimentSurvey?: boolean;
  // Borderless AB testing variables
  @local abtestBorderlessShow?: boolean;
  @local abtestBorderlessExcluded?: boolean;

  // Getters and setters

  // Lifecycle methods

  // Tasks

  // Actions and helpers
  @action
  storeLocationHistory(vendor: Vendor) {
    const maxHistAmount = 10;
    let vendorHistoryBatch = this.vendorHistory ?? [];
    const previousVendorIdx = vendorHistoryBatch.findIndex(obj => obj.slug === vendor.slug);

    if (previousVendorIdx < 0) {
      if (vendorHistoryBatch.length >= maxHistAmount) {
        const lengthDiff =
          vendorHistoryBatch.length > maxHistAmount ? vendorHistoryBatch.length - maxHistAmount : 0;
        const endIdx = vendorHistoryBatch.length - lengthDiff;

        vendorHistoryBatch = vendorHistoryBatch.slice(1, endIdx);
      }

      vendorHistoryBatch.push({
        slug: vendor.slug,
      });
    } else if (
      previousVendorIdx > -1 &&
      previousVendorIdx !== vendorHistoryBatch.length - 1 &&
      vendorHistoryBatch.length > 1
    ) {
      const previousVendor = vendorHistoryBatch[previousVendorIdx];
      vendorHistoryBatch.splice(previousVendorIdx, 1);

      vendorHistoryBatch.push(previousVendor);
    }

    this.vendorHistory = vendorHistoryBatch;
  }
}

declare module '@ember/service' {
  interface Registry {
    storage: StorageService;
  }
}
