import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import DS from 'ember-data';

import { TicketResponse } from 'mobile-web/adapters/ticket';
import isSome from 'mobile-web/lib/utilities/is-some';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import VendorService from 'mobile-web/services/vendor';

export default class PayRoute extends Route {
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;
  @service vendor!: VendorService;
  @service onPremise!: OnPremiseService;
  @service store!: DS.Store;
  @service session!: SessionService;
  @service router!: RouterService;

  async model(params: { vendor_slug: string }) {
    //make sure to dump any previous guest user data
    if (this.session.isSavedGuest) {
      await this.session.logout();
    }
    this.onPremise.payAtTable = true;

    const vendor = await this.vendor.ensureVendorLoaded(params.vendor_slug);
    return { vendor, ticketResponse: undefined } as {
      vendor: Vendor;
      ticketResponse?: TicketResponse;
    };
  }

  async afterModel(_model: ModelForRoute<this>, transition: Transition) {
    this.analytics.trackEvent(AnalyticsEvents.PayAtTableFindCheckPage);

    const ticketNumber =
      transition.to.queryParams.ticketNumber ?? transition.to.queryParams.ticketnumber;

    const tableNumber = transition.to.queryParams.tableposref;

    const openCheckGuid = this.features.flags['split-check-olo-18091']
      ? transition.to.queryParams.opencheckguid
      : undefined;

    const ticketAdapter = this.store.adapterFor('ticket');

    if (tableNumber) {
      const tickets = (await ticketAdapter.getTickets(tableNumber)).tickets;
      const unpaidTicketsCount = tickets.filter(ticket => ticket.totals.due !== 0).length;

      if (unpaidTicketsCount === 1) {
        const unpaidTicket = tickets.filter(ticket => ticket.totals.due !== 0)[0];
        const ticket = this.store.createRecord('ticket', {
          ticketNumber: unpaidTicket.ticketNumber,
        });
        await this.onPremise.payAtTableCheckout(ticket, openCheckGuid);
      } else if (unpaidTicketsCount > 1) {
        this.onPremise.tablePosRef = tableNumber;
        this.router.transitionTo('pay-select', this.vendor.vendorSlug!);
      } else {
        this.router.transitionTo('menu.vendor', this.vendor.vendorSlug!, {
          queryParams: { opencheck: true, tableposref: tableNumber },
        });
      }
      transition.abort();
    } else if (ticketNumber) {
      const response = await ticketAdapter.getTicket(ticketNumber);

      if (isSome(response.ticket)) {
        const isTicketPaid = response.ticket.totals?.due === 0;

        for (const order of response.orders) {
          if (order.userGuid === this.session.currentUser?.id && isTicketPaid) {
            this.router.transitionTo('thank-you', order.orderId);
            transition.abort();
            return;
          }
        }

        if (isTicketPaid) {
          this.onPremise.tablePosRef = response.ticket.table?.id;
          transition.abort();
          this.onPremise.handlePaidCheck();
        } else {
          const ticket = this.store.peekRecord('ticket', response.ticket.id);
          const transitionToCheckout = await this.onPremise.payAtTableCheckout(
            ticket!,
            openCheckGuid
          );
          if (transitionToCheckout) {
            transition.abort(); //do not load current pay route
          }
        }
      }
    }
  }
}
