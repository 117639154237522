import DS from 'ember-data';

export default class QualifyingLoyaltyRewardsSerializer extends DS.RESTSerializer {
  isNewSerializerAPI = true;
  primaryKey = 'externalReference';
}

declare module 'ember-data/types/registries/serializer' {
  interface SerializerRegistry {
    'qualifying-loyalty-rewards': QualifyingLoyaltyRewardsSerializer;
  }
}
