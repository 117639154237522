import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';

import OnPremiseService from 'mobile-web/services/on-premise';
import StorageService from 'mobile-web/services/storage';

export default class OnPremiseContinueModal extends Component {
  // Service injections
  @service onPremise!: OnPremiseService;
  @service storage!: StorageService;
  @service router!: RouterService;

  // Untracked properties

  // Tracked properties
  @tracked isOpen = false;

  // Getters and setters

  // Lifecycle methods
  constructor(owner: unknown, args: UnknownObject) {
    super(owner, args);

    if (this.storage.showOnPremiseContinueModal) {
      this.storage.showOnPremiseContinueModal = false;
      this.isOpen = true;
    }
  }

  // Other methods

  // Tasks
  endOnPremiseTask = task(async (): Promise<void> => {
    this.isOpen = false;
    await this.onPremise.endOnPremise();
    this.onPremise.basketStartOver();
    this.router.transitionTo('application');
  });

  // Actions and helpers
  @action
  continue() {
    this.isOpen = false;
    if (this.onPremise.closedCheck) {
      this.onPremise.closedCheck = {
        ...this.onPremise.closedCheck,
        continueTime: new Date().toISOString(),
      };
    }
  }

  @action
  end() {
    this.endOnPremiseTask.perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OnPremise::ContinueModal': typeof OnPremiseContinueModal;
  }
}
