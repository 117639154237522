import { host } from 'mobile-web/lib/hybrid-util';

import ApplicationAdapter from './application';

export default class LoyaltyAccountAdapter extends ApplicationAdapter.extend({
  coalesceFindRequests: true,

  urlForQueryRecord(
    this: LoyaltyAccountAdapter,
    args: { membershipId: number; vendorId: EmberDataId }
  ) {
    // We need this data in the serializer, so we store it off here
    this.membershipId = args.membershipId;
    this.vendorId = args.vendorId;

    const namespace = this.namespace;
    return `${host()}/${namespace}/loyalty/account`;
  },
}) {
  membershipId?: number;
  vendorId?: EmberDataId;
}
