import Component from '@glimmer/component';

import { range } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

interface Args {
  // Required arguments
  isCheckoutAuth: boolean;
  isCreateAccount: boolean;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class ApplicationLoadingAuth extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get inputEachArray(): number[] {
    const length = this.args.isCreateAccount ? 6 : this.args.isCheckoutAuth ? 4 : 2;
    return range(0, length);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApplicationLoading::Auth': typeof ApplicationLoadingAuth;
  }
}
