import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import DS from 'ember-data';

import BrandModel from 'mobile-web/models/brand';

import style from './index.m.scss';

interface Args {
  // Required arguments
  brand: BrandModel;

  // Optional arguments
}

interface Signature {
  Element: HTMLButtonElement;

  Args: Args;
}

export default class BrandCard extends Component<Signature> {
  // Service injections
  @service store!: DS.Store;
  // Untracked properties
  style = style;

  // Getters and setters
  get activeBrand(): boolean {
    return this.args.brand.isActive;
  }

  get brandName(): SafeString {
    return htmlSafe(this.args.brand.brandName);
  }

  // Other methods

  // Actions and helpers
  @action
  selectBrand() {
    this.store.peekAll('brand').forEach(b => {
      if (b.id !== this.args.brand!.id) {
        b.isActive = false;
      } else {
        b.isActive = true;
      }
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BrandCard: typeof BrandCard;
  }
}
