/**
 * `firstResolvedPromise` resolves to the value of the first promise that
 * resolves, and ignores all rejected promises.
 *
 * If all promises provided to `firstResolvedPromise` reject, then the resultant
 * promise is rejected with an array of all the rejection values.
 *
 * This is essentially the inverse of `Promise.all`, where `Promise.all` rejects
 * as soon as one of the promises rejects, and resolves with an array when all
 * of the promises are resolved.
 */
export const firstResolvedPromise = <T>(promises: PromiseLike<T>[]): Promise<T> =>
  Promise.all(
    promises.map(promise =>
      promise.then(
        x => {
          throw x;
        },
        x => x
      )
    )
  ).then(
    x => {
      throw x;
    },
    x => x
  );
