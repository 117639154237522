import { action, set } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import * as signalR from '@microsoft/signalr';
import IntlService from 'ember-intl/services/intl';

import { calculatePercentage, percentageAmount } from 'mobile-web/lib/payment/tip';
import { SplitCheckStatus, checkId } from 'mobile-web/lib/split-check';
import { OnPremiseDetailsPayload } from 'mobile-web/models/basket';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService, { ON_PREMISE_JUNK_FIRST_NAME } from 'mobile-web/services/on-premise';
import VendorService from 'mobile-web/services/vendor';

import SessionService from './session';

export enum SplitCheckMethod {
  SplitEqually = 'split-equally',
  SplitByItem = 'split-by-item',
  SplitByAmount = 'split-by-amount',
}
export default class SplitCheckService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service vendor!: VendorService;

  // Untracked properties
  connection?: signalR.HubConnection;

  // Tracked properties
  @tracked private _splitCheckStatuses?: SplitCheckStatus[] = [];

  // Getters and setters
  get checkId() {
    return this.basket.basket ? checkId(this.basket.basket) : undefined;
  }

  get statuses(): SplitCheckStatus[] {
    return this._splitCheckStatuses ?? [];
  }

  get currentStatus(): SplitCheckStatus | undefined {
    const guid = this.basket.basket?.guid.toLowerCase();
    const match = this.statuses.find(s => s.basketGuid.toLowerCase() === guid);
    return match;
  }

  get currentMethod(): SplitCheckMethod | undefined {
    if (this.isSplit) {
      if (this.isSplitByAmount) {
        return SplitCheckMethod.SplitByAmount;
      } else if (this.isSplitByItem) {
        return SplitCheckMethod.SplitByItem;
      } else if (this.isSplitEqually) {
        return SplitCheckMethod.SplitEqually;
      }
    }
    if (this.basket.basket?.onPremiseDetails) {
      const { splitAmount, splitItems, payFor, splitBetween } =
        this.basket.basket.onPremiseDetails!;
      if (splitAmount && splitAmount > 0) {
        return SplitCheckMethod.SplitByAmount;
      } else if (splitBetween && payFor && payFor > 0) {
        return SplitCheckMethod.SplitEqually;
      } else if (splitItems && splitItems.length > 0) {
        return SplitCheckMethod.SplitByItem;
      }
    }
    return undefined;
  }

  get isSplit(): boolean {
    return this.isSplitByAmount || this.isSplitByItem || this.isSplitEqually;
  }

  get isSplitByAmount() {
    return this.statuses?.any(
      s => s.isOrderPlaced && s.splitAmount !== undefined && s.splitAmount > 0
    );
  }

  get isSplitByItem() {
    return this.statuses?.any(
      s => s.isOrderPlaced && s.splitItems?.length !== undefined && s.splitItems.length > 0
    );
  }

  get isSplitEqually() {
    return this.statuses?.any(
      s => s.isOrderPlaced && s.payFor !== undefined && s.splitBetween !== undefined
    );
  }

  // Lifecycle methods

  // Other methods
  async setup() {
    const basket = this.basket.basket;

    if (
      !this.features.flags['split-check-olo-18091'] ||
      !this.onPremise.isPayAtTable ||
      !basket?.onPremiseDetails?.checkId
    ) {
      return;
    }

    if (this.useSignalR()) {
      const connection = new signalR.HubConnectionBuilder().withUrl('/splitcheckhub').build();
      connection.on('receiveStatusChange', () => this.fetchStatuses());
      await connection.start();
      await connection.send('join', this.checkId);
      this.connection = connection;
      await this.fetchStatuses();
    } else {
      await this.fetchStatuses();
    }
  }

  useSignalR(): boolean {
    return false; //pending future work on SignalR behavior
  }

  async setSplitDetails(
    status: {
      splitAmount?: number;
      splitItems?: string[];
      payFor?: number;
      splitBetween?: number;
    },
    ticketNumber: string
  ) {
    const basket = this.basket.basket;
    if (basket) {
      const details = basket.onPremiseDetails!;

      const tipPercentage = basket.tip
        ? calculatePercentage(basket.subTotal, basket.tip)
        : undefined;

      await basket.setOnPremiseDetails({
        tablePosReference: details.tablePosReference,
        experienceType: details.experienceType,
        openCheckGuid: details.openCheckGuid,
        splitAmount: status.splitAmount,
        splitItems: status.splitItems,
        payFor: status.payFor,
        splitBetween: status.splitBetween,
        omnivoreTicketNumber: ticketNumber,
      });

      if (tipPercentage) {
        const tip = percentageAmount(tipPercentage, basket.subTotal);
        await this.basket.updateTipTask.perform(tip);
        this.bus.trigger('onTipAmountChange');
      }
    }
  }

  async clearSplitDetails() {
    const basket = this.basket.basket;
    if (basket) {
      await this.setSplitDetails(
        {
          splitAmount: undefined,
          splitItems: [],
          payFor: undefined,
          splitBetween: undefined,
        },
        this.store.peekAll('ticket').toArray()[0].ticketNumber
      );
      await this.fetchStatuses();
      if (this.session.user?.firstName === '') {
        set(this.session.user!, 'firstName', ON_PREMISE_JUNK_FIRST_NAME);
      }
      this.analytics.trackEvent(AnalyticsEvents.SplitCheckClearSplitConfirmed);
    }
  }

  async onStatusChange() {
    if (this.checkId) {
      this.connection?.send('sendStatusChange', this.checkId);
    }
    // NOTE: If SignalR worked, then we wouldn't need to manually fetch here.
    await this.fetchStatuses();
  }

  fetchStatuses = async () => {
    this._splitCheckStatuses = await this.basket.getSplitCheckStatuses();
  };

  async beforePlaceOrder() {
    if (!this.isSplit) {
      return;
    }

    const basket = this.basket.basket;
    if (!basket) {
      return;
    }

    const ticket = this.store.peekAll('ticket').toArray()[0];
    if (!ticket) {
      return;
    }

    if (basket.total < ticket.totals.due) {
      return;
    }

    const firstStatus = this.statuses.find(s => s.isOrderPlaced)!;
    const details: OnPremiseDetailsPayload = {
      ...basket.onPremiseDetails!,
    };
    if (firstStatus.splitAmount) {
      details.splitAmount = ticket.totals.due;
    } else if (firstStatus.splitItems && firstStatus.splitItems.length > 0) {
      const paidItems = this.statuses.filter(s => s.isOrderPlaced).flatMap(s => s.splitItems!);
      const unpaidItems = ticket.items.filter(i => !paidItems.includes(i.id)).map(i => i.id);
      details.splitItems = unpaidItems;
    } else {
      const paidPortions = this.statuses
        .filter(s => s.isOrderPlaced)
        .reduce((sum, status) => sum + status.payFor!, 0);
      details.splitBetween = firstStatus.splitBetween;
      details.payFor = details.splitBetween! - paidPortions;
    }

    await basket.setOnPremiseDetails(details);
  }

  splitCheckAnalyticsProperties(onPremiseDetails: OnPremiseDetailsPayload): Dict<unknown> {
    const ticket = this.store.peekAll('ticket').toArray()[0];

    return {
      [AnalyticsProperties.SplitMethod]: this.currentMethod,
      [AnalyticsProperties.SplitCheckHasSplitPayment]: this.statuses.any(i => i.isOrderPlaced),
      [AnalyticsProperties.SplitItems]: onPremiseDetails.splitItems?.length
        ? ticket.items.filter(i => onPremiseDetails.splitItems?.includes(i.id)).getEach('name')
        : undefined,
      [AnalyticsProperties.SplitAmount]:
        onPremiseDetails.splitAmount && onPremiseDetails.splitAmount > 0
          ? onPremiseDetails.splitAmount
          : undefined,
      [AnalyticsProperties.SplitEqually]:
        onPremiseDetails.payFor && onPremiseDetails.splitBetween
          ? onPremiseDetails.payFor.toString() + '/' + onPremiseDetails.splitBetween.toString()
          : undefined,
      [AnalyticsProperties.CheckId]: onPremiseDetails.checkId,
    };
  }

  // Tasks

  // Actions and helpers
  @action
  inviteToSplitCheck() {
    // ticket will definitely be in the store, given split check context
    const ticket = this.store.peekAll('ticket').toArray()[0];

    if (this.features.flags['split-check-olo-18091']) {
      const siteUrl = this.channel.settings?.fullSiteUrl ?? '';
      const qrUrl = `https://${siteUrl}/pay/${this.vendor.vendor!.slug}?ticketnumber=${
        ticket!.ticketNumber
      }`;
      this.bus.trigger('invite', {
        title: this.intl.t('mwc.splitCheck.shareCheckButtonLabel'),
        description: this.intl.t('mwc.splitCheck.shareInstructions'),
        shareButtonLabel: this.intl.t('mwc.groupOrder.inviteShareButton'),
        url: qrUrl,
        testSelector: 'split-check-invite-modal',
        shareDescription: `${this.intl.t('mwc.splitCheck.shareInstructions')} ${
          this.channel.name
        }!`,
      });
    }
  }

  @action
  clearSplitCheck() {
    this.analytics.trackEvent(AnalyticsEvents.SplitCheckClearSplitOpened);

    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.splitCheck.clearTitle'),
      content: this.intl.t('mwc.splitCheck.clearContent'),
      buttonLabel: this.intl.t('mwc.splitCheck.clearButton'),
      hideCancelButton: true,
      onConfirm: () => this.clearSplitDetails(),
      onClose: () => this.analytics.trackEvent(AnalyticsEvents.SplitCheckClearSplitClosed),
      testSelector: 'clearSplitCheck',
      buttonTestSelector: 'confirmClearSplitCheck',
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'split-check': SplitCheckService;
  }
}
