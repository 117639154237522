import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import NotificationsService, { Notification } from 'mobile-web/services/notifications';

import style from './index.m.scss';

export enum NotificationOrder {
  Ascending,
  Descending,
}

interface Args {
  // Required arguments
  filter(value: Notification, index: number, notifications: Notification[]): unknown;

  // Optional arguments
  testMode?: boolean;
  order?: NotificationOrder;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class Notifications extends Component<Signature> {
  // Service injections
  @service('notifications') notificationsService!: NotificationsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get order() {
    return this.args.order ?? NotificationOrder.Ascending;
  }

  get notifications() {
    const filteredNotifications = this.notificationsService.notifications.filter(this.args.filter);
    if (this.order === NotificationOrder.Descending) {
      return filteredNotifications.reverse();
    }
    return filteredNotifications;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Notifications: typeof Notifications;
  }
}
