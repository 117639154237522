/**
 * Export localStorage so this file can be stubbed out to replicate an environment w/o localStorage
 */
export const _getLocalStorage: { wrapper: Storage | undefined } = {
  wrapper: window.localStorage,
};

const _localStorageValues: Dict<string> = {};

export const getItem = (key: string): string | null => {
  if (_getLocalStorage.wrapper?.getItem) {
    return _getLocalStorage.wrapper!.getItem(key);
  }
  return _localStorageValues[key];
};

export const setItem = (key: string, value: string): void => {
  if (_getLocalStorage.wrapper?.setItem) {
    _getLocalStorage.wrapper!.setItem(key, value);
  } else {
    _localStorageValues[key] = value;
  }
};
export const removeItem = (key: string): void => {
  if (_getLocalStorage.wrapper?.removeItem) {
    _getLocalStorage.wrapper!.removeItem(key);
  } else {
    delete _localStorageValues[key];
  }
};

export const instance = { getItem, setItem, removeItem };

export const getLocalStorage = () => instance;
