import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { OloAuthChallengeError } from 'mobile-web/lib/olo-auth';
import { isOk, ResultErr } from 'mobile-web/lib/result';
import Validation, {
  ValidationConfig,
  ValidationErrors,
  ValidationMessage,
  ValidationResult,
} from 'mobile-web/lib/validation';
import ChannelService from 'mobile-web/services/channel';

import style from './index.m.scss';

class Model {
  @tracked token = '';
}

interface Args {
  // Required arguments

  // Optional arguments
  error?: OloAuthChallengeError;
  onClose?: () => void;
  onSubmit?: (token: string) => void;
}

interface Signature {
  Args: Args;
}

export default class EditContactInfoOloAuthChallengeModal extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;

  // Untracked properties
  formId = 'edit-contact-info-olo-auth-challenge-form';
  style = style;

  // Tracked properties
  @tracked model: Model;
  @tracked validationResult?: ValidationResult;

  // Getters and setters
  get validationConfig(): ValidationConfig<Model> {
    return {
      bindings: [
        {
          targetProp: 'token',
          ruleName: 'notBlank',
          message: 'Code must be set',
        },
        {
          targetProp: 'token',
          ruleName: 'codeLength',
          message: 'Code must be 6 numbers',
        },
      ],
      customRules: [
        {
          name: 'codeLength',
          check: (value: string) => value.length === 6 && !value.match(/[^0-9]/),
        },
      ],
    };
  }

  get isSubmitDisabled() {
    return !this.model.token?.trim();
  }

  get tokenErrorMessages(): ValidationMessage[] {
    const messages: ValidationMessage[] =
      (this.validationResult as ResultErr<ValidationErrors>)?.err?.token || [];
    if (this.errorMessage) {
      messages.push({ message: this.errorMessage, target: 'token' });
    }
    return messages;
  }

  get errorMessage() {
    switch (this.args.error) {
      case 'failed':
        return 'Could not verify code';
      case 'nocode':
        return 'Please provide a code';
      case 'badcode':
        return 'Invalid code';
      default:
        return '';
    }
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.model = new Model();
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  close() {
    this.args.onClose?.();
  }

  @action
  confirm(e?: Event) {
    e?.preventDefault();
    this.validationResult = Validation.validate(this.model, this.validationConfig);
    if (isOk(this.validationResult)) {
      this.args.onSubmit?.(this.model.token);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EditContactInfoOloAuthChallengeModal: typeof EditContactInfoOloAuthChallengeModal;
  }
}
