import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

import { ContentString } from 'mobile-web/services/content';

export const trustedUnsafeHtml = ([string]: [ContentString | undefined]) =>
  htmlSafe(string as string);

const trustedUnsafeHtmlHelper = helper(trustedUnsafeHtml);
export default trustedUnsafeHtmlHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'trusted-unsafe-html': typeof trustedUnsafeHtmlHelper;
  }
}
