import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

interface Args {
  // Required arguments
  vendor: DS.PromiseObject<Vendor> | undefined;

  // Optional arguments
}
interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class CartSingleUseBanner extends Component<Signature> {
  // Service injections
  @service router!: RouterService;
  @service features!: FeaturesService;
  @service onPremise!: OnPremiseService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Getters and setters
  get showBanner(): boolean {
    if (
      this.args.vendor === undefined ||
      (this.onPremise.isEnabled && !this.features.flags['olo-89307-single-use-on-premise'])
    ) {
      return false;
    }

    const singleUseCategories = this.args.vendor.get('singleUseCategories');
    return (
      singleUseCategories?.some(
        category =>
          category.products
            ?.toArray()
            ?.some(product => !product.isDisabled && product.isAvailable) ?? false
      ) ?? false
    );
  }

  get vendorSlug(): string | undefined {
    return this.args.vendor?.get('slug');
  }

  // Lifecycle methods

  // Other methods

  // Task

  // Actions and helpers
  @action
  addButtonTapped() {
    this.analytics.trackEvent(AnalyticsEvents.SingleUseAddButtonTapped);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::SingleUseBanner': typeof CartSingleUseBanner;
  }
}
