import { GetTimeSlotsResponse } from 'mobile-web/models/basket';

import { getTimeZoneOffset } from './timezone';

/**
 * Fixes the time slots returned by the `baskets/:id/gettimeslots` endpoint.
 *
 * Time slots are sent as local times (i.e. no time zone offset specified) in
 * the UTC time zone using the vendor's current UTC offset. The current vendor
 * UTC offset is not appropriate for time slots that have crossed a Daylight
 * Saving Time boundary.
 *
 * In order to fix this, we first undo the adjustment from the vendor's current
 * UTC offset, and then apply the appropriate offset for the vendor's time zone.
 *
 * @param timeSlot the potentially incorrect time slot expressed as an ISO-8601
 * formatted time stamp without a time zone offset in the UTC time zone.
 * @param vendorUtcOffset the vendor's current offset from UTC in hours.
 * @param vendorTimeZone the vendor's IANA time zone ID.
 */
export const fixTimeSlot = (timeSlot: string, vendorUtcOffset: number, vendorTimeZone: string) => {
  // convert the time slot from a local time to a UTC time
  const date = new Date(`${timeSlot}Z`);

  // Undo the original offset adjustment to go back to UTC by applying the
  // offset to get back to a local time in UTC.
  //
  // I.E. 5PM ET becomes 5PM UTC.
  date.setHours(date.getHours() + vendorUtcOffset);

  // Get the correct vendor time zone offset in minutes
  const vendorTimeZoneOffset = getTimeZoneOffset(vendorTimeZone, date);

  // apply the correct offset to go back to the vendor's local time
  date.setMinutes(date.getMinutes() - vendorTimeZoneOffset);

  return date.toISOString();
};

export const fixTimeSlots = (
  response: GetTimeSlotsResponse,
  vendorTimeZone: string
): GetTimeSlotsResponse => ({
  ...response,
  timeSlots: response.timeSlots?.map(timeSlot =>
    fixTimeSlot(timeSlot, response.vendorUtcOffset, vendorTimeZone)
  ),
});
