import { action } from '@ember/object';
import Component from '@glimmer/component';

import style from './index.m.scss';

export type Segment<T> = {
  label: string;
  value: T;
};

export type OnChange<T> = (segment: Segment<T>) => void;

interface Args<T> {
  // Required arguments
  name: string;
  onChange: OnChange<T>;
  segments: Segment<T>[];

  // Optional arguments
  itemClass?: string;
  label?: string;
  value?: T;
}

interface Signature<T> {
  Element: HTMLFormElement;

  Args: Args<T>;
}

export default class SegmentedControl<T> extends Component<Signature<T>> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get hasSingleSegment() {
    return this.args.segments.length === 1;
  }

  get buttonClasses() {
    const value = this.args.value;
    return this.args.segments.map(s =>
      [style.button, this.args.itemClass || '', s.value === value ? style.selected : ''].join(' ')
    );
  }

  get groupLabel(): string {
    return this.args.label || this.args.name;
  }

  get inputIds() {
    const name = this.args.name;
    return this.args.segments.map((_s, i) => `${name}-${i}`);
  }

  @action
  handleChange(segment: Segment<T>) {
    this.args.onChange(segment);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SegmentedControl: typeof SegmentedControl;
  }
}
