import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  upgradeAccount: () => void;

  // Optional arguments
  showHeader?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}
export default class PostCheckoutCreateAccount extends Component<Signature> {
  // Service injections

  // Untracked properties

  style = style;

  // Tracked properties

  // Getters and setters

  get header(): string {
    return this.args.showHeader ? 'Create Account' : '';
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::CreateAccount': typeof PostCheckoutCreateAccount;
  }
}
