/**
 * @depricated Use mobile-web/lib/utilities/raf.ts instead
 */

export default function scroll(action: Action): Action {
  let ticking = false;
  return () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        action();
        ticking = false;
      });

      ticking = true;
    }
  };
}
