/* eslint-disable no-use-before-define */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { HEADER_ID } from 'mobile-web/components/header';
import { safeNext } from 'mobile-web/lib/runloop';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import { SafeString } from 'mobile-web/services/content';
import DeviceService from 'mobile-web/services/device';
import ScrollService from 'mobile-web/services/scroll';

import style from './index.m.scss';

interface BorderPosition {
  start: number;
  end: number;
}

class LinkData<T> {
  // Untracked properties
  readonly href: string;
  readonly id: string;
  readonly name: string;
  private readonly owner: StickyNav<T>;

  // Tracked properties
  @tracked borderPosition: BorderPosition = { start: 0, end: 0 };
  @tracked contained = false;
  @tracked position: number;

  // Getters and setters
  get current() {
    return this.borderPosition.start !== this.borderPosition.end;
  }

  get inDropdown() {
    return this.owner.mode === 'dropdown' && !this.contained;
  }

  get shouldScroll() {
    const first = this.position === 1;
    const firstVisible = this.borderPosition.start > 0 && this.borderPosition.start < 1;
    return this.owner.mode === 'horizontal-scrolling' ? firstVisible : first;
  }

  get styles() {
    return StickyNav.getBorderStartEndStyle(this.borderPosition.start, this.borderPosition.end);
  }

  // Lifecycle methods
  constructor(id: string, name: string, position: number, owner: StickyNav<T>) {
    this.href = `#${id}`;
    this.id = id;
    this.name = name;
    this.owner = owner;
    this.position = position;
  }

  // Other methods

  // Tasks

  // Actions and helpers
}

export interface SectionModel<T> {
  name: string;
  id: string;
  model: T;
}

interface Args<T> {
  // Required arguments
  sections: SectionModel<T>[];

  // Optional arguments
  onResize?: (height: number) => void;
  placeholderClass?: string;
  topOffset?: number;
  /**
   * This property is for testing purposes only so that the section intersection
   * observer can detect intersections with the test fixture instead of the
   * entire document.
   */
  viewport?: Element | Document;
}

interface Signature<T> {
  Element: HTMLDivElement;

  Args: Args<T>;

  Blocks: {
    header: [];
    section: [T];
  };
}

export default class StickyNav<T> extends Component<Signature<T>> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service device!: DeviceService;
  @service scroll!: ScrollService;

  // Untracked properties
  linkData: Map<string, LinkData<T>> = new Map();
  navResizeObserver = new ResizeObserver(this.navResize);
  style = style;
  sectionIntersectionObserverCache = new Map<number, IntersectionObserver>();

  // Tracked properties
  @tracked itemIntersectionObserver?: IntersectionObserver;
  @tracked height = 0;

  // Getters and setters
  get currentIsInDropdown(): boolean {
    return !!this.dropdownLinks.find(l => l.current);
  }

  get dropdownLinks(): LinkData<T>[] {
    return this.links.filter(link => link.inDropdown);
  }

  get links(): LinkData<T>[] {
    return this.args.sections.map(this.getLinkDataForSection);
  }

  get mode() {
    return this.device.touchScreen ? 'horizontal-scrolling' : 'dropdown';
  }

  get moreButtonStyle(): SafeString | undefined {
    if (this.currentIsInDropdown) {
      const borderPositionSums = this.dropdownLinks.reduce(
        (sum, link) => ({
          start: sum.start + link.borderPosition.start,
          end: sum.end + link.borderPosition.end,
        }),
        { start: 0, end: 0 }
      );
      const averageStart = borderPositionSums.start / this.dropdownLinks.length;
      const averageEnd = borderPositionSums.end / this.dropdownLinks.length;
      return StickyNav.getBorderStartEndStyle(averageStart, averageEnd);
    }

    return undefined;
  }

  get sectionIntersectionObserver() {
    // header size changes with the nav height
    const headerHeight = document.getElementById(HEADER_ID)?.offsetHeight ?? 0;

    const top =
      // height of the sticky nav
      this.height +
      // height of the header
      headerHeight +
      // additional offset from other sticky toolbars (i.e. multi-brand selector)
      (this.args.topOffset ?? 0) +
      // 2 extra pixels to account for some variation in floating point
      // arithmetic as well as some minor inconsistencies in browser scroll
      // accuracy
      2;

    let observer = this.sectionIntersectionObserverCache.get(top);

    if (!observer) {
      observer = new IntersectionObserver(this.trackSections, {
        // [0.00, 0.01, 0.02, ..., 1.00]
        threshold: Array.from({ length: 101 }, (_, i) => i * 0.01),
        root: this.args.viewport,
        // Adjust the top intersection to ignore content behind the sticky nav.
        rootMargin: `-${top}px 0px 0px`,
      });

      // Store the observer in the cache so that we use the same observers
      // eslint-disable-next-line ember/no-side-effects
      this.sectionIntersectionObserverCache.set(top, observer);
    }

    return observer;
  }

  get showDropdown() {
    return this.dropdownLinks.length > 0;
  }

  // Lifecycle methods

  // Other methods
  static getBorderStartEndStyle(start: number, end: number): SafeString {
    return htmlSafe(`--border-start: ${start}; --border-end: ${end}`);
  }

  // Tasks

  // Actions and helpers
  @action
  private getLinkDataForSection({ id, name }: SectionModel<T>, index: number) {
    const position = index + 1;
    const linkData = this.linkData.get(id) ?? new LinkData(id, name, position, this);
    this.linkData.set(id, linkData);

    if (linkData.position !== position) {
      safeNext(this, () => {
        linkData.position = position;
      });
    }

    return linkData;
  }

  @action
  navResize(entries: ResizeObserverEntry[]) {
    for (const entry of entries) {
      const { height } = entry.target.getBoundingClientRect();
      this.args.onResize?.(height);
      this.height = height;
      break;
    }
  }

  @action
  async toggleAutoScrolling() {
    this.scroll.isAutoScrolling = true;
    await this.scroll.scrollStop();
    this.scroll.isAutoScrolling = false;
  }

  @action
  setupItemIntersectionObserver(element: HTMLOListElement) {
    this.itemIntersectionObserver = new IntersectionObserver(this.trackItems, {
      root: element,
      threshold: 0.9995,
    });
  }

  @action
  trackItems(entries: IntersectionObserverEntry[]) {
    for (const entry of entries) {
      const id = (entry.target as HTMLElement).dataset.id;

      if (!id) {
        return;
      }

      const linkData = this.linkData.get(id);

      if (!linkData) {
        return;
      }

      linkData.contained = entry.isIntersecting;
    }
  }

  @action
  trackLinkClick(link: LinkData<T>) {
    this.analytics.trackEvent(AnalyticsEvents.StickyNavCategoryClick, () => ({
      [AnalyticsProperties.CategoryName]: link.name,
      [AnalyticsProperties.CategoryIndex]: link.position,
      [AnalyticsProperties.CategoryIsWithinViewMore]: link.inDropdown,
      [AnalyticsProperties.TotalCategories]: this.args.sections.length,
    }));
  }

  @action
  trackSections(entries: IntersectionObserverEntry[]) {
    for (const entry of entries) {
      const { top, bottom, height } = entry.boundingClientRect;

      const { top: rootTop, bottom: rootBottom } = entry.rootBounds ?? { top: 0, bottom: 0 };

      const start =
        // if the bottom of the section is above the top of the viewport, we
        // have scrolled past the section and the border is fully at the right.
        bottom < rootTop
          ? 1
          : // otherwise if the top of the section is above the top of the
          // viewport, but the bottom of the section is below the top of the
          // viewport, the section is only partially on screen and the border
          // should be shifted to the right by the portion of the section that
          // is above the viewport.
          top < rootTop
          ? (rootTop - top) / height
          : // finally, if the top of the section is below the top of the
            // viewport, we haven't yet scrolled past the section and the border
            // should start at the left.
            0;

      const end =
        // if the top of the section is below the bottom of the viewport, we
        // haven't yet scrolled to the section and the border is fully at the
        // left.
        top > rootBottom
          ? 0
          : // otherwise if the bottom of the section is below the bottom of the
          // viewport, but the top of the section is above the bottom of the
          // viewport, the section is only partially on screen and the border
          // should be shifted to the left by the portion of the section that is
          // below the viewport.
          bottom > rootBottom
          ? 1 - (bottom - rootBottom) / height
          : // finally, if the bottom of the section is above the bottom of the
            // viewport we have at least partially scrolled past the end of the
            // section and the and the border should end at the right.
            1;

      const linkData = this.linkData.get(entry.target.id);

      if (!linkData) {
        return;
      }

      linkData.borderPosition = {
        start,
        end,
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    StickyNav: typeof StickyNav;
  }
}
