// These properties are defined in line with the values in _breakpoints.scsss.
export const TABLET = 768;
export const DESKTOP = 1024;
export const DESKTOP_LG = 1280;
export const DESKTOP_XL = 1440;

export default {
  mobile: `(max-width: ${TABLET - 1}px)`,
  tablet: `(min-width: ${TABLET}px)`,
  desktop: `(min-width: ${DESKTOP}px)`,
  desktopLg: `(min-width: ${DESKTOP_LG}px)`,
  desktopXl: `(min-width: ${DESKTOP_XL}px)`,
};
