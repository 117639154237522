import { inject as service } from '@ember/service';
import DS from 'ember-data';

import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import { Code } from 'mobile-web/lib/country';
import { Type } from 'mobile-web/lib/payment/card';

import BillingScheme from './billing-scheme';

/**
 * For Cash schemes, there is always 1 membership, and it has a special hardcoded ID of 'Cash'.
 */
export const CASH_MEMBERSHIP_ID = 'Cash';

export default class BillingMembership extends DS.Model {
  @service store!: DS.Store;

  @DS.attr('string')
  description!: string;

  @DS.belongsTo('billing-scheme', { async: false })
  billingScheme!: BillingScheme;

  @DS.attr('string')
  notes?: string;

  @DS.attr('string')
  cardType?: Type;

  // Required for CreditCard and all GiftCard memberships, not present for others
  @DS.attr('boolean')
  canRemove?: boolean;
  @DS.attr('boolean')
  canCheckBalance?: boolean;
  @DS.attr('number')
  balance?: number;

  // Required for CreditCard, not present for others
  @DS.attr('string')
  zip?: string;

  // Optional for CreditCard, not present for others
  @DS.attr('boolean')
  isDefault?: boolean;
  @DS.attr('string')
  streetAddress1?: string;
  @DS.attr('string')
  streetAddress2?: string;
  @DS.attr('string')
  city?: string;
  @DS.attr('string')
  state?: string;
  @DS.attr('string')
  country?: Code;
  @DS.attr('boolean')
  cvvRevalidationRecommended?: boolean;
  @DS.attr('string')
  securityActionJwt?: string;

  // Only set in my-account
  @DS.attr('boolean')
  isGiftCard?: boolean;
  @DS.attr('boolean')
  isBrandedCard?: boolean;

  @memberAction<void>({
    type: 'put',
    path: 'setdefault',
    before() {
      return { isDefault: true };
    },
    async after(this: BillingMembership) {
      await this.store.findAll('billing-membership', { reload: true });
    },
  })
  makeDefault!: MemberAction<void>;

  async tryLoadBalance(): Promise<void> {
    try {
      if (this.isGiftCard && this.canCheckBalance) {
        const giftCard = await this.store.adapterFor('billing-membership').getGiftCard(this.id);
        this.balance = giftCard.balance;
      }
    } catch {}
  }
}
