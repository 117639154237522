import Helper from '@ember/component/helper';
import { isNone } from '@ember/utils';

export interface Signature {
  Args: {
    Positional: [Record<any, any>, any];
  };
  Return: any;
}

/*
  The emberjs built-in get helper does not support fetching values from records that have a dot in the key name.
  https://github.com/emberjs/ember.js/issues/9630
*/
export default class GetValue extends Helper<Signature> {
  compute(params: Signature['Args']['Positional']) {
    const obj = params[0];
    const key = params[1];

    if (isNone(obj)) {
      return undefined;
    }

    return obj[key];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'get-value': typeof GetValue;
  }
}
