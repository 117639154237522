import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { MediaService } from 'ember-responsive';

import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  isError?: boolean;
}

interface Signature {
  Element: HTMLElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class LogoLink extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service media!: MediaService;
  @service session!: SessionService;
  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  /**
   * Indicates that the link should be disabled.
   *
   * Link should be disabled when:
   *  - device is not a web browser
   *  - screen size is smaller than desktop
   *  - using restricted flow
   *  - the basket is from Switchboard
   */
  get isDisabled() {
    return (
      !this.device.isWeb ||
      !this.media.isDesktop ||
      this.session.isRestrictedFlow ||
      this.basket.isCallcenterOrder
    );
  }

  /**
   * Indicates that the link should open externally
   *
   * Link should open externally when:
   *  - shown on the error page
   *  - channel has `parentSiteUrl`
   */
  get isExternal(): boolean {
    return (this.args.isError ?? false) || !!this.channel.settings?.parentSiteUrl;
  }

  /**
   * The URL to use for external links.
   */
  get href(): string {
    return this.channel.settings?.parentSiteUrl ?? '/';
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Logo::Link': typeof LogoLink;
  }
}
