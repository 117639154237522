export default {
  "root": "_root_1q6lcq",
  "hasImage": "_hasImage_1q6lcq",
  "isDisabled": "_isDisabled_1q6lcq",
  "row": "_row_1q6lcq",
  "image": "_image_1q6lcq",
  "animatedContainer": "_animatedContainer_1q6lcq",
  "productInfo": "_productInfo_1q6lcq",
  "hasFeatured": "_hasFeatured_1q6lcq",
  "menu": "_menu_1q6lcq",
  "name": "_name_1q6lcq",
  "productButton": "_productButton_1q6lcq",
  "priceLine": "_priceLine_1q6lcq",
  "hasOverride": "_hasOverride_1q6lcq",
  "overrideLabel": "_overrideLabel_1q6lcq",
  "price": "_price_1q6lcq",
  "calories": "_calories_1q6lcq",
  "hasPrice": "_hasPrice_1q6lcq",
  "availability": "_availability_1q6lcq _row_1q6lcq",
  "icon": "_icon_1q6lcq",
  "isDisabledBanner": "_isDisabledBanner_1q6lcq _row_1q6lcq",
  "isDisabledDescription": "_isDisabledDescription_1q6lcq"
};
