import { readOnly } from '@ember/object/computed';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { channelSlug } from 'mobile-web/lib/hybrid-util';
import isSome from 'mobile-web/lib/utilities/is-some';
import ChannelService from 'mobile-web/services/channel';
import SessionService from 'mobile-web/services/session';
import WindowService from 'mobile-web/services/window';

export default class SignupRoute extends Route {
  @service channel!: ChannelService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service window!: WindowService;

  @readOnly('channel.settings.useThirdPartyLogin')
  useThirdPartyLogin?: boolean;

  beforeModel(transition: Transition) {
    return this.store.findRecord('bootstrap-data', channelSlug()).then(bootstrapData => {
      if (this.session.isLoggedIn) {
        transition.abort();
        return this.router.transitionTo('application');
      }

      if (isSome(bootstrapData.registerAccountUrl) && this.useThirdPartyLogin) {
        transition.abort();
        return this.window.location().assign(bootstrapData.registerAccountUrl);
      }

      transition.abort();
      return this.router.transitionTo('login', {
        queryParams: {
          upgrade: transition.to.queryParams.upgrade,
          mode: 1, // sign-in-create-account-form#Mode.CreateAccount
        },
      });
    });
  }
}
