import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';

import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';
import VendorService from 'mobile-web/services/vendor';

export default class HubRoute extends Route {
  @service features!: FeaturesService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service vendor!: VendorService;

  beforeModel() {
    if (!this.features.flags['cop-vendor-hub-olo-55642']) {
      this.router.replaceWith('index');
    }
  }

  async model(params: { vendor_slug: string }, transition: Transition) {
    const vendor = await this.vendor.ensureVendorLoaded(params.vendor_slug);

    const tablePosRef = transition.to.queryParams.tableposref;
    if (tablePosRef) {
      this.onPremise.tablePosRef = tablePosRef;
    }

    return vendor;
  }
}
