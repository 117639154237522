import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import { classes } from 'mobile-web/lib/utilities/classes';
import { guids } from 'mobile-web/lib/utilities/guids';
import UpsellItem from 'mobile-web/models/upsell-item';
import AnalyticsService from 'mobile-web/services/analytics';

import style from './index.m.scss';

const UPSELL_ITEM_WIDTH = 128;
// margin-right
const UPSELL_ITEM_MARGIN = 8;
export const UPSELL_ITEM_BLOCK = UPSELL_ITEM_WIDTH + UPSELL_ITEM_MARGIN;

interface Args {
  // Required arguments
  upsellItem: UpsellItem;
  onAdd: Action<[UpsellItem]>;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class CartUpsellItem extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service store!: DS.Store;

  // Untracked properties
  ids = guids(this, 'calories', 'cost', 'label');
  style = style;

  // Tracked properties

  // Getters and setters
  get describedby() {
    return classes(
      // order matters here, description IDs should match the order they appear in the DOM
      {
        [this.ids.calories]: this.args.upsellItem.calorieLabel,
      },
      this.args.upsellItem.labels?.map((_, idx) => `${this.ids.label}-${idx}`),
      this.ids.cost
    );
  }

  get showPrice(): boolean {
    return (this.args.upsellItem.cost ?? 0) > 0.0;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  addUpsellItem() {
    this.args.onAdd(this.args.upsellItem);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::UpsellItem': typeof CartUpsellItem;
  }
}
