import DS from 'ember-data';

import isSome from 'mobile-web/lib/utilities/is-some';
import BaseJSONSerializer from 'mobile-web/serializers/base-json-serializer';

export default class LoyaltyMembershipSerializer extends BaseJSONSerializer {
  primaryKey = 'membershipId';
  modelName = 'loyalty-membership';

  serialize(s: DS.Snapshot, options: UnknownObject): UnknownObject {
    const snapshot = s as DS.Snapshot<'loyalty-membership'>;
    if (isSome(snapshot.record.scheme)) {
      return {
        member: snapshot.record.membershipId,
        schemeId: snapshot.record.scheme.id,
        vendorId: snapshot.record.scheme.vendor.get('id'),
      };
    }
    return super.serialize(snapshot, options);
  }

  normalizeResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: { loyaltyMembership: UnknownObject; loyaltyMemberships: UnknownObject },
    id: EmberDataId,
    requestType: string
  ): UnknownObject {
    return super.normalizeResponse(
      store,
      primaryModelClass,
      payload.loyaltyMembership || payload.loyaltyMemberships || payload,
      id,
      requestType
    );
  }
}
