import ENV from 'mobile-web/config/environment';

export async function initialize() {
  if (ENV.isHybrid) {
    const { SplashScreen } = await import('@capacitor/splash-screen');
    SplashScreen.hide();
  }
}

export default {
  name: 'splash-screen',
  initialize,
};
