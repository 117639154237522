import { assert } from '@ember/debug';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { Breadcrumb } from 'mobile-web/components/breadcrumbs';
import dayjs from 'mobile-web/lib/dayjs';
import { stateFullName, allStatesWithCountry } from 'mobile-web/lib/state';
import { noop } from 'mobile-web/lib/utilities/_';
import { isSome } from 'mobile-web/lib/utilities/is-some';
import { VendorResultRoutes } from 'mobile-web/lib/vendor';
import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import AddressService from 'mobile-web/services/address';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
  TIME_WANTED_ANALYTICS_LABELS,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import UserFeedback from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

interface Args {
  // Required arguments
  results: DS.AdapterPopulatedRecordArray<VendorSearchResultModel>;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class RegionResultsRoute extends Component<Signature> {
  // Service injections
  @service address!: AddressService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;
  @service basket!: BasketService;
  @service('order-criteria') orderCriteriaService!: OrderCriteriaService;
  @service UserFeedback!: UserFeedback;
  @service device!: DeviceService;
  @service features!: FeaturesService;

  // Untracked properties
  noop = noop;
  style = style;

  // Tracked properties

  // Getters and setters
  get regionCode() {
    return this.router.currentRoute.params.region_code ?? '';
  }

  get breadcrumbs(): Breadcrumb[] {
    return [
      { label: this.intl.t('mwc.locations.crumbRootLabel'), route: 'locations', models: [] },
      { label: stateFullName(this.regionCode) ?? '' },
    ];
  }

  get vendor() {
    return this.basket.basket?.vendor?.content;
  }

  get vendors() {
    return this.args.results.sortBy('address.city');
  }

  get heading(): string {
    const state = stateFullName(this.regionCode);
    return `${this.intl.t('mwc.locations.heading')} ${this.intl.t(
      'mwc.locations.headingPreposition'
    )} ${state}`;
  }

  get searchMapURL() {
    const regionInfo = allStatesWithCountry()[this.regionCode];
    const query = `${this.channel.current?.name} in ${
      regionInfo.name
    }, ${regionInfo.countryCode.toUpperCase()}`;
    return this.address.createMapsApplicaitonLink(query);
  }

  get vendorScheduleDay() {
    return dayjs();
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    assert('valid `region_code` required', isSome(stateFullName(this.regionCode)));
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  viewVendor(newVendor: VendorSearchResultModel, order = true) {
    const search = this.orderCriteriaService.criteria;
    const handoffMode = search && order ? search.handoffMode : 'N/A';
    const timeWantedMode =
      search && order ? TIME_WANTED_ANALYTICS_LABELS[search.timeWantedType] : 'N/A';
    this.analytics.trackEvent(
      order ? AnalyticsEvents.BeginOrder : AnalyticsEvents.ViewMenu,
      () => ({
        [AnalyticsProperties.StoreName]: newVendor.name,
        [AnalyticsProperties.StoreCity]: newVendor.address.city,
        [AnalyticsProperties.StoreState]: newVendor.address.state,
        [AnalyticsProperties.StorePostalCode]: newVendor.address.postalCode,
        [AnalyticsProperties.LinkLocation]: 'View Locations Page',
        [AnalyticsProperties.SelectedHandoffMode]: handoffMode,
        [AnalyticsProperties.SelectedTimeWantedMode]: timeWantedMode,
      })
    );
    this.UserFeedback.clearAll();
    this.router.transitionTo(VendorResultRoutes.Menu, newVendor.slug);
  }

  @action
  trackClick() {
    this.analytics.trackEvent("Open Region's Map" as AnalyticsEvents);

    if (this.features.flags['ox-append-channel-name-to-map-link-olo-80911']) {
      this.analytics.trackEvent(AnalyticsEvents.ViewMapClicked);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::RegionResultsRoute': typeof RegionResultsRoute;
  }
}
