import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import authenticated from 'mobile-web/decorators/authenticated';

@authenticated
export default class Favorites extends Route {
  @service store!: DS.Store;

  model() {
    return this.store.query('favorite', {});
  }
}
