import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { CustomizedProductModel } from 'mobile-web/lib/menu';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import { isBasketProduct } from 'mobile-web/models/basket-product';
import AnimationService from 'mobile-web/services/animation';
import GroupOrderService from 'mobile-web/services/group-order';

import style from './index.m.scss';

interface Args {
  // Required arguments
  products: CustomizedProductModel[];

  // Optional arguments
  currency?: string;
  editable?: boolean;
  initialCount?: number;
  onClose?: Action;
  participantName?: string;
  showGroupOrderName?: boolean;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class AggregatedProductList extends Component<Signature> {
  // Service injections
  @service groupOrder!: GroupOrderService;
  @service animation!: AnimationService;

  // Untracked properties
  animationDuration = this.animation.getDuration(40);
  style = style;
  transition = this.animation.slideAndFadeInSlideAndFadeOutTransition;
  ids = guids(this, 'brandHeading');

  // Tracked properties
  @tracked isExpanded = false;

  // Getters and setters
  get shownProducts() {
    return this.isExpanded || isNone(this.args.initialCount)
      ? this.vendorProductMapArray
      : this.initialProducts;
  }

  get hiddenProductCount() {
    return this.totalProductCount - (this.args.initialCount ?? 0);
  }

  get showExpandButton() {
    return (
      !this.isExpanded &&
      isSome(this.args.initialCount) &&
      this.totalProductCount > this.args.initialCount
    );
  }

  get vendorProductMapArray(): Array<{ brandName: string; products: CustomizedProductModel[] }> {
    const map = new Map<string, CustomizedProductModel[]>();
    this.args.products.forEach(basketProduct => {
      if (isBasketProduct(basketProduct)) {
        const product = basketProduct.store.peekRecord('product', basketProduct.product.get('id')!);
        const brandName = product!.brandName!;
        if (!map.has(brandName)) {
          map.set(brandName, [basketProduct]);
        } else {
          map.get(brandName)!.push(basketProduct);
        }
      }
    });
    return Array.from(map).map(([brandName, products]) => ({
      brandName,
      products,
    }));
  }

  get totalProductCount() {
    return this.vendorProductMapArray.reduce(
      (totalProducts, brandProducts) => totalProducts + brandProducts.products.length,
      0
    );
  }

  get initialProducts() {
    const initialProducts: Array<{ brandName: string; products: CustomizedProductModel[] }> = [];
    let numProductsStillNeeded = this.args.initialCount ?? 0;

    this.vendorProductMapArray.forEach(brandProducts => {
      if (numProductsStillNeeded > 0) {
        if (brandProducts.products.length <= numProductsStillNeeded) {
          initialProducts.push(brandProducts);
          numProductsStillNeeded -= brandProducts.products.length;
        } else {
          const initialBrandProducts = {
            brandName: brandProducts.brandName,
            products: brandProducts.products.slice(0, numProductsStillNeeded),
          };
          initialProducts.push(initialBrandProducts);
          numProductsStillNeeded -= initialBrandProducts.products.length;
        }
      }
    });

    return initialProducts;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  expandProducts() {
    this.isExpanded = true;
    const focusElement = document.querySelector(
      '[data-test-editablesection="BasketContents"]'
    ) as HTMLElement;
    focusElement?.focus();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::AggregatedProductList': typeof AggregatedProductList;
  }
}
