import DS from 'ember-data';

import { getTimeWantedTypeById, TimeWantedType } from 'mobile-web/lib/order-criteria';
import ApplicationSerializer from 'mobile-web/serializers/application';

export default class TimeWantedModeSerializer extends ApplicationSerializer {
  normalize(
    modelClass: DS.Model,
    resourceHash: {
      value: number;
      type?: TimeWantedType;
    },
    prop?: string
  ): UnknownObject {
    resourceHash.type = getTimeWantedTypeById(resourceHash.value);

    return super.normalize(modelClass, resourceHash, prop);
  }
}
