export default {
  "button": "_button_7o22y9",
  "highlight": "_highlight_7o22y9",
  "softDisabled": "_softDisabled_7o22y9",
  "main": "_main_7o22y9",
  "minor": "_minor_7o22y9",
  "destructive": "_destructive_7o22y9",
  "hasHighlightContent": "_hasHighlightContent_7o22y9",
  "text": "_text_7o22y9",
  "animatedContainer": "_animatedContainer_7o22y9",
  "icon": "_icon_7o22y9",
  "newIconFormat": "_newIconFormat_7o22y9",
  "left": "_left_7o22y9",
  "iconSvg": "_iconSvg_7o22y9",
  "size-100": "_size-100_7o22y9",
  "size-200": "_size-200_7o22y9",
  "size-300": "_size-300_7o22y9",
  "size-400": "_size-400_7o22y9",
  "size-500": "_size-500_7o22y9",
  "size-600": "_size-600_7o22y9",
  "size-700": "_size-700_7o22y9",
  "size-800": "_size-800_7o22y9",
  "size-900": "_size-900_7o22y9",
  "size-1000": "_size-1000_7o22y9",
  "fullWidth": "_fullWidth_7o22y9"
};
