import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { LoginProvider } from 'mobile-web/models/bootstrap-data';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import DeviceService from 'mobile-web/services/device';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  // Required arguments
  externalLogin: (loginProvider: LoginProvider) => void;
  loginProviders: LoginProvider[];
  showUpperDivider?: boolean;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class ExternalLoginProviders extends Component<Signature> {
  // Service injections

  @service analytics!: AnalyticsService;
  @service device!: DeviceService;
  @service session!: SessionService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get oloAuthLoginProviders(): LoginProvider[] | undefined {
    return this.device.isHybridIOS
      ? this.args.loginProviders.filter(p => !p.isOloAuth)
      : this.args.loginProviders;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  doExternalLogin(loginProvider: LoginProvider) {
    this.analytics.trackEvent(AnalyticsEvents.ExternalSignIn, () => ({
      [AnalyticsProperties.ExternalLoginType]: loginProvider?.slug,
    }));
    this.args.externalLogin(loginProvider);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ExternalLoginProviders: typeof ExternalLoginProviders;
  }
}
