import { getOwner } from '@ember/application';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';

import config from 'mobile-web/config/environment';
import { currentTransitionArgs } from 'mobile-web/lib/routing';
import { Type } from 'mobile-web/services/user-feedback';

export default function (route: { prototype: Route }): void {
  const original = route.prototype.beforeModel;
  route.prototype.beforeModel = function (transition: Transition) {
    const owner = getOwner(this) as AnyObject;
    const router: RouterService = owner.lookup('service:router');
    const session = owner.lookup('service:session');
    const userFeedback = owner.lookup('service:user-feedback');
    const intl = owner.lookup('service:intl');

    if (!session.isLoggedIn) {
      transition.abort();
      session.nextRoute = currentTransitionArgs(transition.to);

      userFeedback.add({
        type: Type.Warning,
        title: intl.t('mwc.errors.loginRequiredTitle'),
        message: intl.t('mwc.errors.loginRequiredMessage'),
        actions: [],
      });

      router.transitionTo('login').then(() => {
        /**
         * This handles a corner case that happens on the web.
         * After changing password, a user is logged out and sent to the login
         * route. If the user goes back via the browser back button, we detect
         * that they are not logged in and therefore fall into this branch,
         * redirecting to the login route. However, even though the login
         * route is rendered, `window.location.pathname` is sometimes still
         * '/my-account/change-password' for some reason. To get everything to
         * line up again, we have to manually set the path to '/login'.
         */
        if (config.environment !== 'test' && window.location.pathname !== '/login') {
          window.location.pathname = '/login';
        }
      });
    } else if (original) {
      original.call(this, transition);
    }
  };
}
