import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import IntlService from 'ember-intl/services/intl';

import VendorService from 'mobile-web/services/vendor';

export interface Signature {
  Args: {
    Named: { format?: string; hideDecimal?: boolean };
    Positional: (number | undefined)[];
  };
  Return: string;
}

export default class FormatCurrency extends Helper<Signature> {
  @service intl!: IntlService;

  @service vendor!: VendorService;

  compute(
    params?: Signature['Args']['Positional'],
    namedArgs: Signature['Args']['Named'] = {}
  ): Signature['Return'] {
    const amount = params?.[0] ?? 0;

    const format = namedArgs.format || this.vendor.currency || 'USD';
    return this.intl.formatNumber(amount, {
      style: 'currency',
      currency: format,
      minimumFractionDigits: namedArgs.hideDecimal && Number.isInteger(amount) ? 0 : 2,
      maximumFractionDigits: 2,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-currency': typeof FormatCurrency;
  }
}
