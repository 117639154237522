import { set, action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import authenticated from 'mobile-web/decorators/authenticated';
import { computedLocal } from 'mobile-web/lib/computed';
import { VendorSearchParams } from 'mobile-web/routes/vendor-search-results';
import VendorService from 'mobile-web/services/vendor';
import VendorSearchService from 'mobile-web/services/vendor-search';

@authenticated
export default class RewardsSearchRoute extends Route {
  @service vendor!: VendorService;
  @service router!: RouterService;
  @service vendorSearch!: VendorSearchService;

  @computedLocal
  localVendorSearchParams?: VendorSearchParams;

  beforeModel(transition: Transition) {
    const isNewSearch =
      transition.to.queryParams.hasOwnProperty('search') &&
      transition.to.queryParams.search === 'new';

    const vendorService = this.vendor;
    if (isNewSearch) {
      set(vendorService, 'vendor', undefined);
    } else {
      const currentVendor = vendorService.vendor;

      if (!isNone(currentVendor)) {
        this.router.transitionTo('menu.vendor.rewards', currentVendor.slug);
      }
    }
  }

  model() {
    return this.vendorSearch.search(this.localVendorSearchParams);
  }

  @action
  searchDidChange() {
    this.refresh();
  }
}
