import { Code, COUNTRIES } from 'mobile-web/lib/country';
import isSome from 'mobile-web/lib/utilities/is-some';

const STATES: Dict<Dict<string>> = {
  /**
   * https://pe.usps.com/text/pub28/28apb.htm
   *
   * Note:
   *
   * If you check Wikipedia, it lists 50 states, 1 district, and 5 inhabited territories:
   * https://en.wikipedia.org/wiki/List_of_states_and_territories_of_the_United_States
   *
   * How do we get 59? Micronesia, Marshall Islands, and Palau, the freely associated states.
   * https://en.wikipedia.org/wiki/Compact_of_Free_Association
   *
   * This matches the list of 59 from Ghost.
   */
  [Code.US]: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FM: 'Federated States of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  /**
   * https://en.wikipedia.org/wiki/Provinces_and_territories_of_Canada
   */
  [Code.CA]: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
};

export type StateData = {
  name: string;
  abbreviation: string;
};

export function states(country: Code): StateData[] {
  const stateDictionary = STATES[country];
  if (isSome(stateDictionary)) {
    return Object.entries(stateDictionary).map(([abbreviation, name]) => ({ name, abbreviation }));
  }

  return [];
}

export function allStatesWithCountry(): Dict<{ name: string; countryCode: Code }> {
  const allStates: Dict<{ name: string; countryCode: Code }> = {};
  for (const code of COUNTRIES) {
    states(code).forEach(
      state => (allStates[state.abbreviation] = { name: state.name, countryCode: code })
    );
  }
  return allStates;
}

export function stateFullName(abbreviation: string): string | undefined {
  for (const code of COUNTRIES) {
    const name = states(code).find(item => item.abbreviation === abbreviation.toUpperCase())?.name;
    if (name) {
      return name;
    }
  }
  return undefined;
}

const State = {
  states,
};

export default State;
