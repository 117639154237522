import DS from 'ember-data';

import { Label } from './product';
const { Model } = DS;

export default class UpsellItemModel extends Model {
  @DS.attr('string')
  name!: string;
  @DS.attr('number')
  cost!: number;
  @DS.attr('string')
  shortDescription!: string;
  @DS.attr('number')
  minimumQuantity?: number;
  @DS.attr('number')
  maximumQuantity?: number;
  @DS.attr('number')
  quantityIncrement!: number;
  @DS.attr('string')
  calorieLabel?: string;
  @DS.attr('number')
  baseCalories?: number;
  @DS.attr('string')
  image?: string;
  @DS.attr('number', { defaultValue: 0 })
  quantity!: number;
  @DS.attr('array')
  labels?: Label[];
  @DS.attr('string')
  recipientName?: string;
}
