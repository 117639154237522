import Component from '@glimmer/component';

import { HandoffMessageParts } from 'mobile-web/lib/order';

import style from './index.m.scss';

interface Args {
  // Required arguments
  handoffLabel: string;
  handoffInstructions: string;
  messageParts: HandoffMessageParts;
  substitutions: AnyObject;
  isPastOrder: boolean;
  isPendingManualFire: boolean;
  canSupportArrival: boolean;
  isDriveThru: boolean;

  // Optional arguments
  isAdvance?: boolean;
}

interface Signature {
  Args: Args;
}

export default class HandoffMessage extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::HandoffMessage': typeof HandoffMessage;
  }
}
