import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import cloneDeep from 'lodash.clonedeep';

import BasketChoiceModel from 'mobile-web/models/basket-choice';
import BasketProductModel from 'mobile-web/models/basket-product';
import ChoiceModel from 'mobile-web/models/choice';
import FavoriteChoiceModel from 'mobile-web/models/favorite-choice';
import FavoriteProductModel from 'mobile-web/models/favorite-product';
import OptionGroupModel, { DisplayGroup } from 'mobile-web/models/option-group';

export type CustomizedProductModel = BasketProductModel | FavoriteProductModel;
export type CustomizedChoiceModel = BasketChoiceModel | FavoriteChoiceModel;

async function createDefaultBasketChoicesHelper(
  store: DS.Store,
  basketProduct: BasketProductModel,
  optionGroups: DS.PromiseManyArray<OptionGroupModel>,
  createdChoices: EmberDataId[]
) {
  const ogs = await optionGroups;
  for (let i = 0; i < ogs.length; i++) {
    const og = ogs.objectAt(i)!;
    for (let j = 0; j < og.choices.length; j++) {
      const c = og.choices.objectAt(j)!;
      if (c.isDefault && !createdChoices.includes(c.id)) {
        createBasketChoice(store, basketProduct, c);
        createdChoices.push(c.id);
      }
      if (createdChoices.includes(c.id)) {
        await createDefaultBasketChoicesHelper(
          store,
          basketProduct,
          c.optionGroups,
          createdChoices
        );
      }
    }
  }
}

/**
 * This creates basket-choice model objects for each default choice
 * in the supplied option groups. This needs to happen when a basket
 * product is first created, in order to have any default choices
 * selected. It also needs to happen when you change a selection,
 * to have any default choices underneath the new selection be
 * selected.
 * @param store Ember Data store
 * @param basketProduct The basket product to tie the new basket choices to
 * @param optionGroups Any default choices in these option groups will have their
 * entire tree of nested choices searched. Any of those choices which are default
 * will have basket choices created for them (if one doesn't already exist).
 */
export async function createDefaultBasketChoices(
  store: DS.Store,
  basketProduct: BasketProductModel,
  optionGroups: DS.PromiseManyArray<OptionGroupModel>
): Promise<void> {
  const createdChoices = store.peekAll('basket-choice').reduce((choiceIds, bc) => {
    const choiceId = bc.belongsTo('choice').id();
    if (bc.basketProduct?.id === basketProduct.id && !choiceIds.includes(choiceId)) {
      return [...choiceIds, choiceId];
    }
    return choiceIds;
  }, [] as EmberDataId[]);
  await createDefaultBasketChoicesHelper(store, basketProduct, optionGroups, createdChoices);
}

export function createBasketChoice(
  store: DS.Store,
  basketProduct: BasketProductModel,
  c: ChoiceModel
): BasketChoiceModel {
  return store.createRecord('basket-choice', {
    name: c.name,
    quantity: isParentNew(basketProduct, c) ? c.defaultQuantity : 0,
    customFieldValues: cloneDeep(c.customFieldValues),
    basketProduct,
    choice: c,
  });
}

export function isParentNew(basketProduct: BasketProductModel, c: ChoiceModel): boolean {
  const parentBasketChoice = basketProduct.basketChoices.find(
    bc => bc.belongsTo('choice').id() === c.optionGroup?.parentChoice?.id
  );
  const parent = parentBasketChoice ?? basketProduct;
  return !!parent.isNew;
}

export function getOrCreateBasketChoice(
  store: DS.Store,
  basketProduct: BasketProductModel,
  c: ChoiceModel
): { choice: BasketChoiceModel; isNew: boolean } {
  const existingChoice = store
    .peekAll('basket-choice')
    .find(bc => bc.belongsTo('choice').id() === c.id && bc.basketProduct?.id === basketProduct.id);

  if (existingChoice) {
    return { choice: existingChoice, isNew: false };
  }
  const newChoice = createBasketChoice(store, basketProduct, c);
  return { choice: newChoice, isNew: true };
}

export function firstInvalidOptionGroup(
  groups: DS.PromiseManyArray<OptionGroupModel> | undefined
): OptionGroupModel | undefined {
  if (!groups?.isFulfilled) {
    return undefined;
  }
  const invalidGroups = (groups.content as OptionGroupModel[]).filter(g => !g.canSubmit);
  if (invalidGroups.length) {
    const first = invalidGroups[0];
    const selectedChoices = first.selectedBasketChoices;
    for (let i = 0; i < selectedChoices.length; i++) {
      const choice = selectedChoices.objectAt(i)!.choice.content!;
      const invalid = firstInvalidOptionGroup(choice.optionGroups);
      if (invalid) {
        return invalid;
      }
    }
    return first;
  }
  return undefined;
}

/**
 * This function accomplishes three purposes:
 * 1. It loads option groups one level below where the user is currently at.
 *    This allows for no lag between when they select a choice with children
 *    and when we display those children.
 * 2. It allows formation groups with default choices to render their complete
 *    label without having to open the formation group.
 * 3. It correctly creates basket-choice objects for all defaulted choices under
 *    a formation group. This is needed because there was an assumption that
 *    defaulted choices would always render to the user, but formation groups
 *    break that assumption and we need to make sure we still have all the data
 *    we need.
 */
export async function loadNestedMenuItems(
  store: DS.Store,
  basketProduct: BasketProductModel,
  choice: ChoiceModel,
  forceRecurse: boolean
): Promise<void> {
  const groups = await choice.optionGroups;
  groups.forEach(og => {
    og.choices.forEach(ogc => {
      const { choice: basketChoice } = getOrCreateBasketChoice(store, basketProduct, ogc);
      if (basketChoice.isSelected && (forceRecurse || !isEmpty(og.formationGroup))) {
        loadNestedMenuItems(store, basketProduct, basketChoice.choice.content!, true);
      }
    });
  });
}

export function groupChoices(optionGroup: OptionGroupModel): Array<ChoiceModel | DisplayGroup> {
  const displayGroups = optionGroup.displayGroups;
  return optionGroup.choices
    .sortBy('isDisabled')
    .reduce<Array<ChoiceModel | DisplayGroup>>((results, c) => {
      if (c.displayGroup) {
        const match = displayGroups.find(g => g.name === c.displayGroup.optionName);
        if (match && !results.find(r => r.name === match.name)) {
          results.push(match);
        }
      } else {
        results.push(c);
      }
      return results;
    }, []);
}

export type GroupedOptionGroup = {
  id: string;
  optionGroups: OptionGroupModel[];
  isFormationGroup: boolean;
};
export function groupOptionGroups(optionGroups: OptionGroupModel[]): GroupedOptionGroup[] {
  return optionGroups.reduce<GroupedOptionGroup[]>((groups, ogs) => {
    const lastGroup = groups[groups.length - 1];
    const isFormationGroup = !isEmpty(ogs.formationGroup);
    if (!lastGroup || lastGroup.isFormationGroup !== isFormationGroup) {
      groups.push({
        id: `${ogs.id}`,
        optionGroups: [ogs].filter(og => !og.isDisabledFormationGroup),
        isFormationGroup,
      });
      return groups;
    }
    lastGroup.optionGroups.push(ogs);
    lastGroup.id += `-${ogs.id}`;
    return groups;
  }, []);
}
