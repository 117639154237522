import { isNone } from '@ember/utils';
import DS from 'ember-data';

import isSome from 'mobile-web/lib/utilities/is-some';

export default class OrderSerializer extends DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin) {
  attrs = {
    deliveryAddress: {
      embedded: 'always',
    },
  };

  normalizeResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: { order?: { currency?: string }; vendor?: { currency?: string } },
    id: EmberDataId,
    requestType: string
  ): UnknownObject {
    if (
      isSome(payload?.order) &&
      isNone(payload.order.currency) &&
      isSome(payload?.vendor?.currency)
    ) {
      payload.order.currency = payload.vendor!.currency;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
