import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import isSome from 'mobile-web/lib/utilities/is-some';
import BasketService from 'mobile-web/services/basket';

export interface LoyaltyRewardRule {
  qualifyingProducts: number[];
  qualifyingCategories: number[];
  isDisabled: boolean;
  description: string;
  imageUrl?: string;
  validMinutes?: number;
  finePrint: string;
  availableOnline: boolean;
  availableOffline: boolean;
}

export default class QualifyingLoyaltyRewardModel extends DS.Model {
  @service basket!: BasketService;
  @service store!: DS.Store;

  @DS.attr('string')
  label!: string;
  @DS.attr('boolean')
  isApplied!: boolean;
  @DS.attr('boolean')
  isAvailable!: boolean;
  @DS.attr()
  externalReference!: EmberDataId;
  @DS.attr('number')
  membershipId!: number;
  @DS.attr()
  loyaltyRewardRule!: LoyaltyRewardRule;
  @DS.attr('date')
  expiresOn?: Date;

  @computed('externalReference', 'basket.basket.reward.externalReference')
  get isActiveReward(): boolean {
    return (
      isSome(this.basket.basket) &&
      isSome(this.basket.basket.reward) &&
      this.externalReference === this.basket.basket.reward.externalReference
    );
  }

  @computed('membershipId')
  get schemeName(): string {
    const membership = this.store
      .peekAll('loyalty-membership')
      .find(m => m.membershipId === this.membershipId);
    if (isSome(membership)) {
      return membership.schemeName;
    }

    return '';
  }
}
