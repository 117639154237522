import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { CustomIcon } from 'mobile-web/lib/custom-icon';
import { Disclaimer } from 'mobile-web/models/vendor';
import ContentService from 'mobile-web/services/content';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  /**
   * This type should technically be `Disclaimer[]`. However, if we do that,
   * Glint complains about `{{#if disclaimer.icon}}`. This is because of the way
   * we wrote the `Disclaimer` union type. Not every member of the union has the
   * property `icon` defined, so Glint errors.
   *
   * Hopefully, a future version of Glint will understand that this is basically
   * the same thing as `if ('icon' in disclaimer)`, which we do all the time in
   * TS files.
   */
  disclaimers?: Array<Disclaimer & { icon?: CustomIcon }>;
}

interface Signature {
  Element: HTMLElement;

  Args: Args;
}

export default class Disclaimers extends Component<Signature> {
  // Service injections
  @service content!: ContentService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Disclaimers: typeof Disclaimers;
  }
}
