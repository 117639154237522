export default {
  "root": "_root_1k4b42",
  "infoContainer": "_infoContainer_1k4b42",
  "img": "_img_1k4b42",
  "info": "_info_1k4b42",
  "header": "_header_1k4b42",
  "text": "_text_1k4b42",
  "nonProductText": "_nonProductText_1k4b42",
  "productText": "_productText_1k4b42",
  "optionGroups": "_optionGroups_1k4b42",
  "menuToggle": "_menuToggle_1k4b42",
  "category": "_category_1k4b42",
  "categoryImg": "_categoryImg_1k4b42",
  "categoryTitle": "_categoryTitle_1k4b42",
  "categoryDescription": "_categoryDescription_1k4b42",
  "viewAllGrid": "_viewAllGrid_1k4b42",
  "viewAllItem": "_viewAllItem_1k4b42",
  "menu": "_menu_1k4b42",
  "menuItem": "_menuItem_1k4b42"
};
