export enum Type {
  Loading,
  Loaded,
  Error,
}

export class Loading {
  readonly type: Type.Loading = Type.Loading;
}

export const isLoading = (t: Data<unknown, unknown>): t is Loading => t.type === Type.Loading;

export class Loaded<T> {
  readonly type: Type.Loaded = Type.Loaded;
  readonly value: T;

  constructor(value: T) {
    this.value = value;
  }

  static withValue<V>(value: V): Loaded<V> {
    return new Loaded(value);
  }
}

export const isLoaded = <T = unknown>(t: Data<T, unknown>): t is Loaded<T> =>
  t.type === Type.Loaded;

export class Error<E> {
  readonly type: Type.Error = Type.Error;
  readonly value: E;

  constructor(value: E) {
    this.value = value;
  }

  static withValue<V>(value: V): Error<V> {
    return new Error(value);
  }
}

export const isError = <E = unknown>(t: Data<unknown, E>): t is Error<E> => t.type === Type.Error;

export type Data<T, E> = Loading | Loaded<T> | Error<E>;
export const Data = {
  Type,
  Loading,
  Loaded,
  Error,
  isLoading,
  isLoaded,
  isError,
};
export default Data;
