import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ensureSafeComponent } from '@embroider/util';
import { WithBoundArgs } from '@glint/template';

import Svg from 'mobile-web/components/svg';
import { classes } from 'mobile-web/lib/utilities/classes';
import { ValidationMessage } from 'mobile-web/lib/validation';
import { ContentString } from 'mobile-web/services/content';

import style from './index.m.scss';

export type CloseEditor = () => void;
export type OpenEditor = () => void;

interface Args {
  // Required arguments
  class?: string;
  /** The label of the wrapped form field. */
  label: string;
  /** The name of the wrapped form field. */
  name: string;
  /** The value to display in the form field. */
  value: string;

  // Optional arguments
  /** Whether or not to hide the label text. */
  hideLabel?: boolean;
  /** Extra classes for the label */
  labelClass?: string;
  /** The icon to display by the label. */
  icon?: WithBoundArgs<typeof Svg, 'icon'>;
  /** Use if you want to override default click behavior. */
  onClick?: (openEditor: OpenEditor) => void;
  /** Placeholder text. */
  placeholder?: ContentString;
  validationMessages?: ValidationMessage[];
  /**
   * Whether or not to make the label responsive, i.e. hidden in small viewports
   * but displayed in larger ones.
   */
  responsiveLabel?: boolean;
}

interface Signature {
  Element: HTMLButtonElement;

  Args: Args;

  Blocks: {
    default: [
      {
        closeEditor: CloseEditor;
      }
    ];
  };
}

export default class FormEditButton extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  /** Whether or not the editor is open. */
  @tracked isEditorOpen = false;

  // Getters and setters
  get icon() {
    const icon = this.args.icon;
    // Passing `icon` to `ensureSafeComponent` stopped working after an upgrade.
    // Glint and Embroider types probably just need a few releases to get in sync.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return icon ? (ensureSafeComponent(icon as any, this) as typeof icon) : undefined;
  }

  get placeholder(): ContentString {
    return this.args.placeholder ?? '';
  }

  get ariaLabel(): string {
    return `Edit ${this.args.label}, ${this.args.value}`;
  }

  get labelClassName(): string {
    return classes(style.label, this.args.labelClass);
  }

  get labelTextClass(): string {
    return classes(style.labelText, this.args.responsiveLabel ? style.responsive : '');
  }

  get invalid(): boolean {
    return (this.args.validationMessages?.length ?? 0) > 0;
  }

  get labelInputContainerClass(): string {
    return classes(this.style.labelInputContainer, {
      [this.style.error]: this.invalid,
    });
  }

  get tagClass(): string {
    return classes(this.style.container, this.args.class);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  handleClick() {
    const onClick = this.args.onClick;
    if (onClick) {
      onClick(this.openEditor);
    } else {
      this.openEditor();
    }
  }

  @action
  openEditor() {
    this.isEditorOpen = true;
  }

  @action
  closeEditor() {
    this.isEditorOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FormEditButton: typeof FormEditButton;
  }
}
