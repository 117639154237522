import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { MAX_EMAIL_LENGTH } from 'mobile-web/lib/customer';
import { isOk } from 'mobile-web/lib/result';
import { PASSWORD_MINLENGTH } from 'mobile-web/lib/security';
import Validation, { ValidationResult, ValidationConfig } from 'mobile-web/lib/validation';
import ErrorService from 'mobile-web/services/error';
import SessionService from 'mobile-web/services/session';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

class Model {
  @tracked currentPassword = '';
  @tracked newPassword = '';
  @tracked confirmPassword = '';
}

type Args = UnknownObject;

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class ChangePasswordRoute extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;
  @service error!: ErrorService;
  @service session!: SessionService;
  @service router!: RouterService;

  // Untracked properties
  maxEmailLength = MAX_EMAIL_LENGTH;
  style = style;

  // Tracked properties
  @tracked model: Model;
  @tracked validationResult?: ValidationResult;

  // Getters and setters
  get validationConfig(): ValidationConfig<Model> {
    return {
      bindings: [
        {
          targetProp: 'newPassword',
          ruleName: 'minLength',
          message: this.intl.t('mwc.password.minLength', { length: PASSWORD_MINLENGTH }),
        },
        {
          targetProp: 'confirmPassword',
          ruleName: 'match',
          match: 'newPassword',
          message: this.intl.t('mwc.changePassword.errors.passwordsMatch'),
        },
      ],
    };
  }

  get isSubmitDisabled(): boolean {
    return (
      isEmpty(this.model.currentPassword) ||
      isEmpty(this.model.newPassword) ||
      isEmpty(this.model.confirmPassword)
    );
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.model = new Model();
  }

  // Other methods

  // Tasks
  changePasswordTask = taskFor(this._changePasswordTask);
  @task *_changePasswordTask(): TaskGenerator<void> {
    this.validationResult = Validation.validate(this.model, this.validationConfig);
    if (isOk(this.validationResult)) {
      try {
        yield this.store.collectionAction('user', 'changePassword', {
          currentPassword: this.model.currentPassword,
          newPassword: this.model.newPassword,
          confirmPassword: this.model.confirmPassword,
        });
        this.userFeedback.add({
          type: Type.Positive,
          title: this.intl.t('mwc.changePassword.successTitle'),
          message: this.intl.t('mwc.changePassword.successMessage'),
        });
        yield this.session.logout();
        this.router.transitionTo('login');
      } catch (e) {
        this.error.reportError(e);
      }
    }
  }

  // Actions and helpers
  @action
  onCancel() {
    this.router.transitionTo('my-account');
  }

  @action
  submit(e: Event) {
    e.preventDefault();

    this.changePasswordTask.perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::ChangePasswordRoute': typeof ChangePasswordRoute;
  }
}
