import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import IntlService from 'ember-intl/services/intl';

import { MINUTES } from 'mobile-web/lib/time/durations';
import AnalyticsService from 'mobile-web/services/analytics';
import GroupOrderService from 'mobile-web/services/group-order';

import style from './index.m.scss';

interface Args {
  // Required arguments
  open: boolean;
  onClose(): void;
  onConfirm: () => void;
  // Optional arguments
  participantName?: string;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class GroupOrderStatusModal extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get inProgressParticipants(): { name: string; time: string }[] {
    return this.groupOrder
      .groupOrder!.participants!.filter(x => x.status === 'InProgress')
      .map(x => {
        const name = x.participantName;
        const joined = dayjs(x.timeJoined).utc();
        const elapsed = Math.round(dayjs().utc().diff(joined) / MINUTES);
        const time = this.intl.t('mwc.groupOrder.hostConfirmOrder.timeAgo', {
          hours: elapsed < 0 ? 0 : ~~(elapsed / 60),
          minutes: elapsed < 0 ? 0 : elapsed % 60,
        });
        return { name, time };
      });
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GroupOrder::StatusModal': typeof GroupOrderStatusModal;
  }
}
