import cloneDeep from 'lodash.clonedeep';

export enum Code {
  US = 'us',
  CA = 'ca',
}
/**
 * The shape of the payload delivered in the bootstrap data.
 */
export type SettingsPayload = {
  [key in Code]?: {
    name: string;
    optIn: boolean;
    showOptInDetails: boolean;
  };
};

// Transformed for internal use
export type Country = { code: Code; name: string };

export const COUNTRIES: Code[] = [Code.US, Code.CA];

/**
 * The currently selected and available-to-select country options.
 */
export type Config = {
  current: Code;
  supported: Country[];
};

const DEFAULT_OPT_IN = false;
const DEFAULT_SHOW_OPT_IN = true;

const DEFAULT_SETTINGS: SettingsPayload = {
  us: {
    name: 'United States',
    optIn: DEFAULT_OPT_IN,
    showOptInDetails: DEFAULT_SHOW_OPT_IN,
  },
};

export const defaultSettings = (): SettingsPayload => cloneDeep(DEFAULT_SETTINGS);

// Using type merging here; both this object and the type will be `Country`.
export const Country = {
  Code,
  defaultSettings,
};

export default Country;
