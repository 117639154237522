import BasketModel from 'mobile-web/models/basket';

export type SplitCheckStatus = {
  // Common properties
  basketGuid: string;
  firstName?: string;
  isOrderPlaced: boolean;
  subTotal: number;
  salesTax: number;
  total: number;

  // These properties are either present or missing depending on the kind of split
  splitAmount?: number;

  splitItems?: string[];

  payFor?: number;
  splitBetween?: number;
};

export type SplitCheckOrderStatus = {
  orderId: string;
  firstName?: string;
  userGuid: string;
  subTotal: number;
  total: number;
  splitAmount?: number;
  splitItems?: string[];
  payFor?: number;
  splitBetween?: number;
};

// TODO: Eventually, this needs to be the combination of vendor ID + date + ticket number
export function checkId(basket: BasketModel): string | undefined {
  return basket.onPremiseDetails?.checkId;
}
