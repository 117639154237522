/**
 * Based on https://github.com/yapplabs/ember-modal-dialog/blob/v4.0.0-alpha.1/addon/instance-initializers/add-modals-container.js
 */

export const MODAL_DIALOG_ROOT_ID = 'modal-overlays';

export function appendContainerElement(rootElementOrId: Element | string, id: string) {
  if (!document) {
    return;
  }

  const rootEl =
    typeof rootElementOrId === 'string'
      ? document.querySelector(rootElementOrId)!
      : rootElementOrId;

  if (rootEl.querySelector('#' + id)) {
    return;
  }

  const modalContainerEl = document.createElement('div');
  modalContainerEl.id = id;
  rootEl.appendChild(modalContainerEl);
}

export function initialize(instance: AnyObject) {
  appendContainerElement(instance.rootElement, MODAL_DIALOG_ROOT_ID);
}

export default {
  initialize,
};
