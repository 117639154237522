import Controller from '@ember/controller';

import ArrivalRoute from 'mobile-web/routes/arrival';

export default class ArrivalController extends Controller {
  declare model: ModelForRoute<ArrivalRoute>;

  hash?: string;
  queryParams = ['hash', 'utm_medium'];
}
