import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import groupOrderRoute from 'mobile-web/decorators/group-order-route';
import GroupOrderService from 'mobile-web/services/group-order';

@groupOrderRoute
export default class GroupOrderParticipantConfirmationRoute extends Route {
  @service groupOrder!: GroupOrderService;
  @service router!: RouterService;

  beforeModel() {
    if (!this.groupOrder.hasGroupOrder || !this.groupOrder.isParticipantMode) {
      this.router.replaceWith('index');
    }
  }
}
