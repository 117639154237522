import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

const htmlSafeHelper = helper((params: string[]) => htmlSafe(params[0]));

export default htmlSafeHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'html-safe': typeof htmlSafeHelper;
  }
}
