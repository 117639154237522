import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';

import { safeNext } from 'mobile-web/lib/runloop';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;
}

export default class MenuFilter extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service orderCriteria!: OrderCriteriaService;
  @service storage!: StorageService;
  @service analytics!: AnalyticsService;
  @service onPremise!: OnPremiseService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get hideMenuToggle() {
    return this.onPremise.isEnabled || this.channel.settings?.showFullMenu;
  }

  // Lifecycle methods
  constructor(owner: unknown, args: UnknownObject) {
    super(owner, args);

    /* this initializes `showFullMenu` for the client, even if the menu filter toggle is not visible */
    safeNext(this, () => {
      if (this.onPremise.isEnabled) {
        this.storage.set('showFullMenu', false);
      } else if (this.channel.settings?.showFullMenu) {
        this.storage.set('showFullMenu', true);
      }

      if (isNone(this.storage.showFullMenu)) {
        this.storage.set('showFullMenu', !!this.orderCriteria.criteria.isDefault);
      }
    });
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onChange(showFullMenu: boolean) {
    this.analytics.trackEvent(AnalyticsEvents.MenuTypeFilter, () => ({
      [AnalyticsProperties.FilterName]: showFullMenu ? 'full' : 'available',
    }));

    this.storage.set('showFullMenu', showFullMenu);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MenuFilter: typeof MenuFilter;
  }
}
