import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class ResetPasswordRoute extends Route {
  @service router!: RouterService;

  beforeModel() {
    this.router.replaceWith('reset-password');
  }
}
