import { not } from '@ember/object/computed';
import DS from 'ember-data';

import Vendor from 'mobile-web/models/vendor';

export default class LoyaltyScheme extends DS.Model {
  @DS.attr('string')
  loyaltyProvider!: string;
  @DS.attr('string')
  schemeName!: string;
  @DS.attr('string')
  identifierFieldName!: string;
  @DS.attr('string')
  loginProviderSlug?: string;
  @DS.attr('boolean')
  compCardsEnabled?: boolean;

  @DS.belongsTo('vendor', { async: false })
  vendor!: Vendor;

  @not('loginProviderSlug')
  canLink!: boolean;
}
