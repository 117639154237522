import { host } from 'mobile-web/lib/hybrid-util';

import ApplicationAdapter from './application';

export default class ShowHouseAccountLanguageAdapter extends ApplicationAdapter {

  urlForFindRecord(passwordResetId: string): string {
    return `${host()}/${this.namespace}/users/resetpassword/${passwordResetId}/showhouseaccountlanguage`;
  }
}
