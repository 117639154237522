import Controller from '@ember/controller';

import CheckInRoute from 'mobile-web/routes/checkin';

export default class CheckinController extends Controller {
  declare model: ModelForRoute<CheckInRoute>;

  hash?: unknown;
  queryParams = ['hash'];
}
