import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import fade from 'ember-animated/transitions/fade';

import { parseMenuImageURLDimensions } from 'mobile-web/lib/image';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import BasketProductModel from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';

import style from './index.m.scss';

export const MAX_DESC_LENGTH = 70;

interface Args {
  // Required arguments
  product: ProductModel;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class ProductSearchResult extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;
  @service error!: ErrorService;
  @service globalEvents!: GlobalEventsService;

  // Untracked properties
  style = style;
  transition = fade;
  ids = guids(this, 'heading');

  // Tracked properties
  @tracked basketProduct?: BasketProductModel;
  @tracked showSuccess = false;

  // Getters and setters
  get productImage() {
    const src = this.args.product.menuImage;
    const singleUse = this.args.product.isSingleUse;
    if (!src || singleUse) {
      return undefined;
    }

    const { width, height } = parseMenuImageURLDimensions(src, this.error);
    return {
      src,
      alt: this.args.product.name,
      width,
      height,
    };
  }

  get linkToModels(): string[] {
    return [this.args.product.vendor.slug, this.args.product.id];
  }

  get showPrice(): boolean {
    return this.args.product.baseCost > 0 && (this.channel.settings?.showProductMenuPrices ?? true);
  }

  get isDisabled(): boolean {
    return this.args.product.isDisabled;
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    assert('`product` is required', isSome(this.args.product));
  }

  // Other methods

  // Tasks

  // Actions and helpers

  @action
  goToProduct() {
    const product = this.args.product;

    if (this.isDisabled) {
      return;
    }

    this.globalEvents.trigger(
      GlobalEventName.ClickProductLink,
      {
        ...product.serializeForGlobalData(),
        hasImages: !!this.productImage,
        hasPrice: this.showPrice,
      },
      'vendor-menu' as ProductClickFrom
    );

    this.analytics.trackEvent(AnalyticsEvents.ViewProductCustomization, () => ({
      [AnalyticsProperties.ProductName]: product.name,
      [AnalyticsProperties.ProductCategory]: product.category?.name,
      [AnalyticsProperties.ProductBasePrice]: product.baseCost,
      [AnalyticsProperties.HasVisibleCalories]: !isEmpty(product.calorieLabel),
      [AnalyticsProperties.VisibleLabels]: product.labels.map(l => l.name),
      [AnalyticsProperties.HasProductImages]: !!this.productImage,
      [AnalyticsProperties.HasCategoryImages]: !isEmpty(product.category?.bannerImage),
      [AnalyticsProperties.HasVisiblePrice]: this.showPrice,
      [AnalyticsProperties.ProductAvailableOptionGroupCount]: product.hasMany('optionGroups').ids()
        .length,
      [AnalyticsProperties.Source]: 'Product Card',
    }));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProductSearchResult: typeof ProductSearchResult;
  }
}
