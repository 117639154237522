/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */

import { isNone } from '@ember/utils';

// These functions were referenced from https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
// Lodash also supports treating objects as arrays. For brevity, that functionality has been omitted.

function getTag(value: any): string {
  if (isNone(value)) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return Object.prototype.toString.call(value);
}

export type PartialDeep<T> = { [P in keyof T]?: PartialDeep<T[P]> };

export const compact = <T>(array: T[]) => array.filter(Boolean);

export const defaultTo = <T>(value: T, defaultValue: T) =>
  isNone(value) || value !== value ? defaultValue : value; // eslint-disable-line no-self-compare

export const isArray = (value: any): value is any[] => Array.isArray(value);

export const isBoolean = (x: any): x is boolean =>
  x === true || x === false || (isObjectLike(x) && getTag(x) === '[object Boolean]');

export const identity = <T>(input: T) => input;

export const isFunction = (x: any): x is Function => typeof x === 'function';

export const isObject = (x: any): x is object =>
  (!isNone(x) && typeof x === 'object') || typeof x === 'function';

export const isObjectLike = (value: any): value is object =>
  typeof value === 'object' && !isNone(value);

export const isNumber = (x: any): x is number =>
  typeof x === 'number' || (isObjectLike(x) && getTag(x) === '[object Number]');

export const isString = (value: any): value is string =>
  typeof value === 'string' ||
  (!isArray(value) && isObjectLike(value) && getTag(value) === '[object String]');

export const noop = (..._args: any[]): void => undefined;

/** range(5,10) => [5, 6, 7, 8, 9]. Produces empty set if from is larger than to, where lodash will generate a set that decrements, eg [10, 9, 8, 7, 6] */
export const range = (from: number, to: number) =>
  Array.from({ length: to - from }, (_, i) => i + from);

export function sortBy<T extends { [key: string]: any }>(input: T[], sortKey: string): T[] {
  const sortByFunc = (key: string) => (a: { [key: string]: any }, b: { [key: string]: any }) =>
    a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;

  return [...input].sort(sortByFunc(sortKey));
}

export const stubTrue = () => true;

export const sum = (input: number[]) => input.reduce((a, b) => a + b, 0);

export const sumBy = <T>(input: T[], iteratee: string | ((x: T) => number)): number =>
  input.reduce((total, next) => {
    if (typeof iteratee === 'string') {
      // @ts-ignore
      const nextValue = next[iteratee];
      return total + nextValue;
    }
    return total + iteratee(next);
  }, 0);

export const toString = (x: any | undefined) => (isNone(x) ? '' : x.toString());

export const unionBy = <T extends object, K extends keyof T>(a: T[], b: T[], key: K): T[] => {
  const set = new Set<T[K]>();
  return [...a, ...b].filter(x => {
    if (set.has(x[key])) {
      return false;
    }
    set.add(x[key]);
    return true;
  });
};

export const uniqWith = <T>(array: T[], comparator: (a: T, b: T) => boolean): T[] => {
  const deduped = [] as T[];
  array.forEach(x => {
    if (!deduped.find(d => comparator(x, d))) {
      deduped.push(x);
    }
  });
  return deduped;
};

export const without = <T>(input: T[], ...values: T[]) => input.filter(x => !values.includes(x));
