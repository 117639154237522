/**
 * Raygun stores unsent events in `localStorage`.
 * This can build up over time and cause issues for Serve.
 */
function clearRaygunStorage(): void {
  try {
    Object.keys(localStorage).forEach(key => {
      if (key.includes('raygunjs+')) {
        localStorage.removeItem(key);
      }
    });
  } catch (error) {
    // noop
  }
}

export default clearRaygunStorage;
