import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { groupChoices, groupOptionGroups } from 'mobile-web/lib/menu';
import { classes } from 'mobile-web/lib/utilities/classes';
import BasketChoiceModel from 'mobile-web/models/basket-choice';
import BasketProductModel from 'mobile-web/models/basket-product';
import ChoiceModel from 'mobile-web/models/choice';
import OptionGroupModel, { DisplayGroup } from 'mobile-web/models/option-group';

import style from './index.m.scss';

interface Args {
  // Required arguments
  basketProduct: BasketProductModel;
  optionGroup: OptionGroupModel;

  // Optional arguments
  inModal?: boolean;
  nested?: boolean;
  openFormationGroup?: boolean;
  onClose?(): void;
  onOpen?(): void;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class ProductCustomizationOptionGroup extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked highlighted = false;

  // Getters and setters
  get nested(): boolean {
    return this.args.nested ?? false;
  }

  get groupedChoices() {
    return groupChoices(this.args.optionGroup);
  }

  get groupedOptionGroups() {
    return groupOptionGroups(this.args.optionGroup.selectedOptionGroups);
  }

  get rootClass() {
    return classes(this.style.root, {
      [this.style.highlight]: this.highlighted,
      [this.style.isNested]: this.nested,
      [this.style.isInModal]: this.args.inModal,
    });
  }

  /**
   * The label for the toggle button. Elements with `role=status` need all their
   * content as direct child text nodes or label attributes in order for some
   * screen readers to notify users that there was a change of status.
   *
   * This is the text-equivalent of the contents of the toggle button.
   */
  get toggleButtonLabel() {
    const { description, helpText, isRequired, isCompleted } = this.args.optionGroup;

    const labels = [description];

    if (helpText) {
      labels.push(helpText);
    }

    if (isRequired) {
      labels.push(
        this.intl.t(`mwc.productCustomization.${isCompleted ? 'completedText' : 'requiredText'}`)
      );
    }

    return labels.join(' ');
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  allowFocus(): boolean {
    return !this.args.optionGroup.canSubmit && !this.args.openFormationGroup;
  }

  @action
  toggle(basketChoice: BasketChoiceModel) {
    const optionGroup = this.args.optionGroup;

    if (basketChoice.quantity === 0) {
      if (optionGroup.canSelectNewChoices) {
        if (!optionGroup.multiSelect) {
          optionGroup.selectedBasketChoices.forEach(bc => {
            bc.quantity = 0;
          });
        }
        basketChoice.quantity = basketChoice.choice.get('minQuantity') ?? 1;
      }
    } else {
      basketChoice.quantity = 0;
    }

    optionGroup.splitChoiceQuantities();

    this.args.optionGroup.quantityChanged();
  }

  asChoiceModel = (groupedChoice: ChoiceModel | DisplayGroup): ChoiceModel | undefined =>
    'choices' in groupedChoice ? undefined : groupedChoice;

  asDisplayGroup = (groupedChoice: ChoiceModel | DisplayGroup): DisplayGroup | undefined =>
    'choices' in groupedChoice ? groupedChoice : undefined;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProductCustomization::OptionGroup': typeof ProductCustomizationOptionGroup;
  }
}
