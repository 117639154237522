/**
 * Create a new function that will be called automatically after a
 * certain amount of time unless it is invoked before then.
 * @param cb
 * @param delay
 */
export function createSelfInvokingCallback(
  cb: (..._: unknown[]) => void,
  delay = 1000
): () => void {
  let timeout: NodeJS.Timeout; // eslint-disable-line prefer-const
  const callback = (...args: unknown[]) => {
    clearTimeout(timeout);
    cb(...args);
  };
  timeout = setTimeout(callback, delay);
  return callback;
}
