import ContentPlaceholdersBase from '../base';

interface Args {
  lines?: number;
}

export default class ContentPlaceholdersText extends ContentPlaceholdersBase<Args> {
  get lines() {
    return this.args.lines ?? 4;
  }

  get linesArray() {
    return Array.from(Array(this.args.lines));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'content-placeholders/text': typeof ContentPlaceholdersText;
  }
}
