import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { MediaService } from 'ember-responsive';

import style from './index.m.scss';

export type Size = 'small' | 'large';

export type Variant = 'minor' | 'major';

export type IconPosition = 'left' | 'right';

export type HeaderButtonMode = 'link' | 'button' | 'content';

export type LabelArg = {
  text: string;
  size?: Size;
};

export type IconArg = {
  svg: string;
  ariaLabel?: string;
  iconClass?: string;
  position: IconPosition;
};

export type Args = {
  // Required arguments
  icon: IconArg;
  label: LabelArg;

  // Optional arguments
  onClick?: Action;
  variant?: Variant;
  showLabel?: boolean;
  testSelector?: string;
  mode?: HeaderButtonMode;
  route?: string;
};

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class HeaderButton extends Component<Signature> {
  // Service injections
  @service media!: MediaService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get mode(): HeaderButtonMode {
    return this.args.mode ?? 'button';
  }

  get route(): string {
    return this.args.route ?? '';
  }

  get testSelector(): string {
    return this.args.testSelector ?? 'root';
  }

  get variant(): Variant {
    return this.args.variant ?? 'minor';
  }

  get buttonStyle(): string {
    return this.style[this.variant];
  }

  get showLabel(): boolean {
    return (
      this.args.showLabel ??
      // Having both isTablet and isDesktop is redundant, since isTablet really
      // means isTabletAndUp, but the setBreakpoint test helper only lets you
      // set one or the other to true, not both. So for tests we have to check both.
      (this.media.isTablet || this.media.isDesktop)
    );
  }

  @action
  onClick(e: Event) {
    this.args.onClick?.(e);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HeaderButton: typeof HeaderButton;
    'header-button': typeof HeaderButton;
  }
}
