/* eslint-disable no-null/no-null -- nulls are required as part of the Storage interface */

/**
 * An in-memory implementation of the Storage API to use when `localStorage` or
 * `sessionStorage` are unavailable. This can happen for apps that are
 * misconfigured and disallow storage mechanisms.
 */
export class MemoryStorage implements Storage {
  private data = new Map<string, string>();

  /**
   * @deprecated This declaration is required to meet the `Storage` interface
   * contract, but is unsupported.
   *
   * Use `getItem`, `setItem`, and `removeItem` instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;

  get length(): number {
    return this.data.size;
  }

  clear(): void {
    this.data.clear();
  }

  getItem(key: string): string | null {
    return this.data.get(String(key)) ?? null;
  }

  key(index: number): string | null {
    const keys = [...this.data.keys()];
    return keys[index] ?? null;
  }

  removeItem(key: string): void {
    this.data.delete(String(key));
  }

  setItem(key: string, value: string): void {
    this.data.set(String(key), String(value));
  }
}
