import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import UrlTemplates from 'ember-data-url-templates';

import { host } from 'mobile-web/lib/hybrid-util';
import BasketService from 'mobile-web/services/basket';

import ApplicationAdapter from './application';

export default class BillingDetailsAdapter extends ApplicationAdapter.extend(UrlTemplates) {
  @service basket!: BasketService;

  urlTemplate = host() + '/{+namespace}/baskets/{basketGuid}/billingdetails';
  urlSegments = {
    basketGuid(this: BillingDetailsAdapter): string {
      if (isNone(this.basket.basket)) {
        throw new Error('Basket GUID for Billing Details Not Found');
      }

      return this.basket.basket.guid;
    },

    namespace(this: BillingDetailsAdapter): string {
      return this.namespace;
    },
  };
}
