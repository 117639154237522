type SplitArgs = {
  into: number;
  by?: number;
};

/**
  Find the nearest multiple of a given factor size to a given division result.

  For example: getting the nearest multiple of 2 for 50 divided by 3:

  ```ts
  nearestUpperMultiple(50, 3, 2); // 18
  ```

  And the same, but for a multiple of 5:

  ```ts
  nearestUpperMultiple(50, 3, 5); // 20
  ```

  @param number The number to find the nearest interval of some divisor for.
  @param divisor The divisor to split the number by.
  @param factor The size of the interval to step upwards by.
 */
const nearestUpperMultiple = (number: number, divisor: number, factor: number) =>
  Math.ceil(number / divisor / factor) * factor;

const splitBy1 = (total: number, splits: number) => {
  if (splits === 1) {
    return [total];
  }

  const baseValue = Math.floor(total / splits);
  if (total % splits === 0) {
    return Array(splits).fill(baseValue);
  }

  const first = baseValue + 1;
  const rest = splitNicely(total - first, { into: splits - 1 });
  return rest ? [first, ...rest] : rest;
};

/**
  Take an integer and split it into as near equal values as possible for a given
  number of components, with an optional interval specifying the increment.

  For example, to split the value `10` into 4 components:

  ```ts
  split(10, { into: 4 }); // Just([3, 3, 2, 2])
  ```

  And the same with an interval of 2:

  ```ts
  split(10, { into: 4, by: 2 }); // Just([4, 2, 2, 2])
  ```

  And the same with a non-solveable combination of component number and interval
  (you cannot evenly divide 10 into even intervals of 4 no matter what):

  ```ts
  split(10, { into: 7, by: 4 }); // Nothing
  ```

  @param total The number to split into components which add up to it.
  @param config.into The number of components to split the total into.
  @param config.by The size of interval to split the total. Optional.
 */
export function splitNicely(
  total: number,
  { into: splits, by: size = undefined }: SplitArgs
): number[] | undefined {
  if (splits < 1 || splits > total) {
    return undefined;
  }

  // If there is no specified interval, it is always possible to divide the
  // total into a number of components by including one odd number unless the
  // number of components requested is greater than the value itself. By
  // contrast, if there is an interval specified, some scenarios are
  // mathematically impossible to resolve, e.g. dividing 50 into 9 parts by
  // intervals of 4.
  if (size !== undefined) {
    const interval = size;
    if (interval < 1) {
      return undefined;
    }

    if (splits === 1 && interval === total) {
      return [total];
    }

    if (interval === 1) {
      return splitBy1(total, splits);
    }

    const first = nearestUpperMultiple(total, splits, interval);
    if (splits === 1 && first === total) {
      return [total];
    }

    const rest = splitNicely(total - first, { into: splits - 1, by: interval });
    return rest ? [first, ...rest] : rest;
  }
  return splitBy1(total, splits);
}

export function truncateDecimals(rawValue: string, decimalPlaces: number): string {
  if (decimalPlaces < 0) {
    throw new RangeError('`decimalPlaces` must be a positive number');
  }

  let value = rawValue.replace(/[^\d.]/g, '');

  const firstDecimalPoint = value.indexOf('.');

  if (firstDecimalPoint < 0) {
    return value;
  }

  const secondDecimalPoint = value.indexOf('.', firstDecimalPoint + 1);
  if (secondDecimalPoint >= 0) {
    value = value.slice(0, secondDecimalPoint);
  }

  const trimIndex = firstDecimalPoint + decimalPlaces + 1;

  return value.slice(0, trimIndex);
}

export function roundDecimals(value: number, decimals = 2): number {
  return +value.toFixed(decimals);
}

export function roundUpToNext(interval: number, value: number): number {
  return Math.ceil(value / interval) * interval;
}

export function roundDownToPrevious(interval: number, value: number): number {
  return Math.floor(value / interval) * interval;
}
