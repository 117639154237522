import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import pick from 'lodash.pick';

import { phoneMask } from 'mobile-web/lib/contact-number-rules';
import { Customer } from 'mobile-web/lib/customer';
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
  MAX_EMAIL_LENGTH,
} from 'mobile-web/lib/customer';
import { isOk } from 'mobile-web/lib/result';
import Validation, { Binding, ValidationConfig, ValidationResult } from 'mobile-web/lib/validation';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import ContactService from 'mobile-web/services/contact';

import style from './index.m.scss';

class Model {
  @tracked firstName = '';
  @tracked lastName = '';
  @tracked emailAddress = '';
  @tracked contactNumber = '';
  @tracked optIn = false;
}

interface Args {
  // Required arguments

  // Optional arguments
  customer?: Customer;
  editContactDisabled?: boolean;
  errorMessage?: string;
  isFullyEditable?: boolean;
  isOloAuthLogin?: boolean;
  isOptInVisible?: boolean;
  modalTitleKey?: string;
  missingContactInfo?: boolean;
  onClose?: () => void;
  onSubmit?: (user: Model) => void;
}

interface Signature {
  Args: Args;
}

export default class EditContactInfoModal extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;

  // Untracked properties
  formId = 'edit-contact-info-form';
  phoneMask = phoneMask;
  maxFirstNameLength = MAX_FIRST_NAME_LENGTH;
  maxLastNameLength = MAX_LAST_NAME_LENGTH;
  maxEmailLength = MAX_EMAIL_LENGTH;
  style = style;

  // Tracked properties
  @tracked model: Model;
  @tracked validationResult?: ValidationResult;
  @service contact!: ContactService;

  // Getters and setters
  get isFullyEditable(): boolean {
    if (this.args.editContactDisabled) {
      return false;
    }
    return this.args.isFullyEditable ?? false;
  }

  get requirePhoneNumber(): boolean {
    return this.contact.phoneNumberRequired;
  }

  get isOptInVisible(): boolean {
    return (this.args.isOptInVisible ?? false) && !this.args.isOloAuthLogin;
  }

  get modalTitleKey(): string {
    return this.args.modalTitleKey ?? 'mwc.editContactInfo.defaultModalTitle';
  }

  get validationConfig(): ValidationConfig<Model> {
    const commonBindings: Binding<Model>[] = [
      {
        targetProp: 'firstName',
        ruleName: 'notBlank',
        message: 'First name must be set',
      },
      {
        targetProp: 'lastName',
        ruleName: 'notBlank',
        message: 'Last name must be set',
      },
      {
        targetProp: 'emailAddress',
        ruleName: 'email',
        message: 'Email address must be set and valid',
      },
    ];

    if (this.requirePhoneNumber) {
      commonBindings.push({
        targetProp: 'contactNumber',
        ruleName: 'phone',
        message: 'Phone number must be set and valid',
      });
    }

    return { bindings: commonBindings };
  }

  get isSubmitDisabled() {
    const model = this.model;
    return (
      !model.firstName ||
      !model.lastName ||
      !model.emailAddress ||
      (!model.contactNumber && this.requirePhoneNumber)
    );
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const model = new Model();
    if (this.args.customer) {
      Object.assign(
        model,
        pick(this.args.customer, 'firstName', 'lastName', 'emailAddress', 'contactNumber', 'optIn')
      );
    }
    this.model = model;
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  close() {
    this.args.onClose?.();
  }

  @action
  confirm(e?: Event) {
    e?.preventDefault();

    if (this.args.editContactDisabled) {
      this.close();
      return;
    }

    this.validationResult = Validation.validate(this.model, this.validationConfig);

    if (isOk(this.validationResult)) {
      this.args.onSubmit?.(
        pick(this.model, 'firstName', 'lastName', 'emailAddress', 'contactNumber', 'optIn')
      );
    }
  }

  @action
  onAccountSettingsClick() {
    this.analytics.trackEvent(AnalyticsEvents.OloAuthCheckoutAccountSettingsClicked, undefined, {
      bucket: 'olo-auth',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EditContactInfoModal: typeof EditContactInfoModal;
  }
}
