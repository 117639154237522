export default {
  "row": "_row_1s35mw",
  "filter": "_filter_1s35mw",
  "breadcrumbsRow": "_breadcrumbsRow_1s35mw",
  "imageContainer": "_imageContainer_1s35mw",
  "image": "_image_1s35mw",
  "data": "_data_1s35mw",
  "heading": "_heading_1s35mw",
  "description": "_description_1s35mw"
};
