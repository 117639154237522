import isSome from 'mobile-web/lib/utilities/is-some';

/**
 * Deserialize is a thin wrapper for JSON.parse that takes a serialized string
 * of JSON data and deserializes it to an object, array, string, number,
 * boolean, or undefined.
 *
 * Deserialize differs from JSON.parse in its handling of `null` and invalid
 * values. These values are transformed to `undefined` to avoid `null` types,
 * and reduce unnecessary error handling.
 *
 * @param data the string to deserialize.
 * @returns the representation of the data as an object, array, string, number,
 * boolean, or undefined.
 */
export const deserialize = (data: string | null | undefined): unknown => {
  try {
    return JSON.parse(data ?? 'null') ?? undefined;
  } catch {
    return undefined;
  }
};

/**
 * Serialize is a thin wrapper for JSON.stringify that takes any object or
 * primitive and serializes it to a string or undefined.
 *
 * Serialize differs from JSON.stringify in its handling of `null` and invalid
 * values. These values are transformed to `undefined` to avoid `null` types,
 * and reduce unnecessary error handling.
 *
 * @param data the object or primitive to serialize.
 * @returns the string representation of the data or undefined.
 */
export const serialize = (data: unknown): string | undefined => {
  try {
    return isSome(data) ? JSON.stringify(data) : undefined;
  } catch {
    return undefined;
  }
};
