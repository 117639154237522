import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ComponentLike } from '@glint/template';

import BasketProductModel from 'mobile-web/models/basket-product';
import Order from 'mobile-web/models/order';
import TicketModel from 'mobile-web/models/ticket';
import GroupOrderService, { groupOrderDisplayName } from 'mobile-web/services/group-order';
import SessionService from 'mobile-web/services/session';

import { PostCheckoutSubsectionLineItemSignature } from '../subsection/line-item';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  order?: Order;
  ticket?: TicketModel;
}

interface Signature {
  Args: Args;

  Blocks: {
    default: [
      {
        lineItem: ComponentLike<PostCheckoutSubsectionLineItemSignature>;
      }
    ];
  };
}

export default class PostCheckoutOrderContents extends Component<Signature> {
  // Service injections
  @service groupOrder!: GroupOrderService;
  @service session!: SessionService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked showItemCustomizations = false;
  @tracked showFullReceipt = false;

  // Getters and setters
  get basketProducts(): BasketProductModel[] {
    return this.args.order?.basketProducts?.toArray() ?? [];
  }

  get groupOrderParticipants(): string[] {
    const participantNames = Object.keys(this.groupBasketProducts);
    const currentName = this.groupHost;
    const participants: string[] = [currentName];

    participantNames.forEach(name => {
      if (name !== currentName) {
        participants.pushObject(name);
      }
    });

    return participants;
  }

  get groupHost(): string {
    return groupOrderDisplayName(this.session.currentUser);
  }

  get groupBasketProducts(): Record<string, BasketProductModel[]> {
    const partProdsMap: Record<string, BasketProductModel[]> = {};
    if (!this.args.order) {
      return partProdsMap;
    }

    const products = this.args.order.basketProducts;

    products.forEach(x => {
      const key = x.recipientName;
      const prodCollection = partProdsMap[key];
      if (!prodCollection) {
        partProdsMap[key] = [x];
      } else {
        prodCollection.push(x);
      }
    });

    return partProdsMap;
  }

  get canShowCustomizations() {
    return this.args.order && !this.args.order.groupOrderId && !this.args.ticket;
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  viewItemCustomizations() {
    this.showItemCustomizations = true;
  }

  @action
  hideItemCustomizations() {
    this.showItemCustomizations = false;
  }

  @action
  viewFullReceipt() {
    this.showFullReceipt = true;
  }

  @action
  hideFullReceipt() {
    this.showFullReceipt = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::OrderContents': typeof PostCheckoutOrderContents;
  }
}
