import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { Variant } from 'mobile-web/components/button';
import BusService from 'mobile-web/services/bus';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

export default class OnPremiseChangeTableButton extends Component {
  // Service injections
  @service bus!: BusService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  changeTable(): void {
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.changeTableButton.title', {
        tableNumber: this.onPremise.tablePosRef,
      }),
      content: this.intl.t('mwc.changeTableButton.content'),
      buttonLabel: this.intl.t('mwc.changeTableButton.confirmation'),
      hideCancelButton: true,
      confirmButtonVariant: Variant.Main,
      testSelector: 'change-table-confirmation-modal',
      buttonTestSelector: 'change-table-confirmation-button',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OnPremise::ChangeTableButton': typeof OnPremiseChangeTableButton;
  }
}
