import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

export default class DispatchStatusRoute extends Route {
  @service store!: DS.Store;

  async model(params: { order_id: number; status_id: number; hash: string }) {
    const dispatchStatus = this.store.findRecord('dispatch-status', params.status_id, {
      reload: true,
      adapterOptions: { orderId: params.order_id, hash: params.hash },
    });

    return this.store
      .findRecord('order', params.order_id, { adapterOptions: { hash: params.hash } })
      .then(order => RSVP.hash({ order, dispatchStatus }));
  }
}
