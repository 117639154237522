export default {
  "root": "_root_8413rr",
  "pop-in": "_pop-in_8413rr",
  "neutral": "_neutral_8413rr",
  "negative": "_negative_8413rr",
  "positive": "_positive_8413rr",
  "warning": "_warning_8413rr",
  "icon": "_icon_8413rr",
  "closeAnimation": "_closeAnimation_8413rr",
  "pop-out": "_pop-out_8413rr",
  "message": "_message_8413rr",
  "actions": "_actions_8413rr",
  "action": "_action_8413rr",
  "upperMessage": "_upperMessage_8413rr",
  "minor": "_minor_8413rr"
};
