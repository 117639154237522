export type ResultVariant = 'Ok' | 'Err';
export type ResultOk<T> = {
  variant: 'Ok';
  value: T;
};
export type ResultErr<E> = {
  variant: 'Err';
  err: E;
};
export type Result<T, E> = ResultOk<T> | ResultErr<E>;

export function isOk<T, E>(r: Result<T, E>): r is ResultOk<T> {
  return r.variant === 'Ok';
}

export function isErr<T, E>(r: Result<T, E>): r is ResultErr<E> {
  return r.variant === 'Err';
}

export function okResult<T>(value: T): ResultOk<T> {
  return { variant: 'Ok', value };
}

export function errResult<E>(err: E): ResultErr<E> {
  return { variant: 'Err', err };
}
