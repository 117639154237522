import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { OrderRequestType } from 'mobile-web/adapters/order';
import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import dayjs from 'mobile-web/lib/dayjs';
import BasketModel, { pushBasketPayload } from 'mobile-web/models/basket';
import BusService from 'mobile-web/services/bus';
import MwcIntl from 'mobile-web/services/mwc-intl';

// Built from the magic strings in https://github.com/ololabs/platform/blob/develop/MoboLogic/src/OrderHistory/OrderSearchResult.cs
export enum OrderSearchResultStatus {
  Complete = 'Complete',
  Failed = 'Failed',
  Canceled = 'Canceled',
  InProgress = 'In Progress',
}

export default class OrderSearchResultModel extends DS.Model {
  @service bus!: BusService;
  @service mwcIntl!: MwcIntl;

  @DS.attr('number')
  favoriteId!: number;
  @DS.attr('string')
  localTimePlaced!: string;
  @DS.attr('string')
  orderDescription!: string;
  @DS.attr('string')
  orderGuid!: string;
  @DS.attr('string')
  displayId!: string;
  @DS.attr('string')
  orderSummary!: string;
  @DS.attr('string')
  status!: OrderSearchResultStatus;
  @DS.attr('string')
  statusDescription!: string;
  @DS.attr('number')
  total!: number;
  @DS.attr('string')
  vendorSlug!: string;
  @DS.attr('string')
  vendorName!: string;
  @DS.attr('string')
  orderHash!: string;
  @DS.attr('boolean')
  canUserCheckin!: boolean;
  @DS.attr('boolean')
  canSupportArrival!: boolean;
  @DS.attr('string')
  vendorCurrency!: string;
  @DS.attr('boolean')
  isMultiConcept!: boolean;

  @memberAction<void, BasketModel>({
    type: 'post',
    urlType: OrderRequestType.Reorder,
    before() {
      return {
        sourceId: this.id,
        sourceType: 'Order',
      };
    },
    after: pushBasketPayload,
  })
  reorder!: MemberAction<void, BasketModel>;

  @computed('localTimePlaced', 'mwcIntl')
  get timePlaced(): string {
    return this.mwcIntl.relativeDateTime(dayjs(this.localTimePlaced));
  }
}
