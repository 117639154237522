import Controller from '@ember/controller';

import PayRoute from 'mobile-web/routes/pay';

export default class PayController extends Controller {
  declare model: ModelForRoute<PayRoute>;

  queryParams = [{ ticketNumber: 'ticketnumber' }, 'paid'];

  paid?: boolean;
}
