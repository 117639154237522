import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { FuzzyOptions, Searcher } from 'fast-fuzzy';

import isSome from 'mobile-web/lib/utilities/is-some';

import style from './index.m.scss';

interface FilterSearch {
  filter: (search: string, obj: unknown) => void;
}

interface FuzzySearch {
  searcher: Searcher<AnyObject, FuzzyOptions>;
}

type Args<T> = {
  // Required arguments
  items: T[];

  // Optional arguments
  onOpen?: () => void;
  onFocus?: () => void;
  onFocusOut?: (searchText: undefined | string, matchingItems: undefined | T[]) => void;
  onClose?: () => void;
  onSelect?: (search: string, items: T) => void;
  placeholderText?: string;
} & (FilterSearch | FuzzySearch);

interface Signature<T> {
  Element: HTMLDivElement;

  Args: Args<T>;

  Blocks: {
    default: [
      {
        item: T;
      }
    ];
  };
}

const MAX_RESULTS = 20;

export default class Typeahead<T> extends Component<Signature<T>> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked active = false;
  @tracked search = '';
  @tracked rootElement?: HTMLElement;

  // Getters and setters
  get matchedItems(): T[] {
    if ('filter' in this.args) {
      return this.args.items
        .filter(obj => (this.args as FilterSearch).filter(this.search, obj))
        .slice(0, MAX_RESULTS);
    } else if (this.args.searcher) {
      return this.args.searcher.search(this.search).slice(0, MAX_RESULTS);
    }
    return [];
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  onFocusIn(): void {
    this.active = true;
    this.args.onOpen?.();
    this.args.onFocus?.();
  }

  @action
  onFocusOut(): void {
    this.args.onFocusOut?.(this.search, this.matchedItems);
  }

  @action
  close(): void {
    this.active = false;
    this.args.onClose?.();
  }

  @action
  onSelect(item: T): void {
    this.args.onSelect?.(this.search, item);
    this.close();
  }

  @action
  setupOutsideClick(rootElement: HTMLElement) {
    this.rootElement = rootElement;
    document.addEventListener('click', this.handleOutsideClick);
  }

  @action
  teardownOutsideClick() {
    this.rootElement = undefined;
    document.removeEventListener('click', this.handleOutsideClick);
  }

  @action
  handleOutsideClick(event: MouseEvent) {
    if (!this.active) {
      return;
    }

    const target = event.target as HTMLElement | null;
    if (isSome(target) && !this.rootElement!.contains(target)) {
      this.close();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Typeahead: typeof Typeahead;
  }
}
