import ErrorService from 'mobile-web/services/error';

type MenuImageURLDimensions = {
  width?: number;
  height?: number;
};

export function parseMenuImageURLDimensions(
  src: string,
  error: ErrorService
): MenuImageURLDimensions {
  /**
   *  Use a try/catch on the off chance that something gets misconfigured and
   * `src` is not an absolute URL. This should theoretically never happen, but
   * if it does, we wouldn't want to crash the page because of it.
   */
  try {
    const url = new URL(src);
    const w = url.searchParams.get('w');
    const width = w ? +w : undefined;

    const h = url.searchParams.get('h');
    const height = h ? +h : undefined;

    return {
      width,
      height,
    };
  } catch {
    error.sendExternalError(new Error('Error parsing the menu image url: ' + src));
    return {};
  }
}
