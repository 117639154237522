import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import UrlTemplates from 'ember-data-url-templates';

import { host } from 'mobile-web/lib/hybrid-util';
import BasketService from 'mobile-web/services/basket';

import ApplicationAdapter from './application';

export default class BasketProductAdapter extends ApplicationAdapter.extend(UrlTemplates) {
  @service basket!: BasketService;

  coalesceFindRequests = true;
  urlTemplate = host() + '/{+namespace}/baskets/{basketGuid}/basketproducts/{basketProductId}';
  urlSegments = {
    basketGuid(
      this: BasketProductAdapter,
      _type: unknown,
      _id: unknown,
      snapshot: DS.Snapshot<'basket-product'> | DS.Snapshot<'basket-product'>[]
    ): string {
      const record = 'length' in snapshot ? snapshot[0] : snapshot;

      const basket = record.belongsTo('basket') as DS.Snapshot<'basket'> | undefined;

      if (isNone(basket) && isNone(this.basket.basket)) {
        throw new Error('Basket GUID for Basket Product Not Found');
      }

      // The line above errors if the conditions here don't hold; `!` is safe
      // accordingly.
      return basket?.record.get('guid') ?? this.basket.basket!.guid;
    },

    basketProductId(_type: unknown, _id: unknown, snapshot: DS.Snapshot<'basket-product'>): string {
      return snapshot.id;
    },

    namespace(this: BasketProductAdapter): string {
      return this.namespace;
    },
  };
}
