import { Motion, rAF } from 'ember-animated';
import { BaseOptions } from 'ember-animated/-private/motion';
import Sprite from 'ember-animated/-private/sprite';

import { animateTo, AnimationKeyframes, AnimationOptions } from 'mobile-web/lib/animation';

export interface KeyframesOptions extends BaseOptions {
  keyframes: AnimationKeyframes;
  keyframeOptions: AnimationOptions;
}

export class Keyframes extends Motion<KeyframesOptions> {
  *animate(): Generator<Promise<unknown>, void, unknown> {
    const sprite = this.sprite;

    // eslint-disable-next-line no-null/no-null
    yield animateTo(sprite.element, this.opts.keyframes || null, {
      ...this.opts.keyframeOptions,
      duration: this.duration,
    });

    yield rAF();
  }

  interrupted(): void {
    // noop
  }
}

export function keyframes(sprite: Sprite, opts: Partial<KeyframesOptions>): Promise<unknown> {
  return new Keyframes(sprite, opts).run();
}
