import Component from '@glimmer/component';

import { getNameForLoginProvider } from 'mobile-web/lib/login-provider';
import { LoginProvider } from 'mobile-web/models/bootstrap-data';

import style from './index.m.scss';

interface Args {
  // Required arguments
  provider: LoginProvider;
  onClick: Action;

  // Optional arguments
  class?: string;
}

interface Signature {
  Args: Args;
}

export default class ExternalLoginButton extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get providerName() {
    return getNameForLoginProvider(this.args.provider);
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ExternalLoginButton: typeof ExternalLoginButton;
  }
}
