/**
 * The callback signature for the `StickyObserver` constructor.
 */
export interface StickyObserverCallback {
  // eslint-disable-next-line no-use-before-define
  (entries: StickyObserverEntry[], observer: StickyObserver): void;
}

/**
 * Instances of `StickyObserverEntry` are delivered to a `StickyObserver`
 * callback in its `entries` parameter.
 */
export class StickyObserverEntry {
  /**
   * A boolean value which is `true` if the target element has
   * `position: sticky` and its containing block is scrolled such that the
   * target is offset from its position in normal flow.
   */
  readonly isStuck: boolean;

  /**
   * The `HTMLElement` whose offset changed.
   */
  readonly target: HTMLElement;

  constructor(target: HTMLElement, isStuck: boolean) {
    this.target = target;
    this.isStuck = isStuck;
  }
}

/**
 * `StickyObserver` provides a way to asynchronously observe changes to the
 * offset of sticky positioned elements.
 */
export class StickyObserver {
  private rafHandle = 0;
  private readonly callback: StickyObserverCallback;
  private readonly targets = new Map<HTMLElement, boolean | undefined>();

  /**
   * The `StickyObserver` constructor creates and returns a new `StickyObserver`
   * object.
   *
   * @param callback A function which is called when the offset of the target
   * element changes. The callback receives two parameters as input:
   *
   *  - `entries` - An array of `StickyObserverEntry` objects, each representing
   *    one offset change.
   *
   *  - `observer` - The `StickyObserver` invoking the callback.
   */
  constructor(callback: StickyObserverCallback) {
    this.queueCheck = this.queueCheck.bind(this);
    this.callback = callback;
  }

  /**
   * The `StickyObserver` method `disconnect()` stops watching all of its target
   * elements for offset changes.
   */
  disconnect() {
    for (const [target] of this.targets) {
      this.unobserve(target);
    }
  }

  /**
   * The `StickyObserver` method `observe()` adds an element to the set of
   * target elements being watched by the `StickyObserver`.
   *
   * @param target An element whose offset within its containing block is to be
   * monitored.
   */
  observe(target: HTMLElement) {
    if (this.targets.has(target)) {
      return;
    }

    if (!this.targets.size) {
      this.setupListeners();
    }

    // initialize the target with undefined so that the value doesn't equal
    // true or false during the stuck check. This guarantees that the element
    // will be initialized immediately after being observed.
    this.targets.set(target, undefined);

    this.queueCheck();
  }

  /**
   * The `StickyObserver` method `unobserve()` stops watching the specific
   * target element for offset changes.
   *
   * @param target An element that should no longer be monitored for offset
   * changes.
   */
  unobserve(target: HTMLElement) {
    this.targets.delete(target);

    if (!this.targets.size) {
      this.teardownListeners();
    }
  }

  /**
   * Check all targets for changes to their stuck state.
   */
  private check() {
    const entries = [...this.targets]
      .map(([target, previouslyStuck]) => {
        const stuck = this.isStuck(target);
        return previouslyStuck !== stuck ? new StickyObserverEntry(target, stuck) : undefined;
      })
      .filter((entry): entry is StickyObserverEntry => entry instanceof StickyObserverEntry);

    if (entries.length) {
      for (const entry of entries) {
        this.targets.set(entry.target, entry.isStuck);
      }
      this.callback.call(undefined, entries, this);
    }
  }

  /**
   * Get the current stuck status for a specific target.
   *
   * @param target The element whose stuck status should be checked.
   * @returns a boolean indicating whether the target element is currently
   * stuck.
   */
  private isStuck(target: HTMLElement): boolean {
    // if the target isn't `position: sticky` it's not in a stuck state
    if (getComputedStyle(target).position !== 'sticky') {
      return false;
    }

    // get the target's current bounds
    const bounds = target.getBoundingClientRect();

    const original = {
      position: target.style.position,
      top: target.style.top,
      left: target.style.left,
      bottom: target.style.bottom,
      right: target.style.right,
    };

    // restore the target to its pre-stuck location
    Object.assign(target.style, {
      position: 'relative',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
    });

    // get the target's pre-stuck bounds
    const relativeBounds = target.getBoundingClientRect();

    // restore the target to its stuck location
    Object.assign(target.style, original);

    // if any of the bounds don't match, the target is stuck
    return (
      bounds.top !== relativeBounds.top ||
      bounds.left !== relativeBounds.left ||
      bounds.bottom !== relativeBounds.bottom ||
      bounds.right !== relativeBounds.right
    );
  }

  /**
   * Uses leading-edge debouncing to queue up checking the sticky state of all
   * targets. Subsequent calls will be ignored until after the next animation
   * frame.
   */
  private queueCheck() {
    if (!this.rafHandle) {
      this.rafHandle = requestAnimationFrame(() => {
        this.rafHandle = 0;
        this.check();
      });
    }
  }

  /**
   * Setup event listeners for scroll and resize events to queue sticky status
   * checks.
   */
  private setupListeners() {
    window.addEventListener('resize', this.queueCheck, true);
    window.addEventListener('scroll', this.queueCheck, true);
  }

  /**
   * Teardown event listeners for scroll and resize events.
   */
  private teardownListeners() {
    window.removeEventListener('resize', this.queueCheck, true);
    window.removeEventListener('scroll', this.queueCheck, true);
  }
}
