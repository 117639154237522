import { action, get } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import TransitionContext from 'ember-animated/-private/transition-context';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { enqueueTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { getDuration } from 'mobile-web/lib/animation';
import { CustomizedChoiceModel, CustomizedProductModel } from 'mobile-web/lib/menu';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import { numberOr0 } from 'mobile-web/lib/utilities/numeric';
import BasketProductModel, { isBasketProduct } from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import FeaturesService from 'mobile-web/services/features';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService from 'mobile-web/services/group-order';
import NotificationsService, { NotificationType } from 'mobile-web/services/notifications';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

const COUNT_SHOWN_BY_DEFAULT = 3;

interface Args {
  // Required arguments
  product: CustomizedProductModel;

  // Optional arguments
  currency?: string;
  editable?: boolean;
  showChoices?: boolean;
  showRecipientName?: boolean;
  onClose?: Action;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class ProductGridProduct extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service features!: FeaturesService;
  @service globalEvents!: GlobalEventsService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service notifications!: NotificationsService;
  @service router!: RouterService;
  @service vendor!: VendorService;
  @service bus!: BusService;

  // Untracked properties
  animationDuration = getDuration(40);
  style = style;
  ids = guids(this, 'choiceList');

  // Tracked properties
  @tracked isExpanded = false;

  // Getters and setters
  get editable(): boolean {
    return this.args.editable ?? false;
  }

  get showChoices(): boolean {
    return this.args.showChoices ?? true;
  }

  get displayableChoices(): CustomizedChoiceModel[] {
    if (isBasketProduct(this.args.product)) {
      return (
        // eslint-disable-next-line ember/no-get
        get(this.args.product, 'basketChoices')?.filter(bc => bc.displayInBasket && !bc.isNew) ?? []
      );
    }

    return (
      // eslint-disable-next-line ember/no-get
      get(this.args.product, 'favoriteChoices').toArray() ?? []
    );
  }

  get shownChoices() {
    const choices = this.displayableChoices;
    return this.isExpanded ? choices : choices.slice(0, COUNT_SHOWN_BY_DEFAULT);
  }

  get hiddenChoiceCount() {
    return this.displayableChoices.length - COUNT_SHOWN_BY_DEFAULT;
  }

  get showToggleChoicesButton() {
    return this.displayableChoices.length > COUNT_SHOWN_BY_DEFAULT;
  }

  get quantity(): number {
    return isBasketProduct(this.args.product)
      ? this.args.product.savedAttributes.quantity
      : this.args.product.quantity;
  }

  get recipientName(): string {
    if (this.groupOrder.hasGroupOrder) {
      return '';
    }

    return isBasketProduct(this.args.product)
      ? this.args.product.savedAttributes.recipientName
      : this.args.product.recipient;
  }

  get specialInstructions(): string {
    return isBasketProduct(this.args.product)
      ? this.args.product.savedAttributes.specialInstructions
      : this.args.product.specialInstructions;
  }

  get showRecipientName(): boolean {
    return this.args?.showRecipientName ?? true;
  }

  get product(): ProductModel | undefined {
    if (isBasketProduct(this.args.product)) {
      return this.args.product.product?.content;
    }
    return undefined;
  }

  get canQuickAdd(): boolean {
    return this.product?.quickAddSupported ?? false;
  }

  get basketProduct(): BasketProductModel | undefined {
    return isBasketProduct(this.args.product) ? this.args.product : undefined;
  }

  // Lifecycle methods

  // Other methods
  *transition({ insertedSprites, removedSprites }: TransitionContext) {
    for (const sprite of insertedSprites) {
      sprite.startTranslatedBy(0, 30);
      fadeIn(sprite, { duration: 200 });
      move(sprite, { duration: 0 });
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite, { duration: 100 });
    }
  }

  // Tasks
  removeProductTask = taskFor(this.removeProductTaskInstance);
  @enqueueTask *removeProductTaskInstance(product: BasketProductModel) {
    const vendorSlug = product.vendor.get('slug')!;

    // Pulling references to the vendor product before the basket product is destroyed
    const vendorProduct = product.product;
    const productQuantity = product.quantity;
    const analyticsProperties = {
      [AnalyticsProperties.ProductCategory]: vendorProduct.get('category')?.name,
      [AnalyticsProperties.ProductName]: vendorProduct.get('name'),
      [AnalyticsProperties.ProductQuantity]: productQuantity,
      [AnalyticsProperties.ProductAvailableOptionGroupCount]: numberOr0(
        vendorProduct.get('optionGroups')?.length
      ),
      [AnalyticsProperties.HasVisibleCalories]:
        isSome(vendorProduct.get('vendor')?.settings.showCalories) &&
        isSome(vendorProduct.get('calorieLabel')),
      [AnalyticsProperties.VisibleLabels]: vendorProduct.get('labels')?.map(l => l.name),
      [AnalyticsProperties.HasProductImages]: !isEmpty(vendorProduct.get('images')),
      [AnalyticsProperties.HasCategoryImages]: !isEmpty(vendorProduct.get('category')?.images),
      [AnalyticsProperties.ProductBasePrice]: vendorProduct.get('baseCost'),
      [AnalyticsProperties.IsFeatured]: vendorProduct.get('isFeatured'),
      [AnalyticsProperties.IsSingleUse]: vendorProduct.get('isSingleUse'),
    };
    const serializedProduct = product.serializeForGlobalData();
    const productId = product.id;

    yield this.basket.deleteItems.perform([product]).then(() => {
      this.analytics.trackEvent(AnalyticsEvents.RemoveFromCart, () => analyticsProperties);

      this.globalEvents.trigger(GlobalEventName.RemoveFromCart, serializedProduct);

      const currentRoute = this.router.currentRoute;
      if (
        currentRoute.name === 'basket.basket-products.edit' &&
        currentRoute.params.basket_product_id === productId
      ) {
        this.router.transitionTo('menu.vendor', vendorSlug);
      } else {
        this.basket.getSmartUpsellDataIfVisible.perform();
        this.vendor.refreshRecentItemsCategoryIfVisible.perform();
      }

      const message = this.intl.t('mwc.notifications.removed', {
        quantity: productQuantity,
      });

      this.notifications.success({
        message,
        type: NotificationType.ProductRemoved,
      });
    });
  }

  // Actions and helpers
  @action
  toggleChoices() {
    this.isExpanded = !this.isExpanded;
  }

  @action
  updateQuickAddProduct() {
    this.bus.trigger('showProductModal', {
      product: this.product!,
      basketProduct: this.args.product as BasketProductModel,
      onClose: () => {
        this.basket.open();
      },
    });
  }

  @action
  edit() {
    this.analytics.trackEvent(AnalyticsEvents.ViewProductCustomization, () => {
      const product = this.product;

      if (!product) {
        // this should never happen because we can only edit a product when we have a product
        return {};
      }
      return {
        [AnalyticsProperties.ProductName]: product.name,
        [AnalyticsProperties.ProductCategory]: product.category?.name,
        [AnalyticsProperties.ProductBasePrice]: product.baseCost,
        [AnalyticsProperties.HasVisibleCalories]: !isEmpty(product.calorieLabel),
        [AnalyticsProperties.VisibleLabels]: product.labels?.map(l => l.name),
        [AnalyticsProperties.HasProductImages]: !isEmpty(product.images),
        [AnalyticsProperties.HasCategoryImages]: !isEmpty(product.category?.bannerImage),
        [AnalyticsProperties.HasVisiblePrice]: true,
        [AnalyticsProperties.ProductAvailableOptionGroupCount]: product.optionGroups?.map(
          og => og.id
        ).length,
        [AnalyticsProperties.Source]: 'Cart',
      };
    });

    this.args.onClose?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProductGrid::Product': typeof ProductGridProduct;
  }
}
