import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { MAX_EMAIL_LENGTH } from 'mobile-web/lib/customer';
import { isErr } from 'mobile-web/lib/result';
import Validation, { ValidationConfig, ValidationResult } from 'mobile-web/lib/validation';
import ChallengeService from 'mobile-web/services/challenge';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  onClose?: Action;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class PasswordResetModal extends Component<Signature> {
  // Service injections
  @service challenge!: ChallengeService;
  @service intl!: IntlService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;

  // Untracked properties
  maxEmailLength = MAX_EMAIL_LENGTH;
  style = style;

  // Tracked properties
  @tracked email = '';
  @tracked submitted = false;
  @tracked validationResult?: ValidationResult;

  // Getters and setters
  get validationConfig(): ValidationConfig<PasswordResetModal> {
    return {
      bindings: [
        {
          targetProp: 'email',
          ruleName: 'email',
          message: this.intl.t('mwc.passwordResetModal.errors.email'),
        },
      ],
    };
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  close() {
    this.args.onClose?.();
  }

  @action
  submit(event: Event) {
    event.preventDefault();

    this.validationResult = Validation.validate(this, this.validationConfig);
    if (isErr(this.validationResult)) {
      return;
    }

    taskFor(this.challenge.request).perform(async () => {
      await this.store.collectionAction('user', 'forgotPassword', { email: this.email });
      this.userFeedback.add({
        type: Type.Positive,
        title: this.intl.t('mwc.passwordResetModal.successTitle'),
        message: this.intl.t('mwc.passwordResetModal.successMessage'),
      });
      this.submitted = true;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PasswordResetModal: typeof PasswordResetModal;
  }
}
