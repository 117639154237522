import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { FavoriteRequestType } from 'mobile-web/adapters/favorite';
import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import BusService from 'mobile-web/services/bus';

import BasketModel, { pushBasketPayload } from './basket';
import FavoriteProductModel from './favorite-product';

export default class FavoriteModel extends DS.Model {
  @service intl!: IntlService;
  @service bus!: BusService;
  @service store!: DS.Store;

  @DS.attr('string')
  description!: string;
  @DS.attr('string')
  productSummary!: string;
  @DS.attr('number')
  vendorId!: number;
  @DS.attr('string')
  vendorName!: string;
  @DS.attr('string')
  vendorSlug!: string;
  @DS.attr('boolean')
  isDisabled!: boolean;
  @DS.attr('string')
  disabledMessage?: string;

  @DS.hasMany('favorite-product', { async: false })
  favoriteProducts!: DS.ManyArray<FavoriteProductModel>;

  @memberAction<void, BasketModel>({
    type: 'post',
    urlType: FavoriteRequestType.Basket,
    before() {
      return {
        sourceId: this.id,
        sourceType: 'OrderFave',
      };
    },
    after: pushBasketPayload,
  })
  reorder!: MemberAction<void, BasketModel>;

  @computed('disabledMessage', 'intl', 'isDisabled', 'productSummary')
  get summary(): string {
    return this.isDisabled
      ? isEmpty(this.disabledMessage)
        ? this.intl.t('mwc.basket.disabledMessage')
        : this.disabledMessage!
      : this.productSummary;
  }
}
