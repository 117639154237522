export default {
  "body": "_body_2psv6u",
  "modalContent": "_modalContent_2psv6u",
  "statusHeader": "_statusHeader_2psv6u",
  "tableHeader": "_tableHeader_2psv6u",
  "row": "_row_2psv6u",
  "time": "_time_2psv6u",
  "buttons": "_buttons_2psv6u",
  "backButton": "_backButton_2psv6u"
};
