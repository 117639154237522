import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import fade from 'ember-animated/transitions/fade';

import { getDuration } from 'mobile-web/lib/animation';
import { HandoffMode, isAtStoreMode, isDeliveryMode } from 'mobile-web/lib/order-criteria';
import { Calendar, Weekday, CalendarType, DailySchedule } from 'mobile-web/lib/time/calendar';
import { hoursForDay } from 'mobile-web/lib/time/weekly-schedule';
import isSome from 'mobile-web/lib/utilities/is-some';
import Vendor from 'mobile-web/models/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  calendar: Calendar;
  vendor: Vendor;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class VendorInfoModalCalendar extends Component<Signature> {
  // Service injections
  @service store!: DS.Store;

  // Untracked properties
  animationDuration = getDuration(400);
  transition = fade;
  style = style;

  // Tracked properties

  // Getters and setters
  get weekDay(): Weekday {
    return this.args.vendor.weeklySchedule.currentWeekDay;
  }

  get currentSchedule(): string {
    return hoursForDay(this.weekDay, this.args.calendar);
  }

  get handoffMode(): HandoffMode | undefined {
    if (this.args.calendar.scheduleDescription === CalendarType.Business) {
      return 'CounterPickup';
    }
    return this.store
      .peekAll('handoff-mode')
      .find(h => h.get('label') === this.args.calendar.scheduleDescription)
      ?.get('type');
  }

  get handoffMinimum(): number {
    if (isSome(this.handoffMode)) {
      const minimumDelivery = this.args.vendor.minimumDeliveryOrder;
      const minimumPickup = this.args.vendor.minimumPickupOrder;
      if (isDeliveryMode(this.handoffMode)) {
        return minimumDelivery;
      }
      if (isAtStoreMode(this.handoffMode)) {
        return minimumPickup;
      }
    }
    return 0;
  }

  get sortedSchedule(): DailySchedule[] {
    const currentIdx = this.args.calendar.schedule.findIndex(s => s.weekDay === this.weekDay);

    return [
      ...this.args.calendar.schedule.slice(currentIdx),
      ...this.args.calendar.schedule.slice(0, currentIdx),
    ];
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'VendorInfoModal::Calendar': typeof VendorInfoModalCalendar;
  }
}
