import Application from '@ember/application';

import { App } from '@capacitor/app';
// @todo: leaving this here for when the plugin starts working with ios. What we're doing below is actually deprecated.
// import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { RaygunRumPayload } from 'global';

import ENV from 'mobile-web/config/environment';
import { channelSlug } from 'mobile-web/lib/hybrid-util';
import isSome from 'mobile-web/lib/utilities/is-some';

/** We need to tweak some URLs so that all our white-label sites can be treated as one site. */
function fixRumPayload(payload: RaygunRumPayload): RaygunRumPayload {
  payload.eventData.forEach(e => {
    if (Array.isArray(e.data)) {
      e.data.forEach(d => {
        // RUM grouping docs: https://raygun.com/docs/pulse/path-segment-rules
        // According to the docs, the following rules should have been OK:
        //     /menu/*
        //     */menu/*
        //     https://*/menu/*
        // But I tried all of them, and they didn't work.
        // In the end, I had to resort to this manual approach.
        if (d.url.includes('/menu')) {
          d.url = d.url.replace(/\/menu\/[^/]+/g, '/menu/*');
        }
        // We can't use the grouping rules here because
        // we specifically want to keep vendors/regions and vendors/search separate,
        // but we want to group vendors/{vendor-slug}
        if (
          d.url.includes('/api/vendors') &&
          !d.url.endsWith('/api/vendors/regions') &&
          !d.url.endsWith('/api/vendors/search')
        ) {
          d.url = d.url.replace(/\/api\/vendors\/[^/]+/g, '/api/vendors/*');
        }

        if (d.url.includes('/api/bootstrapData/')) {
          d.url = d.url.replace(/\/api\/bootstrapData\/[^/]+/g, '/api/bootstrapData/*');
        }
      });
    }
  });
  return payload;
}

export async function initialize(application: Application) {
  // This causes side effects - the rg4js global is defined in vendor/raygun/raygun.js
  import('raygun4js');

  if (ENV.enableRaygun && isSome(ENV.RAYGUN_KEY)) {
    rg4js('apiKey', ENV.RAYGUN_KEY);

    // MWC-2068: Disable RUM if the user agent contains "GSA", which is sent by the Google Search app
    // Data sent by this app has heavily skewed reported render times (10-20 seconds).
    // This data is not actionable, so filter it out.

    rg4js('options', {
      ignoreAjaxError: true,
      // "GSA" means we're in a web view within the Google Search App.
      // It has terrible performance and skews our RUM numbers.
      disablePulse: navigator.userAgent.includes('GSA/'),
      // https://raygun.com/documentation/language-guides/javascript/crash-reporting/script-errors/#unhandled-promise-rejections
      // Unhandled promise rejections don't actually prevent the app from working correctly,
      // and any asynchronous errors within Ember should be caught in Ember.onerror.
      // So these kinds of errors are most likely from GTM or something.
      captureUnhandledRejections: false,
      // https://raygun.com/documentation/language-guides/javascript/crash-reporting/script-errors/#browser-extension-errors
      ignore3rdPartyErrors: true,
      pulseCustomLoadTimeEnabled: true,
    });
    // allow errors from Prod, Prod failover, Staging, ODE, Sandbox, Dev
    rg4js('whitelistCrossOriginDomains', [
      'static.olocdn.net',
      'dc9mv3qymldza.cloudfront.net',
      'olocdnstaging',
      `olo-on-demand`,
      'olocdnsandbox',
      'olocdndev',
    ]);
    const tags = [channelSlug()];
    if (ENV.isHybrid) {
      application.deferReadiness();
      const app = await App.getInfo();
      // @todo: there are errors being thrown that this plugin doesn't yet support ios
      // We've falled back to the deprecated way to get platform until it is supported
      // const device = await Device.getInfo();
      application.advanceReadiness();
      tags.push(
        'hybrid',
        `build-${app.build}`,
        `platform-${Capacitor.platform}`,
        `name-${app.name}`
      );
    }
    if (window.Olo.appVersion) {
      rg4js('setVersion', window.Olo.appVersion);
    }
    rg4js('withTags', tags);
    rg4js('enableCrashReporting', true);
    rg4js('onBeforeSendRUM', payload => fixRumPayload(payload));
    rg4js('saveIfOffline', true);

    rg4js('groupingKey', payload => {
      const message = payload.Details.Error.Message;
      if (/Loading chunk \d+ failed/.test(message)) {
        return 'Loading chunk failed';
      }
      return undefined;
    });
  }
}

export default {
  name: 'raygun',
  initialize,
};
